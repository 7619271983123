import DomPurify, { DOMPurifyI } from 'dompurify';

// eslint-disable-next-line import/no-mutable-exports
let domPurify: DOMPurifyI;
if (typeof window === 'undefined') {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { JSDOM } = require('jsdom');
  const { window } = new JSDOM('');
  domPurify = DomPurify(window);
} else {
  domPurify = DomPurify();
}

// Hook for opening sanitized external links in new tab without affecting security
domPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.hasAttribute('target')) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

export default domPurify;
