import { MultipleFeatureStateType } from './types';

type MultipleFeatureSliceSelectorType<S, R> = (state: S) => MultipleFeatureStateType<R>;

export const createMultipleFeatureErrorByIdSelector =
  <S, R>(sliceSelector: MultipleFeatureSliceSelectorType<S, R>) =>
  (state: S, id: number) =>
    sliceSelector(state).errors[id];

export const createMultipleFeatureStateByIdSelectors =
  <S, R>(sliceSelector: MultipleFeatureSliceSelectorType<S, R>) =>
  (state: S, id: number) => {
    const slice = sliceSelector(state);
    return {
      isPending: slice.isPending[id],
      isFulfilled: slice.isFulfilled[id],
      result: slice.results[id],
      error: slice.errors[id],
    };
  };
