import { RecommendedCity } from 'api/general/models';
import { EntityType } from 'store/entities/types';
import { createAction } from 'store/utils';
import { actionsFactories } from '../utils';

export const detectMarket = actionsFactories.createSingleFeatureActionsFactory<never, EntityType<RecommendedCity>>()(
  'DETECT_MARKET_REQUEST',
  'DETECT_MARKET_SUCCESS',
  'DETECT_MARKET_FAILURE',
  'DETECT_MARKET_RESET',
);

export const selectMarket = createAction('SELECT_MARKET', (market: string) => ({
  payload: {
    market,
  },
}));

export const setSelectedMarket = createAction('SET_SELECTED_MARKET', (market: string) => ({
  payload: {
    market,
  },
}));

export const resetSelectedMarket = createAction('RESET_SELECTED_MARKET');
