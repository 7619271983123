import { select, takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { AdminEventsSelectors } from 'store/selectors';
import * as actions from './actions';

const handleGetadminEventTicketsStatistics = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminEventTicketsStatistics,
  request: AdminOrganizationsApi.getAdminEventTicketsStatistics,
  requestArgsBuilder: (action) => {
    const { organizationId, ...params } = action.payload.params;
    return [organizationId, action.payload.id, { params }];
  },
  transformResponse: function* transformResponse(response, action) {
    yield select(AdminEventsSelectors.adminEventStateById, Number(action.payload.id));
    return { id: action.payload.id, tickets_ordered: response };
  },
});

export default function* adminEventTicketsStatistics() {
  yield takeEvery(actions.getAdminEventTicketsStatistics.request.type, handleGetadminEventTicketsStatistics);
}
