import { AllAttendeesCustomQuestion } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AllAttendeesCustomQuestion>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminAllAttendeesQuestionsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminAllAttendeesQuestions.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminAllAttendeesQuestions.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminAllAttendeesQuestions.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminAllAttendeesQuestions.reset.type]: actionsHandlers.handleGetManyReset,

  [actions.createAdminAllAttendeesQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminAllAttendeesQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminAllAttendeesQuestion.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.createAdminAllAttendeesQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,

  [actions.updateAdminAllAttendeesQuestion.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminAllAttendeesQuestion.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminAllAttendeesQuestion.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.updateAdminAllAttendeesQuestion.success.type]: actionsHandlers.handleUpdateOneSuccess,

  [actions.cloneAdminAllAttendeesQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.cloneAdminAllAttendeesQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.cloneAdminAllAttendeesQuestion.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.cloneAdminAllAttendeesQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,

  [actions.deleteAdminAllAttendeesQuestion.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminAllAttendeesQuestion.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminAllAttendeesQuestion.failure.type]: actionsHandlers.handleDeleteOneFailure,

  [actions.updateAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const question: any = draft.byId[action.payload.params.questionId];
    if (question) {
      const index = question.options.findIndex((question: any) => question.id === action.payload.params.questionId);
      if (index > -1) {
        question.options[index] = {
          ...question.options[index],
          ...action.payload.entity,
        };
      }
    }
  },
  [actions.createAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const question: any = draft.byId[action.payload.params.questionId];
    if (question) {
      if ('options' in question) {
        question.options.push(action.payload.entity);
      } else {
        question.options = [action.payload.entity];
      }
    }
  },
  [actions.deleteAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const question: any = draft.byId[action.payload.params.questionId];
    if (question) {
      const index = question.options.findIndex((option: any) => option.id === action.payload.id);
      if (index > -1) {
        question.options.splice(index, 1);
      }
    }
  },
  [actions.hideAdminQuestion.success.type as any]: (draft: any, action: any) => {
    Object.keys(draft.byId).forEach((id) => {
      if (Number(id) === action.payload.id) {
        const question = draft.byId[id];
        question.is_hidden = action.payload.result.is_hidden;
      }
    });
  },
});

export default adminAllAttendeesQuestionsReducer;
