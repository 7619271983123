import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { additionalInstructionFieldsNames } from './fieldsNames';
import * as keyWindowNames from './keyWindowNames';
import {
  createHasTranslations,
  createMissedTranslationsCounter,
  defaultLanguage,
} from '../adminEventTranslations/selectors';

const missedTranslationsCounter = createMissedTranslationsCounter(additionalInstructionFieldsNames);

const hasDefaultLanguageTranslations = createHasTranslations(additionalInstructionFieldsNames, defaultLanguage);

export const adminAdditionalInstructionTranslationsSlice = (state: RootState) =>
  state.entities.adminAdditionalInstructionTranslations;

export const adminAdditionalInstructionTranslations = selectorsFactory.createDynamicKeyWindowEntitiesMemoizedSelector(
  adminAdditionalInstructionTranslationsSlice,
  keyWindowNames.adminEventAdditionalInstructionTranslations,
);

export const adminAdditionalInstructionTranslationsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminAdditionalInstructionTranslationsSlice,
  keyWindowNames.adminEventAdditionalInstructionTranslations,
);

export const getMissedAdminAdditionalInstructionTranslations = createSelector(
  adminAdditionalInstructionTranslations,
  (translations) => translations.reduce((count, translation) => count + missedTranslationsCounter(translation), 0),
);

export const hasAdminAdditionalInstructionDefaultLanguageTranslations = createSelector(
  adminAdditionalInstructionTranslations,
  (translations) => translations.some(hasDefaultLanguageTranslations),
);
