import PropTypes from 'prop-types';

export const QUESTION_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  TEXT: 'text',
  PARAGRAPH: 'paragraph',
  CUSTOM: 'custom',
};

export const QUESTION_SLUG = {
  EMAIL: 'email',
  ADDRESS: 'address',
  PHONE: 'phone',
  GENDER: 'gender',
  BIRTH_DATE: 'birth-date',
  FULL_NAME: 'full-name',
  POSTAL_CODE: 'postal_code',
  ZOOM_EMAIL: 'zoom-email',
  LANGUAGES_SPOKEN: 'language-spoken',
  PREFERRED_LANGUAGE: 'language-preferred',
  RELIGION: 'religion',
  JEWISH_DESCENDANTS: 'jewish-descendants',
  RELATIONSHIP_STATUS: 'relationship-status',
  CHILDREN_IN_HOUSEHOLD: 'children-in-household',
  MAIDEN_NAME: 'maiden-name',
  RELATIONSHIP_TO_BUYER: 'relationship-to-buyer',
  INTERESTS: 'interests',
};

export const QuestionOptionType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  question: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  has_details: PropTypes.bool,
});

export const QuestionType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  ticket: PropTypes.number,
  addon: PropTypes.number,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(QUESTION_TYPE)),
  slug: PropTypes.oneOf(['', ...Object.values(QUESTION_SLUG)]),
  options: PropTypes.arrayOf(QuestionOptionType).isRequired,
  is_event_specific_question: PropTypes.bool.isRequired,
  is_required: PropTypes.bool.isRequired,
});
