import { Tag } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';

export const getWhatsIncludedTags = actionsFactories.createGetManyActionsFactory<EntityType<Tag>>()(
  'GET_WHATS_INCLUDED_TAGS_REQUEST',
  'GET_WHATS_INCLUDED_TAGS_SUCCESS',
  'GET_WHATS_INCLUDED_TAGS_FAILURE',
  'GET_WHATS_INCLUDED_TAGS_RESET',
  keyWindowNames.whatsIncluded,
);
