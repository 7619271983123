import { EventVideoDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventVideoDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventVideosReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventVideos.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventVideos.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventVideos.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventVideos.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminEventVideo.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminEventVideo.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminEventVideo.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminEventVideo.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminEventVideo.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminEventVideo.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminEventVideo.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminEventVideo.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminEventVideo.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEventVideo.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEventVideo.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminEventVideosReducer;
