import { createStructuredSelector } from 'reselect';
import { EntitiesStateType, EntityType, PaginatedKeyWindowType, AdditionalInfo } from './types';
import { defaultKeyWindowName } from './shared';

type DynamicKeyWindowType = (...args: any[]) => string;
type SliceSelectorType<S, E extends EntityType> = (state: S) => EntitiesStateType<E>;

const makeStaticKeyWindowSelector =
  <K extends string, F extends <S, R>(state: S, keyWindowName: K) => R>(
    selector: F,
    keyWindowName: K,
  ): ((state: Parameters<F>[0]) => ReturnType<F>) =>
  (state) =>
    selector(state, keyWindowName);

const makeDynamicKeyWindowSelector =
  <K extends DynamicKeyWindowType, F extends <S, R>(state: S, keyWindowName: string) => R>(
    selector: F,
    keyWindowName: K,
  ): ((state: Parameters<F>[0], ...args: Parameters<K>) => ReturnType<F>) =>
  (state, ...args) =>
    selector(state, keyWindowName(...args));

export const createCreatingStateSelector = <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) => {
  const selectors = {
    isCreating: (state: S) => sliceSelector(state).isCreating,
    isSuccessfullyCreated: (state: S) => sliceSelector(state).isSuccessfullyCreated,
    creatingError: (state: S) => sliceSelector(state).creatingError,
  };

  return createStructuredSelector<S, { [K in keyof typeof selectors]: ReturnType<(typeof selectors)[K]> }>(selectors);
};

export const createChangingStateSelector = <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) => {
  const selectors = {
    isCreating: (state: S) => sliceSelector(state).isCreating,
    isUpdating: (state: S) => sliceSelector(state).isUpdating,
    isDeleting: (state: S) => sliceSelector(state).isDeleting,
    isSuccessfullyCreated: (state: S) => sliceSelector(state).isSuccessfullyCreated,
    isSuccessfullyUpdated: (state: S) => sliceSelector(state).isSuccessfullyUpdated,
    creatingError: (state: S) => sliceSelector(state).creatingError,
    updatingErrors: (state: S) => sliceSelector(state).updatingErrors,
    deletingErrors: (state: S) => sliceSelector(state).deletingErrors,
  };

  return createStructuredSelector<S, { [K in keyof typeof selectors]: ReturnType<(typeof selectors)[K]> }>(selectors);
};

export const createEntitiesByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S) =>
    sliceSelector(state).byId;

export const createKeyWindowEntitiesSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) => {
    const slice = sliceSelector(state);
    const keyWindow = slice[keyWindowName];
    return keyWindow ? keyWindow.ids.map((id) => slice.byId[id]) : [];
  };

export const createKeyWindowEntitiesMemoizedSelector = <S, E extends EntityType>(
  sliceSelector: SliceSelectorType<S, E>,
  cacheSize = 100,
) => {
  const map = new Map<string, E[]>();
  let lastSlice: EntitiesStateType<E> = null;
  let lastKeyWindowName: string = null;

  return <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) => {
    if (!map.has(keyWindowName)) {
      if (map.size === cacheSize) {
        const earliest = map.keys().next().value;
        map.delete(earliest);
      }

      map.set(keyWindowName, []);
    }

    const slice = sliceSelector(state);
    const entities = map.get(keyWindowName);
    const keyWindow = slice[keyWindowName];

    if (!keyWindow) return entities;
    if (lastSlice === slice && lastKeyWindowName === keyWindowName) return entities;

    lastSlice = slice;
    lastKeyWindowName = keyWindowName;

    const { ids } = keyWindow;
    let changed = ids.length !== entities.length;

    ids.forEach((id, index) => {
      const entity = slice.byId[id];
      if (entities[index] !== entity) {
        entities[index] = entity;
        changed = true;
      }
    });

    if (changed) map.set(keyWindowName, entities.slice(0, ids.length));
    return map.get(keyWindowName);
  };
};

export const createKeyWindowIsFetchingSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) =>
    sliceSelector(state)[keyWindowName]?.isFetching;

export const createKeyWindowIsFetchedSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) =>
    sliceSelector(state)[keyWindowName]?.isFetched;

export const createKeyWindowFetchingErrorSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) =>
    sliceSelector(state)[keyWindowName]?.fetchingError;

export const createKeyWindowCountSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) => {
    const keyWindow = sliceSelector(state)[keyWindowName] as PaginatedKeyWindowType;
    return keyWindow?.count || 0;
  };

export const createKeyWindowPaginationSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) => {
    const keyWindow = sliceSelector(state)[keyWindowName] as PaginatedKeyWindowType;
    return {
      count: keyWindow?.count || 0,
      hasNext: keyWindow ? keyWindow.ids.length < keyWindow.count : true,
    };
  };

export const createKeyWindowAdditionalInfoMemoizedSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) => {
    const keyWindow = sliceSelector(state)[keyWindowName] as AdditionalInfo;
    return keyWindow?.additionalInfo;
  };

export const createKeyWindowStateMemoizedSelector = <S, E extends EntityType>(
  sliceSelector: SliceSelectorType<S, E>,
) => {
  const selectors = {
    entities: createKeyWindowEntitiesMemoizedSelector(sliceSelector),
    count: createKeyWindowCountSelector(sliceSelector),
    isFetching: createKeyWindowIsFetchingSelector(sliceSelector),
    isFetched: createKeyWindowIsFetchedSelector(sliceSelector),
    fetchingError: createKeyWindowFetchingErrorSelector(sliceSelector),
    additionalInfo: createKeyWindowAdditionalInfoMemoizedSelector(sliceSelector),
  };

  return createStructuredSelector<S, { [K in keyof typeof selectors]: ReturnType<(typeof selectors)[K]> }>(selectors);
};

export const createKeyWindowFetchingStateSelector = <S, E extends EntityType>(
  sliceSelector: SliceSelectorType<S, E>,
) => {
  const selectors = {
    isFetching: createKeyWindowIsFetchingSelector(sliceSelector),
    isFetched: createKeyWindowIsFetchedSelector(sliceSelector),
    fetchingError: createKeyWindowFetchingErrorSelector(sliceSelector),
  };

  return createStructuredSelector<S, { [K in keyof typeof selectors]: ReturnType<(typeof selectors)[K]> }>(selectors);
};

export const createKeyWindowHasEntitiesSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) =>
    sliceSelector(state)[keyWindowName]?.ids?.length > 0;

export const createKeyWindowEntitiesIdsSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  <K extends string>(state: S, keyWindowName: K = defaultKeyWindowName as K) =>
    sliceSelector(state)[keyWindowName]?.ids || [];

export const createStaticKeyWindowEntitiesSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowEntitiesSelector(sliceSelector), keyWindowName);

export const createStaticKeyWindowHasEntitiesSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowHasEntitiesSelector(sliceSelector), keyWindowName);

export const createStaticKeyWindowEntitiesIdsSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowEntitiesIdsSelector(sliceSelector), keyWindowName);

export const createStaticKeyWindowEntitiesMemoizedSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowEntitiesMemoizedSelector(sliceSelector), keyWindowName);

export const createStaticKeyWindowIsFetchingSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowIsFetchingSelector(sliceSelector), keyWindowName);

export const createStaticKeyWindowFetchingErrorSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowFetchingErrorSelector(sliceSelector), keyWindowName);

export const createStaticKeyWindowPaginationSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowPaginationSelector(sliceSelector), keyWindowName);

export const createStaticKeyWindowStateMemoizedSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowStateMemoizedSelector(sliceSelector), keyWindowName);

export const createStaticKeyWindowFetchingStateSelector = <S, E extends EntityType, K extends string>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName?: K,
) => makeStaticKeyWindowSelector(createKeyWindowFetchingStateSelector(sliceSelector), keyWindowName);

export const createDynamicKeyWindowEntitiesSelector = <S, E extends EntityType, K extends DynamicKeyWindowType>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName: K,
) => makeDynamicKeyWindowSelector(createKeyWindowEntitiesSelector(sliceSelector), keyWindowName);

export const createDynamicKeyWindowHasEntitiesSelector = <S, E extends EntityType, K extends DynamicKeyWindowType>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName: K,
) => makeDynamicKeyWindowSelector(createKeyWindowHasEntitiesSelector(sliceSelector), keyWindowName);

export const createDynamicKeyWindowEntitiesIdsSelector = <S, E extends EntityType, K extends DynamicKeyWindowType>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName: K,
) => makeDynamicKeyWindowSelector(createKeyWindowEntitiesIdsSelector(sliceSelector), keyWindowName);

export const createDynamicKeyWindowEntitiesMemoizedSelector = <S, E extends EntityType, K extends DynamicKeyWindowType>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName: K,
) => makeDynamicKeyWindowSelector(createKeyWindowEntitiesMemoizedSelector(sliceSelector), keyWindowName);

export const createDynamicKeyWindowPaginationSelector = <S, E extends EntityType, K extends DynamicKeyWindowType>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName: K,
) => makeDynamicKeyWindowSelector(createKeyWindowPaginationSelector(sliceSelector), keyWindowName);

export const createDynamicKeyWindowStateMemoizedSelector = <S, E extends EntityType, K extends DynamicKeyWindowType>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName: K,
) => makeDynamicKeyWindowSelector(createKeyWindowStateMemoizedSelector(sliceSelector), keyWindowName);

export const createDynamicKeyWindowFetchingStateSelector = <S, E extends EntityType, K extends DynamicKeyWindowType>(
  sliceSelector: SliceSelectorType<S, E>,
  keyWindowName: K,
) => makeDynamicKeyWindowSelector(createKeyWindowFetchingStateSelector(sliceSelector), keyWindowName);

export const createEntityByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).byId[id];

export const createHasEntityByIdSelector = <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) => {
  const selector = createEntityByIdSelector(sliceSelector);
  return (state: S, id: number) => Boolean(selector(state, id));
};

export const createIsFetchingByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).isFetching[id];

export const createIsFetchedByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).isFetched[id];

export const createIsUpdatingByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).isUpdating[id];

export const createIsSuccessfullyUpdatedById =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).isSuccessfullyUpdated[id];

export const createIsDeletingByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).isDeleting[id];

export const createFetchingErrorByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).fetchingErrors[id];

export const createUpdatingErrorByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).updatingErrors[id];

export const createDeletingErrorByIdSelector =
  <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) =>
  (state: S, id: number) =>
    sliceSelector(state).deletingErrors[id];

export const createEntityStateByIdSelector = <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) => {
  const selectors = {
    entity: createEntityByIdSelector(sliceSelector),
    isFetching: createIsFetchingByIdSelector(sliceSelector),
    isFetched: createIsFetchedByIdSelector(sliceSelector),
    fetchingError: createFetchingErrorByIdSelector(sliceSelector),
  };

  return createStructuredSelector<S, number, { [K in keyof typeof selectors]: ReturnType<(typeof selectors)[K]> }>(
    selectors,
  );
};

export const createUpdatingStateByIdSelector = <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) => {
  const selectors = {
    isUpdating: createIsUpdatingByIdSelector(sliceSelector),
    isSuccessfullyUpdated: createIsSuccessfullyUpdatedById(sliceSelector),
    updatingError: createUpdatingErrorByIdSelector(sliceSelector),
  };

  return createStructuredSelector<S, number, { [K in keyof typeof selectors]: ReturnType<(typeof selectors)[K]> }>(
    selectors,
  );
};

export const createDeletingStateByIdSelector = <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) => {
  const selectors = {
    isDeleting: createIsDeletingByIdSelector(sliceSelector),
    deletingError: createDeletingErrorByIdSelector(sliceSelector),
  };

  return createStructuredSelector<S, number, { [K in keyof typeof selectors]: ReturnType<(typeof selectors)[K]> }>(
    selectors,
  );
};

export const createFetchingStateByIdSelector = <S, E extends EntityType>(sliceSelector: SliceSelectorType<S, E>) => {
  const selectors = {
    isFetching: createIsFetchingByIdSelector(sliceSelector),
    isFetched: createHasEntityByIdSelector(sliceSelector),
    fetchingError: createDeletingErrorByIdSelector(sliceSelector),
  };

  return createStructuredSelector<S, number, { [K in keyof typeof selectors]: ReturnType<(typeof selectors)[K]> }>(
    selectors,
  );
};
