import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import confirmCardPaymentReducer from './confirmCardPaymentReducer';
import createOrderReducer from './createOrderReducer';
import donationReducer from './donationReducer';
import payOrderReducer from './payOrderReducer';
import setupOfflinePaymentStripeReducer from './setupOfflinePaymentStripeReducer';
import paymentReducer from './paymentReducer';
import promoCodeReducer from './promoCodeReducer';
import registrationReducer from './registrationReducer';
import checkAtendeeReducer from './checkAtendeeReducer';
import spreedlyCheckPurchaseStatusReducer from './spreedlyCheckPurchaseStatus';
import spreedlyPayOrderReducer from './spreedlyPayOrderReducer';
import setupOfflinePaymentSpreedlyReducer from './setupOfflinePaymentSpreedlyReducer';

export default combineReducers({
  cart: cartReducer,
  confirmCardPayment: confirmCardPaymentReducer,
  createOrder: createOrderReducer,
  donation: donationReducer,
  payOrder: payOrderReducer,
  setupOfflinePaymentStripe: setupOfflinePaymentStripeReducer,
  setupOfflinePaymentSpreedly: setupOfflinePaymentSpreedlyReducer,
  payment: paymentReducer,
  promoCode: promoCodeReducer,
  registration: registrationReducer,
  checkAttendee: checkAtendeeReducer,
  spreedlyCheckPurchaseStatus: spreedlyCheckPurchaseStatusReducer,
  spreedlyPayOrder: spreedlyPayOrderReducer,
});
