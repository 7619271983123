import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const orgTypesSlice = (state: RootState) => state.entities.orgTypes;

export const orgTypes = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(
  orgTypesSlice,
  keyWindowNames.orgTypes,
);

export const orgTypesState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(
  orgTypesSlice,
  keyWindowNames.orgTypes,
);

export const hasOrgTypes = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(
  orgTypesSlice,
  keyWindowNames.orgTypes,
);
