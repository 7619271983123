// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AppliedPromoCode,
  EventShort,
  OrderAddOnShort,
  OrderDonation,
  Refund,
  StripeOrderCheckout,
  UserShort,
  UserTicketOrder,
} from '.';

/**
 * @export
 * @interface OrderAdminDetail
 */
export interface OrderAdminDetail {
  /**
   * @type {string}
   * @memberof OrderAdminDetail
   */
  readonly id?: string;
  /**
   * @type {UserShort}
   * @memberof OrderAdminDetail
   */
  user?: UserShort;
  /**
   * @type {string}
   * @memberof OrderAdminDetail
   */
  status: OrderAdminDetailStatusEnum;
  /**
   * @type {number}
   * @memberof OrderAdminDetail
   */
  charged_amount?: number;
  /**
   * @type {string}
   * @memberof OrderAdminDetail
   */
  charged_currency?: OrderAdminDetailChargedCurrencyEnum;
  /**
   * @type {Array<AppliedPromoCode>}
   * @memberof OrderAdminDetail
   */
  applied_promocodes: Array<AppliedPromoCode>;
  /**
   * @type {EventShort}
   * @memberof OrderAdminDetail
   */
  event?: EventShort;
  /**
   * @type {Array<OrderAddOnShort>}
   * @memberof OrderAdminDetail
   */
  readonly addons?: Array<OrderAddOnShort>;
  /**
   * @type {Array<UserTicketOrder>}
   * @memberof OrderAdminDetail
   */
  readonly tickets?: Array<UserTicketOrder>;
  /**
   * @type {Array<OrderDonation>}
   * @memberof OrderAdminDetail
   */
  readonly donations?: Array<OrderDonation>;
  /**
   * @type {Array<StripeOrderCheckout>}
   * @memberof OrderAdminDetail
   */
  readonly payment_data?: Array<StripeOrderCheckout>;
  /**
   * @type {Refund}
   * @memberof OrderAdminDetail
   */
  refund?: Refund;
  /**
   * @type {string}
   * @memberof OrderAdminDetail
   */
  readonly created?: string;
  /**
   * @type {string}
   * @memberof OrderAdminDetail
   */
  readonly updated?: string;
  /**
   * @type {UserShort}
   * @memberof OrderAdminDetail
   */
  created_by?: UserShort;
}

/**
 * @export
 * @enum {string}
 */
export enum OrderAdminDetailStatusEnum {
  InProgress = 'in_progress',
  Pending = 'pending',
  Canceled = 'canceled',
  Abandoned = 'abandoned',
  Paid = 'paid',
  Refunded = 'refunded',
  WaitingForOfflinePayment = 'waiting_for_offline_payment',
  PaidOffline = 'paid_offline',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderAdminDetailChargedCurrencyEnum {
  Usd = 'usd',
  Cad = 'cad',
}
