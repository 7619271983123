// questions doesn't have order, but need to place full name question as first
export const sortQuestions = (questions: any[]) =>
  questions.reduce((result, question) => {
    if (question.slug === 'full-name') {
      result.unshift(question);
    } else {
      result.push(question);
    }

    return result;
  }, []);
