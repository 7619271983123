import { OrganizationSettings } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationSettings>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationSettingReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationSetting.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminOrganizationSetting.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminOrganizationSetting.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminOrganizationSetting.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.updateAdminOrganizationSetting.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminOrganizationSetting.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminOrganizationSetting.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminOrganizationSetting.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default adminOrganizationSettingReducer;
