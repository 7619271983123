import { AdminOrganizationAutoCompleteType } from 'api/types';
import { actionsFactories } from 'store/entities/utils';
import { GetAdminOrganizationsAutoCompleteParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminOrganizationsAutoComplete = actionsFactories.createGetPaginatedManyActionsFactory<
  AdminOrganizationAutoCompleteType,
  GetAdminOrganizationsAutoCompleteParamsType
>()(
  'GET_ADMIN_ORGANIZATIONS_AUTOCOMPLETE_REQUEST',
  'GET_ADMIN_ORGANIZATIONS_AUTOCOMPLETE_SUCCESS',
  'GET_ADMIN_ORGANIZATIONS_AUTOCOMPLETE_FAILURE',
  'GET_ADMIN_ORGANIZATIONS_AUTOCOMPLETE_RESET',
  keyWindowNames.adminEventMentions,
);
