import { get, post } from './client';
import { buildPath, toJson } from './utils';
import { ORDERS_PATH } from './constants';
import { GeneralListType, IdType } from './types';
import { OrderList } from './general/models';

const GENERATE_TICKET_PDF_PATH = 'generate-ticket-pdf';
const GENERATE_ADDON_PDF_PATH = 'generate-addon-pdf';
const RESEND_EMAIL_PATH = 'resend-email';

export const getOrders = (options: any): Promise<GeneralListType<OrderList>> =>
  get(buildPath(ORDERS_PATH), options).then(toJson);

export const getOrder = (orderId: IdType, options: any): Promise<OrderList> =>
  get(buildPath(ORDERS_PATH, orderId), options).then(toJson);

export const getTicketPdf = (orderId: IdType, ticketId: IdType, options: any) =>
  get(buildPath(ORDERS_PATH, orderId, GENERATE_TICKET_PDF_PATH, ticketId), options);

export const getAddonPdf = (orderId: IdType, addonId: IdType, options: any) =>
  get(buildPath(ORDERS_PATH, orderId, GENERATE_ADDON_PDF_PATH, addonId), options);

export const resendEmail = (orderId: IdType, options: any) =>
  post(buildPath(ORDERS_PATH, orderId, RESEND_EMAIL_PATH), options);
