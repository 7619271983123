import { buildPath } from './utils';

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const API_GENERAL_PATH = buildPath(
  process.env.NEXT_PUBLIC_API_GENERAL_PATH,
  process.env.NEXT_PUBLIC_API_GENERAL_VERSION,
);

export const API_ADMIN_PATH = buildPath(
  process.env.NEXT_PUBLIC_API_ADMIN_PATH,
  process.env.NEXT_PUBLIC_API_ADMIN_VERSION,
);

export const API_ADMIN_PATH_V2 = buildPath(process.env.NEXT_PUBLIC_API_ADMIN_PATH, 'v2');

export const API_GENERAL_URL = buildPath(API_BASE_URL, API_GENERAL_PATH);

export const API_ADMIN_URL = buildPath(API_BASE_URL, API_ADMIN_PATH);
export const API_ADMIN_URL_V2 = buildPath(API_BASE_URL, API_ADMIN_PATH_V2);

export const AUTH_PATH = buildPath(API_GENERAL_URL, 'auth');

export const EVENTS_PATH = buildPath(API_GENERAL_URL, 'events');

export const TICKET_PATH = buildPath(API_GENERAL_URL, 'tickets');

export const ORDERS_PATH = buildPath(API_GENERAL_URL, 'orders');

export const NOT_COMPLETED_ORDER_DATA_PATH = buildPath(API_GENERAL_URL, 'not-completed-order-data');

export const ORGANIZATIONS_PATH = buildPath(API_GENERAL_URL, 'organizations');

export const STRIPE_PAYMENT_METHODS_PATH = buildPath(API_GENERAL_URL, 'stripe-payment-methods');

export const SPREEDLY_PAYMENT_METHODS_PATH = buildPath(API_GENERAL_URL, 'spreedly-payment-methods');

export const USER_PATH = buildPath(API_GENERAL_URL, 'users');

export const UPLOAD_PATH = buildPath(API_GENERAL_URL, 'upload');

export const COUNTRIES = buildPath(API_GENERAL_URL, 'countries');

export const MARKETS = buildPath(API_GENERAL_URL, 'markets');

export const CHANNELS = buildPath(API_GENERAL_URL, 'channels');

export const ADMIN_MARKETS = buildPath(API_ADMIN_URL, 'markets');

export const DEFAULT_META_TAGS = buildPath(API_ADMIN_URL, 'default-meta-tags');

export const ADMIN_EVENTS_PATH = buildPath(API_ADMIN_URL, 'events');

export const ADMIN_MARKETS_PATH = buildPath(API_ADMIN_URL, 'markets');

export const ADMIN_EVENTS_PATH_V2 = buildPath(API_ADMIN_URL_V2, 'events');

export const ADMIN_TRANSLATIONS_PATH = buildPath(API_ADMIN_URL, 'translations');

export const ADMIN_ORGANIZATIONS_PATH = buildPath(API_ADMIN_URL, 'organizations');

export const ADMIN_EVENT_CATEGORIES_PATH = buildPath(API_ADMIN_URL, 'event-categories');

export const ADMIN_EVENT_TARGET_AGES_PATH = buildPath(API_ADMIN_URL, 'event-target-ages');

export const ADMIN_TAGS = buildPath(API_ADMIN_URL, 'tags');

export const ADMIN_DEFAULT_QUESTIONS = buildPath(API_ADMIN_URL, 'default-questions');

export const ADMIN_ZOOM = buildPath(API_ADMIN_URL, 'zoom');

export const ADMIN_USERS = buildPath(API_ADMIN_URL, 'users');

export const ADMIN_USER_ROLES = buildPath(API_ADMIN_URL, 'user-roles');

export const ADMIN_ORGANIZATIONS_AUTOCOMPLETE_PATH = buildPath(API_ADMIN_URL, 'organizations-autocomplete-search');

export const MIXPANEL_PATH = buildPath(API_GENERAL_URL, 'mixpanel-page-track');

export const ADMIN_CONTENT_TYPES = buildPath(API_ADMIN_URL, 'content-types');

export const SPREEDLY = buildPath(API_GENERAL_URL, 'spreedly');

export const ADMIN_TRUST_PROFILES = buildPath(API_ADMIN_URL, 'trust-profiles');
