import { call, put, takeEvery } from 'redux-saga/effects';
import { UserApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { GetAction } from 'store/types';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetLanguagesSpokenRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getLanguagesSpoken,
  request: UserApi.getLanguagesSpoken,
});

const handleGetJewishDescendantsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getJewishDescendants,
  request: UserApi.getJewishDescendants,
});

const handleGetOccupationsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getOccupations,
  request: UserApi.getOccupations,
});

const handleGetUserInterestsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getUserInterests,
  request: UserApi.getUserInterests,
});

function* handleUploadOccupations(action: GetAction<typeof actions.getOccupations.success>) {
  const { entities, params } = action.payload;
  if (entities.next) {
    const { page, page_size } = yield call(getNextPageParams, params.page_size, selectors.occupationsState);
    yield put(actions.getOccupations.request({ params: { ...params, page, page_size } }));
  }
}

export default function* optionsSagas() {
  yield takeEvery(actions.getLanguagesSpoken.request.type, handleGetLanguagesSpokenRequest);
  yield takeEvery(actions.getJewishDescendants.request.type, handleGetJewishDescendantsRequest);
  yield takeEvery(actions.getOccupations.request.type, handleGetOccupationsRequest);
  yield takeEvery(actions.getOccupations.success.type, handleUploadOccupations);
  yield takeEvery(actions.getUserInterests.request.type, handleGetUserInterestsRequest);
}
