import { EntityType, AdditionalInfo } from 'store/entities/types';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { AdminEventSettingsActions } from 'store/actions';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<any>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions & typeof AdminEventSettingsActions>;

const adminCCEventsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminAllCCEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminAllCCEvents.success.type]: (draft, action) => {
    actionsHandlers.handleGetPaginatedManySuccess(draft, action);
    const keyWindow = draft[action.payload.keyWindowName] as AdditionalInfo;
    const { entities } = action.payload as any;
    keyWindow.additionalInfo = {};
    keyWindow.additionalInfo.cc_approved = entities.cc_approved;
    keyWindow.additionalInfo.cc_pending = entities.cc_pending;
    keyWindow.additionalInfo.cc_rejected = entities.cc_rejected;
    keyWindow.additionalInfo.cc_expired = entities.cc_expired;
  },
  [actions.getAdminAllCCEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminAllCCEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default adminCCEventsReducer;
