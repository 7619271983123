import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';
import { AdminInvite } from '../../../../api/admin/models';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AdminInvite>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrgUserInvites = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrgUserInvites.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrgUserInvites.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrgUserInvites.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrgUserInvites.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.removeAdminOrgUserInvite.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.removeAdminOrgUserInvite.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.removeAdminOrgUserInvite.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminOrgUserInvites;
