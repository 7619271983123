import { Admin, AdminInvite, CreateAdmin, Group } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import {
  AdminGrantedPermissionsParamsType,
  AdminOrgUserParams,
  AdminUserRoleParams,
  CreateAdminUserTypeParams,
  GetAdminOrgUsersParams,
} from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminUsers = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<Admin>>()(
  'GET_ADMIN_USERS_REQUEST',
  'GET_ADMIN_USERS_SUCCESS',
  'GET_ADMIN_USERS_FAILURE',
  'GET_ADMIN_USERS_RESET',
);

export const getAdminOrgUser = actionsFactories.createGetOneActionsFactory<EntityType<Admin>, AdminOrgUserParams>()(
  'GET_ADMIN_ORG_USER_REQUEST',
  'GET_ADMIN_ORG_USER_SUCCESS',
  'GET_ADMIN_ORG_USER_FAILURE',
  'GET_ADMIN_ORG_USER_RESET',
);

export const getAdminOrgUsers = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Admin>,
  GetAdminOrgUsersParams
>()(
  'GET_ADMIN_ORG_USERS_REQUEST',
  'GET_ADMIN_ORG_USERS_SUCCESS',
  'GET_ADMIN_ORG_USERS_FAILURE',
  'GET_ADMIN_ORG_USERS_RESET',
  keyWindowNames.adminOrgUsers,
);

export const getAdminOrgUserInvites = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<AdminInvite>,
  GetAdminOrgUsersParams
>()(
  'GET_ADMIN_ORG_INVITES_REQUEST',
  'GET_ADMIN_ORG_INVITES_SUCCESS',
  'GET_ADMIN_ORG_INVITES_FAILURE',
  'GET_ADMIN_ORG_INVITES_RESET',
  keyWindowNames.adminOrgUsersInvites,
);

export const createAdminUser = actionsFactories.createCreateOneActionsFactory<
  EntityType<CreateAdmin>,
  CreateAdminUserTypeParams
>()('CREATE_ADMIN_USER_REQUEST', 'CREATE_ADMIN_USER_SUCCESS', 'CREATE_ADMIN_USER_FAILURE', 'CREATE_ADMIN_USER_RESET');

export const getAdminUser = actionsFactories.createGetOneActionsFactory<EntityType<Admin>>()(
  'GET_ADMIN_USER_REQUEST',
  'GET_ADMIN_USER_SUCCESS',
  'GET_ADMIN_USER_FAILURE',
  'GET_ADMIN_USER_RESET',
);

export const getAdminUsersGroups = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<Group>>()(
  'GET_ADMIN_USERS_GROUPS_REQUEST',
  'GET_ADMIN_USERS_GROUPS_SUCCESS',
  'GET_ADMIN_USERS_GROUPS_FAILURE',
  'GET_ADMIN_USERS_GROUPS_RESET',
);

export const getAdminUserOrganizationsPermissions = actionsFactories.createGetOneActionsFactory<
  any,
  AdminGrantedPermissionsParamsType
>()(
  'GET_ADMIN_USER_ORGANIZATIONS_PERMISSIONS_REQUEST',
  'GET_ADMIN_USER_ORGANIZATIONS_PERMISSIONS_SUCCESS',
  'GET_ADMIN_USER_ORGANIZATIONS_PERMISSIONS_FAILURE',
  'GET_ADMIN_USER_ORGANIZATIONS_PERMISSIONS_RESET',
);

export const getAdminUserEventsPermissions = actionsFactories.createGetOneActionsFactory<
  any,
  AdminGrantedPermissionsParamsType
>()(
  'GET_ADMIN_USER_EVENTS_PERMISSIONS_REQUEST',
  'GET_ADMIN_USER_EVENTS_PERMISSIONS_SUCCESS',
  'GET_ADMIN_USER_EVENTS_PERMISSIONS_FAILURE',
  'GET_ADMIN_USER_EVENTS_PERMISSIONS_RESET',
);

export const removeAdminUserRole = actionsFactories.createDeleteOneActionsFactory<AdminUserRoleParams>()(
  'REMOVE_ADMIN_USER_ROLE_REQUEST',
  'REMOVE_ADMIN_USER_ROLE_SUCCESS',
  'REMOVE_ADMIN_USER_ROLE_FAILURE',
);

export const removeAdminOrgUserRole = actionsFactories.createDeleteOneActionsFactory<AdminOrgUserParams>()(
  'REMOVE_ADMIN_ORG_USER_ROLE_REQUEST',
  'REMOVE_ADMIN_ORG_USER_ROLE_SUCCESS',
  'REMOVE_ADMIN_ORG_USER_ROLE_FAILURE',
);

export const removeAdminOrgUserInvite = actionsFactories.createDeleteOneActionsFactory<AdminOrgUserParams>()(
  'REMOVE_ADMIN_ORG_USER_INVITE_REQUEST',
  'REMOVE_ADMIN_ORG_USER_INVITE_SUCCESS',
  'REMOVE_ADMIN_ORG_USER_INVITE_FAILURE',
);
