import { select } from 'redux-saga/effects';
import * as actionsFactories from './actionsFactories';
import * as actionsHandlers from './actionsHandlers';
import * as sagasHandlersFactory from './sagasHandlersFactory';
import * as sagasHelpers from './sagasHelpers';
import * as selectorsFactory from './selectorsFactory';
import * as stateFactories from './stateFactories';

export function* getNextPageParams(
  page_size: number,
  selector: <S, E extends { entities: any[] }>(state: S, ...args: any[]) => E,
  ...selectorArgs: any[]
) {
  const { entities } = yield select(selector, ...selectorArgs);
  return {
    page: Math.ceil((entities.length + 1) / page_size),
    page_size,
  };
}

export { actionsFactories, actionsHandlers, sagasHandlersFactory, sagasHelpers, selectorsFactory, stateFactories };
