import { call, race, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

const modalWindowFeature = 'scrollbars=no,resizable=no,status=no,location=yes,toolbar=no,menubar=no';
const authWindowName = 'zoom';

const authWindowFeatures = () => {
  const { outerWidth, outerHeight } = window;
  const width = Math.min(outerWidth, 600);
  const height = Math.min(outerHeight, 500);
  const top = Math.floor((outerHeight - height) / 2);
  const left = Math.floor((outerWidth - width) / 2);

  return `width=${width},height=${height},top=${top},left=${left},${modalWindowFeature}`;
};

const createPopupCloseChannel = (popup: Window) =>
  eventChannel((emit) => {
    let timer: ReturnType<typeof setInterval> | number;

    const unsubscribe = () => {
      clearInterval(<NodeJS.Timeout>timer);
    };

    timer = setInterval(() => {
      if (popup.closed) {
        unsubscribe();
        emit(true);
      }
    }, 1000);

    return unsubscribe;
  });

const createConfirmationOauthAuthorizationChannel = (message: string) =>
  eventChannel((emit) => {
    const listener = ({ origin, data }: MessageEvent) => {
      if (origin === window.location.origin && data.type === message) {
        emit(true);
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  });

export function* handlePopupListener(url: string, message: string): any {
  const confirmationOauthAuthorizationChannel = yield call(createConfirmationOauthAuthorizationChannel, message);

  const popup = yield call(window.open, url, authWindowName, authWindowFeatures());
  if (popup) {
    const popupCloseChannel = yield call(createPopupCloseChannel, popup);

    const effects = yield race([take(confirmationOauthAuthorizationChannel), take(popupCloseChannel)]);
    const [confirmed, closed] = effects;

    if (!closed) return confirmed;
  }
}
