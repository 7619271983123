// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StripeRefund, UserShort } from '.';

/**
 * @export
 * @interface Refund
 */
export interface Refund {
  /**
   * @type {number}
   * @memberof Refund
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof Refund
   */
  status: RefundStatusEnum;
  /**
   * @type {number}
   * @memberof Refund
   */
  refund_amount?: number;
  /**
   * @type {string}
   * @memberof Refund
   */
  comment?: string;
  /**
   * @type {StripeRefund}
   * @memberof Refund
   */
  refund_transaction?: StripeRefund | null;
  /**
   * @type {UserShort}
   * @memberof Refund
   */
  created_by?: UserShort;
  /**
   * @type {string}
   * @memberof Refund
   */
  readonly created?: string;
  /**
   * @type {string}
   * @memberof Refund
   */
  readonly updated?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RefundStatusEnum {
  Pending = 'pending',
  Canceled = 'canceled',
  Failed = 'failed',
  Refunded = 'refunded',
}
