import { useEffect, useRef } from 'react';

export const useExcludedScripts = (pathname: string, excludedPaths: string[] = []) => {
  const excluded = useRef(false);
  const exclude = excludedPaths.some((path) => path === pathname);

  useEffect(() => {
    if (!exclude) {
      return () => {
        if (!excluded.current) {
          excluded.current = true;

          const scripts = document.getElementsByTagName('script');
          for (let i = 0; i < scripts.length; i += 1) {
            const script = scripts[i];
            if (script.src.startsWith('http') && !(script.integrity && script.crossOrigin)) {
              script.parentNode.removeChild(script);
            }
          }
        }
      };
    }
  }, [exclude]);

  return exclude || excluded.current;
};
