import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationDashboardSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationDashboard;

export const adminOrganizationDashboardUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(
  adminOrganizationDashboardSlice,
);

export const adminOrganizationDashboardStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationDashboardSlice,
);

export const adminOrganizationEventsStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationEventsStatistic;

export const adminOrganizationEventsStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationEventsStatisticSlice,
);

export const adminOrganizationDonationsStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationDonationsStatistic;

export const adminOrganizationDonationsStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationDonationsStatisticSlice,
);

export const adminOrganizationSalesStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationSalesStatistic;

export const adminOrganizationSalesStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationSalesStatisticSlice,
);

export const adminOrganizationTicketsStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationTicketsStatistic;

export const adminOrganizationTicketsStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationTicketsStatisticSlice,
);

export const adminOrganizationPeopleStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationPeopleStatistic;

export const adminOrganizationPeopleStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationPeopleStatisticSlice,
);

export const adminOrganizationTopOutsideOrgStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationTopOutsideOrgStatistic;

export const adminOrganizationTopOutsideOrgStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationTopOutsideOrgStatisticSlice,
);

export const adminOrganizationNeighborhoodsStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationNeighborhoodsStatistic;

export const adminOrganizationNeighborhoodsStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationNeighborhoodsStatisticSlice,
);

export const adminOrganizationGenderStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationGenderStatistic;

export const adminOrganizationGenderStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationGenderStatisticSlice,
);

export const adminOrganizationAgeStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationAgeStatistic;

export const adminOrganizationAgeStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationAgeStatisticSlice,
);

export const adminOrganizationTopInterestStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationTopInterestStatistic;

export const adminOrganizationTopInterestStatisticState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationTopInterestStatisticSlice,
  keyWindowNames.adminOrganizationTopInterest,
);

export const adminOrganizationMostActivePeopleStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationMostActivePeopleStatistic;

export const adminOrganizationMostActivePeopleStatisticStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationMostActivePeopleStatisticSlice,
);

export const adminOrganizationTopEventsStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationTopEventsStatistic;

export const adminOrganizationTopEventsStatisticState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationTopEventsStatisticSlice,
  keyWindowNames.adminOrganizationTopEvents,
);

export const adminOrganizationTicketsRegistrationStatisticSlice = (state: RootState) =>
  state.entities.adminOrganizationDashboard.adminOrganizationTicketsRegistrationStatistic;

// eslint-disable-next-line max-len
export const adminOrganizationTicketsRegistrationStatisticState =
  selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
    adminOrganizationTicketsRegistrationStatisticSlice,
    keyWindowNames.adminOrganizationTicketsRegistration,
  );
