import { select, takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { AdminEventsSelectors } from 'store/selectors';
import * as actions from './actions';

const handleGetAdminEventGenderStatistics = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminEventGenderStatistics,
  request: AdminOrganizationsApi.getAdminEventQuestionStatistics,
  requestArgsBuilder: (action) => {
    const { organizationId, slug, ...params } = action.payload.params;
    return [organizationId, action.payload.id, slug, { params }];
  },
  transformResponse: function* transformResponse(response, action) {
    yield select(AdminEventsSelectors.adminEventStateById, Number(action.payload.id));
    return { id: action.payload.id, answers: response };
  },
});

export default function* adminEventGenderStatistics() {
  yield takeEvery(actions.getAdminEventGenderStatistics.request.type, handleGetAdminEventGenderStatistics);
}
