import { get, put } from './client';
import { buildPath, toJson } from './utils';
import { GeneralListType, IdType } from './types';
import { TrustProfile } from './admin/models/TrustProfile';
import { ADMIN_TRUST_PROFILES } from './constants';

export const getAdminProfiles = (options: any): Promise<GeneralListType<TrustProfile>> =>
  get(buildPath(ADMIN_TRUST_PROFILES), options).then(toJson);

export const getAdminProfile = (id: IdType, options: any): Promise<TrustProfile> =>
  get(buildPath(ADMIN_TRUST_PROFILES, id), options).then(toJson);

export const updateAdminTrustProfile = (id: IdType, options: any): Promise<TrustProfile> =>
  put(buildPath(ADMIN_TRUST_PROFILES, id), options).then(toJson);
