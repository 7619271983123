import { EventImageTranslationDisplay } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import {
  GetAdminEventImageTranslationParamsType,
  ReplaceEventImageTranslationParamsType,
  RemoveEventImageTranslationParamsType,
} from './types';

export const getAdminEventImageTranslations = actionsFactories.createGetManyActionsFactory<
  EntityType<EventImageTranslationDisplay>,
  GetAdminEventImageTranslationParamsType
>()(
  'GET_ADMIN_EVENT_IMAGE_TRANSLATIONS_REQUEST',
  'GET_ADMIN_EVENT_IMAGE_TRANSLATIONS_SUCCESS',
  'GET_ADMIN_EVENT_IMAGE_TRANSLATIONS_FAILURE',
  'GET_ADMIN_EVENT_IMAGE_TRANSLATIONS_RESET',
  keyWindowNames.adminEventImageTranslations,
);

export const removeAdminEventImageTranslation = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EventImageTranslationDisplay>,
  RemoveEventImageTranslationParamsType
>()(
  'REMOVE_ADMIN_EVENT_IMAGE_TRANSLATION_REQUEST',
  'REMOVE_ADMIN_EVENT_IMAGE_TRANSLATION_SUCCESS',
  'REMOVE_ADMIN_EVENT_IMAGE_TRANSLATION_FAILURE',
  'REMOVE_ADMIN_EVENT_IMAGE_TRANSLATION_RESET',
);

export const replaceAdminEventImageTranslation = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EventImageTranslationDisplay>,
  ReplaceEventImageTranslationParamsType
>()(
  'REPLACE_ADMIN_EVENT_IMAGE_TRANSLATION_REQUEST',
  'REPLACE_ADMIN_EVENT_IMAGE_TRANSLATION_SUCCESS',
  'REPLACE_ADMIN_EVENT_IMAGE_TRANSLATION_FAILURE',
  'REPLACE_ADMIN_EVENT_IMAGE_TRANSLATION_RESET',
);
