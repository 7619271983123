import { OrganizationDetail } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationDetail>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const organizationsReducer = createReducer<State, Actions>(initialState, {
  [actions.getOrganization.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getOrganization.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getOrganization.failure.type]: actionsHandlers.handleGetOneFailure,
});

export default organizationsReducer;
