import { OrganizationTranslation } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationTranslation>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationTranslationReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationTranslation.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminOrganizationTranslation.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminOrganizationTranslation.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminOrganizationTranslation.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.updateAdminOrganizationTranslation.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminOrganizationTranslation.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminOrganizationTranslation.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminOrganizationTranslation.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default adminOrganizationTranslationReducer;
