import { call, takeEvery } from 'redux-saga/effects';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { AdminPromoCodesApi } from 'api';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminPromoCodesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminPromoCodes,
  request: AdminPromoCodesApi.getAdminEventPromoCodes,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { eventId, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminPromoCodesState, params);

    return [eventId, { params: { ...rest, page, page_size } }];
  },
});

const handleGetAdminPromoCodeRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminPromoCode,
  request: AdminPromoCodesApi.getAdminEventPromoCode,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

const handleCreateAdminPromoCodeRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminPromoCode,
  request: AdminPromoCodesApi.createAdminPromoCode,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, { body: entity }];
  },
});

const handlePartialUpdateAdminPromoCodeRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.partialUpdateAdminPromoCode,
  request: AdminPromoCodesApi.partialAdminEventPromoCode,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

const handleDeleteAdminPromoCodeRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminPromoCode,
  request: AdminPromoCodesApi.deleteAdminEventPromoCode,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

export default function* adminPromoCodesSagas() {
  yield takeEvery(actions.getAdminPromoCodes.request.type, handleGetAdminPromoCodesRequest);
  yield takeEvery(actions.getAdminPromoCode.request.type, handleGetAdminPromoCodeRequest);
  yield takeEvery(actions.createAdminPromoCode.request.type, handleCreateAdminPromoCodeRequest);
  yield takeEvery(actions.partialUpdateAdminPromoCode.request.type, handlePartialUpdateAdminPromoCodeRequest);
  yield takeEvery(actions.deleteAdminPromoCode.request.type, handleDeleteAdminPromoCodeRequest);
}
