import { call, fork, put, race, take } from 'redux-saga/effects';
import deepmerge, { Options } from 'deepmerge';
import { AnyFunction, AsyncReturnType } from 'types';
import { apiCall, createAsyncActions, CreateAsyncActions, prepareArgs, prepareNotifyError } from 'store/utils';

type AsyncActions = ReturnType<typeof createAsyncActions>;

const deepmergeOptions: Options = {
  arrayMerge: (target, source) => source,
};

export const createCreateOneRequestHandler = <
  A extends CreateAsyncActions<string, AnyFunction, string, AnyFunction, string, AnyFunction>,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  T extends (result: AsyncReturnType<R>, action?: ReturnType<A['request']>) => any,
  N extends ((error: Error, action: ReturnType<A['failure']>) => boolean) | boolean,
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  transformResponse?: T;
  notifyError?: N;
}) =>
  function* handleCreateOneRequest(action: ReturnType<A['request']>): any {
    const {
      actions,
      request,
      requestArgsBuilder = (action) => [{ body: action.payload.entity }],
      transformResponse = (response) => response,
      notifyError: notifyErrorBuilder = true,
    } = args;

    try {
      const requestArgs = yield prepareArgs(requestArgsBuilder, action);
      const response = yield apiCall(request as AnyFunction, ...requestArgs);
      const transformedResponse = yield call(transformResponse as AnyFunction, response, action);
      yield put(actions.success({ entity: transformedResponse, params: action.payload.params }));
    } catch (error) {
      if (error instanceof Error) {
        const notifyError = yield prepareNotifyError(notifyErrorBuilder, error, action);
        yield put(
          actions.failure({
            error,
            notifyError,
            entity: action.payload.entity,
            params: action.payload.params,
          }),
        );
      }
    }
  };

export const createUpdateOneRequestHandler = <
  A extends AsyncActions,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  T extends (result: AsyncReturnType<R>, action?: ReturnType<A['request']>) => any,
  N extends ((error: Error, action: ReturnType<A['failure']>) => boolean) | boolean,
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  transformResponse?: T;
  notifyError?: N;
}) =>
  function* handleUpdateOneRequest(action: ReturnType<A['request']>): any {
    const {
      actions,
      request,
      requestArgsBuilder = (action) => [action.payload.id, { body: action.payload.entity }],
      transformResponse = (response) => response,
      notifyError: notifyErrorBuilder = true,
    } = args;

    try {
      const requestArgs = yield prepareArgs(requestArgsBuilder, action);
      const response = yield apiCall(request as AnyFunction, ...requestArgs);
      const transformedResponse = yield call(transformResponse as AnyFunction, response, action);
      yield put(actions.success({ entity: transformedResponse, params: action.payload.params }));
    } catch (error) {
      if (error instanceof Error) {
        const notifyError = yield prepareNotifyError(notifyErrorBuilder, error, action);
        yield put(
          actions.failure({
            error,
            notifyError,
            id: action.payload.id,
            params: action.payload.params,
            entity: action.payload.entity,
          }),
        );
      }
    }
  };

export const createPartialUpdateOneRequestHandler = <
  A extends AsyncActions,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  T extends (result: AsyncReturnType<R>, action?: ReturnType<A['request']>) => any,
  N extends ((error: Error, action: ReturnType<A['failure']>) => boolean) | boolean,
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  transformResponse?: T;
  deepMerge?: boolean;
  notifyError?: N;
}) =>
  function* handlePartialUpdateOneRequest(action: ReturnType<A['request']>): any {
    const {
      actions,
      request,
      requestArgsBuilder = (action) => [action.payload.id, { body: action.payload.entity }],
      transformResponse = (response) => response,
      notifyError: notifyErrorBuilder,
    } = args;

    try {
      const requestArgs = yield prepareArgs(requestArgsBuilder, action);
      const response = yield apiCall(request as AnyFunction, ...requestArgs);
      const transformedResponse = yield call(transformResponse as AnyFunction, response, action);
      const mergedResponse = deepmerge(action.payload.entity, transformedResponse, deepmergeOptions);
      yield put(
        actions.success({
          entity: mergedResponse,
          params: action.payload.params,
          partial: true,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        const notifyError = yield prepareNotifyError(notifyErrorBuilder, error, action);
        yield put(
          actions.failure({
            error,
            notifyError,
            id: action.payload.id,
            params: action.payload.params,
            entity: action.payload.entity,
          }),
        );
      }
    }
  };

export const createDeleteOneRequestHandler = <
  A extends CreateAsyncActions<string, AnyFunction, string, AnyFunction, string, AnyFunction>,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  N extends ((error: Error, action: ReturnType<A['failure']>) => boolean) | boolean,
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  notifyError?: N;
}) =>
  function* handleDeleteOneRequest(action: ReturnType<A['request']>): any {
    const {
      actions,
      request,
      requestArgsBuilder = (action) => [action.payload.id],
      notifyError: notifyErrorBuilder = true,
    } = args;

    try {
      const requestArgs = yield prepareArgs(requestArgsBuilder, action);
      yield apiCall(request as AnyFunction, ...requestArgs);
      yield put(actions.success({ id: action.payload.id, params: action.payload.params }));
    } catch (error) {
      if (error instanceof Error) {
        const notifyError = yield prepareNotifyError(notifyErrorBuilder, error, action);
        yield put(actions.failure({ error, notifyError, id: action.payload.id, params: action.payload.params }));
      }
    }
  };

export const createGetOneRequestHandler = <
  A extends AsyncActions,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  T extends (result: AsyncReturnType<R>, action?: ReturnType<A['request']>) => any,
  N extends ((error: Error, action: ReturnType<A['failure']>) => boolean) | boolean,
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  transformResponse?: T;
  notifyError?: N;
}) =>
  function* handleGetOneRequest(action: ReturnType<A['request']>): any {
    const {
      actions,
      request,
      requestArgsBuilder = (action) => [action.payload.id],
      transformResponse = (response) => response,
      notifyError: notifyErrorBuilder = true,
    } = args;

    try {
      const requestArgs = yield prepareArgs(requestArgsBuilder, action);
      const response = yield apiCall(request as AnyFunction, ...requestArgs);
      const transformedResponse = yield call(transformResponse as AnyFunction, response, action);
      yield put(actions.success({ entity: transformedResponse }));
    } catch (error) {
      if (error instanceof Error) {
        const notifyError = yield prepareNotifyError(notifyErrorBuilder, error, action);
        yield put(actions.failure({ error, notifyError, id: action.payload.id }));
      }
    }
  };

export const createGetManyRequestHandler = <
  A extends AsyncActions,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  T extends (result: AsyncReturnType<R>, action?: ReturnType<A['request']>) => any,
  N extends ((error: Error, action: ReturnType<A['failure']>) => boolean) | boolean,
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  transformResponse?: T;
  notifyError?: N;
}) =>
  function* handleGetManyRequest(action: ReturnType<A['request']>): any {
    const {
      actions,
      request,
      requestArgsBuilder = (action) => [{ params: action.payload.params }],
      transformResponse = (response) => response,
      notifyError: notifyErrorBuilder = true,
    } = args;

    try {
      const requestArgs = yield prepareArgs(requestArgsBuilder, action);
      const response = yield apiCall(request as AnyFunction, ...requestArgs);
      const transformedResponse = yield call(transformResponse as AnyFunction, response, action);
      yield put(actions.success({ entities: transformedResponse, params: action.payload.params }));
    } catch (error) {
      if (error instanceof Error) {
        const notifyError = yield prepareNotifyError(notifyErrorBuilder, error, action);
        yield put(actions.failure({ error, notifyError, params: action.payload.params }));
      }
    }
  };

export const createRuntimeUpdateHandler = <
  A extends AsyncActions,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  T extends (result: AsyncReturnType<R>, action?: ReturnType<A['request']>) => any,
  M extends <T>(target: T[], source: T[]) => T[],
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  transformResponse?: T;
  arrayMerge?: M;
}) =>
  function* runtimeUpdateHandler(): any {
    const { arrayMerge, ...rest } = args;
    const actionsById = new Map<number, any>();
    const tasksById = new Map<number, any>();
    const partialUpdateHandler = createPartialUpdateOneRequestHandler(rest);

    const omit = (values: any, errors: any): any => {
      if (Object.keys(errors).some((key) => !(key in values))) return;

      return Object.keys(values).reduce((omitted, key) => {
        const error = errors[key];

        let value;
        if (!Array.isArray(error)) {
          value = values[key];
          if (typeof error === 'object') {
            value = omit(value, error);
          }
        }

        if (value === undefined) return omitted;
        return {
          ...omitted,
          [key]: value,
        };
      }, undefined as any);
    };

    const handleFailure = (action: any) => {
      if (action.error.response.status === 400) {
        try {
          const responseError = JSON.parse(action.error.response.responseText);
          const omitted = omit(action.payload.entity, responseError);
          if (omitted) {
            actionsById.set(action.payload.id, {
              ...action,
              payload: {
                ...action.payload,
                entity: omitted,
              },
            });
          }
        } catch (e) {}
      }
    };

    while (true) {
      const action: any = yield take(args.actions.request.type);
      const { id } = action.payload;

      if (!actionsById.has(id)) actionsById.set(id, {});
      actionsById.set(
        id,
        deepmerge(actionsById.get(id), action, {
          ...deepmergeOptions,
          ...(arrayMerge && {
            arrayMerge,
          }),
        }),
      );

      yield fork(function* handleActions() {
        if (tasksById.has(id)) return;

        while (true) {
          const action = actionsById.get(id);
          actionsById.delete(id);

          const task = yield fork(partialUpdateHandler, action);
          tasksById.set(id, task);

          while (true) {
            const effects: any[] = yield race([take(args.actions.success.type), take(args.actions.failure.type)]);
            const [success, failure] = effects;

            if (success && success.payload.entity.id === id) break;
            if (failure && failure.payload.id === id) {
              handleFailure(failure);
              break;
            }
          }

          if (!actionsById.has(id)) {
            tasksById.delete(id);
            break;
          }
        }
      });
    }
  };
