import { SerializedError } from 'store/utils';
import { EntitiesStateType, EntityType } from './types';

export const createInitialEntitiesStateFactory = <E extends EntityType, K extends string[] = string[]>() => {
  function getInitialState(): EntitiesStateType<E, K>;
  function getInitialState<S extends Record<string, unknown>>(additionalState: S): EntitiesStateType<E, K> & S;
  function getInitialState(additionalState = {}) {
    return {
      byId: {},
      isCreating: false,
      isUpdating: {},
      isDeleting: {},
      isFetching: {},
      isFetched: {},
      isSuccessfullyCreated: false,
      isSuccessfullyUpdated: {},
      creatingError: null as SerializedError,
      updatingErrors: {},
      deletingErrors: {},
      fetchingErrors: {},
      keyWindows: [] as K,
      ...additionalState,
    };
  }

  return {
    getInitialState,
  };
};
