import fetch from 'isomorphic-unfetch';
import { getYoutubeId } from './transform';

const getVimeoThumb = (url: string) =>
  fetch(`https://vimeo.com/api/oembed.json?url=${url}`)
    .then((res) => res.json())
    .then(({ thumbnail_url }) => thumbnail_url as string);

const getYoutubeThumb = async (url: string) => `https://img.youtube.com/vi/${getYoutubeId(url)}/mqdefault.jpg`;

export const getVideoThumb = (url: string, showError = false) =>
  new Promise<string>((resolve, reject) => {
    let getThumb;
    if (url.includes('youtu')) getThumb = getYoutubeThumb;
    if (url.includes('vimeo')) getThumb = getVimeoThumb;
    if (getThumb) {
      getThumb(url).then(resolve).catch(reject);
    } else {
      reject(new Error('Invalid video url'));
    }
  }).catch((error) => {
    if (showError) {
      return Promise.reject(error);
    }
  });
