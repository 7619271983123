import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { createSelector } from 'reselect';

export const marketsSlice = (state: RootState) => state.entities.markets;

export const markets = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(marketsSlice);

export const marketsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(marketsSlice);

export const hasMarkets = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(marketsSlice);

export const marketById = selectorsFactory.createEntityByIdSelector(marketsSlice);

export const marketStateById = selectorsFactory.createEntityStateByIdSelector(marketsSlice);

export const marketBySlug = createSelector(
  markets,
  (entity: any, slug: string) => slug,
  (entity, slug) => entity.find((market: any) => market.slug === slug),
);

export const unHiddenMarketEntityState = createSelector(markets, (entity) =>
  entity.filter((market: any) => !market.is_hidden),
);
