import { EventImageCreateUpdate, EventImageDisplay } from 'api/admin/models';
import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { AdminEventImageParamsType, CreateAdminEventImageParamsType, UpdateAdminEventImageParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminEventImages = actionsFactories.createGetManyActionsFactory<
  EntityType<EventImageDisplay>,
  AdminEventImageParamsType
>()(
  'GET_ADMIN_EVENT_IMAGES_REQUEST',
  'GET_ADMIN_EVENT_IMAGES_SUCCESS',
  'GET_ADMIN_EVENT_IMAGES_FAILURE',
  'GET_ADMIN_EVENT_IMAGES_RESET',
  keyWindowNames.adminEvent,
);

export const createAdminEventImage = actionsFactories.createCreateOneActionsFactory<
  EntityType<Omit<EventImageCreateUpdate, 'order'>>,
  CreateAdminEventImageParamsType
>()(
  'CREATE_ADMIN_EVENT_IMAGE_REQUEST',
  'CREATE_ADMIN_EVENT_IMAGE_SUCCESS',
  'CREATE_ADMIN_EVENT_IMAGE_FAILURE',
  'CREATE_ADMIN_EVENT_IMAGE_RESET',
  keyWindowNames.adminEvent,
);

export const updateAdminEventImage = actionsFactories.createUpdateOneActionsFactory<
  EntityType<Partial<EventImageCreateUpdate>>,
  UpdateAdminEventImageParamsType
>()(
  'UPDATE_ADMIN_EVENT_IMAGE_REQUEST',
  'UPDATE_ADMIN_EVENT_IMAGE_SUCCESS',
  'UPDATE_ADMIN_EVENT_IMAGE_FAILURE',
  'UPDATE_ADMIN_EVENT_IMAGE_RESET',
);

export const deleteAdminEventImage = actionsFactories.createDeleteOneActionsFactory<AdminEventImageParamsType>()(
  'DELETE_ADMIN_EVENT_IMAGE_REQUEST',
  'DELETE_ADMIN_EVENT_IMAGE_SUCCESS',
  'DELETE_ADMIN_EVENT_IMAGE_FAILURE',
);

export const createAdminEventImages = createAction(
  'CREATE_ADMIN_EVENT_IMAGES',
  (params: Omit<CreateAdminEventImageParamsType, 'file'> & { files: File[] }) => ({
    payload: {
      params,
    },
  }),
);
