import { takeEvery } from 'redux-saga/effects';
import { EventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetEventSubscription = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getEventEmailSubscription,
  request: EventsApi.getEventEmailSubscription,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { token } = params;
    return [id, token];
  },
  transformResponse: (response, action) => ({ ...response, id: action.payload.id }),
});

const handleToggleEventEmailSubscription = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.toggleEventEmailSubscription,
  request: EventsApi.createEventEmailSubscription,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { token } = params;
    return [id, token];
  },
  transformResponse: (response, action) => ({ ...response, id: action.payload.id }),
});

export default function* eventEmailSubscriptionSagas() {
  yield takeEvery(actions.getEventEmailSubscription.request.type, handleGetEventSubscription);
  yield takeEvery(actions.toggleEventEmailSubscription.request.type, handleToggleEventEmailSubscription);
}
