export const QUESTION_ORDER_SPREAD = 2 ** 10;

export enum QuestionTypes {
  event = 'event',
  allAttendees = 'allAttendees',
  ticket = 'ticket',
  addon = 'addon',
}

export enum QuestionOptionTypes {
  single = 'single',
  multiple = 'multiple',
  paragraph = 'paragraph',
  text = 'text',
}
