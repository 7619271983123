import { Donation } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getEventDonation = actionsFactories.createGetOneActionsFactory<EntityType<Donation>>()(
  'GET_EVENT_DONATION_REQUEST',
  'GET_EVENT_DONATION_SUCCESS',
  'GET_EVENT_DONATION_FAILURE',
  'GET_EVENT_DONATION_RESET',
);
