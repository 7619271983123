// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface OfflineOrderCheckout
 */
export interface OfflineOrderCheckout {
  /**
   * @type {string}
   * @memberof OfflineOrderCheckout
   */
  readonly id?: string;
  /**
   * @type {string}
   * @memberof OfflineOrderCheckout
   */
  payment_type: OfflineOrderCheckoutPaymentTypeEnum;
  /**
   * @type {string}
   * @memberof OfflineOrderCheckout
   */
  comment?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OfflineOrderCheckoutPaymentTypeEnum {
  Cash = 'cash',
  Check = 'check',
  CreditCard = 'credit_card',
  Paypal = 'paypal',
  Square = 'square',
  Other = 'other',
}
