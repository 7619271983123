import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminEventsApi } from 'api';
import { GetAction } from 'store/types';
import { apiCall } from 'store/utils';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminAttendeeRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminAttendee,
  request: AdminEventsApi.getAttendee,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

const handleGetAdminAttendeeDetailsRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminAttendeeDetails,
  request: AdminEventsApi.getAttendeeDetails,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    return [id, { params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminAttendeesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminAttendees,
  request: AdminEventsApi.getAttendees,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { eventId, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminAttendeesState, params);

    return [eventId, { params: { ...rest, page, page_size } }];
  },
});

const handleGetAdminAttendeeTicketsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminAttendeeTickets,
  request: AdminEventsApi.getAttendeeTickets,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { s, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminAttendeeTicketsState, params);

    return [s, { params: { ...rest, page, page_size } }];
  },
});

const handleGetAdminRegistrationStatRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminRegistrationStat,
  request: AdminEventsApi.getRegistrationStats,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    return [id, { params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

function* handleChangeAdminAttendeeStatusRequest(
  action: GetAction<typeof actions.changeAdminAttendeeStatus.request>,
): any {
  const { id, params } = action.payload;
  const { eventId, orderId, attendee_status } = params;

  let request;
  if (attendee_status === 'checked-in') request = AdminEventsApi.attendeeCheckIn;
  if (attendee_status === 'registered') request = AdminEventsApi.attendeeUncheckIn;

  try {
    if (!request) {
      throw new Error(`Unexpected attendee status: ${attendee_status}`);
    }

    const result = yield apiCall(request, eventId, orderId, id);
    yield put(actions.changeAdminAttendeeStatus.success({ result, id, params }));
  } catch (error) {
    if (error instanceof Error) yield put(actions.changeAdminAttendeeStatus.failure({ error, id, params }));
  }
}

function* handleCancelAdminAttendeeTicketRequest(
  action: GetAction<typeof actions.cancelAdminAttendeeTicket.request>,
): any {
  const { id, params } = action.payload;
  const { eventId, orderId } = params;

  const request = AdminEventsApi.attendeeCancelTicket;
  try {
    const result = yield apiCall(request, eventId, orderId, id);
    yield put(actions.cancelAdminAttendeeTicket.success({ result, id, params }));
  } catch (error) {
    if (error instanceof Error) yield put(actions.cancelAdminAttendeeTicket.failure({ error, id, params }));
  }
}

export default function* adminAttendeesSagas() {
  yield takeEvery(actions.getAdminAttendee.request.type, handleGetAdminAttendeeRequest);
  yield takeEvery(actions.getAdminAttendeeDetails.request.type, handleGetAdminAttendeeDetailsRequest);
  yield takeLeading(actions.getAdminAttendees.request.type, handleGetAdminAttendeesRequest);
  yield takeLeading(actions.getAdminAttendeeTickets.request.type, handleGetAdminAttendeeTicketsRequest);
  yield takeEvery(actions.getAdminRegistrationStat.request.type, handleGetAdminRegistrationStatRequest);
  yield takeEvery(actions.changeAdminAttendeeStatus.request.type, handleChangeAdminAttendeeStatusRequest);
  yield takeEvery(actions.cancelAdminAttendeeTicket.request.type, handleCancelAdminAttendeeTicketRequest);
}
