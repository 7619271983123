import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { selectorsFactory as featuresSelectorsFactory } from 'store/features/utils';
import { createSelector } from 'reselect';

export const calendarSettingsSlice = (state: RootState) => state.entities.calendarSettings.calendarSettings;

export const calendarSettingsList =
  selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(calendarSettingsSlice);

export const calendarSettingsListState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(calendarSettingsSlice);

export const calendarSettingsBySlug = createSelector(
  calendarSettingsList,
  (entity: any, slug: string) => slug,
  (entity, slug) => entity.find((calendarSettings: any) => calendarSettings.slug === slug),
);

export const calendarSettingsStateById = selectorsFactory.createEntityStateByIdSelector(calendarSettingsSlice);

export const calendarSettingsUpdatingById = selectorsFactory.createUpdatingStateByIdSelector(calendarSettingsSlice);

export const publicCalendarIcsFeedLinkSlice = (state: RootState) =>
  state.entities.calendarSettings.publicCalendarIcsFeedLink;

export const publicCalendarIcsFeedLinkByIdState =
  featuresSelectorsFactory.createMultipleFeatureStateByIdSelectors(publicCalendarIcsFeedLinkSlice);

export const communityCalendarIcsFeedLinkSlice = (state: RootState) =>
  state.entities.calendarSettings.communityCalendarIcsFeedLink;

export const communityCalendarIcsFeedLinkByIdState = featuresSelectorsFactory.createMultipleFeatureStateByIdSelectors(
  communityCalendarIcsFeedLinkSlice,
);
