import { EventTranslation } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getAdminEventTranslation = actionsFactories.createGetOneActionsFactory<EntityType<EventTranslation>>()(
  'GET_ADMIN_EVENT_TRANSLATION_REQUEST',
  'GET_ADMIN_EVENT_TRANSLATION_SUCCESS',
  'GET_ADMIN_EVENT_TRANSLATION_FAILURE',
  'GET_ADMIN_EVENT_TRANSLATION_RESET',
);

export const updateAdminEventTranslation = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EventTranslation>
>()(
  'UPDATE_ADMIN_EVENT_TRANSLATION_REQUEST',
  'UPDATE_ADMIN_EVENT_TRANSLATION_SUCCESS',
  'UPDATE_ADMIN_EVENT_TRANSLATION_FAILURE',
  'UPDATE_ADMIN_EVENT_TRANSLATION_RESET',
);
