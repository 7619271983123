import { createAction, createAsyncActions } from 'store/utils';
import { actionsFactories as featuresActionsFactories } from 'store/features/utils';
import { CreateEventDraftParams } from './types';

export const uploadHeroImage = createAsyncActions(
  'UPLOAD_HERO_IMAGE_REQUEST',
  (file: any) => ({
    payload: {
      file,
    },
  }),
  'UPLOAD_HERO_IMAGE_SUCCESS',
  (image: any) => ({
    payload: {
      image,
    },
  }),
  'UPLOAD_HERO_IMAGE_FAILURE',
  (error: Error) => ({
    error,
  }),
);

export const setMediaItemsPending = createAction('SET_MEDIA_ITEMS_PENDING', (isPending: any) => ({
  payload: {
    isPending,
  },
}));

export const setMediaItems = createAction('SET_MEDIA_ITEMS', (items: any) => ({
  payload: {
    items,
  },
}));

export const setHeroImage = createAction('SET_HERO_IMAGE', (image: any) => ({
  payload: {
    image,
  },
}));

export const addMediaItem = createAction('ADD_MEDIA_ITEM', (media: any) => ({
  payload: {
    media,
  },
}));

export const addImages = createAction('ADD_IMAGES', (files: any) => ({
  payload: {
    files,
  },
}));

export const addVideos = createAction('ADD_VIDEOS', (urls: any) => ({
  payload: {
    urls,
  },
}));

export const removeMediaItems = createAction('REMOVE_MEDIA_ITEMS');

export const removeMediaItem = createAction('REMOVE_MEDIA_ITEM', (index: any) => ({
  payload: {
    index,
  },
}));

export const removeHeroImage = createAction('REMOVE_HERO_IMAGE');

export const swapMediaItems = createAction('SWAP_MEDIA_ITEMS', (oldIndex: any, newIndex: any) => ({
  payload: {
    oldIndex,
    newIndex,
  },
}));

export const createEvent = featuresActionsFactories.createSingleFeatureActionsFactory<CreateEventDraftParams>()(
  'CREATE_ADMIN_EVENT_REQUEST',
  'CREATE_ADMIN_EVENT_SUCCESS',
  'CREATE_ADMIN_EVENT_FAILURE',
  'CREATE_ADMIN_EVENT_RESET',
);

export const saveEvent = createAction(
  'SAVE_EVENT',
  (organizationId: any, eventId: any, values: any, callback: any) => ({
    payload: {
      organizationId,
      eventId,
      values,
      callback,
    },
  }),
);

export const getErrorEvent = createAction('GET_ERROR_EVENT', (error: any) => ({
  payload: {
    error,
  },
}));

export const removeErrorEvent = createAction('REMOVE_ERROR_EVENT');

export const initEventMedia = createAction('INIT_EVENT_MEDIA', (eventId: number) => ({
  payload: {
    eventId,
  },
}));
