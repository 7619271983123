import {
  OrganizationAgeStatistic,
  OrganizationDonationsStatistic,
  OrganizationEventsStatistics,
  OrganizationGenderStatistic,
  OrganizationInterestStatistic,
  OrganizationMostActivePeopleStatistic,
  OrganizationNeighborhoodsStatistic,
  OrganizationPeopleStatistic,
  OrganizationSalesStatistic,
  OrganizationStatistics,
  OrganizationTicketsRegistrationStatistic,
  OrganizationTicketsStatistic,
  OrganizationTopEventsStatistic,
  OrganizationTopOutsideOrgStatistic,
} from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import {
  AdminOrganizationTicketsRegistrationParamsType,
  AdminOrganizationTopEventsParamsType,
  AdminOrganizationTopInterestParamsType,
} from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminOrganizationDashboard = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationStatistics>
>()(
  'GET_ADMIN_ORGANIZATION_DASHBOARD_REQUEST',
  'GET_ADMIN_ORGANIZATION_DASHBOARD_SUCCESS',
  'GET_ADMIN_ORGANIZATION_DASHBOARD_FAILURE',
  'RESET_ADMIN_ORGANIZATION_DASHBOARD',
);

export const getAdminOrganizationEventsStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationEventsStatistics>
>()(
  'GET_ADMIN_ORGANIZATION_EVENTS_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_EVENTS_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_EVENTS_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_EVENTS_STATISTICS',
);

export const getAdminOrganizationDonationsStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationDonationsStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_DONATIONS_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_DONATIONS_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_DONATIONS_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_DONATIONS_STATISTICS',
);

export const getAdminOrganizationSalesStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationSalesStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_SALES_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_SALES_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_SALES_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_SALES_STATISTICS',
);

export const getAdminOrganizationTicketsStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationTicketsStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_TICKETS_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_TICKETS_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_TICKETS_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_TICKETS_STATISTICS',
);

export const getAdminOrganizationPeopleStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationPeopleStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_PEOPLE_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_PEOPLE_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_PEOPLE_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_PEOPLE_STATISTICS',
);

export const getAdminOrganizationTopOutsideOrgStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationTopOutsideOrgStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_TOP_OUTSIDE_ORG_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_TOP_OUTSIDE_ORG_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_TOP_OUTSIDE_ORG_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_TOP_OUTSIDE_ORG_STATISTICS',
);

export const getAdminOrganizationNeighborhoodsStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationNeighborhoodsStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_NEIGHBORHOODS_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_NEIGHBORHOODS_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_NEIGHBORHOODS_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_NEIGHBORHOODS_STATISTICS',
);

export const getAdminOrganizationGenderStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationGenderStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_GENDER_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_GENDER_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_GENDER_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_GENDER_STATISTICS',
);

export const getAdminOrganizationAgeStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationAgeStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_AGE_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_AGE_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_AGE_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_AGE_STATISTICS',
);

export const getAdminOrganizationTopInterestStatistic = actionsFactories.createGetManyActionsFactory<
  EntityType<OrganizationInterestStatistic>,
  AdminOrganizationTopInterestParamsType
>()(
  'GET_ADMIN_ORGANIZATION_TOP_INTEREST_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_TOP_INTEREST_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_TOP_INTEREST_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_TOP_INTEREST_STATISTICS',
  keyWindowNames.adminOrganizationTopInterest,
);

export const getAdminOrganizationMostActivePeopleStatistic = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationMostActivePeopleStatistic>
>()(
  'GET_ADMIN_ORGANIZATION_MOST_ACTIVE_PEOPLE_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_MOST_ACTIVE_PEOPLE_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_MOST_ACTIVE_PEOPLE_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_MOST_ACTIVE_PEOPLE_STATISTICS',
);

export const getAdminOrganizationTopEventsStatistic = actionsFactories.createGetManyActionsFactory<
  EntityType<OrganizationTopEventsStatistic>,
  AdminOrganizationTopEventsParamsType
>()(
  'GET_ADMIN_ORGANIZATION_TOP_EVENTS_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_TOP_EVENTS_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_TOP_EVENTS_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_TOP_EVENTS_STATISTICS',
  keyWindowNames.adminOrganizationTopEvents,
);

export const getAdminOrganizationTicketsRegistrationStatistic = actionsFactories.createGetManyActionsFactory<
  EntityType<OrganizationTicketsRegistrationStatistic>,
  AdminOrganizationTicketsRegistrationParamsType
>()(
  'GET_ADMIN_ORGANIZATION_TICKETS_REGISTRATION_STATISTICS_REQUEST',
  'GET_ADMIN_ORGANIZATION_TICKETS_REGISTRATION_STATISTICS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_TICKETS_REGISTRATION_STATISTICS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_TICKETS_REGISTRATION_STATISTICS',
  keyWindowNames.adminOrganizationTicketsRegistration,
);
