import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminEventOrganizationMentionsSlice = (state: RootState) => state.entities.adminEventOrganizationMentions;

export const adminEventOrganizationMentionsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventOrganizationMentionsSlice,
  keyWindowNames.adminEvent,
);

export const adminEventOrganizationMentionsChangingState = selectorsFactory.createChangingStateSelector(
  adminEventOrganizationMentionsSlice,
);

export const adminEventOrganizationMentionsFetchingState = selectorsFactory.createDynamicKeyWindowFetchingStateSelector(
  adminEventOrganizationMentionsSlice,
  keyWindowNames.adminEvent,
);
