import { combineReducers } from 'redux';
import adminAttendeesReducer from './adminAttendeesReducer';
import adminAttendeeReducer from './adminAttendeeReducer';
import adminRegistrationStatReducer from './adminRegistrationStatReducer';
import changeAdminAttendeeStatusReducer from './changeAdminAttendeeStatusReducer';
import adminAttendeeTicketsReducer from './adminAttendeeTicketsReducer';
import AdminAttendeeDetailsReducer from './adminAttendeeDetailsReducer';
import cancelAdminAttendeeTicketReducer from './cancelAdminAttendeeTicketReducer';

export default combineReducers({
  entitiesState: adminAttendeesReducer,
  entityState: adminAttendeeReducer,
  adminRegistrationStat: adminRegistrationStatReducer,
  changeAdminAttendeeStatus: changeAdminAttendeeStatusReducer,
  adminAttendeeTicketsState: adminAttendeeTicketsReducer,
  adminAttendeeDetailsState: AdminAttendeeDetailsReducer,
  cancelAdminAttendeeTicket: cancelAdminAttendeeTicketReducer,
});
