import { actionsFactories } from 'store/entities/utils';
import { createAction } from 'store/utils';
import { UserPreferences } from 'api/general/models';
import { UserPassport } from 'api/general/models/UserPassport';
import { ImageType, UserType } from './types';
import { EntityType } from '../types';

export const getUserDetail = actionsFactories.createGetOneActionsFactory<UserType>()(
  'GET_USER_DETAIL_REQUEST',
  'GET_USER_DETAIL_SUCCESS',
  'GET_USER_DETAIL_FAILURE',
  'GET_USER_DETAIL_RESET',
);

export const partialUpdateUser = actionsFactories.createUpdateOneActionsFactory<UserType>()(
  'PARTIAL_UPDATE_USER_REQUEST',
  'PARTIAL_UPDATE_USER_SUCCESS',
  'PARTIAL_UPDATE_USER_FAILURE',
  'PARTIAL_UPDATE_USER_RESET',
);

export const updateUserImage = actionsFactories.createUpdateOneActionsFactory<UserType, ImageType>()(
  'UPDATE_USER_IMAGE_REQUEST',
  'UPDATE_USER_IMAGE_SUCCESS',
  'UPDATE_USER_IMAGE_FAILURE',
  'UPDATE_USER_IMAGE_RESET',
);

export const toggleUserInterest = createAction('TOGGLE_USER_INTEREST', (id: number) => ({
  payload: {
    id,
  },
}));

export const getUserPreferences = actionsFactories.createGetOneActionsFactory<EntityType<UserPreferences>>()(
  'GET_USER_PREFERENCES_REQUEST',
  'GET_USER_PREFERENCES_SUCCESS',
  'GET_USER_PREFERENCES_FAILURE',
  'GET_USER_PREFERENCES_RESET',
);

export const partialUpdateUserPreferences = actionsFactories.createUpdateOneActionsFactory<
  EntityType<UserPreferences>
>()(
  'PARTIAL_UPDATE_USER_PREFERENCES_REQUEST',
  'PARTIAL_UPDATE_USER_PREFERENCES_SUCCESS',
  'PARTIAL_UPDATE_USER_PREFERENCES_FAILURE',
  'PARTIAL_UPDATE_USER_PREFERENCES_RESET',
);

export const getUserPreferencesByEmail = actionsFactories.createGetOneActionsFactory<EntityType<UserPreferences>>()(
  'GET_USER_PREFERENCES_BY_EMAIL_REQUEST',
  'GET_USER_PREFERENCES_BY_EMAIL_SUCCESS',
  'GET_USER_PREFERENCES_BY_EMAIL_FAILURE',
  'GET_USER_PREFERENCES_BY_EMAIL_RESET',
);

export const partialUpdateUserPreferencesByEmail = actionsFactories.createUpdateOneActionsFactory<
  EntityType<UserPreferences>
>()(
  'PARTIAL_UPDATE_USER_BY_EMAIL_PREFERENCES_REQUEST',
  'PARTIAL_UPDATE_USER_BY_EMAIL_PREFERENCES_SUCCESS',
  'PARTIAL_UPDATE_USER_BY_EMAIL_PREFERENCES_FAILURE',
  'PARTIAL_UPDATE_USER_BY_EMAIL_PREFERENCES_RESET',
);

export const getUserPassports = actionsFactories.createGetManyActionsFactory<EntityType<UserPassport>>()(
  'GET_USER_PASSPORTS_REQUEST',
  'GET_USER_PASSPORTS_SUCCESS',
  'GET_USER_PASSPORTS_FAILURE',
  'GET_USER_PASSPORTS_RESET',
);

export const createUserPassport = actionsFactories.createCreateOneActionsFactory<EntityType<UserPassport>>()(
  'CREATE_USER_PASSPORTS_REQUEST',
  'CREATE_USER_PASSPORTS_SUCCESS',
  'CREATE_USER_PASSPORTS_FAILURE',
  'CREATE_USER_PASSPORTS_RESET',
);
