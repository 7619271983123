import { AudienceAggregatedData, EventRegistrantsEmailMessageDisplay } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import {
  AdminRegistrantsStatsParamsType,
  CreateAdminRegistrantsEmailParamsType,
  GetAdminRegistrantsEmailParamsType,
} from './types';

export const getAdminRegistrantsEmail = actionsFactories.createGetOneActionsFactory<
  EntityType<EventRegistrantsEmailMessageDisplay>,
  GetAdminRegistrantsEmailParamsType
>()(
  'GET_ADMIN_REGISTRANTS_EMAIL_REQUEST',
  'GET_ADMIN_REGISTRANTS_EMAIL_SUCCESS',
  'GET_ADMIN_REGISTRANTS_EMAIL_FAILURE',
  'GET_ADMIN_REGISTRANTS_EMAIL_RESET',
);

export const createAdminRegistrantsEmail = actionsFactories.createCreateOneActionsFactory<
  EntityType<EventRegistrantsEmailMessageDisplay>,
  CreateAdminRegistrantsEmailParamsType
>()(
  'CREATE_ADMIN_REGISTRANTS_EMAIL_REQUEST',
  'CREATE_ADMIN_REGISTRANTS_EMAIL_SUCCESS',
  'CREATE_ADMIN_REGISTRANTS_EMAIL_FAILURE',
  'CREATE_ADMIN_REGISTRANTS_EMAIL_RESET',
  keyWindowNames.adminRegistrantsEmail,
);

export const getAdminRegistrantsEmails = actionsFactories.createGetPaginatedManyActionsFactory()(
  'GET_ADMIN_REGISTRANTS_EMAILS_REQUEST',
  'GET_ADMIN_REGISTRANTS_EMAILS_SUCCESS',
  'GET_ADMIN_REGISTRANTS_EMAILS_FAILURE',
  'GET_ADMIN_REGISTRANTS_EMAILS_RESET',
  keyWindowNames.adminEventRegistrantsEmails,
);

export const getAdminRegistrantsStats = actionsFactories.createGetOneActionsFactory<
  EntityType<AudienceAggregatedData>,
  AdminRegistrantsStatsParamsType
>()(
  'GET_ADMIN_REGISTRANTS_STATS_REQUEST',
  'GET_ADMIN_REGISTRANTS_STATS_SUCCESS',
  'GET_ADMIN_REGISTRANTS_STATS_FAILURE',
  'GET_ADMIN_REGISTRANTS_STATS_RESET',
);
