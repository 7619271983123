import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';
import { EntityType } from '../../../entities/types';
import { SocialLinks } from '../../../../api/general/models/SocailLinks';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory<EntityType<SocialLinks>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const socialLinksReducer = createReducer<State, Actions>(initialState, {
  [actions.socialLinks.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.socialLinks.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.socialLinks.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.socialLinks.reset.type]: actionsHandlers.handleSingleFeatureReset,
});

export default socialLinksReducer;
