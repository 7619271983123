import { get } from './client';
import { buildPath, toJson } from './utils';
import { ORGANIZATIONS_PATH } from './constants';
import { GeneralListType, IdType } from './types';
import { OrganizationDetail, OrganizationListGuestUser } from './general/models';
import { OrganizationType } from './admin/models';

const TYPES = 'types';

export const getOrganizations = (options: any): Promise<GeneralListType<OrganizationListGuestUser>> =>
  get(buildPath(ORGANIZATIONS_PATH), options).then(toJson);

export const getOrganization = (id: IdType, options: any): Promise<OrganizationDetail> =>
  get(buildPath(ORGANIZATIONS_PATH, id), options).then(toJson);

export const getOrganizationTypes = (options: any): Promise<GeneralListType<OrganizationType>> =>
  get(buildPath(ORGANIZATIONS_PATH, TYPES), options).then(toJson);
