import { UserPreferences } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<UserPreferences>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const userPreferencesReducer = createReducer<State, Actions>(initialState, {
  [actions.getUserPreferences.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getUserPreferences.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getUserPreferences.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.partialUpdateUserPreferences.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.partialUpdateUserPreferences.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.partialUpdateUserPreferences.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.partialUpdateUserPreferences.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default userPreferencesReducer;
