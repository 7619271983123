// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Address,
  BillingAddress,
  ImageDisplay,
  LanguageSpoken,
  Market,
  UserInterest,
  UserOccupation,
  ZoomUserOAuthData,
} from '.';

/**
 * @export
 * @interface User
 */
export interface User {
  /**
   * @type {number}
   * @memberof User
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof User
   */
  email?: string | null;
  /**
   * @type {string}
   * @memberof User
   */
  first_name?: string;
  /**
   * @type {string}
   * @memberof User
   */
  last_name?: string;
  /**
   * @type {string}
   * @memberof User
   */
  date_joined?: string;
  /**
   * @type {string}
   * @memberof User
   */
  last_login?: string | null;
  /**
   * @type {boolean}
   * @memberof User
   */
  is_active?: boolean;
  /**
   * @type {string}
   * @memberof User
   */
  title?: UserTitleEnum;
  /**
   * @type {string}
   * @memberof User
   */
  readonly title_display?: string;
  /**
   * @type {string}
   * @memberof User
   */
  maiden_name?: string;
  /**
   * @type {string}
   * @memberof User
   */
  phone?: string;
  /**
   * @type {string}
   * @memberof User
   */
  home_phone?: string;
  /**
   * @type {string}
   * @memberof User
   */
  work_phone?: string;
  /**
   * @type {string}
   * @memberof User
   */
  gender?: UserGenderEnum;
  /**
   * @type {string}
   * @memberof User
   */
  readonly gender_display?: string;
  /**
   * @type {string}
   * @memberof User
   */
  birth_date?: string | null;
  /**
   * @type {Address}
   * @memberof User
   */
  address: Address;
  /**
   * @type {BillingAddress}
   * @memberof User
   */
  billing_address: BillingAddress;
  /**
   * @type {string}
   * @memberof User
   */
  about?: string;
  /**
   * @type {UserOccupation}
   * @memberof User
   */
  occupation?: UserOccupation;
  /**
   * @type {Array<UserInterest>}
   * @memberof User
   */
  readonly interests?: Array<UserInterest>;
  /**
   * @type {boolean}
   * @memberof User
   */
  billing_alert?: boolean;
  /**
   * @type {boolean}
   * @memberof User
   */
  notifications?: boolean;
  /**
   * @type {boolean}
   * @memberof User
   */
  is_terms_and_conditions_accepted?: boolean;
  /**
   * @type {Market}
   * @memberof User
   */
  primary_city?: Market | null;
  /**
   * @type {string}
   * @memberof User
   */
  mailing_address?: string | null;
  /**
   * @type {string}
   * @memberof User
   */
  children_in_household?: string;
  /**
   * @type {string}
   * @memberof User
   */
  preferred_language?: UserPreferredLanguageEnum;
  /**
   * @type {string}
   * @memberof User
   */
  readonly preferred_language_display?: string;
  /**
   * @type {Array<LanguageSpoken>}
   * @memberof User
   */
  readonly languages_spoken?: Array<LanguageSpoken>;
  /**
   * @type {string}
   * @memberof User
   */
  religion?: UserReligionEnum;
  /**
   * @type {string}
   * @memberof User
   */
  readonly religion_display?: string;
  /**
   * @type {string}
   * @memberof User
   */
  jewish_descendants?: UserJewishDescendantsEnum;
  /**
   * @type {string}
   * @memberof User
   */
  readonly jewish_descendants_display?: string;
  /**
   * @type {string}
   * @memberof User
   */
  relationship_status?: UserRelationshipStatusEnum;
  /**
   * @type {string}
   * @memberof User
   */
  readonly relationship_status_display?: string;
  /**
   * @type {ImageDisplay}
   * @memberof User
   */
  image: ImageDisplay;
  /**
   * @type {string}
   * @memberof User
   */
  readonly has_zoom_connected?: string;
  /**
   * @type {string}
   * @memberof User
   */
  readonly is_admin?: string;
  /**
   * @type {boolean}
   * @memberof User
   */
  readonly is_super_admin?: boolean;
  /**
   * @type {boolean}
   * @memberof User
   */
  readonly is_market_admin?: boolean;
  /**
   * @type {boolean}
   * @memberof User
   */
  google_sync?: boolean;
  /**
   * @type {ZoomUserOAuthData}
   * @memberof User
   */
  zoom_oauth_data?: ZoomUserOAuthData;
}

/**
 * @export
 * @enum {string}
 */
export enum UserTitleEnum {
  Empty = '',
  Dr = 'dr',
  Mr = 'mr',
  Mrs = 'mrs',
  Ms = 'ms',
  Miss = 'miss',
  Rabbi = 'rabbi',
  Cantor = 'cantor',
}
/**
 * @export
 * @enum {string}
 */
export enum UserGenderEnum {
  Empty = '',
  F = 'f',
  M = 'm',
  Transgender = 'transgender',
  NonBinary = 'non_binary',
  Other = 'other',
  NoAnswer = 'no_answer',
}
/**
 * @export
 * @enum {string}
 */
export enum UserPreferredLanguageEnum {
  En = 'en',
  Fr = 'fr',
}
/**
 * @export
 * @enum {string}
 */
export enum UserReligionEnum {
  Empty = '',
  ReformJudaism = 'reform_judaism',
  ConservativeJudaism = 'conservative_judaism',
  ConservadoxJudaism = 'conservadox_judaism',
  OrthodoxJudaism = 'orthodox_judaism',
  ModernOrthodoxJudaism = 'modern_orthodox_judaism',
  HarediOrthodoxJudaism = 'haredi_orthodox_judaism',
  HasidicJudaism = 'hasidic_judaism',
  ReconstructionistJudaism = 'reconstructionist_judaism',
  JustJewish = 'just_jewish',
  CulturallyJewish = 'culturally_jewish',
  TraditionalJudaism = 'traditional_judaism',
  Other = 'other',
  NotJewish = 'not_jewish',
  JewishRenewal = 'jewish_renewal',
}
/**
 * @export
 * @enum {string}
 */
export enum UserJewishDescendantsEnum {
  Empty = '',
  Ashkenazi = 'ashkenazi',
  Sephardic = 'sephardic',
  Mizrahi = 'mizrahi',
}
/**
 * @export
 * @enum {string}
 */
export enum UserRelationshipStatusEnum {
  Empty = '',
  Married = 'married',
  CivilUnion = 'civil_union',
  Single = 'single',
  Divorced = 'divorced',
  Engaged = 'engaged',
  InRelationship = 'in_relationship',
  Complicated = 'complicated',
  Widowed = 'widowed',
}
