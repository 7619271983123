import { call, fork, take, takeEvery } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import { AdminEventsApi } from 'api';
import { isServer } from 'utils/next';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import { createLoadUnsavedIds, createSyncUpdateUnsaved } from '../adminEvents/unsavedUtils';

const UNSAVED_TYPE = 'settings';

export const loadUnsavedIds = createLoadUnsavedIds({
  type: UNSAVED_TYPE,
  action: actions.addUnsavedAdminEventSettings,
});

export const updateUnsavedAdminEventSettings = createSyncUpdateUnsaved({
  type: UNSAVED_TYPE,
  actions: actions.updateAdminEventSettings,
  entityBuilder: (data) => data,
});

const handleGetAdminEventSettings = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminEventSettings,
  request: AdminEventsApi.getAdminEventSettings,
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleRuntimeUpdateAdminEventSettings = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminEventSettings,
  request: AdminEventsApi.partialUpdateAdminEventSettings,
  transformResponse: (response, action) => ({ ...response, id: action.payload.id }),
});

const handleUpdateAdminEventSettings = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminEventSettings,
  request: AdminEventsApi.partialUpdateAdminEventSettings,
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

export default function* adminEventSettingsSagas() {
  yield takeEvery(actions.getAdminEventSettings.request.type, handleGetAdminEventSettings);
  yield takeEvery(actions.updateAdminEventSettings.request.type, handleUpdateAdminEventSettings);
  yield fork(handleRuntimeUpdateAdminEventSettings);

  if (!isServer()) {
    yield fork(function* wait() {
      yield take(HYDRATE);
      yield call(loadUnsavedIds);
    });
  }
}
