import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { DefaultMetaTagsApi } from 'api';
import { GetAction } from 'store/types';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetDefaultMetaTagsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getDefaultMetaTags,
  request: DefaultMetaTagsApi.getDefaultMetaTags,
});

function* handleGetNextDefaultMetaTags(action: GetAction<typeof actions.getDefaultMetaTags.success>) {
  const { entities, params } = action.payload;
  if (entities.next) {
    const { page, page_size } = yield call(getNextPageParams, params.page_size, selectors.defaultMetaTagsState);
    yield put(actions.getDefaultMetaTags.request({ params: { ...params, page, page_size } }));
  }
}

export default function* defaultMetaTagsSagas() {
  yield takeLeading(actions.getDefaultMetaTags.request.type, handleGetDefaultMetaTagsRequest);
  yield takeEvery(actions.getDefaultMetaTags.success.type, handleGetNextDefaultMetaTags);
}
