import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationVideosSlice = (state: RootState) => state.entities.adminOrganizationVideos;

export const adminOrganizationVideosState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationVideosSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationVideos = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminOrganizationVideosSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationVideoCreatingState =
  selectorsFactory.createCreatingStateSelector(adminOrganizationVideosSlice);
