import { RootState } from 'store/rootReducer';
import { createSelector } from 'reselect';

export const getHeroImage = (state: RootState) => state.features.eventDraft.heroImage.item;
export const getErrorEvent = (state: RootState) => state.features.eventDraft.errorEvent.error;
export const isHeroImageUploading = (state: RootState) => state.features.eventDraft.heroImage.isUploading;

export const getMedia = (state: RootState) => state.features.eventDraft.media.items;
export const isMediaPending = (state: RootState) => state.features.eventDraft.media.isPending;

export const createEventDraft = (state: RootState) => state.features.eventDraft.createEventDraft;

export const eventError = createSelector(getErrorEvent, (error) => {
  let errors = {};
  try {
    if (error?.response?.responseText) errors = JSON.parse(error.response.responseText);
  } catch (e) {}
  return errors;
});
