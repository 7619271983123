// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PromoCode
 */
export interface PromoCode {
  /**
   * @type {number}
   * @memberof PromoCode
   */
  readonly id?: number;
  /**
   * @type {number}
   * @memberof PromoCode
   */
  discount_value?: number;
  /**
   * @type {string}
   * @memberof PromoCode
   */
  discount_type?: PromoCodeDiscountTypeEnum;
  /**
   * @type {string}
   * @memberof PromoCode
   */
  strategy?: PromoCodeStrategyEnum;
  /**
   * @type {string}
   * @memberof PromoCode
   */
  code: string;
  /**
   * @type {string}
   * @memberof PromoCode
   */
  valid_from?: string;
  /**
   * @type {string}
   * @memberof PromoCode
   */
  valid_to: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PromoCodeDiscountTypeEnum {
  Flat = 'flat',
  Percent = 'percent',
}
/**
 * @export
 * @enum {string}
 */
export enum PromoCodeStrategyEnum {
  All = 'all',
  Ticket = 'ticket',
}
