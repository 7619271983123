import { BaseDefaultQuestionDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<BaseDefaultQuestionDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminTicketQuestionsReducer = createReducer<State, Actions>(initialState, {
  [actions.createAdminTicketQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminTicketQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminTicketQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminTicketQuestion.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminTicketQuestion.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminTicketQuestion.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminTicketQuestion.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminTicketQuestion.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.cloneAdminTicketQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.cloneAdminTicketQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.cloneAdminTicketQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.cloneAdminTicketQuestion.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.deleteAdminTicketQuestion.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminTicketQuestion.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminTicketQuestion.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminTicketQuestionsReducer;
