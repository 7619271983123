import { EntityType } from 'store/entities/types';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { AdminEventSettingsActions } from 'store/actions';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

// todo: deal with type conflicts
const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<any>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions & typeof AdminEventSettingsActions>;

const adminForRoleEventsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminForRoleEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminForRoleEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminForRoleEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminForRoleEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default adminForRoleEventsReducer;
