import { call, put, race, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminEventsApi, UploadApi } from 'api';
import { apiCall } from 'store/utils';
import { GetAction } from 'store/types';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import { getNextMediaOrder } from '../adminEvents/sagas';

function* getNextImageOrder(imageType: string, eventId: number): any {
  if (imageType === 'hero_image') return 1;
  return yield call(getNextMediaOrder, { eventId });
}

const handleGetAdminEventImagesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventImages,
  request: AdminEventsApi.getImages,
  requestArgsBuilder: (action) => action.payload.params.eventId,
});

const handleCreateAdminEventImageRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminEventImage,
  request: AdminEventsApi.createImage,
  requestArgsBuilder: function* builder(action): any {
    const { params } = action.payload;
    const { file, imageType, eventId } = params;

    const order = yield call(getNextImageOrder, imageType, eventId);
    const response = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type: imageType,
      },
    });

    return [eventId, { body: { image: response.id, order } }];
  },
});

const handleUpdateAdminEventImageRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminEventImage,
  request: AdminEventsApi.updateImage,
  requestArgsBuilder: function* builder(action): any {
    const { id, entity, params } = action.payload;
    const { eventId, file, imageType } = params;

    if (!(file && imageType)) {
      return [eventId, id, { body: entity }];
    }

    const response = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type: imageType,
      },
    });

    return [eventId, id, { body: { ...entity, image: response.id } }];
  },
});

const handleDeleteAdminEventImageRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEventImage,
  request: AdminEventsApi.deleteImage,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

function* handleUploadAdminEventImages(action: GetAction<typeof actions.createAdminEventImages>) {
  const { files, ...rest } = action.payload.params;
  for (let i = 0; i < files.length; i += 1) {
    yield put(actions.createAdminEventImage.request({ params: { file: files[i], ...rest } }));
    yield race([take(actions.createAdminEventImage.success.type), take(actions.createAdminEventImage.failure.type)]);
  }
}

export default function* adminEventImagesSagas() {
  yield takeEvery(actions.getAdminEventImages.request.type, handleGetAdminEventImagesRequest);
  yield takeEvery(actions.createAdminEventImage.request.type, handleCreateAdminEventImageRequest);
  yield takeEvery(actions.updateAdminEventImage.request.type, handleUpdateAdminEventImageRequest);
  yield takeEvery(actions.deleteAdminEventImage.request.type, handleDeleteAdminEventImageRequest);
  yield takeLeading(actions.createAdminEventImages.type, handleUploadAdminEventImages);
}
