import { call, takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import { getNextMediaOrder } from '../adminOrganizations/sagas';

const handleGetAdminOrganizationVideosRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationVideos,
  request: AdminOrganizationsApi.getVideos,
  requestArgsBuilder: (action) => action.payload.params.organizationId,
});

const handleCreateAdminOrganizationVideoRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminOrganizationVideo,
  request: AdminOrganizationsApi.createVideo,
  requestArgsBuilder: function* builder(action) {
    const { params, entity } = action.payload;
    const { organizationId } = params;
    const order: any = yield call(getNextMediaOrder, params);
    return [organizationId, { body: { ...entity, order } }];
  },
});

const handleUpdateAdminOrganizationVideoRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminOrganizationVideo,
  request: AdminOrganizationsApi.updateVideo,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { organizationId } = params;
    return [organizationId, id, { body: entity }];
  },
});

const handleDeleteAdminOrganizationVideoRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminOrganizationVideo,
  request: AdminOrganizationsApi.deleteVideo,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

export default function* adminOrganizationVideosSagas() {
  yield takeEvery(actions.getAdminOrganizationVideos.request.type, handleGetAdminOrganizationVideosRequest);
  yield takeEvery(actions.createAdminOrganizationVideo.request.type, handleCreateAdminOrganizationVideoRequest);
  yield takeEvery(actions.updateAdminOrganizationVideo.request.type, handleUpdateAdminOrganizationVideoRequest);
  yield takeEvery(actions.deleteAdminOrganizationVideo.request.type, handleDeleteAdminOrganizationVideoRequest);
}
