import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const adminEventTicketsStatisticsSlice = (state: RootState) => state.entities.adminEventTicketsStatistics;

export const adminEventTicketsStatisticsUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(
  adminEventTicketsStatisticsSlice,
);

export const adminEventTicketsStatisticsStateById = selectorsFactory.createEntityStateByIdSelector(
  adminEventTicketsStatisticsSlice,
);
