import { actionsFactories } from 'store/entities/utils';
import { Metatag, MetatagToCreateUpdate } from 'api/general/models';
import * as keyWindowNames from './keyWindowNames';
import { EntityType } from '../types';
import { AdminOrganizationMetaTagsParamsType } from './types';

export const getAdminOrganizationMetaTags = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Metatag>
>()(
  'GET_ADMIN_ORGANIZATIONS_META_TAGS_REQUEST',
  'GET_ADMIN_ORGANIZATIONS_META_TAGS_SUCCESS',
  'GET_ADMIN_ORGANIZATIONS_META_TAGS_FAILURE',
  'GET_ADMIN_ORGANIZATIONS_META_TAGS_RESET',
  keyWindowNames.adminOrganizationMetaTags,
);

export const createAdminOrganizationMetaTags = actionsFactories.createCreateManyActionsFactory<
  EntityType<MetatagToCreateUpdate>,
  AdminOrganizationMetaTagsParamsType
>()(
  'CREATE_ADMIN_ORGANIZATIONS_META_TAGS_REQUEST',
  'CREATE_ADMIN_ORGANIZATIONS_META_TAGS_SUCCESS',
  'CREATE_ADMIN_ORGANIZATIONS_META_TAGS_FAILURE',
  'CREATE_ADMIN_ORGANIZATIONS_META_TAGS_RESET',
  keyWindowNames.adminOrganizationMetaTags,
);

export const updateAdminOrganizationMetaTags = actionsFactories.createUpdateManyActionsFactory<
  EntityType<MetatagToCreateUpdate>,
  AdminOrganizationMetaTagsParamsType
>()(
  'UPDATE_ADMIN_ORGANIZATIONS_META_TAGS_REQUEST',
  'UPDATE_ADMIN_ORGANIZATIONS_META_TAGS_SUCCESS',
  'UPDATE_ADMIN_ORGANIZATIONS_META_TAGS_FAILURE',
  'UPDATE_ADMIN_ORGANIZATIONS_META_TAGS_RESET',
  keyWindowNames.adminOrganizationMetaTags,
);

export const deleteAdminOrganizationMetaTags =
  actionsFactories.createDeleteOneActionsFactory<AdminOrganizationMetaTagsParamsType>()(
    'DELETE_ADMIN_ORGANIZATIONS_META_TAGS_REQUEST',
    'DELETE_ADMIN_ORGANIZATIONS_META_TAGS_SUCCESS',
    'DELETE_ADMIN_ORGANIZATIONS_META_TAGS_FAILURE',
  );
