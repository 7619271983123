import { AdminTicketDetails, TicketCreate, TicketUpdate } from 'api/admin/models';
import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { AdminEventTicketsParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminTickets = actionsFactories.createGetManyActionsFactory<
  EntityType<AdminTicketDetails>,
  AdminEventTicketsParamsType
>()(
  'GET_ADMIN_TICKETS_REQUEST',
  'GET_ADMIN_TICKETS_SUCCESS',
  'GET_ADMIN_TICKETS_FAILURE',
  'GET_ADMIN_TICKETS_RESET',
  keyWindowNames.adminEventTickets,
);

export const createAdminTicket = actionsFactories.createCreateOneActionsFactory<
  EntityType<TicketCreate>,
  AdminEventTicketsParamsType
>()(
  'CREATE_ADMIN_TICKET_REQUEST',
  'CREATE_ADMIN_TICKET_SUCCESS',
  'CREATE_ADMIN_TICKET_FAILURE',
  'CREATE_ADMIN_TICKET_RESET',
  keyWindowNames.adminEventTickets,
);

export const updateAdminTicket = actionsFactories.createUpdateOneActionsFactory<
  EntityType<TicketUpdate>,
  AdminEventTicketsParamsType
>()(
  'UPDATE_ADMIN_TICKET_REQUEST',
  'UPDATE_ADMIN_TICKET_SUCCESS',
  'UPDATE_ADMIN_TICKET_FAILURE',
  'UPDATE_ADMIN_TICKET_RESET',
);

export const runtimeUpdateAdminTicket = actionsFactories.createUpdateOneActionsFactory<
  EntityType<TicketUpdate>,
  AdminEventTicketsParamsType
>()(
  'RUNTIME_UPDATE_ADMIN_TICKET_REQUEST',
  'RUNTIME_UPDATE_ADMIN_TICKET_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_TICKET_FAILURE',
  'RUNTIME_UPDATE_ADMIN_TICKET_RESET',
);

export const deleteAdminTicket = actionsFactories.createDeleteOneActionsFactory<AdminEventTicketsParamsType>()(
  'DELETE_ADMIN_TICKET_REQUEST',
  'DELETE_ADMIN_TICKET_SUCCESS',
  'DELETE_ADMIN_TICKET_FAILURE',
);

export const addUnsavedAdminTicket = createAction('ADD_UNSAVED_ADMIN_TICKET', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeUnsavedAdminTicket = createAction('REMOVE_UNSAVED_ADMIN_TICKET', (id: number) => ({
  payload: {
    id,
  },
}));

export const addIgnoredUnsavingAdminTicket = createAction('ADD_IGNORED_UNSAVING_ADMIN_TICKET', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeIgnoredUnsavingAdminTicket = createAction('REMOVE_IGNORED_UNSAVING_ADMIN_TICKET', (id: number) => ({
  payload: {
    id,
  },
}));
