import { RootState } from 'store/rootReducer';
import { createSelector } from 'reselect';
import { selectorsFactory } from '../utils';
import * as keyWindowNames from './keyWindowNames';

export const adminEventRegistrantsEmailsListSlice = (state: RootState) =>
  state.entities.adminEventRegistrantsEmails.registrantsEmailsList;

export const adminEventRegistrantsStatsSlice = (state: RootState) =>
  state.entities.adminEventRegistrantsEmails.registrantsStats;

export const adminEventRegistrantsEmailsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventRegistrantsEmailsListSlice,
  keyWindowNames.adminEventRegistrantsEmails,
);

export const adminEventRegistrantsEmailsPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminEventRegistrantsEmailsListSlice,
  keyWindowNames.adminEventRegistrantsEmails,
);

export const adminEventRegistrantsEmailsById = selectorsFactory.createEntityStateByIdSelector(
  adminEventRegistrantsEmailsListSlice,
);

export const adminEventRegistrantsEmailsCreatingState = selectorsFactory.createCreatingStateSelector(
  adminEventRegistrantsEmailsListSlice,
);

export const adminEventRegistrantsStatsStateById = selectorsFactory.createEntityStateByIdSelector(
  adminEventRegistrantsStatsSlice,
);

export const registrantsStatsByGroupTypes = createSelector(adminEventRegistrantsStatsStateById, (stats) => {
  if (stats.entity) {
    const { buyers_count, registrants_count, checked_in_count, not_yet_checked_in_count, canceled_count } =
      stats.entity;
    return {
      all_buyers: buyers_count,
      all_attendees: registrants_count,
      checked_in_attendees: checked_in_count,
      not_yet_checked_in_attendees: not_yet_checked_in_count,
      cancelled: canceled_count,
    };
  }
});

export const sortedAdminEventRegistrantsEmails = createSelector(adminEventRegistrantsEmailsState, (state) =>
  state?.entities.slice().sort((a: any, b: any) => b.id - a.id),
);
