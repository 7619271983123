// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RecommendedCity
 */
export interface RecommendedCity {
  /**
   * @type {number}
   * @memberof RecommendedCity
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof RecommendedCity
   */
  title?: string;
  /**
   * @type {string}
   * @memberof RecommendedCity
   */
  country: RecommendedCityCountryEnum;
  /**
   * @type {string}
   * @memberof RecommendedCity
   */
  slug?: string | null;
  /**
   * @type {number}
   * @memberof RecommendedCity
   */
  distance?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RecommendedCityCountryEnum {
  Ca = 'CA',
  Us = 'US',
}
