import { BaseDefaultQuestionDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<BaseDefaultQuestionDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminAddonQuestionsReducer = createReducer<State, Actions>(initialState, {
  [actions.createAdminAddonQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminAddonQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminAddonQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminAddonQuestion.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminAddonQuestion.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminAddonQuestion.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminAddonQuestion.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminAddonQuestion.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.cloneAdminAddonQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.cloneAdminAddonQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.cloneAdminAddonQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.cloneAdminAddonQuestion.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.deleteAdminAddonQuestion.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminAddonQuestion.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminAddonQuestion.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminAddonQuestionsReducer;
