import { get, patch } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_TRANSLATIONS_PATH } from './constants';
import { IdType } from './types';
import { AdditionalinstructionTranslation, DonationTranslation, EventTranslation } from './admin/models';

const EVENT_PATH = 'event';
const ADDITIONALINSTRUCTIONS = 'additionalinstructions';
const DONATION = 'donation';

export const getEventTranslation = (eventId: IdType, options: any): Promise<EventTranslation> =>
  get(buildPath(ADMIN_TRANSLATIONS_PATH, EVENT_PATH, eventId), options).then(toJson);

export const updateEventTranslation = (eventId: IdType, options: any): Promise<EventTranslation> =>
  patch(buildPath(ADMIN_TRANSLATIONS_PATH, EVENT_PATH, eventId), options).then(toJson);

export const getAdminAdditionalInstructionTranslations = (
  eventId: IdType,
  options: any,
): Promise<AdditionalinstructionTranslation> =>
  get(buildPath(ADMIN_TRANSLATIONS_PATH, EVENT_PATH, eventId, ADDITIONALINSTRUCTIONS), options).then(toJson);

export const updateAdminAdditionalInstructionTranslation = (
  eventId: IdType,
  id: IdType,
  options: any,
): Promise<AdditionalinstructionTranslation> =>
  patch(buildPath(ADMIN_TRANSLATIONS_PATH, EVENT_PATH, eventId, ADDITIONALINSTRUCTIONS, id), options).then(toJson);

export const getAdminDonationTranslation = (eventId: IdType, options: any): Promise<DonationTranslation> =>
  get(buildPath(ADMIN_TRANSLATIONS_PATH, EVENT_PATH, eventId, DONATION), options).then(toJson);

export const updateAdminDonationTranslation = (eventId: IdType, options: any): Promise<DonationTranslation> =>
  patch(buildPath(ADMIN_TRANSLATIONS_PATH, EVENT_PATH, eventId, DONATION), options).then(toJson);
