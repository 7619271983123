// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface BillingAddress
 */
export interface BillingAddress {
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  address_additional_info?: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  address_name?: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  address1: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  address2?: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  postal_code?: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  state?: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  readonly state_display?: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  city: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  country: BillingAddressCountryEnum;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  readonly country_display?: string;
  /**
   * @type {string}
   * @memberof BillingAddress
   */
  tax_number?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum BillingAddressCountryEnum {
  Ca = 'CA',
  Us = 'US',
}
