import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminZoomEventsSlice = (state: RootState) => state.entities.adminZoomEvents;

export const adminZoomMeetingsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(
  adminZoomEventsSlice,
  keyWindowNames.meetings,
);

export const adminZoomWebinarsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(
  adminZoomEventsSlice,
  keyWindowNames.webinars,
);
