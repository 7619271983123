const mixins: any = {
  toolbar: {
    minHeight: 100,
    '@media (max-width: 600px)': {
      minHeight: 72,
    },
  },
  appBar: {
    minHeight: 64,
    '@media (max-width: 600px)': {
      minHeight: 56,
    },
  },
  scrollBarOverflowYAuto: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  disableScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    'scrollbar-width': 'none',
    msOverflowStyle: 'none',
  },
  disableArrowBar: {
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
} as const;

export default mixins;
