// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * @type {string}
   * @memberof Address
   */
  address_additional_info?: string;
  /**
   * @type {string}
   * @memberof Address
   */
  address_name?: string;
  /**
   * @type {string}
   * @memberof Address
   */
  address1: string;
  /**
   * @type {string}
   * @memberof Address
   */
  address2?: string;
  /**
   * @type {string}
   * @memberof Address
   */
  postal_code?: string;
  /**
   * @type {string}
   * @memberof Address
   */
  state?: string;
  /**
   * @type {string}
   * @memberof Address
   */
  readonly state_display?: string;
  /**
   * @type {string}
   * @memberof Address
   */
  city: string;
  /**
   * @type {string}
   * @memberof Address
   */
  country: AddressCountryEnum;
  /**
   * @type {string}
   * @memberof Address
   */
  readonly country_display?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AddressCountryEnum {
  Ca = 'CA',
  Us = 'US',
}
