import { Tag } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Tag>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const tagsReducer = createReducer<State, Actions>(initialState, {
  [actions.getWhatsIncludedTags.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getWhatsIncludedTags.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getWhatsIncludedTags.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getWhatsIncludedTags.reset.type]: actionsHandlers.handleGetManyReset,
});

export default tagsReducer;
