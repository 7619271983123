import { EventQuestionStatistics } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventQuestionStatistics>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventNeighborhoodStatisticsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventNeighborhoodStatistics.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminEventNeighborhoodStatistics.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminEventNeighborhoodStatistics.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminEventNeighborhoodStatistics.reset.type]: actionsHandlers.handleGetOneReset,
});

export default adminEventNeighborhoodStatisticsReducer;
