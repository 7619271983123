import { SerializedError } from './serializeError';

export const isFetchingStates = <S extends { isFetching: boolean; isFetched: boolean }[]>(...states: S): boolean =>
  states.some((state) => state.isFetching || !state.isFetched);

export const isFetchedStates = <S extends { isFetched: boolean; entity?: any; entities?: any }[]>(
  ...states: S
): boolean => states.every((state) => state.isFetched && Boolean(state.entity || state.entities?.length));

export const isCreatingStates = <S extends { isCreating: boolean }[]>(...states: S): boolean =>
  states.some((state) => state.isCreating);

export const isPendingStates = <S extends { isPending: boolean }[]>(...states: S): boolean =>
  states.some((state) => state.isPending);

export const getErrorFromStates = <S extends { fetchingError: SerializedError }[]>(...states: S): SerializedError[] =>
  states.filter((state) => state.fetchingError).map((state) => state.fetchingError);

export const isUserHasPermission = (...permissions: boolean[]): boolean =>
  permissions.every((permission) => permission);
