import { get, put } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_ORGANIZATIONS_PATH, API_GENERAL_PATH } from './constants';

const SETTINGS = 'settings';
const CHECK_RECAPTCHA = 'check-recaptcha';
const UPDATE_EVENTS_RECAPTCHA = 'update-events-recaptcha';

export const checkRecaptcha = (options: any) => get(buildPath(API_GENERAL_PATH, CHECK_RECAPTCHA), options).then(toJson);

export const updateAdminOrganizationEventsReCaptcha = (orgId: number, options: any) =>
  put(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, SETTINGS, UPDATE_EVENTS_RECAPTCHA), options).then(toJson);
