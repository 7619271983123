import { AdminAddOnDetails } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import {
  createAddIdActionHandler,
  createRemoveIdActionHandler,
  createUnsavingState,
} from 'store/entities/adminEvents/unsavedUtils';
import * as questionsActions from 'store/entities/adminQuestions/actions';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AdminAddOnDetails>>();
const initialState = stateFactory.getInitialState({
  ...createUnsavingState(),
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

export const handleCreateQuestion = (entity: any, action: any) => {
  if (entity) entity.questions.push(action.payload.entity);
};

export const handleUpdateQuestion = (entity: any, action: any) => {
  if (entity) {
    const index = entity.questions.findIndex((question: any) => question.id === action.payload.entity.id);
    if (index !== -1) {
      entity.questions[index] = {
        ...entity.questions[index],
        ...action.payload.entity,
      };
    }
  }
};

export const handleDeleteSpecificEntityQuestion = (entity: any, action: any) => {
  if (entity) {
    const index = entity.questions.findIndex((question: any) => question.id === action.payload.id);
    if (index > -1) {
      entity.questions.splice(index, 1);
    }
  }
};

export const handleUpdateQuestionOption = (entity: any, action: any) => {
  if (entity) {
    const questionIndex = entity.questions.findIndex(
      (question: any) => question.id === action.payload.params.questionId,
    );
    if (questionIndex > -1) {
      const index = entity.questions[questionIndex].options.findIndex(
        (option: any) => option.id === action.payload.entity.id,
      );
      if (index > -1) {
        entity.questions[questionIndex].options[index] = {
          ...entity.questions[questionIndex].options[index],
          ...action.payload.entity,
        };
      }
    }
  }
};

export const handleCreateQuestionOption = (entity: any, action: any) => {
  if (entity) {
    const index = entity.questions.findIndex((question: any) => question.id === action.payload.params.questionId);
    if (index !== -1) {
      if ('options' in entity.questions[index]) {
        entity.questions[index].options.push(action.payload.entity);
      } else {
        entity.questions[index].options = [action.payload.entity];
      }
    }
  }
};

export const handleDeleteQuestionOption = (entity: any, action: any) => {
  if (entity) {
    const questionIndex = entity.questions.findIndex(
      (question: any) => question.id === action.payload.params.questionId,
    );
    if (questionIndex > -1) {
      const index = entity.questions[questionIndex].options.findIndex((option: any) => option.id === action.payload.id);
      if (index > -1) {
        entity.questions[questionIndex].options.splice(index, 1);
      }
    }
  }
};

export const handleFindEntityId = (draft: any, action: any) => {
  const entityId = Object.keys(draft.byId).find((addonId) =>
    draft.byId[addonId].questions.some((question: any) => question.id === action.payload.params.questionId),
  );
  return entityId;
};

// todo: refactor duplicate code
const adminAddonsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminAddons.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminAddons.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminAddons.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminAddons.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminAddon.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminAddon.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminAddon.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminAddon.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminAddon.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminAddon.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminAddon.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminAddon.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.runtimeUpdateAdminAddon.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.runtimeUpdateAdminAddon.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.runtimeUpdateAdminAddon.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.runtimeUpdateAdminAddon.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminAddon.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminAddon.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminAddon.failure.type]: actionsHandlers.handleDeleteOneFailure,
  [actions.addUnsavedAdminAddon.type]: createAddIdActionHandler('unsavedIds'),
  [actions.removeUnsavedAdminAddon.type]: createRemoveIdActionHandler('unsavedIds'),
  [actions.addIgnoredUnsavingAdminAddon.type]: createAddIdActionHandler('ignoredUnsavingIds'),
  [actions.removeIgnoredUnsavingAdminAddon.type]: createRemoveIdActionHandler('ignoredUnsavingIds'),
  [questionsActions.cloneAdminAddonQuestion.success.type as any]: (draft: any, action: any) => {
    const addon = draft.byId[action.payload.params.entityId];
    handleCreateQuestion(addon, action);
  },
  [questionsActions.createAdminAddonQuestion.success.type as any]: (draft: any, action: any) => {
    const addon = draft.byId[action.payload.params.entityId];
    handleCreateQuestion(addon, action);
  },
  [questionsActions.updateAdminAddonQuestion.success.type as any]: (draft: any, action: any) => {
    const addon: any = draft.byId[action.payload.params.entityId];
    handleUpdateQuestion(addon, action);
  },
  [questionsActions.deleteAdminAddonQuestion.success.type as any]: (draft: any, action: any) => {
    const addon = draft.byId[action.payload.params.entityId];
    handleDeleteSpecificEntityQuestion(addon, action);
  },
  [questionsActions.updateAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const addonId = handleFindEntityId(draft, action);
    const addon: any = draft.byId[addonId];
    handleUpdateQuestionOption(addon, action);
  },
  [questionsActions.createAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const addonId = handleFindEntityId(draft, action);
    const addon: any = draft.byId[addonId];
    handleCreateQuestionOption(addon, action);
  },
  [questionsActions.deleteAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const addonId = handleFindEntityId(draft, action);
    const addon: any = draft.byId[addonId];
    handleDeleteQuestionOption(addon, action);
  },
  [questionsActions.hideAdminQuestion.success.type as any]: (draft: any, action: any) => {
    Object.keys(draft.byId).forEach((id) => {
      const addon = draft.byId[id];
      addon.questions.forEach((question: { id: any; is_hidden: any }) => {
        if (question.id === action.payload.id) {
          question.is_hidden = action.payload.result.is_hidden;
        }
      });
    });
  },
});

export default adminAddonsReducer;
