import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { donationFieldsNames } from './fieldsNames';
import {
  createHasTranslations,
  createMissedTranslationsCounter,
  defaultLanguage,
} from '../adminEventTranslations/selectors';

export const adminDonationTranslationsSlice = (state: RootState) => state.entities.adminDonationTranslations;

export const adminDonationTranslationById = selectorsFactory.createEntityByIdSelector(adminDonationTranslationsSlice);

export const adminDonationTranslationStateById =
  selectorsFactory.createEntityStateByIdSelector(adminDonationTranslationsSlice);

export const getMissedDonationTranslations = createSelector(
  adminDonationTranslationById,
  createMissedTranslationsCounter(donationFieldsNames),
);

export const hasDonationDefaultLanguageTranslations = createSelector(
  adminDonationTranslationById,
  createHasTranslations(donationFieldsNames, defaultLanguage),
);
