import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/features/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const confirmOauthAuthorizationReducer = createReducer<State, Actions>(initialState, {
  [actions.confirmOauthAuthorization.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.confirmOauthAuthorization.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.confirmOauthAuthorization.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.confirmOauthAuthorization.reset.type]: actionsHandlers.handleSingleFeatureReset,
});

export default confirmOauthAuthorizationReducer;
