import { call, takeEvery } from 'redux-saga/effects';
import { AdminEventsApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminRegistrantsEmailRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminRegistrantsEmail,
  request: AdminEventsApi.getAdminEventRegistrantsEmail,
  requestArgsBuilder: (action) => [action.payload.params.eventId, action.payload.id],
});

const handleGetAdminRegistrantsEmailsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminRegistrantsEmails,
  request: AdminEventsApi.getAdminEventRegistrantsEmails,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { eventId, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminEventRegistrantsEmailsState, params);

    return [eventId, { params: { ...rest, page, page_size } }];
  },
});

const handleGetAdminRegistrantsStatsRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminRegistrantsStats,
  request: AdminEventsApi.getAdminEventRegistrantsStats,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    return [id, { params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleCreateAdminRegistrantsEmailRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminRegistrantsEmail,
  request: AdminEventsApi.createAdminEventRegistrantsEmails,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, { body: { ...entity } }];
  },
});

export default function* eventsSagas() {
  yield takeEvery(actions.getAdminRegistrantsEmails.request.type, handleGetAdminRegistrantsEmailsRequest);
  yield takeEvery(actions.getAdminRegistrantsEmail.request.type, handleGetAdminRegistrantsEmailRequest);
  yield takeEvery(actions.getAdminRegistrantsStats.request.type, handleGetAdminRegistrantsStatsRequest);
  yield takeEvery(actions.createAdminRegistrantsEmail.request.type, handleCreateAdminRegistrantsEmailRequest);
}
