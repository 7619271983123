const common = ({ path }) => ({ path });
const min = ({ path, min }) => ({ path, min });
const max = ({ path, max }) => ({ path, max });
const notType = ({ path, type }) => ({ path, type });

const string = {
  matches: common,
  url: common,
  email: common,
  min,
  max,
};

const mixed = {
  default: common,
  required: common,
  notType,
};

const date = {
  min,
  max,
};

const prepare = (custom) =>
  Object.keys(custom).reduce((locale, type) => {
    const methods = custom[type];

    locale[type] = Object.keys(methods).reduce((values, method) => {
      values[method] = (args) => (t, namespace, context) => {
        const { path, ...options } = methods[method](args);
        return t(`common:field.validation.${type}.${method}`, {
          context: [namespace, path.replace(/\[\d+]$/, ''), context].filter(Boolean).join('_'),
          defaultValue: '',
          ...options,
        });
      };

      return values;
    }, {});

    return locale;
  }, {});

export default prepare({
  string,
  mixed,
  date,
});
