import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const adminEventTotalDonationsStatisticsSlice = (state: RootState) =>
  state.entities.adminEventTotalDonationsStatistics;

export const adminEventTotalDonationsStatisticsUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(
  adminEventTotalDonationsStatisticsSlice,
);

export const adminEventTotalDonationsStatisticsStateById = selectorsFactory.createEntityStateByIdSelector(
  adminEventTotalDonationsStatisticsSlice,
);
