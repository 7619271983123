import { Report } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import { GetAdminOrganizationReportsParamsType, GetAdminOrganizationReportParamsType } from './types';

export const getAdminOrganizationReports = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Report>,
  GetAdminOrganizationReportsParamsType
>()(
  'GET_ADMIN_ORGANIZATION_REPORTS_REQUEST',
  'GET_ADMIN_ORGANIZATION_REPORTS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_REPORTS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_REPORTS',
  keyWindowNames.adminOrganization,
);

export const createAdminOrganizationReport = actionsFactories.createCreateOneActionsFactory<
  EntityType<Report>,
  GetAdminOrganizationReportParamsType
>()(
  'CREATE_ADMIN_ORGANIZATION_REPORT_REQUEST',
  'CREATE_ADMIN_ORGANIZATION_REPORT_SUCCESS',
  'CREATE_ADMIN_ORGANIZATION_REPORT_FAILURE',
  'RESET_ADMIN_ORGANIZATION_REPORT',
  keyWindowNames.adminOrganization,
);
