import { OrganizationTranslation } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getAdminOrganizationTranslation = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationTranslation>
>()(
  'GET_ADMIN_ORGANIZATION_TRANSLATION_REQUEST',
  'GET_ADMIN_ORGANIZATION_TRANSLATION_SUCCESS',
  'GET_ADMIN_ORGANIZATION_TRANSLATION_FAILURE',
  'GET_ADMIN_ORGANIZATION_TRANSLATION_RESET',
);

export const updateAdminOrganizationTranslation = actionsFactories.createUpdateOneActionsFactory<
  EntityType<OrganizationTranslation>
>()(
  'UPDATE_ADMIN_ORGANIZATION_TRANSLATION_REQUEST',
  'UPDATE_ADMIN_ORGANIZATION_TRANSLATION_SUCCESS',
  'UPDATE_ADMIN_ORGANIZATION_TRANSLATION_FAILURE',
  'UPDATE_ADMIN_ORGANIZATION_TRANSLATION_RESET',
);
