import { get, post, del, patch } from './client';
import { buildPath, toJson } from './utils';
import { STRIPE_PAYMENT_METHODS_PATH } from './constants';

const SET_DEFAULT = 'set-default';

export const getStripePaymentMethods = (options: any): Promise<any> =>
  get(buildPath(STRIPE_PAYMENT_METHODS_PATH), options).then(toJson);

export const createStripePaymentMethods = (options: any): Promise<any> =>
  post(buildPath(STRIPE_PAYMENT_METHODS_PATH), options).then(toJson);

export const updateStripePaymentMethods = (options: any): Promise<any> =>
  patch(buildPath(STRIPE_PAYMENT_METHODS_PATH), options).then(toJson);

export const deleteStripePaymentMethods = (options: any): Promise<any> =>
  del(buildPath(STRIPE_PAYMENT_METHODS_PATH), options);

export const setDefaultStripePaymentMethod = (options: any): Promise<any> =>
  post(buildPath(STRIPE_PAYMENT_METHODS_PATH, SET_DEFAULT), options).then(toJson);
