import { RootState } from 'store/rootReducer';

export const zoomSlice = (state: RootState) => state.features.zoom;

export const requestOauthAuthorizationState = (state: RootState) => zoomSlice(state).requestOauthAuthorization;

export const removeOauthAuthorizationState = (state: RootState) => zoomSlice(state).removeOauthAuthorization;

export const confirmOauthAuthorizationState = (state: RootState) => zoomSlice(state).confirmOauthAuthorization;

export const isAuthorizing = (state: RootState) => requestOauthAuthorizationState(state).isPending;

export const isDeauthorizing = (state: RootState) => removeOauthAuthorizationState(state).isPending;
