import { put, race, take, takeEvery } from 'redux-saga/effects';
import { AdminZoomApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const page_size = 100;

const handleGetAdminZoomMeetingsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminZoomMeetings,
  request: AdminZoomApi.getMeetings,
  requestArgsBuilder: () => ({
    params: {
      page: 1,
      page_size,
    },
  }),
  transformResponse: (response) => response.results,
});

const handleGetAdminZoomWebinarsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminZoomWebinars,
  request: AdminZoomApi.getWebinars,
  requestArgsBuilder: () => ({
    params: {
      page: 1,
      page_size,
    },
  }),
  transformResponse: (response) => response.results,
  notifyError: false,
});

function* handleGetAdminZoomEvents() {
  yield put(actions.getAdminZoomMeetings.request());
  yield race([take(actions.getAdminZoomMeetings.success.type), take(actions.getAdminZoomMeetings.failure.type)]);
  yield put(actions.getAdminZoomWebinars.request());
}

export default function* adminZoomEventsSagas() {
  yield takeEvery(actions.getAdminZoomMeetings.request.type, handleGetAdminZoomMeetingsRequest);
  yield takeEvery(actions.getAdminZoomWebinars.request.type, handleGetAdminZoomWebinarsRequest);
  yield takeEvery(actions.getAdminZoomEvents.type, handleGetAdminZoomEvents);
}
