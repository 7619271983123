import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { AdminZoomEventType } from './types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<AdminZoomEventType>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminZoomEventsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminZoomMeetings.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminZoomMeetings.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminZoomMeetings.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminZoomMeetings.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.getAdminZoomWebinars.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminZoomWebinars.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminZoomWebinars.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminZoomWebinars.reset.type]: actionsHandlers.handleGetManyReset,
});

export default adminZoomEventsReducer;
