import { AdditionalInstructionAdmin } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';
import { GetAdminEventAdditionalInstructionsParamsType } from './types';
import { EntityType } from '../types';

export const getAdminEventAdditionalInstructions = actionsFactories.createGetManyActionsFactory<
  EntityType<AdditionalInstructionAdmin>,
  GetAdminEventAdditionalInstructionsParamsType
>()(
  'GET_ADMIN_EVENT_ADDITIONAL_INSTRUCTIONS_REQUEST',
  'GET_ADMIN_EVENT_ADDITIONAL_INSTRUCTIONS_SUCCESS',
  'GET_ADMIN_EVENT_ADDITIONAL_INSTRUCTIONS_FAILURE',
  'GET_ADMIN_EVENT_ADDITIONAL_INSTRUCTIONS_RESET',
  keyWindowNames.adminEventAdditionalInstructions,
);

export const createAdminEventAdditionalInstruction = actionsFactories.createCreateOneActionsFactory<
  EntityType<AdditionalInstructionAdmin>,
  GetAdminEventAdditionalInstructionsParamsType
>()(
  'CREATE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_REQUEST',
  'CREATE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_SUCCESS',
  'CREATE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_FAILURE',
  'CREATE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_RESET',
  keyWindowNames.adminEventAdditionalInstructions,
);

export const updateAdminEventAdditionalInstruction = actionsFactories.createUpdateOneActionsFactory<
  EntityType<AdditionalInstructionAdmin>,
  GetAdminEventAdditionalInstructionsParamsType
>()(
  'UPDATE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_REQUEST',
  'UPDATE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_SUCCESS',
  'UPDATE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_FAILURE',
  'UPDATE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_RESET',
);

export const deleteAdminEventAdditionalInstruction =
  actionsFactories.createDeleteOneActionsFactory<GetAdminEventAdditionalInstructionsParamsType>()(
    'DELETE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_REQUEST',
    'DELETE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_SUCCESS',
    'DELETE_ADMIN_EVENT_ADDITIONAL_INSTRUCTION_FAILURE',
  );
