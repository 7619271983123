import { call } from 'redux-saga/effects';

export const createFakeRequest =
  <T, R extends (...args: any[]) => Promise<T>>(request: R) =>
  (args: {
    shouldCallFakeRequest: (...args: Parameters<R>) => boolean | Generator<unknown, boolean>;
    fakeRequest: R | ((...args: Parameters<R>) => ReturnType<R> | Generator<unknown, T>);
  }) =>
    function* fakeRequest(...requestArgs: Parameters<R>): any {
      const shouldCallFakeRequest: boolean = yield call(args.shouldCallFakeRequest, ...requestArgs);
      return yield call(shouldCallFakeRequest ? args.fakeRequest : request, ...requestArgs);
    };
