import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import publicCalendarIcsFeedLinkReducer from './publicCalendarIcsFeedLinkReducer';
import communityCalendarIcsFeedLinkReducer from './communityCalendarIcsFeedLinkReducer';

export default combineReducers({
  calendarSettings: settingsReducer,
  publicCalendarIcsFeedLink: publicCalendarIcsFeedLinkReducer,
  communityCalendarIcsFeedLink: communityCalendarIcsFeedLinkReducer,
});
