// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ImageDisplay } from '.';

/**
 * @export
 * @interface OrganizationImageTranslationDisplay
 */
export interface OrganizationImageTranslationDisplay {
  /**
   * @type {number}
   * @memberof OrganizationImageTranslationDisplay
   */
  readonly id?: number;
  /**
   * @type {boolean}
   * @memberof OrganizationImageTranslationDisplay
   */
  readonly main?: boolean;
  /**
   * @type {number}
   * @memberof OrganizationImageTranslationDisplay
   */
  readonly order?: number;
  /**
   * @type {string}
   * @memberof OrganizationImageTranslationDisplay
   */
  image_type: OrganizationImageTranslationDisplayImageTypeEnum;
  /**
   * @type {ImageDisplay}
   * @memberof OrganizationImageTranslationDisplay
   */
  image_en?: ImageDisplay;
  /**
   * @type {ImageDisplay}
   * @memberof OrganizationImageTranslationDisplay
   */
  image_he?: ImageDisplay;
  /**
   * @type {ImageDisplay}
   * @memberof OrganizationImageTranslationDisplay
   */
  image_fr?: ImageDisplay;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationImageTranslationDisplayImageTypeEnum {
  Empty = '',
  Logo = 'logo',
  Avatar = 'avatar',
  Market = 'market',
  Organization = 'organization',
  Event = 'event',
  EventArchive = 'event_archive',
  HeroImage = 'hero_image',
  EventSponsors = 'event_sponsors',
  OrganizationHeroImage = 'organization_hero_image',
}
