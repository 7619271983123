import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { createSelector } from 'reselect';

export const eventCategoriesSlice = (state: RootState) => state.entities.eventCategories;

export const eventCategoriesState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(eventCategoriesSlice);

export const eventCategories = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(eventCategoriesSlice);

export const hasEventCategories = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(eventCategoriesSlice);

export const getEventTypes = createSelector(eventCategories, (categories) =>
  categories.filter((category) => category.type === 'type'),
);

export const getRootEventTypes = createSelector(getEventTypes, (types) => types.filter((type) => type.parent === null));

export const getEventTopics = createSelector(eventCategories, (categories) =>
  categories.filter((category) => category.type === 'topic'),
);

export const eventTypesState = createSelector(eventCategoriesState, (state) => ({
  ...state,
  entities: state.entities.filter((category) => category.type === 'type'),
}));

export const eventTopicsState = createSelector(eventCategoriesState, (state) => ({
  ...state,
  entities: state.entities.filter((category) => category.type === 'topic'),
}));
