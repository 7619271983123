// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Address,
  OrganizationImage,
  OrganizationMarket,
  OrganizationSettings,
  OrganizationShortLogo,
  OrganizationType,
  OrganizationVideoGallery,
} from '.';

/**
 * @export
 * @interface OrganizationDetail
 */
export interface OrganizationDetail {
  /**
   * @type {number}
   * @memberof OrganizationDetail
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  status?: OrganizationDetailStatusEnum;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  readonly status_display?: string;
  /**
   * @type {OrganizationType}
   * @memberof OrganizationDetail
   */
  type?: OrganizationType;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  title: string;
  /**
   * @type {Address}
   * @memberof OrganizationDetail
   */
  address?: Address;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  site_url?: string | null;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  site_url_preview?: string;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  description?: string;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  tagline?: string;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  email?: string;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  facebook?: string;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  twitter?: string;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  instagram?: string;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  readonly default_currency?: OrganizationDetailDefaultCurrencyEnum;
  /**
   * @type {OrganizationMarket}
   * @memberof OrganizationDetail
   */
  org_market?: OrganizationMarket;
  /**
   * @type {OrganizationShortLogo}
   * @memberof OrganizationDetail
   */
  parent?: OrganizationShortLogo | null;
  /**
   * @type {Array<OrganizationShortLogo>}
   * @memberof OrganizationDetail
   */
  readonly family_tree?: Array<OrganizationShortLogo>;
  /**
   * @type {Array<OrganizationImage>}
   * @memberof OrganizationDetail
   */
  readonly images?: Array<OrganizationImage>;
  /**
   * @type {Array<OrganizationVideoGallery>}
   * @memberof OrganizationDetail
   */
  readonly videos?: Array<OrganizationVideoGallery>;
  /**
   * @type {OrganizationSettings}
   * @memberof OrganizationDetail
   */
  settings?: OrganizationSettings;
  /**
   * @type {string}
   * @memberof OrganizationDetail
   */
  logo?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationDetailStatusEnum {
  Draft = 'draft',
  Pending = 'pending',
  Published = 'published',
  Rejected = 'rejected',
  Hidden = 'hidden',
  Terminated = 'terminated',
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationDetailDefaultCurrencyEnum {
  Usd = 'usd',
  Cad = 'cad',
}
