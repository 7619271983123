import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Slide, Snackbar, SnackbarContent } from '@mui/material';
import { darken, lighten } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { capitalize } from '@mui/material/utils';

const useStyles = makeStyles()((theme) => ({
  content: {
    boxShadow: '0px 4px 8px rgba(60, 85, 165, 0.16)',
  },
  severityError: {
    color: darken(theme.palette.error.main, 0.6),
    backgroundColor: lighten(theme.palette.error.main, 0.9),
  },
  severityWarning: {
    color: darken(theme.palette.warning.main, 0.6),
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
  },
  severityInfo: {
    color: darken(theme.palette.info.main, 0.6),
    backgroundColor: lighten(theme.palette.info.main, 0.9),
  },
  severitySuccess: {
    color: darken(theme.palette.success.main, 0.6),
    backgroundColor: lighten(theme.palette.success.main, 0.9),
  },
}));

const TransitionProps = {
  direction: 'right',
};

function Notification({ id, open, message, severity, onClose }) {
  const { classes, cx } = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    onClose(id);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      TransitionComponent={Slide}
      TransitionProps={TransitionProps}
    >
      <SnackbarContent
        className={cx(classes.content, classes[`severity${capitalize(severity)}`])}
        message={message}
        elevation={0}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Snackbar>
  );
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  onClose: PropTypes.func.isRequired,
};

export default memo(Notification);
