import { actionsFactories } from 'store/entities/utils';
import { NotCompletedOrderType } from './types';

export const getNotCompletedOrder = actionsFactories.createGetOneActionsFactory<NotCompletedOrderType>()(
  'GET_NOT_COMPLETED_ORDER_REQUEST',
  'GET_NOT_COMPLETED_ORDER_SUCCESS',
  'GET_NOT_COMPLETED_ORDER_FAILURE',
  'RESET_NOT_COMPLETED_ORDER',
);

export const getNotCompletedOrders = actionsFactories.createGetPaginatedManyActionsFactory<NotCompletedOrderType>()(
  'GET_NOT_COMPLETED_ORDERS_REQUEST',
  'GET_NOT_COMPLETED_ORDERS_SUCCESS',
  'GET_NOT_COMPLETED_ORDERS_FAILURE',
  'RESET_NOT_COMPLETED_ORDERS',
);

export const createNotCompletedOrder = actionsFactories.createCreateOneActionsFactory<NotCompletedOrderType, any>()(
  'CREATE_NOT_COMPLETED_ORDERS_REQUEST',
  'CREATE_NOT_COMPLETED_ORDERS_SUCCESS',
  'CREATE_NOT_COMPLETED_ORDERS_FAILURE',
  'CREATE_NOT_COMPLETED_ORDERS_RESET',
);

export const updateNotCompletedOrder = actionsFactories.createUpdateOneActionsFactory<NotCompletedOrderType>()(
  'UPDATE_NOT_COMPLETED_ORDERS_REQUEST',
  'UPDATE_NOT_COMPLETED_ORDERS_SUCCESS',
  'UPDATE_NOT_COMPLETED_ORDERS_FAILURE',
  'UPDATE_NOT_COMPLETED_ORDERS_RESET',
);
