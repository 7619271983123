import { combineReducers } from 'redux';
import userMfaReducer from './userMfaReducer';
import createUserMfaReducer from './createUserMfaReducer';
import confirmUserMfaReducer from './confirmUserMfaReducer';

export default combineReducers({
  userMfa: userMfaReducer,
  createUserMfa: createUserMfaReducer,
  confirmUserMfa: confirmUserMfaReducer,
});
