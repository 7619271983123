import { Admin } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Admin>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminUserReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminUser.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminUser.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminUser.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminUser.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.createAdminUser.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminUser.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminUser.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminUser.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.removeAdminUserRole.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.removeAdminUserRole.success.type]: (draft, action) => {
    delete draft.isDeleting[action.payload.id];
    const index = draft.byId[action.payload.params.userId].user_roles.findIndex(
      (item) => item.id === action.payload.id,
    );
    if (index !== -1) {
      draft.byId[action.payload.params.userId].user_roles.splice(index, 1);
    }
  },
  [actions.removeAdminUserRole.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminUserReducer;
