// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { QuestionOptionCreateUpdate } from '.';

/**
 * @export
 * @interface AllAttendeesQuestionCreateUpdate
 */
export interface AllAttendeesQuestionCreateUpdate {
  /**
   * @type {number}
   * @memberof AllAttendeesQuestionCreateUpdate
   */
  id?: number;
  /**
   * @type {string}
   * @memberof AllAttendeesQuestionCreateUpdate
   */
  text: string;
  /**
   * @type {string}
   * @memberof AllAttendeesQuestionCreateUpdate
   */
  description?: string;
  /**
   * @type {string}
   * @memberof AllAttendeesQuestionCreateUpdate
   */
  type?: AllAttendeesQuestionCreateUpdateTypeEnum;
  /**
   * @type {Array<QuestionOptionCreateUpdate>}
   * @memberof AllAttendeesQuestionCreateUpdate
   */
  options?: Array<QuestionOptionCreateUpdate>;
  /**
   * @type {string}
   * @memberof AllAttendeesQuestionCreateUpdate
   */
  readonly slug?: string;
  /**
   * @type {boolean}
   * @memberof AllAttendeesQuestionCreateUpdate
   */
  is_required?: boolean;
  /**
   * @type {boolean}
   * @memberof AllAttendeesQuestionCreateUpdate
   */
  is_options_custom_text_allowed?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum AllAttendeesQuestionCreateUpdateTypeEnum {
  Single = 'single',
  Multiple = 'multiple',
  Paragraph = 'paragraph',
  Text = 'text',
  Custom = 'custom',
}
