import { takeEvery } from 'redux-saga/effects';
import { ChannelsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetChannelsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getChannels,
  request: ChannelsApi.getChannels,
  transformResponse: (response) => response.results,
});

export default function* channelsSagas() {
  yield takeEvery(actions.getChannels.request.type, handleGetChannelsRequest);
}
