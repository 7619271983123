import { takeEvery } from 'redux-saga/effects';
import { CountriesApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetCountriesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getCountries,
  request: CountriesApi.getCountries,
  transformResponse: (result) =>
    result.map((country) => ({
      id: country.code,
      ...country,
    })),
});

export default function* countriesSagas() {
  yield takeEvery(actions.getCountries.request.type, handleGetCountriesRequest);
}
