import { EmailSubscribeToken } from 'api/types';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { EventEmailSubscriptionParamsType } from './types';

export const getEventEmailSubscription = actionsFactories.createGetOneActionsFactory<
  EntityType<EmailSubscribeToken>,
  EventEmailSubscriptionParamsType
>()(
  'GET_EVENT_EMAIL_SUBSCRIPTION_REQUEST',
  'GET_EVENT_EMAIL_SUBSCRIPTION_SUCCESS',
  'GET_EVENT_EMAIL_SUBSCRIPTION_FAILURE',
  'GET_EVENT_EMAIL_SUBSCRIPTION_RESET',
);

export const toggleEventEmailSubscription = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EmailSubscribeToken>,
  EventEmailSubscriptionParamsType
>()(
  'TOGGLE_EVENT_EMAIL_SUBSCRIPTION_REQUEST',
  'TOGGLE_EVENT_EMAIL_SUBSCRIPTION_SUCCESS',
  'TOGGLE_EVENT_EMAIL_SUBSCRIPTION_FAILURE',
  'TOGGLE_EVENT_EMAIL_SUBSCRIPTION_RESET',
);
