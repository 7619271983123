// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CreatePromoCode
 */
export interface CreatePromoCode {
  /**
   * @type {number}
   * @memberof CreatePromoCode
   */
  readonly id?: number;
  /**
   * @type {Array<number>}
   * @memberof CreatePromoCode
   */
  events: Array<number>;
  /**
   * @type {number}
   * @memberof CreatePromoCode
   */
  quantity_left?: number;
  /**
   * @type {number}
   * @memberof CreatePromoCode
   */
  discount_value?: number;
  /**
   * @type {string}
   * @memberof CreatePromoCode
   */
  discount_type?: CreatePromoCodeDiscountTypeEnum;
  /**
   * @type {string}
   * @memberof CreatePromoCode
   */
  code: string;
  /**
   * @type {string}
   * @memberof CreatePromoCode
   */
  valid_from?: string;
  /**
   * @type {string}
   * @memberof CreatePromoCode
   */
  valid_to: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CreatePromoCodeDiscountTypeEnum {
  Flat = 'flat',
  Percent = 'percent',
}
