import { call, fork, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import { AdminEventsApi } from 'api';
import { AddOnUpdate, AdminAddOnDetails } from 'api/admin/models';
import { isServer } from 'utils/next';
import { AdminEventsSelectors } from 'store/selectors';
import { sagasHandlersFactory } from 'store/entities/utils';
import { createFakeRequest } from 'store/entities/sagasHelpers';
import {
  createLoadUnsavedIds,
  createSyncUpdateUnsaved,
  getUnsaved,
  setUnsaved,
} from 'store/entities/adminEvents/unsavedUtils';
import * as actions from './actions';
import * as selectors from './selectors';

const UNSAVED_TYPE = 'addon';

const addonDetailsToAddonUpdate = (addon: AdminAddOnDetails): AddOnUpdate => ({
  title: addon.title,
  description: addon.description,
  total_amount: addon.total_amount,
  min_amount_per_order: addon.min_amount_per_order,
  max_amount_per_order: addon.max_amount_per_order,
  price: addon.price,
  tax_rate: addon.tax_rate,
  is_hidden: addon.is_hidden,
  sales_start: addon.sales_start,
  sales_end: addon.sales_end,
  redeemable: addon.redeemable,
});

const addonUpdateToAddonDetails = (addon: AddOnUpdate): AdminAddOnDetails => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { questions, redeemable, ...rest } = addon;
  return {
    redeemable,
    ...rest,
  };
};

const loadUnsavedIds = createLoadUnsavedIds({
  type: UNSAVED_TYPE,
  action: actions.addUnsavedAdminAddon,
});

export const updateUnsavedAdminAddon = createSyncUpdateUnsaved({
  type: UNSAVED_TYPE,
  actions: actions.updateAdminAddon,
  entityBuilder: addonDetailsToAddonUpdate,
});

const handleGetAdminEventAddonsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminAddons,
  request: AdminEventsApi.getAddons,
  requestArgsBuilder: (action) => action.payload.params.eventId,
  transformResponse: function* transformResponse(response) {
    const unsavedIds: number[] = yield select(selectors.getUnsavedIds);
    return response.reduce((entities, entity, index) => {
      if (unsavedIds.includes(entity.id)) {
        const data: any = getUnsaved(UNSAVED_TYPE, entity.id);
        if (data) entities[index] = data;
      }

      return entities;
    }, response.slice());
  },
});

const handleCreateAdminEventAddonRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminAddon,
  request: createFakeRequest(AdminEventsApi.createAddon)({
    shouldCallFakeRequest: function* shouldCallFakeRequest(eventId): any {
      return yield select(AdminEventsSelectors.isPublished, Number(eventId));
    },
    fakeRequest: function* fakeRequest(...args): any {
      const result = yield call(AdminEventsApi.createAddon, ...args);
      yield put(actions.addIgnoredUnsavingAdminAddon(result.id));
      return result;
    },
  }),
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, { body: entity }];
  },
});

const handleDeleteAdminAddonRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminAddon,
  request: AdminEventsApi.deleteAddon,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

const handleUpdateAdminAddonRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminAddon,
  request: AdminEventsApi.partialUpdateAddon,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
  notifyError: false,
});

const handleRuntimeUpdateAdminAddon = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminAddon,
  request: createFakeRequest(AdminEventsApi.partialUpdateAddon)({
    shouldCallFakeRequest: function* shouldCallFakeRequest(eventId, addonId) {
      const isPublished: boolean = yield select(AdminEventsSelectors.isPublished, Number(eventId));
      const hasIgnoredUnsavingId: boolean = yield select(selectors.hasIgnoredUnsavingId, Number(addonId));
      return isPublished && !hasIgnoredUnsavingId;
    },
    fakeRequest: function* fakeRequest(eventId, addonId, options): any {
      const id = Number(addonId);
      yield put(actions.addUnsavedAdminAddon(id));
      const adminAddon = yield select(selectors.adminAddonById, id);
      const updates = yield call(addonUpdateToAddonDetails, options.body);
      const unsaved = { ...adminAddon, ...updates };
      yield call(setUnsaved, UNSAVED_TYPE, id, unsaved);

      return unsaved;
    },
  }),
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

export default function* adminAddonsSagas() {
  yield takeLeading(actions.getAdminAddons.request.type, handleGetAdminEventAddonsRequest);
  yield takeEvery(actions.createAdminAddon.request.type, handleCreateAdminEventAddonRequest);
  yield takeEvery(actions.updateAdminAddon.request.type, handleUpdateAdminAddonRequest);
  yield takeEvery(actions.deleteAdminAddon.request.type, handleDeleteAdminAddonRequest);
  yield fork(handleRuntimeUpdateAdminAddon);

  if (!isServer()) {
    yield fork(function* wait() {
      yield take(HYDRATE);
      yield call(loadUnsavedIds);
    });
  }
}
