import { takeEvery } from 'redux-saga/effects';
import { EventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { sortQuestions } from 'store/entities/events/utils';
import * as actions from './actions';

const handleGetEventAddonsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getEventAddons,
  request: EventsApi.getEventAddons,
  requestArgsBuilder: (action) => action.payload.params.eventId,
  transformResponse: (response) =>
    response.map((item) => ({
      ...item,
      questions: sortQuestions(item.questions),
    })),
});

export default function* addonsSagas() {
  yield takeEvery(actions.getEventAddons.request.type, handleGetEventAddonsRequest);
}
