// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AdditionalinstructionTranslation
 */
export interface AdditionalinstructionTranslation {
  /**
   * @type {number}
   * @memberof AdditionalinstructionTranslation
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  text_for_non_registered_en?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  text_for_non_registered_he?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  text_for_non_registered_fr?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  title_he?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  title_fr?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  title_en?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  text_for_registered_he?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  text_for_registered_fr?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  text_for_registered_en?: string | null;
  /**
   * @type {string}
   * @memberof AdditionalinstructionTranslation
   */
  readonly slug?: AdditionalinstructionTranslationSlugEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AdditionalinstructionTranslationSlugEnum {
  Parking = 'parking',
  ArrivalCheckIn = 'arrival_check_in',
  Forms = 'forms',
  DressCode = 'dress_code',
  GeneralUpdate = 'general_update',
  Custom = 'custom',
}
