import { call, put, select, takeEvery } from 'redux-saga/effects';
import { AdminZoomApi } from 'api';
import { messageTypes } from 'utils/messages';
import { sagasHandlersFactory } from 'store/features/utils';
import { AuthSelectors } from 'store/selectors';
import { AuthActions, NotificationsActions, UserActions } from 'store/actions';
import { apiCall, handlePopupListener } from 'store/utils';
import * as actions from './actions';

function* handleRequestOauthAuthorizationRequest(): any {
  try {
    const response = yield apiCall(AdminZoomApi.requestOauthAuthorization);

    const confirmed = yield call(
      handlePopupListener,
      response.auth_url,
      messageTypes.CONFIRM_ZOOM_OAUTH_AUTHORIZATION_SUCCESS,
    );

    if (confirmed) {
      yield put(actions.requestOauthAuthorization.success());
      yield put(AuthActions.reloadCurrentUser());
      return;
    }

    yield put(actions.requestOauthAuthorization.reset());
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.requestOauthAuthorization.failure({ error }));
      yield put(NotificationsActions.showError(error));
    }
  }
}

function* handleRemoveOauthAuthorizationSuccess(): any {
  const currentUser = yield select(AuthSelectors.currentUser);
  if (currentUser) {
    yield put(
      UserActions.partialUpdateUser.success({
        entity: {
          ...currentUser,
          has_zoom_connected: false,
          zoom_oauth_data: null,
        },
      }),
    );
  }
}

const handleRemoveOauthAuthorizationRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.removeOauthAuthorization,
  request: AdminZoomApi.removeOauthAuthorization,
});

const handleConfirmOauthAuthorizationRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.confirmOauthAuthorization,
  request: AdminZoomApi.confirmOauthAuthorization,
  requestArgsBuilder: (action) => ({ params: action.payload.params }),
  notifyError: false,
});

export default function* zoomSagas() {
  // requestOauthAuthorization
  yield takeEvery(actions.requestOauthAuthorization.request.type, handleRequestOauthAuthorizationRequest);
  // confirmOauthAuthorization
  yield takeEvery(actions.confirmOauthAuthorization.request.type, handleConfirmOauthAuthorizationRequest);
  // removeOauthAuthorization
  yield takeEvery(actions.removeOauthAuthorization.request.type, handleRemoveOauthAuthorizationRequest);
  yield takeEvery(actions.removeOauthAuthorization.success.type, handleRemoveOauthAuthorizationSuccess);
}
