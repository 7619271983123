import { combineReducers } from 'redux';
import heroImageReducer from './heroImageReducer';
import mediaReducer from './mediaReducer';
import errorEventReducer from './errorEventReducer';
import createEventDraftReducer from './createEventDraftReducer';

export default combineReducers({
  heroImage: heroImageReducer,
  media: mediaReducer,
  errorEvent: errorEventReducer,
  createEventDraft: createEventDraftReducer,
});
