import { EventReminder } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventRemindersParamsType, UpdateAdminEventReminderParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminEventReminders = actionsFactories.createGetManyActionsFactory<
  EntityType<EventReminder>,
  GetAdminEventRemindersParamsType
>()(
  'GET_ADMIN_EVENT_REMINDERS_REQUEST',
  'GET_ADMIN_EVENT_REMINDERS_SUCCESS',
  'GET_ADMIN_EVENT_REMINDERS_FAILURE',
  'GET_ADMIN_EVENT_REMINDERS_EMAILS_RESET',
  keyWindowNames.adminEventReminders,
);

export const runtimeUpdateAdminEventReminder = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EventReminder>,
  UpdateAdminEventReminderParamsType
>()(
  'RUNTIME_UPDATE_ADMIN_EVENT_REMINDER_REQUEST',
  'RUNTIME_UPDATE_ADMIN_EVENT_REMINDER_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_EVENT_REMINDER_FAILURE',
  'RUNTIME_UPDATE_ADMIN_EVENT_REMINDER_RESET',
);
