import { AdminEventQuestionsApi, AdminOrganizationsApi } from 'api';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { GetAction } from 'store/types';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { createChangeOrderHandler } from 'store/entities/adminEvents/sagasUtils';
import * as actions from './actions';
import * as selectors from './selectors';
import { QUESTION_ORDER_SPREAD, QuestionTypes } from './constants';
import { createSubmitQuestion } from './sagasUltils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from '../../features/utils';

const questionsSelectorByType: Record<QuestionTypes, any> = {
  [QuestionTypes.event]: selectors.adminEventQuestions,
  [QuestionTypes.allAttendees]: selectors.adminAllAttendeesQuestions,
  [QuestionTypes.ticket]: selectors.adminTicketQuestions,
  [QuestionTypes.addon]: selectors.adminAddonQuestions,
};

const questionUpdateActionsByType: Record<QuestionTypes, any> = {
  [QuestionTypes.event]: actions.updateAdminEventQuestion,
  [QuestionTypes.allAttendees]: actions.updateAdminAllAttendeesQuestion,
  [QuestionTypes.ticket]: actions.updateAdminTicketQuestion,
  [QuestionTypes.addon]: actions.updateAdminAddonQuestion,
};

const questionCreateActionsByType: Record<QuestionTypes, any> = {
  [QuestionTypes.event]: actions.createAdminEventQuestion,
  [QuestionTypes.allAttendees]: actions.createAdminAllAttendeesQuestion,
  [QuestionTypes.ticket]: actions.createAdminTicketQuestion,
  [QuestionTypes.addon]: actions.createAdminAddonQuestion,
};

const handleChangeAdminQuestionOrder = createChangeOrderHandler<
  GetAction<typeof actions.changeAdminQuestionOrder>,
  any
>({
  spread: QUESTION_ORDER_SPREAD,
  getEntities: function* getEntities({ type, ...params }) {
    return yield select(questionsSelectorByType[type], params);
  },
  updateEntityOrder: function* updateEntityOrder(question, order, { type, ...params }) {
    yield put(
      questionUpdateActionsByType[type].request({
        id: question.id,
        params,
        entity: {
          order,
        },
      }),
    );
  },
});

const handleGetAdminDefaultQuestionsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminDefaultQuestions,
  request: AdminEventQuestionsApi.getDefaultQuestions,
  requestArgsBuilder: (action) => {
    const { params }: any = action.payload;

    return { params: { ...params, page: 1, page_size: 100 } };
  },
  transformResponse: (response) => response.results,
});

const handleGetAdminManyTimeQuestionsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminManyTimeQuestions,
  request: AdminEventQuestionsApi.getManyTimeQuestions,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organizationId, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminManyTimeQuestionsState, params);

    return [organizationId, { params: { ...rest, page, page_size } }];
  },
});

const handleCreateAdminManyTimeQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminManyTimeQuestion,
  request: AdminEventQuestionsApi.createAdminManyTimeQuestion,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { organizationId } = params;

    return [organizationId, { body: entity }];
  },
});

const handleDeleteAdminManyTimeQuestionRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminManyTimeQuestion,
  request: AdminEventQuestionsApi.deleteAdminManyTimeQuestion,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

const handleUpdateAdminManyTimeQuestionRequest = sagasHandlersFactory.createPartialUpdateOneRequestHandler({
  actions: actions.updateAdminManyTimeQuestion,
  request: AdminEventQuestionsApi.partialUpdateManyTimeQuestion,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { organizationId } = params;

    return [organizationId, id, { body: entity }];
  },
});

const handleCreateAdminQuestionOptionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminQuestionOption,
  request: AdminEventQuestionsApi.createQuestionOption,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId, questionId } = params;

    return [eventId, questionId, { body: entity }];
  },
});

const handleUpdateAdminQuestionOptionRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminQuestionOption,
  request: AdminEventQuestionsApi.updateQuestionOption,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId, questionId } = params;

    return [eventId, questionId, id, { body: entity }];
  },
});

const handleDeleteAdminQuestionOptionRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminQuestionOption,
  request: AdminEventQuestionsApi.deleteQuestionOption,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId, questionId } = params;

    return [eventId, questionId, id];
  },
});

// ------ Event Questions Sagas -----
const handleCreateAdminEventQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminEventQuestion,
  request: AdminEventQuestionsApi.createEventQuestion,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;

    return [eventId, { body: entity }];
  },
});

function* handleCreateAdminEventQuestionSuccess(action: GetAction<typeof actions.createAdminEventQuestion.success>) {
  const { entity, params } = action.payload;
  const { eventId, order } = params;
  yield put(
    actions.updateAdminEventQuestion.request({
      id: entity.id,
      entity: { order },
      params: { eventId },
    }),
  );
}

const handleDeleteAdminEventQuestionRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEventQuestion,
  request: AdminEventQuestionsApi.deleteEventQuestion,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;

    return [eventId, id];
  },
});

const handleUpdateAdminEventQuestionRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminEventQuestion,
  request: AdminEventQuestionsApi.partialUpdateEventQuestion,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;

    return [eventId, id, { body: entity }];
  },
});

const handleCloneAdminEventQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.cloneAdminEventQuestion,
  request: AdminEventQuestionsApi.cloneEventQuestion,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { eventId, default_question } = params;

    return [eventId, { body: { default_question } }];
  },
});

function* handleCloneAdminEventQuestionSuccess(action: GetAction<typeof actions.cloneAdminEventQuestion.success>) {
  const { entity, params } = action.payload;
  const { eventId, order } = params;
  yield put(
    actions.updateAdminEventQuestion.request({
      id: entity.id,
      entity: { order },
      params: { eventId },
    }),
  );
}

// ----- All Attendees Sagas -----
const handleGetAdminAllAttendeesQuestionsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminAllAttendeesQuestions,
  request: AdminEventQuestionsApi.getAllAttendeesQuestions,
  requestArgsBuilder: (action) => action.payload.params.eventId,
});

const handleCreateAdminAllAttendeesQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminAllAttendeesQuestion,
  request: AdminEventQuestionsApi.createAllAttendeesQuestion,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;

    return [eventId, { body: entity }];
  },
});

function* handleCreateAdminAllAttendeesQuestionSuccess(
  action: GetAction<typeof actions.createAdminAllAttendeesQuestion.success>,
) {
  const { entity, params } = action.payload;
  const { eventId, order } = params;
  yield put(
    actions.updateAdminAllAttendeesQuestion.request({
      id: entity.id,
      entity: { order },
      params: { eventId },
    }),
  );
}

const handleDeleteAllAttendeesQuestionRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminAllAttendeesQuestion,
  request: AdminEventQuestionsApi.deleteAllAttendeesQuestion,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;

    return [eventId, id];
  },
});

const handleUpdateAdminAllAttendeesQuestionRequest = sagasHandlersFactory.createPartialUpdateOneRequestHandler({
  actions: actions.updateAdminAllAttendeesQuestion,
  request: AdminEventQuestionsApi.partialUpdateAllAttendeesQuestion,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;

    return [eventId, id, { body: entity }];
  },
});

const handleCloneAllAttendeesQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.cloneAdminAllAttendeesQuestion,
  request: AdminEventQuestionsApi.cloneAllAttendeesQuestion,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { eventId, default_question } = params;

    return [eventId, { body: { default_question } }];
  },
});

function* handleCloneAllAttendeesQuestionSuccess(
  action: GetAction<typeof actions.cloneAdminAllAttendeesQuestion.success>,
) {
  const { entity, params } = action.payload;
  const { eventId, order } = params;
  yield put(
    actions.updateAdminAllAttendeesQuestion.request({
      id: entity.id,
      entity: { order },
      params: { eventId },
    }),
  );
}

// ----- Specific Tickets Sagas -----
const handleCreateAdminTicketQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminTicketQuestion,
  request: AdminEventQuestionsApi.createAdminTicketQuestion,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId, entityId } = params;

    return [eventId, entityId, { body: entity }];
  },
});

function* handleCreateAdminTicketQuestionSuccess(action: GetAction<typeof actions.createAdminTicketQuestion.success>) {
  const { params, entity: question } = action.payload;
  const { eventId, entityId, order } = params;
  yield put(
    actions.updateAdminTicketQuestion.request({
      id: question.id,
      entity: { order },
      params: { eventId, entityId },
    }),
  );
}

const handleCloneAdminTicketQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.cloneAdminTicketQuestion,
  request: AdminEventQuestionsApi.cloneAdminTicketQuestion,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { eventId, entityId, default_question } = params;

    return [eventId, entityId, { body: { default_question } }];
  },
});

function* handleCloneAdminTicketQuestionSuccess(action: GetAction<typeof actions.cloneAdminTicketQuestion.success>) {
  const { entity, params } = action.payload;
  const { eventId, entityId, order } = params;
  yield put(
    actions.updateAdminTicketQuestion.request({
      id: entity.id,
      entity: { order },
      params: { eventId, entityId },
    }),
  );
}

const handleDeleteAdminTicketQuestionRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminTicketQuestion,
  request: AdminEventQuestionsApi.deleteAdminTicketQuestion,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId, entityId } = params;

    return [eventId, entityId, id];
  },
});

const handleUpdateAdminTicketQuestionRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminTicketQuestion,
  request: AdminEventQuestionsApi.partialUpdateAdminTicketQuestion,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId, entityId } = params;

    return [eventId, entityId, id, { body: entity }];
  },
});

// ----- Specific Addons Sagas -----
const handleCreateAdminAddonQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminAddonQuestion,
  request: AdminEventQuestionsApi.createAdminAddonQuestion,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId, entityId } = params;

    return [eventId, entityId, { body: entity }];
  },
});

function* handleCreateAdminAddonQuestionSuccess(action: GetAction<typeof actions.createAdminAddonQuestion.success>) {
  const { params, entity: question } = action.payload;
  const { eventId, entityId, order } = params;
  yield put(
    actions.updateAdminAddonQuestion.request({
      id: question.id,
      entity: { order },
      params: { eventId, entityId },
    }),
  );
}

const handleCloneAdminAddonQuestionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.cloneAdminAddonQuestion,
  request: AdminEventQuestionsApi.cloneAdminAddonQuestion,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { eventId, entityId, default_question } = params;

    return [eventId, entityId, { body: { default_question } }];
  },
});

function* handleCloneAdminAddonQuestionSuccess(action: GetAction<typeof actions.cloneAdminAddonQuestion.success>) {
  const { entity, params } = action.payload;
  const { eventId, entityId, order } = params;
  yield put(
    actions.updateAdminAddonQuestion.request({
      id: entity.id,
      entity: { order },
      params: { eventId, entityId },
    }),
  );
}

const handleDeleteAdminAddonQuestionRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminAddonQuestion,
  request: AdminEventQuestionsApi.deleteAdminAddonQuestion,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId, entityId } = params;

    return [eventId, entityId, id];
  },
});

const handleUpdateAdminAddonQuestionRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminAddonQuestion,
  request: AdminEventQuestionsApi.partialUpdateAdminAddonQuestion,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId, entityId } = params;

    return [eventId, entityId, id, { body: entity }];
  },
});

const handleSubmitQuestion = createSubmitQuestion<GetAction<typeof actions.submitAdminQuestion>, any>({
  getQuestionActions: (params) => {
    const { questionId, type } = params;
    if (questionId) {
      return questionUpdateActionsByType[type];
    }
    return questionCreateActionsByType[type];
  },
  getOldOptions: function* getOldOptions({ type, ...params }) {
    return yield select(questionsSelectorByType[type], params);
  },
  getRequestArgs: (params) => {
    const {
      questionId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      entity: { options, ...restEntity },
      ...rest
    } = params;
    if (questionId) {
      return {
        id: questionId,
        params: rest,
        entity: restEntity,
      };
    }
    return {
      params: rest,
      entity: restEntity,
    };
  },
});

const handleHideAdminQuestionRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.hideAdminQuestion,
  request: AdminOrganizationsApi.hideAdminQuestion,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId, eventId } = params;
    return [organizationId, eventId, { body: { question: id } }];
  },
});

export default function* adminQuestionsSagas() {
  yield takeEvery(actions.getAdminDefaultQuestions.request.type, handleGetAdminDefaultQuestionsRequest);
  yield takeEvery(actions.getAdminManyTimeQuestions.request.type, handleGetAdminManyTimeQuestionsRequest);
  yield takeEvery(actions.getAdminAllAttendeesQuestions.request.type, handleGetAdminAllAttendeesQuestionsRequest);

  yield takeEvery(actions.createAdminManyTimeQuestion.request.type, handleCreateAdminManyTimeQuestionRequest);
  yield takeEvery(actions.createAdminEventQuestion.request.type, handleCreateAdminEventQuestionRequest);
  yield takeEvery(actions.createAdminEventQuestion.success.type, handleCreateAdminEventQuestionSuccess);
  yield takeEvery(actions.createAdminAllAttendeesQuestion.request.type, handleCreateAdminAllAttendeesQuestionRequest);
  yield takeEvery(actions.createAdminAllAttendeesQuestion.success.type, handleCreateAdminAllAttendeesQuestionSuccess);
  yield takeEvery(actions.createAdminTicketQuestion.request.type, handleCreateAdminTicketQuestionRequest);
  yield takeEvery(actions.createAdminTicketQuestion.success.type, handleCreateAdminTicketQuestionSuccess);
  yield takeEvery(actions.createAdminAddonQuestion.request.type, handleCreateAdminAddonQuestionRequest);
  yield takeEvery(actions.createAdminAddonQuestion.success.type, handleCreateAdminAddonQuestionSuccess);

  yield takeEvery(actions.createAdminQuestionOption.request.type, handleCreateAdminQuestionOptionRequest);
  yield takeEvery(actions.updateAdminQuestionOption.request.type, handleUpdateAdminQuestionOptionRequest);
  yield takeEvery(actions.deleteAdminQuestionOption.request.type, handleDeleteAdminQuestionOptionRequest);

  yield takeEvery(actions.updateAdminEventQuestion.request.type, handleUpdateAdminEventQuestionRequest);
  yield takeEvery(actions.updateAdminAllAttendeesQuestion.request.type, handleUpdateAdminAllAttendeesQuestionRequest);
  yield takeEvery(actions.updateAdminTicketQuestion.request.type, handleUpdateAdminTicketQuestionRequest);
  yield takeEvery(actions.updateAdminAddonQuestion.request.type, handleUpdateAdminAddonQuestionRequest);
  yield takeEvery(actions.updateAdminManyTimeQuestion.request.type, handleUpdateAdminManyTimeQuestionRequest);

  yield takeEvery(actions.deleteAdminEventQuestion.request.type, handleDeleteAdminEventQuestionRequest);
  yield takeEvery(actions.deleteAdminAllAttendeesQuestion.request.type, handleDeleteAllAttendeesQuestionRequest);
  yield takeEvery(actions.deleteAdminTicketQuestion.request.type, handleDeleteAdminTicketQuestionRequest);
  yield takeEvery(actions.deleteAdminAddonQuestion.request.type, handleDeleteAdminAddonQuestionRequest);
  yield takeEvery(actions.deleteAdminManyTimeQuestion.request.type, handleDeleteAdminManyTimeQuestionRequest);

  yield takeEvery(actions.cloneAdminEventQuestion.request.type, handleCloneAdminEventQuestionRequest);
  yield takeEvery(actions.cloneAdminEventQuestion.success.type, handleCloneAdminEventQuestionSuccess);
  yield takeEvery(actions.cloneAdminAllAttendeesQuestion.request.type, handleCloneAllAttendeesQuestionRequest);
  yield takeEvery(actions.cloneAdminAllAttendeesQuestion.success.type, handleCloneAllAttendeesQuestionSuccess);
  yield takeEvery(actions.cloneAdminTicketQuestion.request.type, handleCloneAdminTicketQuestionRequest);
  yield takeEvery(actions.cloneAdminTicketQuestion.success.type, handleCloneAdminTicketQuestionSuccess);
  yield takeEvery(actions.cloneAdminAddonQuestion.request.type, handleCloneAdminAddonQuestionRequest);
  yield takeEvery(actions.cloneAdminAddonQuestion.success.type, handleCloneAdminAddonQuestionSuccess);

  yield takeEvery(actions.changeAdminQuestionOrder.type, handleChangeAdminQuestionOrder);
  yield takeEvery(actions.submitAdminQuestion.type, handleSubmitQuestion);
  yield takeEvery(actions.hideAdminQuestion.request.type, handleHideAdminQuestionRequest);
}
