import { createAction, serializeError } from 'store/utils';
import { SeverityType } from './shared';

export const showError = createAction('SHOW_ERROR', <E extends Error>(error: E) => ({
  payload: {
    error: serializeError(error),
  },
}));

export const showNotification = createAction(
  'SHOW_NOTIFICATION',
  (id: string, message: string, severity: SeverityType) => ({
    payload: {
      id,
      message,
      severity,
    },
  }),
);

export const hideNotification = createAction('HIDE_NOTIFICATION', (id: string) => ({
  payload: {
    id,
  },
}));

export const removeNotification = createAction('REMOVE_NOTIFICATION', (id: string) => ({
  payload: {
    id,
  },
}));
