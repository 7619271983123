import { Action, createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { SelectedMarketState } from '../types';
import * as actions from '../actions';

const initialState: SelectedMarketState = {
  market: null,
};

type Actions = RootActions<typeof actions>;

const setMarket = <T extends Action<string, { market: string }>>(draft: SelectedMarketState, action: T) => {
  draft.market = action.payload.market;
};

const detectMarketReducer = createReducer<SelectedMarketState, Actions>(initialState, {
  [actions.setSelectedMarket.type]: setMarket,
  [actions.selectMarket.type]: setMarket,
  [actions.resetSelectedMarket.type]: () => initialState,
});

export default detectMarketReducer;
