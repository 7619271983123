import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const addonsSlice = (state: RootState) => state.entities.addons;

export const addonsById = selectorsFactory.createEntitiesByIdSelector(addonsSlice);

export const addonById = selectorsFactory.createEntityByIdSelector(addonsSlice);

export const eventAddonsEntities = selectorsFactory.createDynamicKeyWindowEntitiesMemoizedSelector(
  addonsSlice,
  keyWindowNames.eventAddons,
);

export const eventAddonsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  addonsSlice,
  keyWindowNames.eventAddons,
);
