import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { createSelector } from 'reselect';

export const adminMarketsSlice = (state: RootState) => state.entities.adminMarkets;

export const adminMarkets = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(adminMarketsSlice);

export const adminMarketsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminMarketsSlice);

export const hasAdminMarkets = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(adminMarketsSlice);

export const adminMarketById = selectorsFactory.createEntityByIdSelector(adminMarketsSlice);

export const adminMarketStateById = selectorsFactory.createEntityStateByIdSelector(adminMarketsSlice);

export const adminMarketBySlug = createSelector(
  adminMarkets,
  (entity: any, slug: string) => slug,
  (entity, slug) => entity.find((market: any) => market.slug === slug),
);

export const unHiddenMarketEntityState = createSelector(adminMarkets, (entity) =>
  entity.filter((market: any) => !market.is_hidden),
);
