import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { UserPassport } from 'api/general/models/UserPassport';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<UserPassport>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const userPassportsReducer = createReducer<State, Actions>(initialState, {
  [actions.getUserPassports.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getUserPassports.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getUserPassports.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getUserPassports.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createUserPassport.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createUserPassport.success.type]: (draft, action) => {
    actionsHandlers.handleCreateOneSuccess(draft, action);
    const { entity } = action.payload;
    if (!draft.all) draft.all = { ids: [], isFetching: false, isFetched: true, fetchingError: null };
    draft.all.ids.push(entity.id);
    draft.byId[entity.id] = entity;
  },
  [actions.createUserPassport.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createUserPassport.reset.type]: actionsHandlers.handleCreateOneReset,
});

export default userPassportsReducer;
