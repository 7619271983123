import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { selectorsFactory as featuresSelectorsFactory } from 'store/features/utils';

// adminOrganizationSlice

export const adminOrganizationSlice = (state: RootState) => state.entities.adminOrganizations.details;

export const adminOrganizationById = selectorsFactory.createEntityByIdSelector(adminOrganizationSlice);

export const adminOrganizationStateById = selectorsFactory.createEntityStateByIdSelector(adminOrganizationSlice);

export const adminOrganizationUpdatingStateById =
  selectorsFactory.createUpdatingStateByIdSelector(adminOrganizationSlice);

export const adminOrganizationEntityById = selectorsFactory.createEntityByIdSelector(adminOrganizationSlice);

// adminOrganizationsSlice

export const adminOrganizationsSlice = (state: RootState) => state.entities.adminOrganizations.list;

export const hasAdminOrganizations = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(adminOrganizationsSlice);

export const adminOrganizationEntitiesById = selectorsFactory.createEntitiesByIdSelector(adminOrganizationsSlice);

export const adminOrganizationsState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminOrganizationsSlice);

export const adminOrganizationsPagination =
  selectorsFactory.createStaticKeyWindowPaginationSelector(adminOrganizationsSlice);

// adminAllOrganizations

export const adminAllOrganizations = (state: RootState) => state.entities.adminOrganizations.adminAllOrganizations;

export const adminAllOrganizationsState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminAllOrganizations);

export const adminAllOrganizationsEntities =
  selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(adminAllOrganizations);

export const adminAllOrganizationsAlphabeticalEntitiesState = createSelector(adminAllOrganizationsState, (state) => ({
  ...state,
  entities: state.entities.slice().sort((a: any, b: any) => a.title.localeCompare(b.title)),
}));

export const allOrganizationsById = selectorsFactory.createEntityByIdSelector(adminAllOrganizations);

// adminOrganizationSettingSlice

export const adminOrganizationSettingSlice = (state: RootState) =>
  state.entities.adminOrganizations.adminOrganizationSetting;

export const adminOrganizationSettingStateById =
  selectorsFactory.createEntityStateByIdSelector(adminOrganizationSettingSlice);

export const adminOrganizationSettingUpdatingStateById =
  selectorsFactory.createUpdatingStateByIdSelector(adminOrganizationSettingSlice);

export const hasEventMessageTranslationFields = createSelector(adminOrganizationSettingStateById, (state) => {
  const eventTranslationStatus = ['translations_required_all', 'translations_optional_all'];
  return eventTranslationStatus.includes(state.entity?.event_translation_allowance);
});

export const isEventsTranslationsOptional = createSelector(
  adminOrganizationSettingStateById,
  (state) => state.entity?.event_translation_allowance === 'translations_optional_all',
);

// updateAdminOrganizationDetailsSlice

export const updateAdminOrganizationDetailsSlice = (state: RootState) =>
  state.entities.adminOrganizations.updateAdminOrganizationDetails;

export const updateAdminOrganizationDetailsStateById = featuresSelectorsFactory.createMultipleFeatureStateByIdSelectors(
  updateAdminOrganizationDetailsSlice,
);

// adminOrganizationIcsFeedLinkSlice

export const adminOrganizationIcsFeedLinkSlice = (state: RootState) =>
  state.entities.adminOrganizations.adminOrganizationIcsFeedLink;

export const adminOrganizationIcsFeedLinkByIdState = featuresSelectorsFactory.createMultipleFeatureStateByIdSelectors(
  adminOrganizationIcsFeedLinkSlice,
);

// stripeSlice

export const stripeSlice = (state: RootState) => state.entities.adminOrganizations.stripe;

export const requestOauthAuthorizationState = (state: RootState) => stripeSlice(state);

export const isAuthorizing = (state: RootState) => requestOauthAuthorizationState(state).isPending;

// stripeDashboardLinkSlice

export const stripeDashboardLinkSlice = (state: RootState) => state.entities.adminOrganizations.stripeDashboardLink;

export const stripeDashboardLinkState = selectorsFactory.createEntityStateByIdSelector(stripeDashboardLinkSlice);

// confirmStripeSlice

export const confirmStripeSlice = (state: RootState) => state.entities.adminOrganizations.confirmStripe;

export const confirmOauthAuthorizationState = (state: RootState) => confirmStripeSlice(state);

// Spreedly moneris connect

export const connectSpreedlySlice = (state: RootState) => state.entities.adminOrganizations.spreedly;

export const connectSpreedlyState = (state: RootState) => connectSpreedlySlice(state);

// Create Organisation
export const adminCreateOrganizationSlice = (state: RootState) =>
  state.entities.adminOrganizations.adminCreateOrganizations;

export const adminOrganizationCreatingState =
  selectorsFactory.createCreatingStateSelector(adminCreateOrganizationSlice);
export const createOrganizationState = selectorsFactory.createEntitiesByIdSelector(adminCreateOrganizationSlice);

// Specific Event Slice
export const adminOrganizationSettingSpecificEventSlice = (state: RootState) =>
  state.entities.adminOrganizations.adminOrgSettingSpecificEvent;

export const adminOrganizationSettingSpecificEventStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationSettingSpecificEventSlice,
);
