import { createTheme } from '@mui/material/styles';

import palette from './palette';
import typography, { fontStyles } from './typography';
import overrides from './overrides';
import mixins from './mixins';
import breakpoints from './breakpoints';

type CustomColors = (typeof palette)['custom'];

type CustomMixins = typeof mixins;

type CustomFontStyles = typeof fontStyles;

declare module '@mui/material/styles/createTypography' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface FontStyle extends CustomFontStyles {}
}

declare module '@mui/material/styles/createMixins' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Mixins extends CustomMixins {}
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    custom: CustomColors;
  }
  interface PaletteOptions {
    custom: CustomColors;
  }
}

export default createTheme({
  palette,
  typography,
  components: overrides,
  mixins,
  breakpoints,
});
