import { call, put, race, take } from 'redux-saga/effects';
import { Action, apiCall, CreateAction, createAsyncActions } from 'store/utils';
import { AnyFunction } from 'types';
import { getDiff } from 'utils/transform';

export type UnsavingState = {
  unsavedIds: number[];
  ignoredUnsavingIds: number[];
};

export type CreateActionHandler = <K extends keyof UnsavingState>(
  key: K,
) => <S extends UnsavingState, T extends string>(draft: S, action: Action<T, { id: number }>) => void;

export const createUnsavingState = (): UnsavingState => ({
  unsavedIds: [],
  ignoredUnsavingIds: [],
});

export const createAddIdActionHandler: CreateActionHandler = (key) => (draft, action) => {
  const index = draft[key].indexOf(action.payload.id);
  if (index === -1) draft[key].push(action.payload.id);
};

export const createRemoveIdActionHandler: CreateActionHandler = (key) => (draft, action) => {
  const index = draft[key].indexOf(action.payload.id);
  if (index !== -1) draft[key].splice(index, action.payload.id);
};

const unsavedKeySeparator = ':';

const getUnsavedKey = (type: string, id: number) => `unsaved${unsavedKeySeparator}${type}${unsavedKeySeparator}${id}`;

export const getUnsaved = <T>(type: string, id: number) => {
  try {
    return JSON.parse(sessionStorage.getItem(getUnsavedKey(type, id))) as T;
  } catch (e) {}
};

export const setUnsaved = <T>(type: string, id: number, data: T) => {
  sessionStorage.setItem(getUnsavedKey(type, id), JSON.stringify(data));
};

export const removeUnsaved = (type: string, id: number) => {
  sessionStorage.removeItem(getUnsavedKey(type, id));
};

export const getUnsavedIds = (type: string) => {
  const unsavedKeyPart = getUnsavedKey(type, 0).slice(0, -1);
  const unsavedIds: number[] = [];
  for (let i = 0; i < sessionStorage.length; i += 1) {
    const key = sessionStorage.key(i);
    if (key && key.startsWith(unsavedKeyPart)) {
      const index = key.lastIndexOf(unsavedKeySeparator);
      const id = Number(key.slice(index + 1, key.length));
      if (id > 0) {
        unsavedIds.push(id);
      }
    }
  }

  return unsavedIds;
};

export const createLoadUnsavedIds = <A extends CreateAction<string, (id: number) => any>>(args: {
  type: string;
  action: A;
}) =>
  function* loadUnsavedIds() {
    const unsavedIds: number[] = yield call(getUnsavedIds, args.type);
    for (let i = 0; i < unsavedIds.length; i += 1) {
      yield put(args.action(unsavedIds[i]));
    }
  };

export const createSyncUpdateUnsaved = <A extends ReturnType<typeof createAsyncActions>, R extends AnyFunction>(args: {
  type: string;
  actions: A;
  originalRequest?: R;
  requestArgsBuilder?: (payload: Omit<Parameters<A['request']>[0], 'entity'>) => number[];
  entityBuilder: <T>(data: T) => Parameters<A['request']>[0]['entity'];
}) =>
  function* updateUnsaved(requestArgs: Omit<Parameters<A['request']>[0], 'entity'>): any {
    const { type, entityBuilder, actions, originalRequest, requestArgsBuilder } = args;
    let originalEntity;
    const data = yield call(getUnsaved, type, requestArgs.id);
    if (data) {
      if (originalRequest) originalEntity = yield apiCall(originalRequest, ...requestArgsBuilder(requestArgs));
      const entity = yield call(entityBuilder, getDiff(originalEntity, data));
      // TODO:
      if (data.is_virtual && data.zoom_event) entity.zoom_event = data.zoom_event;
      yield put(
        actions.request({
          ...requestArgs,
          entity,
        }),
      );

      const effects = yield race([
        take((action: any) => action.type === actions.success.type && action.payload.entity.id === requestArgs.id),
        take((action: any) => action.type === actions.failure.type && action.payload.id === requestArgs.id),
      ]);

      const [success, error] = effects;

      if (success) {
        yield call(removeUnsaved, type, requestArgs.id);
      } else {
        const err: any = new Error();
        err.response = error.error;
        throw err;
      }
    }
  };

export const createGetUnsavedIdsSelector =
  <S>(sliceSelector: (state: S) => UnsavingState) =>
  (state: S) =>
    sliceSelector(state).unsavedIds;

export const createHasUnsavedIdSelector =
  <S>(sliceSelector: (state: S) => UnsavingState) =>
  (state: S, id: number) =>
    sliceSelector(state).unsavedIds.includes(id);

export const createHasIgnoredUnsavingIdSelector =
  <S>(sliceSelector: (state: S) => UnsavingState) =>
  (state: S, id: number) =>
    sliceSelector(state).ignoredUnsavingIds.includes(id);
