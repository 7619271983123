import { takeEvery } from 'redux-saga/effects';
import { AdminEventOptionsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetWhatsIncludedTagsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getWhatsIncludedTags,
  request: AdminEventOptionsApi.getTags,
  requestArgsBuilder: () => ({
    params: {
      type: 'whats_included',
      page: 1,
      page_size: 200,
    },
  }),
  transformResponse: (response) => response.results,
});

export default function* tagsSagas() {
  yield takeEvery(actions.getWhatsIncludedTags.request.type, handleGetWhatsIncludedTagsRequest);
}
