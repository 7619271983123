import { Draft } from 'immer';
import { OrderAdmin, OrderAdminStatusEnum } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrderAdmin>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const updateRefunded = (draft: Draft<State>, order: any) => {
  const { id, status, refunds } = order;
  const orderList = draft.byId[id];
  if (orderList) {
    draft.byId[id] = {
      ...orderList,
      status,
      refunds,
    };
  }
};

const adminOrdersReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrders.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrders.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrders.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrders.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.getAdminOrder.success.type]: (draft, action) => {
    updateRefunded(draft, action.payload.entity);
  },
  [actions.fullRefundAdminOrder.success.type]: (draft, action) => {
    updateRefunded(draft, action.payload.result);
  },
  [actions.partialRefundAdminOrder.success.type]: (draft, action) => {
    updateRefunded(draft, action.payload.result);
  },
  [actions.setPayOfflineAdminOrder.success.type]: (draft, action) => {
    const order = draft.byId[action.payload.id];
    if (order) {
      order.status = OrderAdminStatusEnum.PaidOffline;
    }
  },
});

export default adminOrdersReducer;
