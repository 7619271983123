import { createAction } from 'store/utils';
import {
  EntityType,
  IdType,
  KeyWindowNameType,
  PaginatedEntitiesParamsType,
  PaginatedEntitiesType,
  ParamsType,
} from './types';
import { defaultKeyWindowName } from './shared';

const getKeyWindowName = <P, K extends KeyWindowNameType<P>>(k: K, p?: P) => {
  type F = Exclude<K, string>;
  type S = [K] extends [string] ? K : never;

  if (typeof k === 'function' && p) return (k as F)(p) as ReturnType<F>;
  if (typeof k === 'string') return k as S;

  throw new Error('Incorrect type of key window');
};

export const createCreateOneActionsFactory =
  <E extends EntityType, P extends ParamsType = never>() =>
  <T1 extends string, T2 extends string, T3 extends string, T4 extends string, K extends KeyWindowNameType<P> = never>(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
    keyWindowName?: K,
  ) => ({
    request: createAction(type1, (args: { entity?: Partial<E>; params?: P; silent?: boolean }) => ({
      meta: {
        silent: args.silent,
      },
      payload: {
        keyWindowName: keyWindowName && getKeyWindowName(keyWindowName, args.params),
        params: args.params,
        entity: args.entity,
      },
    })),
    success: createAction(type2, (args: { entity: E; params?: P }) => ({
      payload: {
        keyWindowName: keyWindowName && getKeyWindowName(keyWindowName, args.params),
        params: args.params,
        entity: args.entity,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; entity: Partial<E>; params?: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        keyWindowName: keyWindowName && getKeyWindowName(keyWindowName, args.params),
        params: args.params,
        entity: args.entity,
      },
    })),
    reset: createAction(type4),
  });

export const createCreateManyActionsFactory =
  <E extends EntityType, P extends ParamsType = never>() =>
  <
    T1 extends string,
    T2 extends string,
    T3 extends string,
    T4 extends string,
    K extends KeyWindowNameType<P> = typeof defaultKeyWindowName,
  >(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
    keyWindowName: K = defaultKeyWindowName as K,
  ) => ({
    request: createAction(type1, (args: { entities: Partial<E>[]; params?: P; silent?: boolean }) => ({
      meta: {
        silent: args.silent,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
        params: args.params,
        entities: args.entities,
      },
    })),
    success: createAction(type2, (args: { entities: E[]; params?: P; partial?: boolean }) => ({
      meta: {
        partial: args.partial,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
        params: args.params,
        entities: args.entities,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; params?: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args.params),
        params: args.params,
      },
    })),
    reset: createAction(type4, (args?: { params: P }) => ({
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
      },
    })),
  });

export const createUpdateOneActionsFactory =
  <E extends EntityType, P extends ParamsType = never>() =>
  <T1 extends string, T2 extends string, T3 extends string, T4 extends string>(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
  ) => ({
    request: createAction(type1, (args: { id: number; entity?: Partial<E>; params?: P; silent?: boolean }) => ({
      meta: {
        silent: args.silent,
      },
      payload: {
        id: args.id,
        params: args.params,
        entity: args.entity,
      },
    })),
    success: createAction(type2, (args: { entity: E; params?: P; partial?: boolean }) => ({
      meta: {
        partial: args.partial,
      },
      payload: {
        params: args.params,
        entity: args.entity,
      },
    })),
    failure: createAction(
      type3,
      (args: { error: Error; notifyError?: boolean; id: number; entity: Partial<E>; params?: P }) => ({
        error: args.error,
        meta: {
          notifyError: args.notifyError,
        },
        payload: {
          id: args.id,
          params: args.params,
          entity: args.entity,
        },
      }),
    ),
    reset: createAction(type4, (args?: { id: number }) => ({
      payload: {
        id: args?.id,
      },
    })),
  });

export const createUpdateManyActionsFactory =
  <E extends EntityType, P extends ParamsType = never>() =>
  <
    T1 extends string,
    T2 extends string,
    T3 extends string,
    T4 extends string,
    K extends KeyWindowNameType<P> = typeof defaultKeyWindowName,
  >(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
    keyWindowName: K = defaultKeyWindowName as K,
  ) => ({
    request: createAction(type1, (args: { entities: E[]; params?: P; silent?: boolean }) => ({
      meta: {
        silent: args.silent,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
        params: args.params,
        entities: args.entities,
      },
    })),
    success: createAction(type2, (args: { entities: E[]; params?: P; partial?: boolean }) => ({
      meta: {
        partial: args.partial,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
        params: args.params,
        entities: args.entities,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; entities: E[]; params?: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args.params),
        params: args.params,
        entities: args.entities,
      },
    })),
    reset: createAction(type4, (args?: { entities: E[]; params: P }) => ({
      payload: {
        entities: args.entities,
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
      },
    })),
  });

export const createDeleteOneActionsFactory =
  <P extends ParamsType = never>() =>
  <T1 extends string, T2 extends string, T3 extends string>(type1: T1, type2: T2, type3: T3) => ({
    request: createAction(type1, (args: { id: IdType; params?: P; silent?: boolean }) => ({
      meta: {
        silent: args.silent,
      },
      payload: {
        id: args.id,
        params: args.params,
      },
    })),
    success: createAction(type2, (args: { id: IdType; params?: P }) => ({
      payload: {
        id: args.id,
        params: args.params,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; id: IdType; params?: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        id: args.id,
        params: args.params,
      },
    })),
  });

export const createGetOneActionsFactory =
  <E extends EntityType, P = never>() =>
  <T1 extends string, T2 extends string, T3 extends string, T4 extends string = never>(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
  ) => ({
    request: createAction(type1, (args: { id: number; params?: P; silent?: boolean }) => ({
      meta: {
        silent: args.silent,
      },
      payload: {
        id: args.id,
        params: args.params,
      },
    })),
    success: createAction(type2, (args: { entity: E; params?: P }) => ({
      payload: {
        entity: args.entity,
        params: args.params,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; id: number; params?: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        id: args.id,
        params: args.params,
      },
    })),
    reset: createAction(type4, (args: { id: number }) => ({
      payload: {
        id: args.id,
      },
    })),
  });

export const createGetManyActionsFactory =
  <E extends EntityType, P extends ParamsType = never>() =>
  <
    T1 extends string,
    T2 extends string,
    T3 extends string,
    T4 extends string,
    K extends KeyWindowNameType<P> = typeof defaultKeyWindowName,
  >(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
    keyWindowName: K = defaultKeyWindowName as K,
  ) => ({
    request: createAction(type1, (args?: { params?: P; silent?: boolean }) => ({
      meta: {
        silent: args?.silent,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
        params: args?.params,
      },
    })),
    success: createAction(type2, (args: { entities: E[]; params?: P }) => ({
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args.params),
        params: args.params,
        entities: args.entities,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; params?: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args.params),
        params: args.params,
      },
    })),
    reset: createAction(type4, (args?: { params: P }) => ({
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
      },
    })),
  });

export const createGetPaginatedManyActionsFactory =
  <E extends EntityType, P extends PaginatedEntitiesParamsType = PaginatedEntitiesParamsType>() =>
  <
    T1 extends string,
    T2 extends string,
    T3 extends string,
    T4 extends string,
    K extends KeyWindowNameType<P> = typeof defaultKeyWindowName,
  >(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
    keyWindowName: K = defaultKeyWindowName as K,
  ) => ({
    request: createAction(type1, (args?: { params?: P; silent?: boolean }) => ({
      meta: {
        silent: args?.silent,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args?.params),
        params: args?.params,
      },
    })),
    success: createAction(type2, (args: { entities: PaginatedEntitiesType<E>; params: P }) => ({
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args.params),
        entities: args.entities,
        params: args.params,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; params: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        keyWindowName: getKeyWindowName(keyWindowName, args.params),
        params: args.params,
      },
    })),
    reset: createAction(
      type4,
      (args?: { params: Omit<P, keyof PaginatedEntitiesParamsType> & Partial<PaginatedEntitiesParamsType> }) => ({
        payload: {
          keyWindowName: getKeyWindowName(keyWindowName, args?.params),
        },
      }),
    ),
  });

export type CreateOneActionsFactoryType = typeof createCreateOneActionsFactory;

export type CreateManyActionsFactoryType = typeof createCreateManyActionsFactory;

export type UpdateOneActionsFactoryType = typeof createUpdateOneActionsFactory;

export type UpdateManyActionsFactoryType = typeof createUpdateManyActionsFactory;

export type DeleteOneActionsFactoryType = typeof createDeleteOneActionsFactory;

export type GetOneActionsFactoryType = typeof createGetOneActionsFactory;

export type GetManyActionsFactoryType = typeof createGetManyActionsFactory;

export type GetPaginatedManyActionsFactoryType = typeof createGetPaginatedManyActionsFactory;
