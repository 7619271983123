// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface EventSettings
 */
export interface EventSettings {
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  is_public?: boolean;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  readonly is_hidden?: boolean;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  is_password_required?: boolean;
  /**
   * @type {string}
   * @memberof EventSettings
   */
  password: string;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  use_third_party_link?: boolean;
  /**
   * @type {string}
   * @memberof EventSettings
   */
  third_party_link?: string;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  show_organization_mentions?: boolean;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  show_crm_meta_data?: boolean;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  has_general_ledger_number?: boolean;
  /**
   * @type {string}
   * @memberof EventSettings
   */
  general_ledger_number: string;
  /**
   * @type {string}
   * @memberof EventSettings
   */
  event_translation_allowance?: EventSettingsEventTranslationAllowanceEnum;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  is_offline_payment_allowed?: boolean;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  is_total_capacity_enabled?: boolean;
  /**
   * @type {number}
   * @memberof EventSettings
   */
  capacity?: number;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  readonly has_email_sender_name?: boolean;
  /**
   * @type {string}
   * @memberof EventSettings
   */
  readonly email_sender_name?: string;
  /**
   * @type {boolean}
   * @memberof EventSettings
   */
  internal_passport_required?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum EventSettingsEventTranslationAllowanceEnum {
  DisabledAll = 'translations_disabled_all',
  RequiredAll = 'translations_required_all',
  OptionalAll = 'translations_optional_all',
}
