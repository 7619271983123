import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { createSelector } from 'reselect';
import { adminOrganizationById } from '../adminOrganizations/selectors';

const translationByLanguage = (translations: any, language: string) => {
  const ending = `_${language}`;
  return Object.keys(translations)
    .filter((key) => key.endsWith(ending))
    .reduce((translation, key) => {
      translation[key.slice(0, -ending.length)] = translations[key];
      return translation;
    }, {} as any);
};

export const adminOrganizationTranslationSlice = (state: RootState) => state.entities.adminOrganizationTranslation;

export const adminOrganizationTranslationById = selectorsFactory.createEntityByIdSelector(
  adminOrganizationTranslationSlice,
);

export const adminOrganizationTranslationStateById = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationTranslationSlice,
);

export const adminOrganizationTranslationUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(
  adminOrganizationTranslationSlice,
);

export const adminOrganizationTranslationByLanguage = createSelector(
  adminOrganizationById,
  adminOrganizationTranslationById,
  (state, organizationId, params: { language: string }) => params,
  (organization: any, translation, params) => {
    if (!translation) return {};
    const { language } = params;
    const { title, description, tagline, address_name, address_additional_info, address1, address2, city } =
      translationByLanguage(translation, language);
    return {
      ...organization,
      title,
      description,
      tagline,
      address: {
        ...organization.address,
        address_name,
        address_additional_info,
        address1,
        address2,
        city,
      },
    };
  },
);
