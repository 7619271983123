import { Draft } from 'immer';
import { AnyFunction } from 'types';
import { EntitiesStateType, PaginatedKeyWindowType, PaginateMetaType } from './types';
import {
  CreateManyActionsFactoryType,
  CreateOneActionsFactoryType,
  DeleteOneActionsFactoryType,
  GetManyActionsFactoryType,
  GetOneActionsFactoryType,
  GetPaginatedManyActionsFactoryType,
  UpdateManyActionsFactoryType,
  UpdateOneActionsFactoryType,
} from './actionsFactories';

interface IHandler<F extends AnyFunction, K extends string> {
  <S extends EntitiesStateType>(draft: Draft<S>, action: ReturnType<ReturnType<ReturnType<F>>[K]>): S | void;
}

export const handleCreateOneRequest: IHandler<CreateOneActionsFactoryType, 'request'> = (draft, action) => {
  draft.creatingError = null;
  draft.isSuccessfullyCreated = false;
  if (!action.meta.silent) draft.isCreating = true;
};

export const handleCreateManyRequest: IHandler<CreateManyActionsFactoryType, 'request'> = (draft, action) => {
  draft.creatingError = null;
  draft.isSuccessfullyCreated = false;
  if (!action.meta.silent) draft.isCreating = true;
};

export const handleCreateOneSuccess: IHandler<CreateOneActionsFactoryType, 'success'> = (draft, action) => {
  const {
    payload: { keyWindowName, entity },
  } = action;

  draft.isCreating = false;
  draft.isSuccessfullyCreated = true;

  if (entity) {
    draft.byId[entity.id] = entity;
    if (keyWindowName && draft.keyWindows.includes(keyWindowName)) {
      const keyWindow = draft[keyWindowName];
      keyWindow.ids.push(entity.id);
      if (Number.isInteger((keyWindow as Draft<PaginatedKeyWindowType>).count)) {
        (keyWindow as Draft<PaginatedKeyWindowType>).count += 1;
      }
    }
  }
};

export const handleCreateManySuccess: IHandler<CreateManyActionsFactoryType, 'success'> = (draft, action) => {
  const {
    payload: { keyWindowName, entities },
  } = action;

  draft.isCreating = false;
  draft.isSuccessfullyCreated = true;

  entities.forEach((entity) => {
    draft.byId[entity.id] = entity;
    if (keyWindowName && draft.keyWindows.includes(keyWindowName)) {
      const keyWindow = draft[keyWindowName];
      keyWindow.ids.push(entity.id);
      if (Number.isInteger((keyWindow as Draft<PaginatedKeyWindowType>).count)) {
        (keyWindow as Draft<PaginatedKeyWindowType>).count += 1;
      }
    }
  });
};

export const handleCreateOneFailure: IHandler<CreateOneActionsFactoryType, 'failure'> = (draft, action) => {
  draft.isCreating = false;
  draft.creatingError = action.error;
};

export const handleCreateManyFailure: IHandler<CreateManyActionsFactoryType, 'failure'> = (draft, action) => {
  draft.isCreating = false;
  draft.creatingError = action.error;
};

export const handleCreateOneReset: IHandler<CreateOneActionsFactoryType, 'reset'> = (draft) => {
  draft.isCreating = false;
  draft.isSuccessfullyCreated = false;
  draft.creatingError = null;
};

export const handleCreateManyReset: IHandler<CreateManyActionsFactoryType, 'reset'> = (draft) => {
  draft.isCreating = false;
  draft.isSuccessfullyCreated = false;
  draft.creatingError = null;
};

export const handleUpdateOneRequest: IHandler<UpdateOneActionsFactoryType, 'request'> = (draft, action) => {
  delete draft.updatingErrors[action.payload.id];
  delete draft.isSuccessfullyUpdated[action.payload.id];
  if (!action.meta.silent) draft.isUpdating[action.payload.id] = true;
};

export const handleUpdateManyRequest: IHandler<UpdateManyActionsFactoryType, 'request'> = (draft, action) => {
  action.payload.entities.forEach((entity) => {
    delete draft.updatingErrors[entity.id];
    delete draft.isSuccessfullyUpdated[entity.id];
    if (!action.meta.silent) draft.isUpdating[entity.id] = true;
  });
};

export const handleUpdateOneSuccess: IHandler<UpdateOneActionsFactoryType, 'success'> = (draft, action) => {
  const {
    payload: { entity },
    meta: { partial },
  } = action;

  delete draft.isUpdating[entity.id];
  draft.isSuccessfullyUpdated[entity.id] = true;

  if (!partial || !draft.byId[entity.id]) {
    draft.byId[entity.id] = entity;
  } else {
    Object.keys(action.payload.entity).forEach(<T extends keyof typeof entity>(key: T) => {
      draft.byId[entity.id][key] = entity[key];
    });
  }
};

export const handleUpdateManySuccess: IHandler<UpdateManyActionsFactoryType, 'success'> = (draft, action) => {
  const {
    payload: { entities },
    meta: { partial },
  } = action;

  entities.forEach((entity) => {
    delete draft.isUpdating[entity.id];
    draft.isSuccessfullyUpdated[entity.id] = true;

    if (!partial || !draft.byId[entity.id]) {
      draft.byId[entity.id] = entity;
    } else {
      Object.keys(entity).forEach(<T extends keyof typeof entity>(key: T) => {
        draft.byId[entity.id][key] = entity[key];
      });
    }
  });
};

export const handleUpdateOneFailure: IHandler<UpdateOneActionsFactoryType, 'failure'> = (draft, action) => {
  delete draft.isUpdating[action.payload.id];
  draft.updatingErrors[action.payload.id] = action.error;
};

export const handleUpdateManyFailure: IHandler<UpdateManyActionsFactoryType, 'failure'> = (draft, action) => {
  action.payload.entities?.forEach((entity) => {
    delete draft.isUpdating[entity.id];
    draft.updatingErrors[entity.id] = action.error;
  });
};

/**
 * The function deletes "Updating state" depending on action ID
 */
export const handleUpdateOneReset: IHandler<UpdateOneActionsFactoryType, 'reset'> = (draft, action) => {
  if (action.payload.id) {
    draft.isUpdating = {};
    draft.isSuccessfullyUpdated = {};
    draft.updatingErrors = {};
  } else {
    delete draft.isUpdating[action.payload.id];
    delete draft.isSuccessfullyUpdated[action.payload.id];
    delete draft.updatingErrors[action.payload.id];
  }
};

export const handleUpdateManyReset: IHandler<UpdateManyActionsFactoryType, 'reset'> = (draft, action) => {
  action.payload.entities.forEach((entity) => {
    if (entity.id) {
      draft.isUpdating = {};
      draft.isSuccessfullyUpdated = {};
      draft.updatingErrors = {};
    } else {
      delete draft.isUpdating[entity.id];
      delete draft.isSuccessfullyUpdated[entity.id];
      delete draft.updatingErrors[entity.id];
    }
  });
};

export const handleDeleteOneRequest: IHandler<DeleteOneActionsFactoryType, 'request'> = (draft, action) => {
  delete draft.deletingErrors[action.payload.id];
  if (!action.meta.silent) draft.isDeleting[action.payload.id] = true;
};

export const handleDeleteOneSuccess: IHandler<DeleteOneActionsFactoryType, 'success'> = (draft, action) => {
  delete draft.isDeleting[action.payload.id];
  delete draft.byId[action.payload.id];

  draft.keyWindows.forEach((keyWindow) => {
    const index = draft[keyWindow].ids.indexOf(action.payload.id);
    if (index !== -1) {
      draft[keyWindow].ids.splice(index, 1);
      if ('count' in draft[keyWindow]) (draft[keyWindow] as Draft<PaginateMetaType>).count -= 1;
    }
  });
};

export const handleDeleteOneFailure: IHandler<DeleteOneActionsFactoryType, 'failure'> = (draft, action) => {
  delete draft.isDeleting[action.payload.id];
  draft.deletingErrors[action.payload.id] = action.error;
};

export const handleGetOneRequest: IHandler<GetOneActionsFactoryType, 'request'> = (draft, action) => {
  delete draft.fetchingErrors[action.payload.id];
  if (!action.meta.silent) draft.isFetching[action.payload.id] = true;
  draft.isFetched[action.payload.id] = false;
};

export const handleGetOneSuccess: IHandler<GetOneActionsFactoryType, 'success'> = (draft, action) => {
  delete draft.isFetching[action.payload.entity.id];
  draft.byId[action.payload.entity.id] = action.payload.entity;
  draft.isFetched[action.payload.entity.id] = true;
};

export const handleGetOneFailure: IHandler<GetOneActionsFactoryType, 'failure'> = (draft, action) => {
  delete draft.isFetching[action.payload.id];
  draft.fetchingErrors[action.payload.id] = action.error;
  draft.isFetched[action.payload.id] = true;
};

export const handleGetOneReset: IHandler<GetOneActionsFactoryType, 'reset'> = (draft, action) => {
  delete draft.isFetching[action.payload.id];
  delete draft.isFetched[action.payload.id];
  delete draft.fetchingErrors[action.payload.id];
  delete draft.byId[action.payload.id];
};

export const handleGetManyRequest: IHandler<GetManyActionsFactoryType, 'request'> = (draft, action) => {
  const {
    payload: { keyWindowName },
    meta: { silent },
  } = action;

  if (!draft.keyWindows.includes(keyWindowName)) {
    draft.keyWindows.push(keyWindowName);
    (draft as Draft<EntitiesStateType>)[keyWindowName] = {
      ids: [],
      isFetching: false,
      isFetched: false,
      fetchingError: null,
    };
  }

  const keyWindow = draft[keyWindowName];
  if (!silent) keyWindow.isFetching = true;
  keyWindow.fetchingError = null;
};

export const handleGetManySuccess: IHandler<GetManyActionsFactoryType, 'success'> = (draft, action) => {
  const {
    payload: { keyWindowName, entities },
  } = action;

  const keyWindow = draft[keyWindowName];
  keyWindow.ids = [];
  keyWindow.isFetching = false;
  keyWindow.isFetched = true;
  entities.forEach((e) => {
    draft.byId[e.id] = e;
    keyWindow.ids.push(e.id);
  });
};

export const handleGetManyFailure: IHandler<GetManyActionsFactoryType, 'failure'> = (draft, action) => {
  const keyWindow = draft[action.payload.keyWindowName];
  keyWindow.isFetching = false;
  keyWindow.isFetched = true;
  keyWindow.fetchingError = action.error;
};

export const handleGetManyReset: IHandler<GetManyActionsFactoryType, 'reset'> = (draft, action) => {
  const keyWindow = draft[action.payload.keyWindowName];
  if (keyWindow) {
    keyWindow.ids = [];
    keyWindow.isFetching = false;
    keyWindow.isFetched = false;
    keyWindow.fetchingError = null;
  }
};

export const handleGetPaginatedManyRequest: IHandler<GetPaginatedManyActionsFactoryType, 'request'> = (
  draft,
  action,
) => {
  const {
    payload: { keyWindowName },
    meta: { silent },
  } = action;

  if (!draft.keyWindows.includes(keyWindowName)) {
    draft.keyWindows.push(keyWindowName);
    (draft as Draft<EntitiesStateType>)[keyWindowName] = {
      ids: [],
      count: 0,
      next: 'unknown',
      isFetching: false,
      isFetched: false,
      fetchingError: null,
    };
  }

  const keyWindow = draft[keyWindowName];
  if (!silent) keyWindow.isFetching = true;
  keyWindow.fetchingError = null;
};

export const handleGetPaginatedManySuccess: IHandler<GetPaginatedManyActionsFactoryType, 'success'> = (
  draft,
  action,
) => {
  const {
    payload: {
      keyWindowName,
      entities: { count, next, results },
    },
  } = action;

  const keyWindow = draft[keyWindowName] as PaginatedKeyWindowType;
  keyWindow.isFetching = false;
  keyWindow.isFetched = true;
  keyWindow.count = count;
  keyWindow.next = next;

  results.forEach((e) => {
    draft.byId[e.id] = e;
    if (!keyWindow.ids.includes(e.id)) {
      keyWindow.ids.push(e.id);
    }
  });
};

export const handleGetPaginatedManyFailure: IHandler<GetPaginatedManyActionsFactoryType, 'failure'> = (
  draft,
  action,
) => {
  const keyWindow = draft[action.payload.keyWindowName];
  keyWindow.isFetching = false;
  keyWindow.isFetched = true;
  keyWindow.fetchingError = action.error;
};

export const handleGetPaginatedManyReset: IHandler<GetPaginatedManyActionsFactoryType, 'reset'> = (draft, action) => {
  const keyWindow = draft[action.payload.keyWindowName] as PaginatedKeyWindowType;
  if (keyWindow) {
    keyWindow.ids = [];
    keyWindow.count = 0;
    keyWindow.next = 'unknown';
    keyWindow.isFetching = false;
    keyWindow.isFetched = false;
    keyWindow.fetchingError = null;
  }
};
