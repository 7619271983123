import { get } from './client';
import { buildPath, toJson } from './utils';
import { MARKETS } from './constants';
import { GeneralListType } from './types';
import { Market, RecommendedMarket } from './general/models';

const DETECT_MARKET = 'detect-market';

export const getMarkets = (options: any): Promise<GeneralListType<Market>> =>
  get(buildPath(MARKETS), options).then(toJson);

export const detectMarket = (options: any): Promise<RecommendedMarket> =>
  get(buildPath(MARKETS, DETECT_MARKET), options).then(toJson);
