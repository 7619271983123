import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import {
  createGetUnsavedIdsSelector,
  createHasIgnoredUnsavingIdSelector,
  createHasUnsavedIdSelector,
} from 'store/entities/adminEvents/unsavedUtils';

export const adminEventSettingsSlice = (state: RootState) => state.entities.adminEventSettings;

export const adminEventSettingsUpdatingStateById =
  selectorsFactory.createUpdatingStateByIdSelector(adminEventSettingsSlice);

export const adminEventSettingsStateById = selectorsFactory.createEntityStateByIdSelector(adminEventSettingsSlice);

export const adminEventSettingsById = selectorsFactory.createEntityByIdSelector(adminEventSettingsSlice);

export const adminEventSettingsFetchingStateById =
  selectorsFactory.createFetchingStateByIdSelector(adminEventSettingsSlice);

export const getUnsavedIds = createGetUnsavedIdsSelector(adminEventSettingsSlice);

export const hasUnsavedId = createHasIgnoredUnsavingIdSelector(adminEventSettingsSlice);

export const hasIgnoredUnsavingId = createHasUnsavedIdSelector(adminEventSettingsSlice);
