import { isServer } from './next';

export enum GtagEventName {
  add_to_cart = 'add_to_cart',
  remove_from_cart = 'remove_from_cart',
  begin_checkout = 'begin_checkout',
  add_payment_info = 'add_payment_info',
  purchase = 'purchase',
  generate_lead = 'generate_lead',
  view_item = 'view_item',
  view_item_list = 'view_item_list',
}

export const sendGtagEvent = (event: GtagEventName, data: any) => {
  if (!isServer() && window.gtag) {
    window.gtag('event', event, data);
  }
};

export const createEcommerceItem = (eventEntity: any, orderEntity?: any, quantity = 1) => ({
  item_name: eventEntity.title,
  item_id: eventEntity.id,
  price: eventEntity.min_ticket_price,
  currency: eventEntity.default_currency,
  item_brand: eventEntity.organization.id,
  item_category: eventEntity.category?.slug,
  quantity,
  ...(orderEntity && {
    price: orderEntity.price,
    item_variant: orderEntity.title,
  }),
});

export const createEcommerceItemData = (eventEntity: any, orderEntity?: any, quantity = 1) => ({
  items: [createEcommerceItem(eventEntity, orderEntity, quantity)],
});

export const createEcommerceItemMapper =
  (eventEntity: any, orderItemCounts: Record<number, number>) => (orderItem: any) =>
    createEcommerceItem(eventEntity, orderItem, orderItemCounts[orderItem.id]);
