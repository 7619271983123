// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface UserTicketOrder
 */
export interface UserTicketOrder {
  /**
   * @type {number}
   * @memberof UserTicketOrder
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof UserTicketOrder
   */
  readonly title?: string;
  /**
   * @type {number}
   * @memberof UserTicketOrder
   */
  charged_amount?: number;
  /**
   * @type {string}
   * @memberof UserTicketOrder
   */
  charged_currency?: UserTicketOrderChargedCurrencyEnum;
  /**
   * @type {number}
   * @memberof UserTicketOrder
   */
  charged_tax_rate?: number | null;
  /**
   * @type {string}
   * @memberof UserTicketOrder
   */
  readonly attendee?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UserTicketOrderChargedCurrencyEnum {
  Usd = 'usd',
  Cad = 'cad',
}
