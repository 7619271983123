import { call, put, race, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminOrganizationsApi, UploadApi } from 'api';
import { apiCall } from 'store/utils';
import { GetAction } from 'store/types';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import { getNextMediaOrder } from '../adminOrganizations/sagas';

function* getNextImageOrder(imageType: string, organizationId: number): any {
  if (imageType === 'hero_image') return 1;
  if (imageType === 'logo') return 2;
  return yield call(getNextMediaOrder, { organizationId });
}

const handleGetAdminOrganizationImagesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationImages,
  request: AdminOrganizationsApi.getImages,
  requestArgsBuilder: (action) => action.payload.params.organizationId,
});

const handleCreateAdminOrganizationImageRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminOrganizationImage,
  request: AdminOrganizationsApi.createImage,
  requestArgsBuilder: function* builder(action): any {
    const { params } = action.payload;
    const { file, imageType, organizationId } = params;

    const order: any = yield call(getNextImageOrder, imageType, organizationId);
    const response: any = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type: imageType,
      },
    });

    return [organizationId, { body: { image: response.id, order } }];
  },
});

const handleUpdateAdminOrganizationImageRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminOrganizationImage,
  request: AdminOrganizationsApi.updateImage,
  requestArgsBuilder: function* builder(action): any {
    const { id, entity, params } = action.payload;
    const { organizationId, file, imageType } = params;

    if (!(file && imageType)) {
      return [organizationId, id, { body: entity }];
    }

    const response: any = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type: imageType,
      },
    });

    return [organizationId, { body: { ...entity, image: response.id } }];
  },
});

const handleDeleteAdminOrganizationImageRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminOrganizationImage,
  request: AdminOrganizationsApi.deleteImage,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

function* handleUploadAdminOrganizationImages(action: GetAction<typeof actions.createAdminOrganizationImages>) {
  const { files, ...rest } = action.payload.params;
  for (let i = 0; i < files.length; i += 1) {
    yield put(actions.createAdminOrganizationImage.request({ params: { file: files[i], ...rest } }));
    yield race([
      take(actions.createAdminOrganizationImage.success.type),
      take(actions.createAdminOrganizationImage.failure.type),
    ]);
  }
}

export default function* adminOrganizationImagesSagas() {
  yield takeEvery(actions.getAdminOrganizationImages.request.type, handleGetAdminOrganizationImagesRequest);
  yield takeEvery(actions.createAdminOrganizationImage.request.type, handleCreateAdminOrganizationImageRequest);
  yield takeEvery(actions.updateAdminOrganizationImage.request.type, handleUpdateAdminOrganizationImageRequest);
  yield takeEvery(actions.deleteAdminOrganizationImage.request.type, handleDeleteAdminOrganizationImageRequest);
  yield takeLeading(actions.createAdminOrganizationImages.type, handleUploadAdminOrganizationImages);
}
