import { Attendee, EventRegistrationAggregatedData } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { actionsFactories as featuresActionsFactories } from 'store/features/utils';
import * as keyWindowNames from './keyWindowNames';
import {
  CancelAdminAttendeeTicketParamsType,
  ChangeAttendeeStatusParamsType,
  GetAdminAttendeeDetailsParamsType,
  GetAdminAttendeeParamsType,
  GetAdminAttendeesParamsType,
  GetAdminAttendeeTicketsParamsType,
} from './types';
import { AttendeeDetails } from '../../../api/admin/models/AttendeeDetails';

export const getAdminAttendee = actionsFactories.createGetOneActionsFactory<
  EntityType<Attendee>,
  GetAdminAttendeeParamsType
>()(
  'GET_ADMIN_ATTENDEE_REQUEST',
  'GET_ADMIN_ATTENDEE_SUCCESS',
  'GET_ADMIN_ATTENDEE_FAILURE',
  'GET_ADMIN_ATTENDEE_RESET',
);

export const getAdminAttendeeDetails = actionsFactories.createGetOneActionsFactory<
  EntityType<AttendeeDetails>,
  GetAdminAttendeeDetailsParamsType
>()(
  'GET_ADMIN_ATTENDEE_DETAILS_REQUEST',
  'GET_ADMIN_ATTENDEE_DETAILS_SUCCESS',
  'GET_ADMIN_ATTENDEE_DETAILS_FAILURE',
  'GET_ADMIN_ATTENDEE_DETAILS_RESET',
);

export const getAdminAttendees = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Attendee>,
  GetAdminAttendeesParamsType
>()(
  'GET_ADMIN_ATTENDEES_REQUEST',
  'GET_ADMIN_ATTENDEES_SUCCESS',
  'GET_ADMIN_ATTENDEES_FAILURE',
  'RESET_ADMIN_ATTENDEES',
  keyWindowNames.adminAttendees,
);

export const getAdminAttendeeTickets = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Attendee>,
  GetAdminAttendeeTicketsParamsType
>()(
  'GET_ADMIN_ATTENDEE_TICKETS_REQUEST',
  'GET_ADMIN_ATTENDEE_TICKETS_SUCCESS',
  'GET_ADMIN_ATTENDEE_TICKETS_FAILURE',
  'RESET_ADMIN_ATTENDEE_TICKETS',
  keyWindowNames.adminAttendeeTickets,
);

export const changeAdminAttendeeStatus = featuresActionsFactories.createMultipleFeatureActionsFactory<
  ChangeAttendeeStatusParamsType,
  EntityType<Attendee>
>()(
  'CHANGE_ADMIN_ATTENDEE_STATUS_REQUEST',
  'CHANGE_ADMIN_ATTENDEE_STATUS_SUCCESS',
  'CHANGE_ADMIN_ATTENDEE_STATUS_FAILURE',
  'RESET_ADMIN_ATTENDEE_STATUS',
);

export const getAdminRegistrationStat = actionsFactories.createGetOneActionsFactory<
  EntityType<EventRegistrationAggregatedData>
>()(
  'GET_ADMIN_REGISTRATION_STAT_REQUEST',
  'GET_ADMIN_REGISTRATION_STAT_SUCCESS',
  'GET_ADMIN_REGISTRATION_STAT_FAILURE',
  'GET_ADMIN_REGISTRATION_STAT_RESET',
);

export const cancelAdminAttendeeTicket = featuresActionsFactories.createMultipleFeatureActionsFactory<
  CancelAdminAttendeeTicketParamsType,
  EntityType<Attendee>
>()(
  'CANCEL_ADMIN_ATTENDEE_TICKET_REQUEST',
  'CANCEL_ADMIN_ATTENDEE_TICKET_SUCCESS',
  'CANCEL_ADMIN_ATTENDEE_TICKET_FAILURE',
  'RESET_CANCEL_ADMIN_ATTENDEE_TICKET',
);
