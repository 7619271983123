import { takeEvery } from 'redux-saga/effects';
import { EventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetEventDonationRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getEventDonation,
  request: EventsApi.getEventDonation,
  transformResponse: (response, action) => ({
    ...response,
    donationId: response.id,
    id: action.payload.id,
  }),
  notifyError: false,
});

export default function* donationsSagas() {
  yield takeEvery(actions.getEventDonation.request.type, handleGetEventDonationRequest);
}
