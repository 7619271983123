import { AdminOrganizationAutoCompleteType } from 'api/types';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<AdminOrganizationAutoCompleteType>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationsMetaTagsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationMetaTags.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrganizationMetaTags.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrganizationMetaTags.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrganizationMetaTags.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.createAdminOrganizationMetaTags.request.type]: actionsHandlers.handleCreateManyRequest,
  [actions.createAdminOrganizationMetaTags.success.type]: actionsHandlers.handleCreateManySuccess,
  [actions.createAdminOrganizationMetaTags.failure.type]: actionsHandlers.handleCreateManyFailure,
  [actions.createAdminOrganizationMetaTags.reset.type]: actionsHandlers.handleCreateManyReset,
  [actions.updateAdminOrganizationMetaTags.request.type]: actionsHandlers.handleUpdateManyRequest,
  [actions.updateAdminOrganizationMetaTags.success.type]: actionsHandlers.handleUpdateManySuccess,
  [actions.updateAdminOrganizationMetaTags.failure.type]: actionsHandlers.handleUpdateManyFailure,
  [actions.updateAdminOrganizationMetaTags.reset.type]: actionsHandlers.handleUpdateManyReset,
  [actions.deleteAdminOrganizationMetaTags.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminOrganizationMetaTags.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminOrganizationMetaTags.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminOrganizationsMetaTagsReducer;
