import { useEffect, useState } from 'react';

const useMedian = () => {
  const [isMedian, setIsMedian] = useState(false);

  useEffect(() => {
    // Check if window is defined to ensure it's a client environment
    if (typeof window !== 'undefined') {
      import('median-js-bridge')
        .then((Median) => {
          setIsMedian(Median.default.isNativeApp());
        })
        .catch((err) => console.error('Error loading Median:', err));
    }
  }, []);

  return {
    isMedian,
  };
};

export default useMedian;
