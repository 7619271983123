import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { actionsFactories as featureActionsFactories } from 'store/features/utils';
import { EntityType } from 'store/entities/types';
import {
  AdminOrganizationIcsFeedLinkType,
  SpreedlyConnectType,
  SpreedlyDisconnectType,
  StripeAuthorizationType,
  StripeDashboardLinkType,
} from 'api/types';
import {
  OrganizationAdminList,
  OrganizationAdminShortList,
  OrganizationSettings,
  OrganizationSettingsSpecificEvent,
  OrganizationType,
  OrganizationUpdate,
} from 'api/admin/models';
import { CreateAdminOrganizationParamsType, StripeConfirmAuthorizationType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminOrganization = actionsFactories.createGetOneActionsFactory<EntityType<OrganizationType>>()(
  'GET_ADMIN_ORGANIZATION_REQUEST',
  'GET_ADMIN_ORGANIZATION_SUCCESS',
  'GET_ADMIN_ORGANIZATION_FAILURE',
  'GET_ADMIN_ORGANIZATION_RESET',
);

export const updateAdminOrganization = actionsFactories.createUpdateOneActionsFactory<EntityType<OrganizationUpdate>>()(
  'UPDATE_ADMIN_ORGANIZATION_REQUEST',
  'UPDATE_ADMIN_ORGANIZATION_SUCCESS',
  'UPDATE_ADMIN_ORGANIZATION_FAILURE',
  'UPDATE_ADMIN_ORGANIZATION_RESET',
);

export const getAdminOrganizations = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<OrganizationAdminList>
>()(
  'GET_ADMIN_ORGANIZATIONS_REQUEST',
  'GET_ADMIN_ORGANIZATIONS_SUCCESS',
  'GET_ADMIN_ORGANIZATIONS_FAILURE',
  'GET_ADMIN_ORGANIZATIONS_RESET',
);

export const getAdminAllOrganizations = actionsFactories.createGetManyActionsFactory<
  EntityType<OrganizationAdminShortList>
>()(
  'GET_ADMIN_ALL_ORGANIZATIONS_REQUEST',
  'GET_ADMIN_ALL_ORGANIZATIONS_SUCCESS',
  'GET_ADMIN_ALL_ORGANIZATIONS_FAILURE',
  'GET_ADMIN_ALL_ORGANIZATIONS_RESET',
);

export const getAdminOrganizationSetting = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationSettings>
>()(
  'GET_ADMIN_ORGANIZATION_SETTING_REQUEST',
  'GET_ADMIN_ORGANIZATION_SETTING_SUCCESS',
  'GET_ADMIN_ORGANIZATION_SETTING_FAILURE',
  'RESET_ADMIN_ORGANIZATION_SETTING',
);

export const updateAdminOrganizationSetting = actionsFactories.createUpdateOneActionsFactory<
  EntityType<OrganizationSettings>
>()(
  'UPDATE_ADMIN_ORGANIZATION_SETTING_REQUEST',
  'UPDATE_ADMIN_ORGANIZATION_SETTING_SUCCESS',
  'UPDATE_ADMIN_ORGANIZATION_SETTING_FAILURE',
  'UPDATE_ADMIN_ORGANIZATION_SETTING_RESET',
);

export const getAdminOrganizationSettingSpecificEvent = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationSettingsSpecificEvent>
>()(
  'GET_ADMIN_ORGANIZATION_SETTING_SPECIFIC_EVENT_REQUEST',
  'GET_ADMIN_ORGANIZATION_SETTING_SPECIFIC_EVENT_SUCCESS',
  'GET_ADMIN_ORGANIZATION_SETTING_SPECIFIC_EVENT_FAILURE',
  'RESET_ADMIN_ORGANIZATION_SETTING_SPECIFIC_EVENT',
);

export const updateAdminOrganizationDetails = featureActionsFactories.createMultipleFeatureActionsFactory<any>()(
  'UPDATE_ADMIN_ORGANIZATION_DETAILS_REQUEST',
  'UPDATE_ADMIN_ORGANIZATION_DETAILS_SUCCESS',
  'UPDATE_ADMIN_ORGANIZATION_DETAILS_FAILURE',
  'UPDATE_ADMIN_ORGANIZATION_DETAILS_RESET',
);

export const requestOauthAuthorization =
  featureActionsFactories.createSingleFeatureActionsFactory<StripeAuthorizationType>()(
    'STRIPE_REQUEST_OAUTH_AUTHORIZATION_REQUEST',
    'STRIPE_REQUEST_OAUTH_AUTHORIZATION_SUCCESS',
    'STRIPE_REQUEST_OAUTH_AUTHORIZATION_FAILURE',
    'STRIPE_REQUEST_OAUTH_AUTHORIZATION_RESET',
  );

export const connectSpreedlyMoneris = featureActionsFactories.createSingleFeatureActionsFactory<SpreedlyConnectType>()(
  'CONNECT_SPREEDLY_MONERIS_REQUEST',
  'CONNECT_SPREEDLY_MONERIS_SUCCESS',
  'CONNECT_SPREEDLY_MONERIS_FAILURE',
  'CONNECT_SPREEDLY_MONERIS_RESET',
);

export const connectSpreedlyStripe = featureActionsFactories.createSingleFeatureActionsFactory<SpreedlyConnectType>()(
  'CONNECT_SPREEDLY_STRIPE_REQUEST',
  'CONNECT_SPREEDLY_STRIPE_SUCCESS',
  'CONNECT_SPREEDLY_STRIPE_FAILURE',
  'CONNECT_SPREEDLY_STRIPE_RESET',
);

export const connectSpreedlyAuthorizeNet =
  featureActionsFactories.createSingleFeatureActionsFactory<SpreedlyConnectType>()(
    'CONNECT_SPREEDLY_AUTHORIZE_NET_REQUEST',
    'CONNECT_SPREEDLY_AUTHORIZE_NET_SUCCESS',
    'CONNECT_SPREEDLY_AUTHORIZE_NET_FAILURE',
    'CONNECT_SPREEDLY_AUTHORIZE_NET_RESET',
  );

export const disconnectSpreedly = featureActionsFactories.createSingleFeatureActionsFactory<SpreedlyDisconnectType>()(
  'DISCONNECT_SPREEDLY_REQUEST',
  'DISCONNECT_SPREEDLY_SUCCESS',
  'DISCONNECT_SPREEDLY_FAILURE',
  'DISCONNECT_SPREEDLY_RESET',
);

export const disconnectStripe = featureActionsFactories.createSingleFeatureActionsFactory<SpreedlyDisconnectType>()(
  'DISCONNECT_STRIPE_REQUEST',
  'DISCONNECT_STRIPE_SUCCESS',
  'DISCONNECT_STRIPE_FAILURE',
  'DISCONNECT_STRIPE_RESET',
);

export const confirmOauthAuthorization =
  featureActionsFactories.createSingleFeatureActionsFactory<StripeConfirmAuthorizationType>()(
    'STRIPE_CONFIRM_OAUTH_AUTHORIZATION_REQUEST',
    'STRIPE_CONFIRM_OAUTH_AUTHORIZATION_SUCCESS',
    'STRIPE_CONFIRM_OAUTH_AUTHORIZATION_FAILURE',
    'STRIPE_CONFIRM_OAUTH_AUTHORIZATION_RESET',
  );

export const getStripeDashboardLink = actionsFactories.createGetOneActionsFactory<StripeDashboardLinkType>()(
  'GET_STRIPE_DASHBOARD_LINK_REQUEST',
  'GET_STRIPE_DASHBOARD_LINK_SUCCESS',
  'GET_STRIPE_DASHBOARD_LINK_FAILURE',
  'GET_STRIPE_DASHBOARD_LINK_RESET',
);

export const getAdminOrganizationIcsFeedLink = featureActionsFactories.createMultipleFeatureActionsFactory<
  never,
  AdminOrganizationIcsFeedLinkType
>()(
  'GET_ORGANIZATION_ICS_FEED_LINK_REQUEST',
  'GET_ORGANIZATION_ICS_FEED_LINK_SUCCESS',
  'GET_ORGANIZATION_ICS_FEED_LINK_FAILURE',
  'GET_ORGANIZATION_ICS_FEED_LINK_RESET',
);

export const createAdminOrganization = actionsFactories.createCreateOneActionsFactory<
  EntityType<OrganizationAdminList>,
  CreateAdminOrganizationParamsType
>()(
  'CREATE_ADMIN_ORGANIZATION_REQUEST',
  'CREATE_ADMIN_ORGANIZATION_SUCCESS',
  'CREATE_ADMIN_ORGANIZATION_FAILURE',
  'CREATE_ADMIN_ORGANIZATION_RESET',
  keyWindowNames.adminOrganization,
);

export const changeAdminOrganizationMediaOrder = createAction(
  'CHANGE_ADMIN_ORGANIZATION_MEDIA_ORDER',
  (oldIndex: number, newIndex: number, organizationId: number) => ({
    payload: {
      oldIndex,
      newIndex,
      organizationId,
    },
  }),
);
