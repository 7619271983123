import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { HistoryStateType, StepType } from '../types';
import * as actions from '../actions';

type State = HistoryStateType<StepType>;
type Actions = RootActions<typeof actions>;

const initialState: State = {
  past: [],
  present: null,
  future: [],
};

const handleResetState = () => initialState;

const isSameStep = (a: StepType, b: StepType) => a.itemType === b.itemType && a.itemIndex === b.itemIndex;

const registrationReducer = createReducer<State, Actions>(initialState, {
  [actions.nextQuestion.type]: (draft) => {
    draft.past.push(draft.present);
    draft.present = draft.future.shift();
  },
  [actions.prevQuestion.type]: (draft) => {
    draft.future.unshift(draft.present);
    draft.present = draft.past.pop();
  },
  [actions.removeFutureStep.type]: (draft, action) => {
    const index = draft.future.findIndex((step) => isSameStep(step, action.payload));
    if (index !== -1) draft.future.splice(index, 1);
  },
  [actions.initRegistration.type]: (draft, action) => action.payload.state,
  [actions.resetRegistration.type]: handleResetState,
  [actions.resetCreatingOrder.type]: handleResetState,
});

export default registrationReducer;
