// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BaseQuestionOption } from '.';

/**
 * @export
 * @interface OrganizationManyTimeQuestionDisplay
 */
export interface OrganizationManyTimeQuestionDisplay {
  /**
   * @type {number}
   * @memberof OrganizationManyTimeQuestionDisplay
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof OrganizationManyTimeQuestionDisplay
   */
  text: string;
  /**
   * @type {string}
   * @memberof OrganizationManyTimeQuestionDisplay
   */
  description?: string;
  /**
   * @type {string}
   * @memberof OrganizationManyTimeQuestionDisplay
   */
  type?: OrganizationManyTimeQuestionDisplayTypeEnum;
  /**
   * @type {Array<BaseQuestionOption>}
   * @memberof OrganizationManyTimeQuestionDisplay
   */
  options?: Array<BaseQuestionOption>;
  /**
   * @type {string}
   * @memberof OrganizationManyTimeQuestionDisplay
   */
  readonly slug?: string;
  /**
   * @type {boolean}
   * @memberof OrganizationManyTimeQuestionDisplay
   */
  is_required?: boolean;
  /**
   * @type {boolean}
   * @memberof OrganizationManyTimeQuestionDisplay
   */
  is_options_custom_text_allowed?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationManyTimeQuestionDisplayTypeEnum {
  Single = 'single',
  Multiple = 'multiple',
  Paragraph = 'paragraph',
  Text = 'text',
  Custom = 'custom',
}
