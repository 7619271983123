import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminEventMetaTagsSlice = (state: RootState) => state.entities.adminEventMetaTags;

export const adminEventMetaTagsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventMetaTagsSlice,
  keyWindowNames.adminEventMetaTags,
);

export const adminEventMetaTagsCreatingState = selectorsFactory.createCreatingStateSelector(adminEventMetaTagsSlice);

export const adminEventMetaTagsFetchingState = selectorsFactory.createDynamicKeyWindowFetchingStateSelector(
  adminEventMetaTagsSlice,
  keyWindowNames.adminEventMetaTags,
);

export const adminMetaTagsContentTypesState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(
  adminEventMetaTagsSlice,
  keyWindowNames.adminMetaTagsContentTypes,
);
