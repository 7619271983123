import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { createSelector } from 'reselect';
import { UserPassport, UserPassportTypes } from '../../../api/general/models/UserPassport';

export const usersSlice = (state: RootState) => state.entities.users.entitiesState;

export const userPreferencesSlice = (state: RootState) => state.entities.users.userPreferences;
export const userPreferencesByEmailSlice = (state: RootState) => state.entities.users.userPreferencesByEmail;

export const userPassportsSlice = (state: RootState) => state.entities.users.userPassports;

export const userPreferencesById = selectorsFactory.createEntityByIdSelector(userPreferencesSlice);
export const userPreferencesStateById = selectorsFactory.createEntityStateByIdSelector(userPreferencesSlice);

export const userPreferencesByEmail = selectorsFactory.createEntityByIdSelector(userPreferencesByEmailSlice);
export const userPreferencesStateByEmail = selectorsFactory.createEntityStateByIdSelector(userPreferencesByEmailSlice);

export const userById = selectorsFactory.createEntityByIdSelector(usersSlice);

export const isUserImageUpdating = createSelector(usersSlice, (state) => state.isImageUpdating);

export const isUserUpdating = selectorsFactory.createIsUpdatingByIdSelector(usersSlice);

export const userFetchingErrorById = selectorsFactory.createFetchingErrorByIdSelector(usersSlice);

export const userIsSuccessfullyUpdated = selectorsFactory.createIsSuccessfullyUpdatedById(usersSlice);

export const userPassportsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(userPassportsSlice);

export const userPassportCreatingState = selectorsFactory.createCreatingStateSelector(userPassportsSlice);

const getJLivePassport = (passportsState: any) =>
  passportsState.entities.find((passport: UserPassport) => passport.passport_type === UserPassportTypes.INTERNAL);
export const userJLivePassport = createSelector(userPassportsState, getJLivePassport);
