import { CalendarSettings, Market } from './general/models';
import { get, patch } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_MARKETS } from './constants';
import { GeneralListType, IcsFeedLinkGenerator } from './types';

const ICS_FEED_LINK_GENERATOR = 'ics-feed-link-generator';
const CALENDAR_LINK_GENERATOR = 'community-calendar-link-generator';

export const getCalendarSettingsList = (options: any): Promise<GeneralListType<CalendarSettings>> =>
  get(buildPath(ADMIN_MARKETS), options).then(toJson);

export const getCalendarSettings = (marketSlug: string, options: any): Promise<Market> =>
  get(buildPath(ADMIN_MARKETS, marketSlug), options).then(toJson);

export const updateCalendarSettings = (marketSlug: string, options: any): Promise<Market> =>
  patch(buildPath(ADMIN_MARKETS, marketSlug), options).then(toJson);

export const generateMarketICSFeedUrl = (marketSlug: string, options: any): Promise<IcsFeedLinkGenerator> =>
  get(buildPath(ADMIN_MARKETS, marketSlug, ICS_FEED_LINK_GENERATOR), options).then(toJson);

export const generateCommunityCalendarICSFeedUrl = (marketSlug: string, options: any): Promise<IcsFeedLinkGenerator> =>
  get(buildPath(ADMIN_MARKETS, marketSlug, CALENDAR_LINK_GENERATOR), options).then(toJson);
