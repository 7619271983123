import { OrderList } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { actionsFactories as featureActionsFactories } from 'store/features/utils';

export const getOrders = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<OrderList>>()(
  'GET_ORDERS_REQUEST',
  'GET_ORDERS_SUCCESS',
  'GET_ORDERS_FAILURE',
  'GET_ORDERS_RESET',
);

export const getOrder = actionsFactories.createGetOneActionsFactory<EntityType<OrderList>>()(
  'GET_ORDER_REQUEST',
  'GET_ORDER_SUCCESS',
  'GET_ORDER_FAILURE',
  'GET_ORDER_RESET',
);

export const resendOrderEmail = featureActionsFactories.createMultipleFeatureActionsFactory()(
  'RESEND_ORDER_EMAIL_REQUEST',
  'RESEND_ORDER_EMAIL_SUCCESS',
  'RESEND_ORDER_EMAIL_FAILURE',
  'RESEND_ORDER_EMAIL_RESET',
);
