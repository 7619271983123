import { Draft } from 'immer';
import { createEcommerceItem, GtagEventName } from 'utils/gtag';
import { createReducer } from 'store/utils';
import { GetAction, RootActions } from 'store/types';
import { EventsActions } from 'store/actions';
import { State } from './types';
import * as actions from './actions';

type Actions = RootActions<typeof actions & typeof EventsActions>;

const initialState: State = {
  gtagEvents: [],
};

const handleEvent = <A extends typeof EventsActions.getEvent.success>(draft: Draft<State>, action: GetAction<A>) => {
  draft.gtagEvents.push({
    event: GtagEventName.view_item,
    data: {
      items: [createEcommerceItem(action.payload.entity)],
    },
  });
};

const handleEvents = <A extends typeof EventsActions.getEvents.success>(draft: Draft<State>, action: GetAction<A>) => {
  draft.gtagEvents.push({
    event: GtagEventName.view_item_list,
    data: {
      items: action.payload.entities.results.map((entity) => createEcommerceItem(entity)),
    },
  });
};

const analyticsReducer = createReducer<State, Actions>(initialState, {
  [actions.resetGtagEvents.type]: () => initialState,
  ...(typeof window === 'undefined' && {
    [EventsActions.getEvent.success.type]: handleEvent,
    [EventsActions.getEvents.success.type]: handleEvents,
    [EventsActions.getPastEvents.success.type]: handleEvents,
    [EventsActions.getUpcomingEvents.success.type]: handleEvents,
    [EventsActions.getUserFavoriteEvents.success.type]: handleEvents,
    [EventsActions.getUserFavoritePastEvents.success.type]: handleEvents,
    [EventsActions.getUserFavoriteUpcomingEvents.success.type]: handleEvents,
  }),
});

export default analyticsReducer;
