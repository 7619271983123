import { EventRegistrantsEmailMessageDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory =
  stateFactories.createInitialEntitiesStateFactory<EntityType<EventRegistrantsEmailMessageDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventRegistrantsEmailsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminRegistrantsEmails.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminRegistrantsEmails.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminRegistrantsEmails.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminRegistrantsEmails.reset.type]: actionsHandlers.handleGetPaginatedManyReset,

  [actions.getAdminRegistrantsEmail.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminRegistrantsEmail.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminRegistrantsEmail.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminRegistrantsEmail.reset.type]: actionsHandlers.handleGetOneReset,

  [actions.createAdminRegistrantsEmail.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminRegistrantsEmail.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminRegistrantsEmail.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminRegistrantsEmail.reset.type]: actionsHandlers.handleCreateOneReset,
});

export default adminEventRegistrantsEmailsReducer;
