import { RootActions } from 'store/types';
import { createReducer } from 'store/utils';
import * as actions from '../actions';

const initialState = {
  isPending: false,
  items: [] as any[],
};

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const mediaReducer = createReducer<State, Actions>(initialState, {
  [actions.setMediaItemsPending.type]: (draft, action) => {
    draft.isPending = action.payload.isPending;
  },
  [actions.setMediaItems.type]: (draft, action) => {
    draft.items = action.payload.items;
  },
  [actions.addMediaItem.type]: (draft, action) => {
    draft.items.push(action.payload.media);
  },
  [actions.removeMediaItem.type]: (draft, action) => {
    draft.items.splice(action.payload.index, 1);
  },
  [actions.removeMediaItems.type]: (draft) => {
    draft.items = [];
  },
  [actions.swapMediaItems.type]: (draft, action) => {
    const { oldIndex, newIndex } = action.payload;
    const startIndex = newIndex < 0 ? draft.items.length + newIndex : newIndex;
    const item = draft.items.splice(oldIndex, 1)[0];
    draft.items.splice(startIndex, 0, item);
  },
});

export default mediaReducer;
