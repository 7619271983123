import { get, post, del, patch } from './client';
import { buildPath, toJson } from './utils';
import { SPREEDLY_PAYMENT_METHODS_PATH } from './constants';

const SET_DEFAULT = 'set-default';

export const getSpreedlyPaymentMethods = (options: any): Promise<any> =>
  get(buildPath(SPREEDLY_PAYMENT_METHODS_PATH), options).then(toJson);

export const createSpreedlyPaymentMethods = (options: any): Promise<any> =>
  post(buildPath(SPREEDLY_PAYMENT_METHODS_PATH), options).then(toJson);

export const updateSpreedlyPaymentMethods = (options: any): Promise<any> =>
  patch(buildPath(SPREEDLY_PAYMENT_METHODS_PATH), options).then(toJson);

export const deleteSpreedlyPaymentMethods = (options: any): Promise<any> =>
  del(buildPath(SPREEDLY_PAYMENT_METHODS_PATH), options);

export const setDefaultSpreedlyPaymentMethod = (options: any): Promise<any> =>
  post(buildPath(SPREEDLY_PAYMENT_METHODS_PATH, SET_DEFAULT), options).then(toJson);
