import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const adminEventTotalSalesStatisticsSlice = (state: RootState) => state.entities.adminEventTotalSalesStatistics;

export const adminEventTotalSalesStatisticsUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(
  adminEventTotalSalesStatisticsSlice,
);

export const adminEventTotalSalesStatisticsStateById = selectorsFactory.createEntityStateByIdSelector(
  adminEventTotalSalesStatisticsSlice,
);
