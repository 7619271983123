import { IdType, Options } from './types';
import { del, get, patch, post } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_EVENTS_PATH } from './constants';
import { AdminPromoCode } from './general/models/AdminPromoCode';

const PROMO_CODES = 'promocodes';

export const getAdminEventPromoCodes = (eventId: IdType, options: any): Promise<AdminPromoCode> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, PROMO_CODES), options).then(toJson);

export const getAdminEventPromoCode = (eventId: IdType, id: IdType, options: any): Promise<AdminPromoCode> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, PROMO_CODES, id), options).then(toJson);

export const createAdminPromoCode = (eventId: IdType, options: Options<AdminPromoCode>): Promise<AdminPromoCode> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, PROMO_CODES), options).then(toJson);

export const partialAdminEventPromoCode = (
  eventId: IdType,
  promoCodeId: IdType,
  options: Options<AdminPromoCode>,
): Promise<AdminPromoCode> =>
  patch(buildPath(ADMIN_EVENTS_PATH, eventId, PROMO_CODES, promoCodeId), options).then(toJson);

export const deleteAdminEventPromoCode = (eventId: IdType, promoCodeId: IdType, options: any): Promise<any> =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, PROMO_CODES, promoCodeId), options);
