import { IcsFeedLinkGenerator } from 'api/types';
import { EventList, Event } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { actionsFactories as featureActionsFactories } from 'store/features/utils';
import {
  GetContextualEventsParamsType,
  GetEventsParamsType,
  GetSearchEventsParamsType,
  GetUpcomingEventsParamsType,
} from './types';
import * as keyWindowNames from './keyWindowNames';

export const getEvent = actionsFactories.createGetOneActionsFactory<EntityType<Event>>()(
  'GET_EVENT_REQUEST',
  'GET_EVENT_SUCCESS',
  'GET_EVENT_FAILURE',
  'GET_EVENT_RESET',
);

export const getEvents = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<EventList>>()(
  'GET_EVENTS_REQUEST',
  'GET_EVENTS_SUCCESS',
  'GET_EVENTS_FAILURE',
  'GET_EVENTS_RESET',
);

export const getSearchEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Event>,
  GetSearchEventsParamsType
>()(
  'GET_SEARCH_EVENTS_REQUEST',
  'GET_SEARCH_EVENTS_SUCCESS',
  'GET_SEARCH_EVENTS_FAILURE',
  'GET_SEARCH_EVENTS_RESET',
  keyWindowNames.searchEvents,
);

export const getContextualEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Event>,
  GetContextualEventsParamsType
>()(
  'GET_CONTEXTUAL_EVENTS_REQUEST',
  'GET_CONTEXTUAL_EVENTS_SUCCESS',
  'GET_CONTEXTUAL_EVENTS_FAILURE',
  'GET_CONTEXTUAL_EVENTS_RESET',
  keyWindowNames.contextualEvents,
);

export const getUpcomingEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Event>,
  GetUpcomingEventsParamsType
>()(
  'GET_UPCOMING_EVENTS_REQUEST',
  'GET_UPCOMING_EVENTS_SUCCESS',
  'GET_UPCOMING_EVENTS_FAILURE',
  'GET_UPCOMING_EVENTS_RESET',
  keyWindowNames.upcomingEvents,
);

export const getPastEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<Event>,
  GetEventsParamsType
>()(
  'GET_PAST_EVENTS_REQUEST',
  'GET_PAST_EVENTS_SUCCESS',
  'GET_PAST_EVENTS_FAILURE',
  'GET_PAST_EVENTS_RESET',
  keyWindowNames.pastEvents,
);

export const toggleFavoriteEvent = featureActionsFactories.createMultipleFeatureActionsFactory()(
  'TOGGLE_FAVORITE_EVENT_REQUEST',
  'TOGGLE_FAVORITE_EVENT_SUCCESS',
  'TOGGLE_FAVORITE_EVENT_FAILURE',
  'TOGGLE_FAVORITE_EVENT_RESET',
);

export const getEventIcsFeedLinkGenerator = featureActionsFactories.createMultipleFeatureActionsFactory<
  EntityType<IcsFeedLinkGenerator>
>()(
  'GET_EVENT_ICS_FEED_LINK_GENERATOR_REQUEST',
  'GET_EVENT_ICS_FEED_LINK_GENERATOR_SUCCESS',
  'GET_EVENT_ICS_FEED_LINK_GENERATOR_FAILURE',
  'GET_EVENT_ICS_FEED_LINK_GENERATOR_RESET',
);

export const getUserFavoriteEvents = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<Event>>()(
  'GET_USER_FAVORITE_EVENTS_REQUEST',
  'GET_USER_FAVORITE_EVENTS_SUCCESS',
  'GET_USER_FAVORITE_EVENTS_FAILURE',
  'RESET_USER_FAVORITE_EVENTS',
);

export const getUserFavoriteUpcomingEvents = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<Event>>()(
  'GET_USER_FAVORITE_UPCOMING_EVENTS_REQUEST',
  'GET_USER_FAVORITE_UPCOMING_EVENTS_SUCCESS',
  'GET_USER_FAVORITE_UPCOMING_EVENTS_FAILURE',
  'RESET_USER_FAVORITE_UPCOMING_EVENTS',
  keyWindowNames.userFavoriteUpcomingEvents,
);

export const getUserFavoritePastEvents = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<Event>>()(
  'GET_USER_FAVORITE_PAST_EVENTS_REQUEST',
  'GET_USER_FAVORITE_PAST_EVENTS_SUCCESS',
  'GET_USER_FAVORITE_PAST_EVENTS_FAILURE',
  'RESET_USER_FAVORITE_PAST_EVENTS',
  keyWindowNames.userFavoritePastEvents,
);
