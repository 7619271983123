// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AdditionalInstructionAdmin
 */
export interface AdditionalInstructionAdmin {
  /**
   * @type {number}
   * @memberof AdditionalInstructionAdmin
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof AdditionalInstructionAdmin
   */
  title?: string;
  /**
   * @type {string}
   * @memberof AdditionalInstructionAdmin
   */
  slug: AdditionalInstructionAdminSlugEnum;
  /**
   * @type {string}
   * @memberof AdditionalInstructionAdmin
   */
  text_for_registered?: string;
  /**
   * @type {string}
   * @memberof AdditionalInstructionAdmin
   */
  text_for_non_registered?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AdditionalInstructionAdminSlugEnum {
  InternalPassportRequired = 'internal_passport_required',
  Parking = 'parking',
  ArrivalCheckIn = 'arrival_check_in',
  Forms = 'forms',
  DressCode = 'dress_code',
  GeneralUpdate = 'general_update',
  Custom = 'custom',
}
