import { DefaultLanguageEventDisplay, EventDisplayList, EventUpdate } from 'api/admin/models';
import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { actionsFactories as featureActionsFactories } from 'store/features/utils';
import * as keyWindowNames from './keyWindowNames';
import {
  ArchiveAdminEventParamsType,
  CancelAdminEventParamsType,
  CloneAdminEventParamsType,
  DeleteAdminEventParamsType,
  GetAdminAllEventsParamsType,
  GetAdminEventParamsType,
  GetAdminEventsParamsType,
  GetAdminForRoleEventsParamsType,
  MarkAsTestAdminEventParamsType,
  MarkAsTestAdminEventResultType,
  PublishAdminEventParamsType,
  PublishAdminEventResultType,
  SaveAsDraftAdminEventParamsType,
  UnarchiveAdminEventParamsType,
  UpdateAdminCCEventStatusParamsType,
  UpdateAdminCCEventStatusResultType,
  UpdateAdminEventParamsType,
} from './types';

export const getAdminEvent = actionsFactories.createGetOneActionsFactory<
  EntityType<DefaultLanguageEventDisplay>,
  GetAdminEventParamsType
>()('GET_ADMIN_EVENT_REQUEST', 'GET_ADMIN_EVENT_SUCCESS', 'GET_ADMIN_EVENT_FAILURE', 'RESET_ADMIN_EVENT');

export const getAdminEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<EventDisplayList>,
  GetAdminEventsParamsType
>()(
  'GET_ADMIN_EVENTS_REQUEST',
  'GET_ADMIN_EVENTS_SUCCESS',
  'GET_ADMIN_EVENTS_FAILURE',
  'RESET_ADMIN_EVENTS',
  keyWindowNames.adminOrganization,
);

export const getAdminOrgEventsAutocomplete = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<EventDisplayList>,
  GetAdminEventsParamsType
>()(
  'GET_ADMIN_ORG_EVENTS_AUTOCOMPLETE_REQUEST',
  'GET_ADMIN_ORG_EVENTS_AUTOCOMPLETE_SUCCESS',
  'GET_ADMIN_ORG_EVENTS_AUTOCOMPLETE_FAILURE',
  'RESET_ADMIN_ORG_EVENTS_AUTOCOMPLETE',
  keyWindowNames.adminOrganization,
);

export const getAdminAllEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<EventDisplayList>,
  GetAdminAllEventsParamsType
>()(
  'GET_ALL_ADMIN_EVENTS_REQUEST',
  'GET_ALL_ADMIN_EVENTS_SUCCESS',
  'GET_ALL_ADMIN_EVENTS_FAILURE',
  'RESET_ALL_ADMIN_EVENTS',
);

export const getAdminForRoleEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<EventDisplayList>,
  GetAdminForRoleEventsParamsType
>()(
  'GET_ADMIN_FOR_ROLE_EVENTS_REQUEST',
  'GET_ADMIN_FOR_ROLE_EVENTS_SUCCESS',
  'GET_ADMIN_FOR_ROLE_EVENTS_FAILURE',
  'RESET_ADMIN_FOR_ROLE_EVENTS',
  keyWindowNames.adminOrganizationAdmin,
);

export const getAdminForSelectRoleEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<EventDisplayList>,
  GetAdminForRoleEventsParamsType
>()(
  'GET_ADMIN_FOR_SELECT_ROLE_EVENTS_REQUEST',
  'GET_ADMIN_FOR_SELECT_ROLE_EVENTS_SUCCESS',
  'GET_ADMIN_FOR_SELECT_ROLE_EVENTS_FAILURE',
  'RESET_ADMIN_FOR_SELECT_ROLE_EVENTS',
  keyWindowNames.adminOrganizationAdmin,
);

export const getAdminAllCCEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<EventDisplayList>,
  GetAdminAllEventsParamsType
>()(
  'GET_ALL_ADMIN_CC_EVENTS_REQUEST',
  'GET_ALL_ADMIN_CC_EVENTS_SUCCESS',
  'GET_ALL_ADMIN_CC_EVENTS_FAILURE',
  'RESET_ALL_ADMIN_CC_EVENTS',
);

export const updateAdminCCEventStatus = featureActionsFactories.createMultipleFeatureActionsFactory<
  UpdateAdminCCEventStatusParamsType,
  UpdateAdminCCEventStatusResultType
>()(
  'UPDATE_ADMIN_CC_EVENT_STATUS_REQUEST',
  'UPDATE_ADMIN_CC_EVENT_STATUS_SUCCESS',
  'UPDATE_ADMIN_CC_EVENT_STATUS_FAILURE',
  'RESET_UPDATE_ADMIN_CC_EVENT_STATUS',
);

export const updateAdminEvent = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EventUpdate>,
  UpdateAdminEventParamsType
>()(
  'UPDATE_ADMIN_EVENTS_REQUEST',
  'UPDATE_ADMIN_EVENTS_SUCCESS',
  'UPDATE_ADMIN_EVENTS_FAILURE',
  'UPDATE_ADMIN_EVENTS_RESET',
);

export const runtimeUpdateAdminEvent = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EventUpdate>,
  UpdateAdminEventParamsType
>()(
  'RUNTIME_UPDATE_ADMIN_EVENTS_REQUEST',
  'RUNTIME_UPDATE_ADMIN_EVENTS_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_EVENTS_FAILURE',
  'RUNTIME_UPDATE_ADMIN_EVENTS_RESET',
);

export const deleteAdminEvent = actionsFactories.createDeleteOneActionsFactory<DeleteAdminEventParamsType>()(
  'DELETE_ADMIN_EVENTS_REQUEST',
  'DELETE_ADMIN_EVENTS_SUCCESS',
  'DELETE_ADMIN_EVENTS_FAILURE',
);

export const cloneAdminEvent = featureActionsFactories.createMultipleFeatureActionsFactory<CloneAdminEventParamsType>()(
  'CLONE_ADMIN_EVENT_REQUEST',
  'CLONE_ADMIN_EVENT_SUCCESS',
  'CLONE_ADMIN_EVENT_FAILURE',
  'RESET_CLONE_ADMIN_EVENT',
);

export const publishAdminEvent = featureActionsFactories.createMultipleFeatureActionsFactory<
  PublishAdminEventParamsType,
  PublishAdminEventResultType
>()(
  'PUBLISH_EVENT_ADMIN_REQUEST',
  'PUBLISH_EVENT_ADMIN_SUCCESS',
  'PUBLISH_EVENT_ADMIN_FAILURE',
  'RESET_PUBLISH_EVENT_ADMIN',
);

export const saveAsDraftAdminEvent =
  featureActionsFactories.createMultipleFeatureActionsFactory<SaveAsDraftAdminEventParamsType>()(
    'SAVE_AS_DRAFT_ADMIN_EVENT_REQUEST',
    'SAVE_AS_DRAFT_ADMIN_EVENT_SUCCESS',
    'SAVE_AS_DRAFT_ADMIN_EVENT_FAILURE',
    'RESET_SAVE_AS_DRAFT_ADMIN_EVENT',
  );

export const cancelAdminEvent =
  featureActionsFactories.createMultipleFeatureActionsFactory<CancelAdminEventParamsType>()(
    'CANCEL_ADMIN_EVENT_REQUEST',
    'CANCEL_ADMIN_EVENT_SUCCESS',
    'CANCEL_ADMIN_EVENT_FAILURE',
    'RESET_CANCEL_ADMIN_EVENT',
  );

export const archiveAdminEvent =
  featureActionsFactories.createMultipleFeatureActionsFactory<ArchiveAdminEventParamsType>()(
    'ARCHIVE_ADMIN_EVENT_REQUEST',
    'ARCHIVE_ADMIN_EVENT_SUCCESS',
    'ARCHIVE_ADMIN_EVENT_FAILURE',
    'ARCHIVE_ADMIN_EVENT_RESET',
  );

export const unarchiveAdminEvent =
  featureActionsFactories.createMultipleFeatureActionsFactory<UnarchiveAdminEventParamsType>()(
    'UNARCHIVE_ADMIN_EVENT_REQUEST',
    'UNARCHIVE_ADMIN_EVENT_SUCCESS',
    'UNARCHIVE_ADMIN_EVENT_FAILURE',
    'UNARCHIVE_ADMIN_EVENT_RESET',
  );

export const markAsTestAdminEvent = featureActionsFactories.createMultipleFeatureActionsFactory<
  MarkAsTestAdminEventParamsType,
  MarkAsTestAdminEventResultType
>()(
  'MARK_AS_TEST_ADMIN_EVENT_REQUEST',
  'MARK_AS_TEST_ADMIN_EVENT_SUCCESS',
  'MARK_AS_TEST_ADMIN_EVENT_FAILURE',
  'RESET_MARK_AS_TEST_ADMIN_EVENT',
);

export const publishUnsaved = featureActionsFactories.createMultipleFeatureActionsFactory<
  GetAdminEventParamsType,
  PublishAdminEventResultType
>()(
  'PUBLISH_UNSAVED_ADMIN_EVENT_REQUEST',
  'PUBLISH_UNSAVED_ADMIN_EVENT_SUCCESS',
  'PUBLISH_UNSAVED_ADMIN_EVENT_FAILURE',
  'PUBLISH_UNSAVED_ADMIN_EVENT_RESET',
);

export const changeAdminEventShowBuyerOnlyQuestions = createAction(
  'CHANGE_ADMIN_EVENT_SHOW_BUYER_ONLY_QUESTIONS',
  (organizationId: number, eventId: number, show: false) => ({
    payload: {
      organizationId,
      eventId,
      show_buyer_only_questions: show,
    },
  }),
);

export const changeAdminEventShowAllAttendeesQuestions = createAction(
  'CHANGE_ADMIN_EVENT_SHOW_ALL_ATTENDEES_QUESTIONS',
  (organizationId: number, eventId: number, show: false) => ({
    payload: {
      organizationId,
      eventId,
      show_all_attendees_questions: show,
    },
  }),
);

export const changeAdminEventShowTicketsQuestions = createAction(
  'CHANGE_ADMIN_EVENT_SHOW_TICKETS_QUESTIONS',
  (organizationId: number, eventId: number, show: false) => ({
    payload: {
      organizationId,
      eventId,
      show_ticket_specific_questions: show,
    },
  }),
);

export const changeAdminEventShowAddonsQuestions = createAction(
  'CHANGE_ADMIN_EVENT_SHOW_ADDONS_QUESTIONS',
  (organizationId: number, eventId: number, show: false) => ({
    payload: {
      organizationId,
      eventId,
      show_addon_specific_questions: show,
    },
  }),
);

export const changeAdminEventMediaOrder = createAction(
  'CHANGE_ADMIN_EVENT_MEDIA_ORDER',
  (oldIndex: number, newIndex: number, eventId: number) => ({
    payload: {
      oldIndex,
      newIndex,
      eventId,
    },
  }),
);

export const addUnsavedAdminEvent = createAction('ADD_UNSAVED_ADMIN_EVENT', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeUnsavedAdminEvent = createAction('REMOVE_UNSAVED_ADMIN_EVENT', (id: number) => ({
  payload: {
    id,
  },
}));

export const addIgnoredUnsavingAdminEvent = createAction('ADD_IGNORED_UNSAVING_ADMIN_EVENT', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeIgnoredUnsavingAdminEvent = createAction('REMOVE_IGNORED_UNSAVING_ADMIN_EVENT', (id: number) => ({
  payload: {
    id,
  },
}));
