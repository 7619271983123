import { createReducer } from '../../../utils';
import { RootActions } from '../../../types';
import { actionsHandlers, stateFactories } from '../../utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const checkRecaptchaReducer = () =>
  createReducer<State, Actions>(initialState, {
    [actions.checkReCaptcha.request.type]: actionsHandlers.handleSingleFeatureRequest,
    [actions.checkReCaptcha.success.type]: actionsHandlers.handleSingleFeatureSuccess,
    [actions.checkReCaptcha.failure.type]: actionsHandlers.handleSingleFeatureFailure,
    [actions.checkReCaptcha.reset.type]: actionsHandlers.handleSingleFeatureReset,
  });

export default checkRecaptchaReducer;
