import { RootActions } from 'store/types';
import { createReducer } from 'store/utils';
import * as actions from '../actions';

const initialState = {
  isUploading: false,
  item: null as any,
};

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const heroImageReducer = createReducer<State, Actions>(initialState, {
  [actions.uploadHeroImage.request.type]: (draft) => {
    draft.isUploading = true;
  },
  [actions.uploadHeroImage.success.type]: (draft, action) => {
    draft.isUploading = false;
    draft.item = action.payload.image;
  },
  [actions.uploadHeroImage.failure.type]: (draft) => {
    draft.isUploading = false;
    draft.item = null;
  },
  [actions.setHeroImage.type]: (draft, action) => {
    draft.item = action.payload.image;
  },
  [actions.removeHeroImage.type]: (draft) => {
    draft.item = null;
  },
});

export default heroImageReducer;
