import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminDonationsSlice = (state: RootState) => state.entities.adminDonations.entitiesState;

export const adminDonationOptionsSlice = (state: RootState) => state.entities.adminDonations.donationOptions;

export const adminDonationState = selectorsFactory.createEntityStateByIdSelector(adminDonationsSlice);

export const hasAdminDonationState = selectorsFactory.createHasEntityByIdSelector(adminDonationsSlice);

export const adminDonationOptionsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminDonationOptionsSlice,
  keyWindowNames.adminEvent,
);

export const hasAdminDonationOptionsState = selectorsFactory.createDynamicKeyWindowHasEntitiesSelector(
  adminDonationOptionsSlice,
  keyWindowNames.adminEvent,
);

export const adminDonationOptionsCreatingState =
  selectorsFactory.createCreatingStateSelector(adminDonationOptionsSlice);
