// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StripeCardPaymentMethod } from '.';

/**
 * @export
 * @interface StripeOrderCheckout
 */
export interface StripeOrderCheckout {
  /**
   * @type {string}
   * @memberof StripeOrderCheckout
   */
  readonly id?: string;
  /**
   * @type {string}
   * @memberof StripeOrderCheckout
   */
  status: StripeOrderCheckoutStatusEnum;
  /**
   * @type {string}
   * @memberof StripeOrderCheckout
   */
  payment_type?: StripeOrderCheckoutPaymentTypeEnum;
  /**
   * @type {string}
   * @memberof StripeOrderCheckout
   */
  type?: StripeOrderCheckoutTypeEnum;
  /**
   * @type {number}
   * @memberof StripeOrderCheckout
   */
  application_fee_amount?: number;
  /**
   * @type {StripeCardPaymentMethod}
   * @memberof StripeOrderCheckout
   */
  payment_method_data: StripeCardPaymentMethod;
}

/**
 * @export
 * @enum {string}
 */
export enum StripeOrderCheckoutStatusEnum {
  Pending = 'pending',
  Canceled = 'canceled',
  Abandoned = 'abandoned',
  Failed = 'failed',
  Succeeded = 'succeeded',
}
/**
 * @export
 * @enum {string}
 */
export enum StripeOrderCheckoutPaymentTypeEnum {
  Empty = '',
  Card = 'card',
  ApplePay = 'apple_pay',
}
/**
 * @export
 * @enum {string}
 */
export enum StripeOrderCheckoutTypeEnum {
  Empty = '',
  Card = 'card',
}
