import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { MarketsApi } from 'api';
import { GetAction } from 'store/types';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetMarketsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getMarkets,
  request: MarketsApi.getMarkets,
});

function* handleGetNextMarkets(action: GetAction<typeof actions.getMarkets.success>) {
  const { entities, params } = action.payload;
  if (entities.next) {
    const { page, page_size } = yield call(getNextPageParams, params.page_size, selectors.marketsState);
    yield put(actions.getMarkets.request({ params: { ...params, page, page_size } }));
  }
}

export default function* marketsSagas() {
  yield takeLeading(actions.getMarkets.request.type, handleGetMarketsRequest);
  yield takeEvery(actions.getMarkets.success.type, handleGetNextMarkets);
}
