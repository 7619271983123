import { AddOnCreate, AddOnUpdate, AdminAddOnDetails } from 'api/admin/models';
import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { AdminEventAddonsParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminAddons = actionsFactories.createGetManyActionsFactory<
  EntityType<AdminAddOnDetails>,
  AdminEventAddonsParamsType
>()(
  'GET_ADMIN_ADDONS_REQUEST',
  'GET_ADMIN_ADDONS_SUCCESS',
  'GET_ADMIN_ADDONS_FAILURE',
  'GET_ADMIN_ADDONS_RESET',
  keyWindowNames.adminEventAddons,
);

export const createAdminAddon = actionsFactories.createCreateOneActionsFactory<
  EntityType<AddOnCreate>,
  AdminEventAddonsParamsType
>()(
  'CREATE_ADMIN_ADDON_REQUEST',
  'CREATE_ADMIN_ADDON_SUCCESS',
  'CREATE_ADMIN_ADDON_FAILURE',
  'CREATE_ADMIN_ADDON_RESET',
  keyWindowNames.adminEventAddons,
);

export const updateAdminAddon = actionsFactories.createUpdateOneActionsFactory<
  EntityType<AddOnUpdate>,
  AdminEventAddonsParamsType
>()(
  'UPDATE_ADMIN_ADDON_REQUEST',
  'UPDATE_ADMIN_ADDON_SUCCESS',
  'UPDATE_ADMIN_ADDON_FAILURE',
  'UPDATE_ADMIN_ADDON_RESET',
);

export const runtimeUpdateAdminAddon = actionsFactories.createUpdateOneActionsFactory<
  EntityType<AddOnUpdate>,
  AdminEventAddonsParamsType
>()(
  'RUNTIME_UPDATE_ADMIN_ADDON_REQUEST',
  'RUNTIME_UPDATE_ADMIN_ADDON_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_ADDON_FAILURE',
  'RUNTIME_UPDATE_ADMIN_ADDON_RESET',
);

export const deleteAdminAddon = actionsFactories.createDeleteOneActionsFactory<AdminEventAddonsParamsType>()(
  'DELETE_ADMIN_ADDON_REQUEST',
  'DELETE_ADMIN_ADDON_SUCCESS',
  'DELETE_ADMIN_ADDON_FAILURE',
);

export const addUnsavedAdminAddon = createAction('ADD_UNSAVED_ADMIN_ADDON', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeUnsavedAdminAddon = createAction('REMOVE_UNSAVED_ADMIN_ADDON', (id: number) => ({
  payload: {
    id,
  },
}));

export const addIgnoredUnsavingAdminAddon = createAction('ADD_IGNORED_UNSAVING_ADMIN_ADDON', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeIgnoredUnsavingAdminAddon = createAction('REMOVE_IGNORED_UNSAVING_ADMIN_ADDON', (id: number) => ({
  payload: {
    id,
  },
}));
