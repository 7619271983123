import { takeEvery } from 'redux-saga/effects';
import { EventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { sortQuestions } from 'store/entities/events/utils';
import { TicketDetails } from 'api/general/models';
import * as actions from './actions';

const handleGetEventTicketsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getEventTickets,
  request: EventsApi.getEventTickets,
  requestArgsBuilder: (action) => action.payload.params.eventId,
  transformResponse: (response) =>
    response.reduce((result: any[], item: any) => {
      item.data.forEach(({ tickets }: any) => {
        tickets
          .sort((a: TicketDetails, b: TicketDetails) => (a.order ? a.order - b.order : 1))
          .forEach((ticket: TicketDetails) => {
            result.push({
              ...ticket,
              questions: sortQuestions(ticket.questions),
            });
          });
      });
      return result;
    }, []),
});

export default function* ticketsSagas() {
  yield takeEvery(actions.getEventTickets.request.type, handleGetEventTicketsRequest);
}
