import { StripeDashboardLinkType } from 'api/types';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<StripeDashboardLinkType>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const stripeDashboardLinkReducer = createReducer<State, Actions>(initialState, {
  [actions.getStripeDashboardLink.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getStripeDashboardLink.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getStripeDashboardLink.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getStripeDashboardLink.reset.type]: actionsHandlers.handleGetOneReset,
});

export default stripeDashboardLinkReducer;
