import { OrganizationAdminShortList } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationAdminShortList>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminCreateOrganizationReducer = createReducer<State, Actions>(initialState, {
  [actions.createAdminOrganization.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminOrganization.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminOrganization.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminOrganization.reset.type]: actionsHandlers.handleCreateOneReset,
});

export default adminCreateOrganizationReducer;
