import { call, put, select, takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { OrganizationImage, OrganizationVideoDisplay } from 'api/admin/models';
import { messageTypes } from 'utils/messages';
import { apiCall, handlePopupListener } from 'store/utils';
import { GetAction } from 'store/types';
import { AdminOrganizationImagesActions, AdminOrganizationVideosActions, NotificationsActions } from 'store/actions';
import { AdminOrganizationImagesSelectors, AdminOrganizationVideosSelectors } from 'store/selectors';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from 'store/features/utils';
import { MEDIA_ORDER_OFFSET, MEDIA_ORDER_SPREAD } from './constants';
import * as actions from './actions';
import * as selectors from './selectors';
import { createChangeOrderHandler } from '../adminEvents/sagasUtils';

const handleGetAdminOrganizationRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganization,
  request: AdminOrganizationsApi.getOrganization,
});

const handleGetAdminOrganizationsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizations,
  request: AdminOrganizationsApi.getOrganizations,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminOrganizationsState, params);

    return { params: { ...rest, page, page_size } };
  },
});

const handleGetAdminAllOrganizationsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminAllOrganizations,
  request: AdminOrganizationsApi.getAllOrganizations,
});

const handleUpdateAdminOrganizationRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminOrganization,
  request: AdminOrganizationsApi.partialUpdateOrganization,
});

const handleGetStripeDashboardLinkRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getStripeDashboardLink,
  request: AdminOrganizationsApi.getStripeExpressDashboardLink,
  transformResponse: (response, action) => ({ id: action.payload.id, ...response }),
});

const handleGetAdminOrganizationIcsFeedLink = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.getAdminOrganizationIcsFeedLink,
  request: AdminOrganizationsApi.getIcsFeedLink,
  requestArgsBuilder: (action) => action.payload.id,
});

function* handleRequestOauthAuthorizationRequest(
  action: GetAction<typeof actions.requestOauthAuthorization.request>,
): any {
  try {
    const response = yield apiCall(
      AdminOrganizationsApi.requestOauthAuthorization,
      action.payload.params.organizationId,
    );

    const confirmed = yield call(
      handlePopupListener,
      response.auth_url,
      messageTypes.CONFIRM_STRIPE_OAUTH_AUTHORIZATION_SUCCESS,
    );

    if (confirmed) {
      yield put(actions.getStripeDashboardLink.request({ id: action.payload.params.organizationId }));
      yield put(
        actions.requestOauthAuthorization.success({
          params: { organizationId: action.payload.params.organizationId },
        }),
      );
      return;
    }

    yield put(actions.requestOauthAuthorization.reset());
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.requestOauthAuthorization.failure({ error }));
      yield put(NotificationsActions.showError(error));
    }
  }
}

const handleSpreedlyMonerisAuthorization = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.connectSpreedlyMoneris,
  request: AdminOrganizationsApi.connectSpreedlyMoneris,
  requestArgsBuilder: (action) => {
    const { organizationId, values } = action.payload.params;
    const { organization_name, address, api_token, store_id } = values;
    return [organizationId, { body: { organization_name, address, api_token, store_id } }];
  },
  transformResponse: (response, action) => ({
    ...response,
    id: action.payload.params.organizationId,
  }),
  // notifyError: false,
});

const handleSpreedlyStripeAuthorization = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.connectSpreedlyStripe,
  request: AdminOrganizationsApi.connectSpreedlyStripe,
  requestArgsBuilder: (action) => {
    const { organizationId, values } = action.payload.params;
    const { organization_name, address, login } = values;
    return [organizationId, { body: { organization_name, address, login } }];
  },
  transformResponse: (response, action) => ({
    ...response,
    id: action.payload.params.organizationId,
  }),
  // notifyError: false,
});

const handleSpreedlyAuthorizeNetAuthorization = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.connectSpreedlyAuthorizeNet,
  request: AdminOrganizationsApi.connectSpreedlyAuthorizeNet,
  requestArgsBuilder: (action) => {
    const { organizationId, values, sandbox } = action.payload.params;
    const { organization_name, address, login, password } = values;

    return [
      organizationId,
      {
        body: {
          organization_name,
          address,
          login,
          password,
          sandbox,
        },
      },
    ];
  },
  transformResponse: (response, action) => ({
    ...response,
    id: action.payload.params.organizationId,
  }),
  // notifyError: false,
});

const handleSpreedlyDisconnect = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.disconnectSpreedly,
  request: AdminOrganizationsApi.disconnectSpreedly,
  requestArgsBuilder: (action) => {
    const { organizationId } = action.payload.params;
    return [organizationId];
  },
  transformResponse: (response, action) => ({
    ...response,
    id: action.payload.params.organizationId,
  }),
  // notifyError: false,
});

const handleStripeDisconnect = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.disconnectStripe,
  request: AdminOrganizationsApi.disconnectStripe,
  requestArgsBuilder: (action) => {
    const { organizationId } = action.payload.params;
    return [organizationId];
  },
  transformResponse: (response, action) => ({
    ...response,
    id: action.payload.params.organizationId,
  }),
  // notifyError: false,
});

function* handleUpdateAdminOrganizationDetailsRequest(
  action: GetAction<typeof actions.updateAdminOrganizationDetails.request>,
): any {
  const { id, params } = action.payload;
  const {
    linked_page_button_text,
    linked_page_button_url,
    show_powered_by,
    show_past_events,
    accept_donations_on_profile_page,
    enable_multilingual_profile_page,
    ...rest
  } = params;

  try {
    const organization = yield apiCall(AdminOrganizationsApi.partialUpdateOrganization, id, {
      body: {
        ...rest,
      },
    });

    const settings = yield apiCall(AdminOrganizationsApi.updateAdminOrganizationSetting, id, {
      body: {
        linked_page_button_text,
        linked_page_button_url,
        show_powered_by,
        show_past_events,
        accept_donations_on_profile_page,
        enable_multilingual_profile_page,
      },
    });

    yield put(actions.updateAdminOrganization.success({ entity: organization }));
    yield put(actions.updateAdminOrganizationSetting.success({ entity: { id, ...settings } }));
    yield put(actions.updateAdminOrganizationDetails.success({ id, params }));
  } catch (error) {
    if (error instanceof Error)
      yield put(actions.updateAdminOrganizationDetails.failure({ error, notifyError: true, id, params }));
  }
}

const handleGetAdminOrganizationSetting = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationSetting,
  request: AdminOrganizationsApi.getAdminOrganizationSetting,
  transformResponse: (response, action) => ({ ...response, id: action.payload.id }),
});

const handleGetAdminOrgSettingSpecificEvent = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationSettingSpecificEvent,
  request: AdminOrganizationsApi.getAdminOrganizationSettingSpecificEvent,
  transformResponse: (response, action) => ({ ...response, eventId: response?.id, id: action.payload.id }),
});

const handleUpdateAdminOrganizationSetting = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminOrganizationSetting,
  request: AdminOrganizationsApi.updateAdminOrganizationSetting,
  transformResponse: (response, action) => ({ ...response, id: action.payload.id }),
});

const handleConfirmOauthAuthorizationRequest = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.confirmOauthAuthorization,
  request: AdminOrganizationsApi.requestStripeConnect,
  requestArgsBuilder: (action) => ({ params: action.payload.params }),
  notifyError: false,
});

export function* getMedias(params: { organizationId: number }): any {
  const images: any = yield select(AdminOrganizationImagesSelectors.adminOrganizationGalleryImages, params);
  const videos: any = yield select(AdminOrganizationVideosSelectors.adminOrganizationVideos, params);
  return images.concat(videos) as (OrganizationImage | OrganizationVideoDisplay)[];
}

export function* getNextMediaOrder(params: { organizationId: number }): any {
  const medias: any = yield call(getMedias, params);
  return (medias.length === 0 ? MEDIA_ORDER_OFFSET : medias[medias.length - 1].order) + MEDIA_ORDER_SPREAD;
}

const handleCreateOrganizationRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminOrganization,
  request: AdminOrganizationsApi.createOrganization,
  requestArgsBuilder: (action) => [{ body: action.payload.params.entity }],
});

const handleSwapAdminOrganizationMedia = createChangeOrderHandler<
  GetAction<typeof actions.changeAdminOrganizationMediaOrder>,
  OrganizationImage | OrganizationVideoDisplay
>({
  spread: MEDIA_ORDER_SPREAD,
  offset: MEDIA_ORDER_OFFSET,
  getEntities: getMedias,
  updateEntityOrder: function* update(media, order, params) {
    if ((media as OrganizationVideoDisplay).link) {
      yield put(
        AdminOrganizationVideosActions.updateAdminOrganizationVideo.request({
          id: media.id,
          params,
          entity: {
            order,
            link: (media as OrganizationVideoDisplay).link,
          },
        }),
      );
    } else {
      yield put(
        AdminOrganizationImagesActions.updateAdminOrganizationImage.request({
          id: media.id,
          params,
          entity: {
            order,
            image: (media as OrganizationImage).image.id,
          },
        }),
      );
    }
  },
});

export default function* adminOrganizationsSagas() {
  yield takeEvery(actions.getAdminOrganization.request.type, handleGetAdminOrganizationRequest);
  yield takeEvery(actions.getAdminOrganizations.request.type, handleGetAdminOrganizationsRequest);
  yield takeEvery(actions.getAdminAllOrganizations.request.type, handleGetAdminAllOrganizationsRequest);
  yield takeEvery(actions.updateAdminOrganization.request.type, handleUpdateAdminOrganizationRequest);
  yield takeEvery(actions.getStripeDashboardLink.request.type, handleGetStripeDashboardLinkRequest);
  yield takeEvery(actions.getAdminOrganizationIcsFeedLink.request.type, handleGetAdminOrganizationIcsFeedLink);
  yield takeEvery(actions.requestOauthAuthorization.request.type, handleRequestOauthAuthorizationRequest);
  yield takeEvery(actions.connectSpreedlyMoneris.request.type, handleSpreedlyMonerisAuthorization);
  yield takeEvery(actions.connectSpreedlyStripe.request.type, handleSpreedlyStripeAuthorization);
  yield takeEvery(actions.connectSpreedlyAuthorizeNet.request.type, handleSpreedlyAuthorizeNetAuthorization);
  yield takeEvery(actions.disconnectSpreedly.request.type, handleSpreedlyDisconnect);
  yield takeEvery(actions.disconnectStripe.request.type, handleStripeDisconnect);
  yield takeEvery(actions.confirmOauthAuthorization.request.type, handleConfirmOauthAuthorizationRequest);
  yield takeEvery(actions.getAdminOrganizationSetting.request.type, handleGetAdminOrganizationSetting);
  yield takeEvery(actions.updateAdminOrganizationSetting.request.type, handleUpdateAdminOrganizationSetting);
  yield takeEvery(actions.getAdminOrganizationSettingSpecificEvent.request.type, handleGetAdminOrgSettingSpecificEvent);
  yield takeEvery(actions.updateAdminOrganizationDetails.request.type, handleUpdateAdminOrganizationDetailsRequest);
  yield takeEvery(actions.changeAdminOrganizationMediaOrder.type, handleSwapAdminOrganizationMedia);
  yield takeEvery(actions.createAdminOrganization.request.type, handleCreateOrganizationRequest);
}
