import { Attendee } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Attendee>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminAttendeesReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminAttendees.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminAttendees.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminAttendees.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminAttendees.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.changeAdminAttendeeStatus.success.type]: (draft, action) => {
    const { id, result, params } = action.payload;
    const { attendee_status } = params;
    draft.byId[id] = result;
    draft.keyWindows.forEach((key) => {
      if (key.includes(attendee_status) && !draft[key].ids.includes(id)) draft[key].ids.push(id);
    });
  },
  [actions.cancelAdminAttendeeTicket.success.type]: (draft, action) => {
    const { id, result } = action.payload;
    draft.byId[id] = result;
    draft.keyWindows.forEach((key) => {
      if (key.includes('canceled') && !draft[key].ids.includes(id)) draft[key].ids.push(id);
    });
  },
});

export default adminAttendeesReducer;
