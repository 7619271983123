import { AnyObject, AnyFunction } from 'types';
import { SerializedError, serializeError } from './serializeError';

export type Action<T extends string, P = never, M = never, E = never> = {
  type: T;
} & ([P] extends [never]
  ? AnyObject
  : {
      payload: P;
    }) &
  ([M] extends [never]
    ? AnyObject
    : {
        meta: M;
      }) &
  ([E] extends [never]
    ? AnyObject
    : {
        error: SerializedError;
      });

export type PrepareAction<F extends AnyFunction = AnyFunction> = F extends (...args: infer P) => infer R
  ? (...args: P) => R
  : never;

export type CreateAction<T extends string = string, PA extends PrepareAction = never> = ((
  ...args: Parameters<PA>
) => [PA] extends [never]
  ? Action<T>
  : Action<T, ReturnType<PA>['payload'], ReturnType<PA>['meta'], ReturnType<PA>['error']>) & {
  type: T;
  toString: () => T;
};

/**
 * A utility function to create an action creator for the given action type string.
 *
 * @param type The action type to use for created actions.
 * @param prepareAction (optional) a method that takes any number of arguments and returns action.
 * @returns The action creator
 */
export const createAction = <T extends string, PA extends PrepareAction = never>(
  type: T,
  prepareAction?: PA,
): CreateAction<T, PA> => {
  function creator(...args: any[]) {
    if (prepareAction) {
      const { error, ...prepared } = prepareAction(...args);
      return {
        type,
        ...prepared,
        ...(error && {
          error: serializeError(error),
        }),
      };
    }

    return {
      type,
    };
  }

  creator.type = type;
  creator.toString = () => type;

  return creator;
};
