import {
  DefaultLanguageEventDisplay,
  DefaultLanguageEventDisplayStatusDisplayEnum,
  DefaultLanguageEventDisplayStatusEnum,
} from 'api/admin/models';

import { createReducer } from 'store/utils';
import { GetAction, RootActions } from 'store/types';
import { EntityType, PaginateMetaType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as questionsActions from 'store/entities/adminQuestions/actions';

import {
  handleCreateQuestion,
  handleCreateQuestionOption,
  handleDeleteQuestionOption,
  handleDeleteSpecificEntityQuestion,
  handleUpdateQuestion,
  handleUpdateQuestionOption,
} from 'store/entities/adminAddons/reducer';

import { AdminQuestionsActions } from 'store/actions';
import { createAddIdActionHandler, createRemoveIdActionHandler, createUnsavingState } from '../unsavedUtils';
import * as actions from '../actions';
import * as keyWindowNames from '../keyWindowNames';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<DefaultLanguageEventDisplay>>();
const initialState = stateFactory.getInitialState({
  ...createUnsavingState(),
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

export const handleCancelAdminEvent = (draft: any, action: GetAction<typeof actions.cancelAdminEvent.success>) => {
  const event = draft.byId[action.payload.id];
  if (event) {
    event.status = DefaultLanguageEventDisplayStatusEnum.Canceled;
    event.status_display = DefaultLanguageEventDisplayStatusDisplayEnum.Canceled;
  }
};

export const handleSaveAsDraftAdminEvent = (
  draft: any,
  action: GetAction<typeof actions.saveAsDraftAdminEvent.success>,
) => {
  const event = draft.byId[action.payload.id];
  if (event) {
    event.status = DefaultLanguageEventDisplayStatusEnum.Draft;
    event.status_display = DefaultLanguageEventDisplayStatusDisplayEnum.Draft;
  }
};

export const handleArchiveAdminEvent = (draft: any, action: GetAction<typeof actions.archiveAdminEvent.success>) => {
  const event = draft.byId[action.payload.id];
  if (event) {
    event.status_display = DefaultLanguageEventDisplayStatusDisplayEnum.Archived;
  }
};

export const handleMarkAsTestAdminEvent = (
  draft: any,
  action: GetAction<typeof actions.markAsTestAdminEvent.success>,
) => {
  const event = draft.byId[action.payload.id];
  if (event) {
    event.is_test = action.payload.result.is_test;
  }
};

export const handleUnarchiveAdminEvent = (
  draft: any,
  action: GetAction<typeof actions.unarchiveAdminEvent.success>,
) => {
  const event = draft.byId[action.payload.id];
  if (event) {
    const keyWindowName = keyWindowNames.adminOrganization({ organization: event.organization.id });

    if (draft.keyWindows.includes(keyWindowName)) {
      const index = draft[keyWindowName].ids.indexOf(event.id);
      if (index !== -1) {
        draft[keyWindowName].ids.splice(index, 1);
        if ('count' in draft[keyWindowName]) {
          (draft[keyWindowName] as PaginateMetaType).count -= 1;
        }
      }
    }
  }
};

// todo: refactor duplicate code
const adminEventReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEvent.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminEvent.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminEvent.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminEvent.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.updateAdminEvent.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminEvent.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminEvent.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminEvent.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.runtimeUpdateAdminEvent.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.runtimeUpdateAdminEvent.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.runtimeUpdateAdminEvent.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.runtimeUpdateAdminEvent.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminEvent.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEvent.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEvent.failure.type]: actionsHandlers.handleDeleteOneFailure,
  [actions.cancelAdminEvent.success.type]: handleCancelAdminEvent,
  [actions.saveAsDraftAdminEvent.success.type]: handleSaveAsDraftAdminEvent,
  [actions.markAsTestAdminEvent.success.type]: handleMarkAsTestAdminEvent,
  [actions.archiveAdminEvent.success.type]: handleArchiveAdminEvent,
  [actions.unarchiveAdminEvent.success.type]: handleUnarchiveAdminEvent,
  [actions.addUnsavedAdminEvent.type]: createAddIdActionHandler('unsavedIds'),
  [actions.removeUnsavedAdminEvent.type]: createRemoveIdActionHandler('unsavedIds'),
  [actions.addIgnoredUnsavingAdminEvent.type]: createAddIdActionHandler('ignoredUnsavingIds'),
  [actions.removeIgnoredUnsavingAdminEvent.type]: createRemoveIdActionHandler('ignoredUnsavingIds'),
  [actions.publishAdminEvent.success.type]: (draft, action) => {
    const event: any = draft.byId[action.payload.id];
    if (event && action.payload.result.published) event.status = 'published';
  },
  [questionsActions.createAdminEventQuestion.success.type as any]: (draft: any, action: any) => {
    const event: any = draft.byId[action.payload.params.eventId];
    handleCreateQuestion(event, action);
  },
  [questionsActions.cloneAdminEventQuestion.success.type as any]: (draft: any, action: any) => {
    const event: any = draft.byId[action.payload.params.eventId];
    if (event)
      event.questions.push({
        ...action.payload.entity,
        default_question: action.payload.params.default_question,
      });
  },
  [questionsActions.updateAdminEventQuestion.success.type as any]: (draft: any, action: any) => {
    const event: any = draft.byId[action.payload.params.eventId];
    handleUpdateQuestion(event, action);
  },
  [questionsActions.deleteAdminEventQuestion.success.type as any]: (draft: any, action: any) => {
    const event: any = draft.byId[action.payload.params.eventId];
    handleDeleteSpecificEntityQuestion(event, action);
  },
  [questionsActions.updateAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const event: any = draft.byId[action.payload.params.eventId];
    handleUpdateQuestionOption(event, action);
  },
  [questionsActions.createAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const event: any = draft.byId[action.payload.params.eventId];
    handleCreateQuestionOption(event, action);
  },
  [questionsActions.deleteAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const event: any = draft.byId[action.payload.params.eventId];
    handleDeleteQuestionOption(event, action);
  },
  [AdminQuestionsActions.hideAdminQuestion.success.type as any]: (draft: any, action: any) => {
    Object.keys(draft.byId).forEach((id) => {
      const event = draft.byId[id];
      if (event.questions) {
        event.questions.forEach((question: { id: any; is_hidden: any }) => {
          if (question.id === action.payload.id) {
            question.is_hidden = action.payload.result.is_hidden;
          }
        });
      }
    });
  },
});

export default adminEventReducer;
