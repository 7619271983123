import { OrganizationAdminDetail } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationAdminDetail>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganization.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminOrganization.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminOrganization.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminOrganization.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.updateAdminOrganization.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminOrganization.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminOrganization.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminOrganization.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.updateAdminOrganizationSetting.success.type]: (draft, action) => {
    const organization = draft.byId[action.payload.entity.id];
    if (organization) {
      organization.settings = action.payload.entity;
    }
  },
  [actions.disconnectSpreedly.success.type]: (draft, action) => {
    const organization = draft.byId[action.payload.params.organizationId];
    if (organization) {
      organization.payment_system = null;
      organization.gateway_type = null;
    }
  },
  [actions.disconnectStripe.success.type]: (draft, action) => {
    const organization = draft.byId[action.payload.params.organizationId];
    if (organization) {
      organization.payment_system = null;
      organization.gateway_type = null;
    }
  },
});

export default adminOrganizationReducer;
