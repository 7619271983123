import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const stripePaymentMethodsSlice = (state: RootState) => state.entities.stripe.stripePaymentMethods;

export const getStripePaymentMethods =
  selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(stripePaymentMethodsSlice);

export const setDefaultStripePaymentMethodState = (state: RootState) =>
  state.entities.stripe.setDefaultStripePaymentMethod;

export const getStripePaymentMethodsState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(stripePaymentMethodsSlice);

export const hasStripePaymentMethods =
  selectorsFactory.createStaticKeyWindowHasEntitiesSelector(stripePaymentMethodsSlice);

export const stripePaymentMethodsCreatingState =
  selectorsFactory.createCreatingStateSelector(stripePaymentMethodsSlice);

export const stripePaymentMethodsUpdatingState =
  selectorsFactory.createUpdatingStateByIdSelector(stripePaymentMethodsSlice);

export const getTempStripePaymentMethod = (state: RootState) =>
  stripePaymentMethodsSlice(state).tempStripePaymentMethod;
