import { select, takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { AdminEventsSelectors } from 'store/selectors';
import * as actions from './actions';

const handleGetAdminEventTotalTicketsStatictics = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminEventTotalTicketsStatistics,
  request: AdminOrganizationsApi.getAdminEventTotalTicketsStatistics,
  requestArgsBuilder: (action) => {
    const { organizationId } = action.payload.params;
    return [organizationId, action.payload.id];
  },
  transformResponse: function* transformResponse(response, action) {
    yield select(AdminEventsSelectors.adminEventStateById, Number(action.payload.id));
    return { id: action.payload.id, ...response };
  },
});

export default function* adminEventTotalTicketsStatistics() {
  yield takeEvery(actions.getAdminEventTotalTicketsStatistics.request.type, handleGetAdminEventTotalTicketsStatictics);
}
