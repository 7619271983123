import { AdminPromoCode } from 'api/general/models/AdminPromoCode';
import { actionsFactories } from '../utils';
import { EntityType } from '../types';
import * as keyWindowNames from './keyWindowNames';
import {
  CreateAdminPromoCodeParams,
  DeleteAdminPromoCodeParams,
  GetAdminPromoCodesParams,
  UpdateAdminPromoCodeParams,
} from './types';

export const getAdminPromoCodes = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<AdminPromoCode>,
  GetAdminPromoCodesParams
>()(
  'GET_ADMIN_PROMO_CODES_REQUEST',
  'GET_ADMIN_PROMO_CODES_SUCCESS',
  'GET_ADMIN_PROMO_CODES_FAILURE',
  'GET_ADMIN_PROMO_CODES_RESET',
  keyWindowNames.adminPromoCodes,
);

export const getAdminPromoCode = actionsFactories.createGetOneActionsFactory<
  EntityType<AdminPromoCode>,
  CreateAdminPromoCodeParams
>()(
  'GET_ADMIN_PROMO_CODE_REQUEST',
  'GET_ADMIN_PROMO_CODE_SUCCESS',
  'GET_ADMIN_PROMO_CODE_FAILURE',
  'GET_ADMIN_PROMO_CODES_RESET',
);

export const createAdminPromoCode = actionsFactories.createCreateOneActionsFactory<
  EntityType<AdminPromoCode>,
  CreateAdminPromoCodeParams
>()(
  'CREATE_ADMIN_PROMO_CODE_REQUEST',
  'CREATE_ADMIN_PROMO_CODE_SUCCESS',
  'CREATE_ADMIN_PROMO_CODE_FAILURE',
  'CREATE_ADMIN_PROMO_CODE_RESET',
);

export const partialUpdateAdminPromoCode = actionsFactories.createUpdateOneActionsFactory<
  EntityType<AdminPromoCode>,
  UpdateAdminPromoCodeParams
>()(
  'PARTIAL_UPDATE_ADMIN_PROMO_CODE_REQUEST',
  'PARTIAL_UPDATE_ADMIN_PROMO_CODE_SUCCESS',
  'PARTIAL_UPDATE_ADMIN_PROMO_CODE_FAILURE',
  'PARTIAL_UPDATE_ADMIN_PROMO_CODE_RESET',
);

export const deleteAdminPromoCode = actionsFactories.createDeleteOneActionsFactory<DeleteAdminPromoCodeParams>()(
  'DELETE_ADMIN_PROMO_CODE_REQUEST',
  'DELETE_ADMIN_PROMO_CODE_SUCCESS',
  'DELETE_ADMIN_PROMO_CODE_FAILURE',
);
