import { DefaultMetaTagDisplay } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getDefaultMetaTags = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<DefaultMetaTagDisplay>
>()(
  'GET_DEFAULT_META_TAGS_REQUEST',
  'GET_DEFAULT_META_TAGS_SUCCESS',
  'GET_DEFAULT_META_TAGS_FAILURE',
  'GET_DEFAULT_META_TAGS_RESET',
);
