// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserShort } from '.';

/**
 * @export
 * @interface EventRegistrantsEmailMessageDisplay
 */
export interface EventRegistrantsEmailMessageDisplay {
  /**
   * @type {number}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  group_type: EventRegistrantsEmailMessageDisplayGroupTypeEnum;
  /**
   * @type {number}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  recipients?: number;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  subject: string;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  body?: string;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  subject_en?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  subject_he?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  subject_fr?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  body_en?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  body_he?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  body_fr?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  readonly is_finished?: string;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  readonly created?: string;
  /**
   * @type {UserShort}
   * @memberof EventRegistrantsEmailMessageDisplay
   */
  created_by?: UserShort;
}

/**
 * @export
 * @enum {string}
 */
export enum EventRegistrantsEmailMessageDisplayGroupTypeEnum {
  AllBuyers = 'all_buyers',
  AllAttendees = 'all_attendees',
  CheckedInAttendees = 'checked_in_attendees',
  NotYetCheckedInAttendees = 'not_yet_checked_in_attendees',
  Cancelled = 'cancelled',
  Itself = 'itself',
}
