import { LanguageSpoken, Occupation, UserInterest } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getLanguagesSpoken = actionsFactories.createGetManyActionsFactory<EntityType<LanguageSpoken>>()(
  'GET_LANGUAGES_SPOKEN_REQUEST',
  'GET_LANGUAGES_SPOKEN_SUCCESS',
  'GET_LANGUAGES_SPOKEN_FAILURE',
  'GET_LANGUAGES_SPOKEN_RESET',
);

export const getJewishDescendants = actionsFactories.createGetManyActionsFactory<EntityType<LanguageSpoken>>()(
  'GET_JEWISH_DESCENDANTS_REQUEST',
  'GET_JEWISH_DESCENDANTS_SUCCESS',
  'GET_JEWISH_DESCENDANTS_FAILURE',
  'GET_JEWISH_DESCENDANTS_RESET',
);

export const getOccupations = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<Occupation>>()(
  'GET_OCCUPATIONS_REQUEST',
  'GET_OCCUPATIONS_SUCCESS',
  'GET_OCCUPATIONS_FAILURE',
  'GET_OCCUPATIONS_RESET',
);

export const getUserInterests = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<UserInterest>>()(
  'GET_USER_INTERESTS_REQUEST',
  'GET_USER_INTERESTS_SUCCESS',
  'GET_USER_INTERESTS_FAILURE',
  'GET_USER_INTERESTS_RESET',
);
