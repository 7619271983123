import { OrganizationManyTimeQuestionDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory =
  stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationManyTimeQuestionDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminManyTimeQuestionsReducer = createReducer<State, Actions>(initialState, {
  [actions.createAdminManyTimeQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminManyTimeQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminManyTimeQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminManyTimeQuestion.reset.type]: actionsHandlers.handleCreateOneReset,

  [actions.updateAdminManyTimeQuestion.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminManyTimeQuestion.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminManyTimeQuestion.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.updateAdminManyTimeQuestion.success.type]: actionsHandlers.handleUpdateOneSuccess,

  [actions.getAdminManyTimeQuestions.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminManyTimeQuestions.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminManyTimeQuestions.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminManyTimeQuestions.reset.type]: actionsHandlers.handleGetPaginatedManyReset,

  [actions.deleteAdminManyTimeQuestion.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminManyTimeQuestion.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminManyTimeQuestion.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminManyTimeQuestionsReducer;
