import { takeLeading } from 'redux-saga/effects';
import { AdminEventOptionsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetEventCategoriesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getEventCategories,
  request: AdminEventOptionsApi.getEventCategories,
});

export default function* eventCategoriesSagas() {
  yield takeLeading(actions.getEventCategories.request.type, handleGetEventCategoriesRequest);
}
