import { SerializedError } from './serializeError';

const expectedErrorKeys = ['detail', 'message', 'reason', 'error'] as const;

export const findErrorMessage = (parsed: any): string => {
  if (parsed) {
    if (Array.isArray(parsed)) return findErrorMessage(parsed[0]);
    if (typeof parsed === 'string') return parsed;
    if (typeof parsed === 'object') {
      for (let i = 0; i < expectedErrorKeys.length; i += 1) {
        const value = parsed[expectedErrorKeys[i]];
        if (value) return findErrorMessage(value);
      }

      return findErrorMessage(Object.values(parsed)[0]);
    }
  }

  return null;
};

export const parseErrorMessage = (message: string): string => {
  if (!message) return message;
  try {
    return findErrorMessage(JSON.parse(message)) || message;
  } catch (e) {
    return message;
  }
};

export const parseError = (error: SerializedError): string => {
  if (!error) return null;
  if (error.response?.responseText) return parseErrorMessage(error.response.responseText);
  if (error.response?.statusText) return parseErrorMessage(error.response.statusText);
  return parseErrorMessage(error.message);
};
