import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminEventImageTranslationsSlice = (state: RootState) => state.entities.adminEventImageTranslations;

export const adminEventImageTranslationsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventImageTranslationsSlice,
  keyWindowNames.adminEventImageTranslations,
);

export const adminEventImageTranslationUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(
  adminEventImageTranslationsSlice,
);

export const adminEventImageTranslations = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminEventImageTranslationsSlice,
  keyWindowNames.adminEventImageTranslations,
);

export const adminEventMainImageTranslation = createSelector(adminEventImageTranslations, (imageTranslations) =>
  imageTranslations.find((imageTranslation) => imageTranslation.main),
);
