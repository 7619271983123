import { Attendee } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Attendee>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminAttendeeReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminAttendee.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminAttendee.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminAttendee.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminAttendee.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.changeAdminAttendeeStatus.success.type]: (draft, action) => {
    const { id, result } = action.payload;
    if (draft.byId[id]) draft.byId[id] = result;
  },
  [actions.cancelAdminAttendeeTicket.success.type]: (draft, action) => {
    const { id, result } = action.payload;
    if (draft.byId[id]) draft.byId[id] = result;
  },
});

export default adminAttendeeReducer;
