import { Draft } from 'immer';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { CartStateType, ItemStringType, OrderItemStringType } from '../types';
import * as actions from '../actions';

const initialState: CartStateType = {
  event: null,
  tickets: [],
  addons: [],
  sendTo: '',
  isRegisteringByAdmin: false,
  convertToHardUser: false,
  paymentSystem: '',
  gateWayType: '',
  recaptcha: null,
};

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const handleResetState = () => initialState;

const getItems = (draft: Draft<CartStateType>, itemType: OrderItemStringType) => {
  if (itemType === 'ticket') return draft.tickets;
  if (itemType === 'addon') return draft.addons;
};

const getItem = (draft: Draft<CartStateType>, itemType: ItemStringType, itemIndex?: number) => {
  if (itemType === 'event') return draft.event;
  return getItems(draft, itemType)[itemIndex];
};

const cartReducer = createReducer<State, Actions>(initialState, {
  [actions.addOrderItem.type]: (draft, action) => {
    const items = getItems(draft, action.payload.itemType);
    const index = items.findIndex((item: any) => item[action.payload.itemType] === action.payload.id);
    items.splice(
      index < 0 ? items.length : index,
      0,
      ...Array(action.payload.count).fill({
        answers: [],
        order: action.payload.order,
        hasQuestions: action.payload.hasQuestions,
        [action.payload.itemType]: action.payload.id,
        ...(draft.isRegisteringByAdmin && {
          for_buyer: false,
        }),
      }),
    );
    items.sort((a, b) => a.order - b.order);
  },
  [actions.removeOrderItem.type]: (draft, action) => {
    const items = getItems(draft, action.payload.itemType);
    const index = items.findIndex((item: any) => item[action.payload.itemType] === action.payload.id);
    items.splice(index, 1);
  },
  [actions.setSendTo.type]: (draft, action) => {
    draft.sendTo = action.payload.sendTo;
  },
  [actions.setAnswer.type]: (draft, action) => {
    const item = getItem(draft, action.payload.itemType, action.payload.itemIndex);
    const index = item.answers.findIndex((answer) => answer.question === action.payload.answer.question);
    item.answers[index !== -1 ? index : item.answers.length] = action.payload.answer;
  },
  [actions.clearAnswer.type]: (draft, action) => {
    const item = getItem(draft, action.payload.itemType, action.payload.itemIndex);
    item.answers = [];
  },
  [actions.setForBuyer.type]: (draft, action) => {
    if (action.payload.itemType === 'ticket') {
      draft.tickets[action.payload.itemIndex].for_buyer = action.payload.forBuyer;
    }
  },
  [actions.setConvertToHardUser.type]: (draft, action) => {
    draft.convertToHardUser = action.payload.convertToHardUser;
  },
  [actions.setReCaptcha.type]: (draft, action) => {
    draft.recaptcha = action.payload.recaptcha;
  },
  [actions.clearRecaptcha.type]: (draft) => {
    draft.recaptcha = null;
  },
  [actions.initCart.type]: (draft, action) => {
    const state = { ...initialState };
    return Object.keys(state).reduce((state, key) => {
      const value = action.payload.state[key as keyof CartStateType];
      if (value) (state as any)[key] = value;
      return state;
    }, state);
  },
  [actions.resetCart.type]: handleResetState,
  [actions.resetCreatingOrder.type]: handleResetState,
});

export default cartReducer;
