import { get, patch } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_TRANSLATIONS_PATH } from './constants';
import { IdType } from './types';
import { EventImageTranslationDisplay } from './admin/models';

const EVENT_PATH = 'event';
const IMAGES_PATH = 'eventimages';
const IMAGE_PATH = 'eventimage';

export const getEventImagesTranslation = (eventId: IdType, options: any): Promise<EventImageTranslationDisplay> =>
  get(buildPath(ADMIN_TRANSLATIONS_PATH, EVENT_PATH, eventId, IMAGES_PATH), options).then(toJson);

export const updateEventImageTranslation = (
  eventId: IdType,
  eventImageId: IdType,
  options: any,
): Promise<EventImageTranslationDisplay> =>
  patch(buildPath(ADMIN_TRANSLATIONS_PATH, EVENT_PATH, eventId, IMAGE_PATH, eventImageId), options).then(toJson);
