import { Donation, DonationOption } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { DonationOptionsParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';
import { EntityType } from '../types';

export const getAdminDonation = actionsFactories.createGetOneActionsFactory<EntityType<Donation>>()(
  'GET_ADMIN_DONATION_REQUEST',
  'GET_ADMIN_DONATION_SUCCESS',
  'GET_ADMIN_DONATION_FAILURE',
  'GET_ADMIN_DONATION_RESET',
);

export const runtimeUpdateAdminDonation = actionsFactories.createUpdateOneActionsFactory<EntityType<Donation>>()(
  'RUNTIME_UPDATE_ADMIN_DONATION_REQUEST',
  'RUNTIME_UPDATE_ADMIN_DONATION_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_DONATION_FAILURE',
  'RUNTIME_UPDATE_ADMIN_DONATION_RESET',
);

export const getAdminDonationOptions = actionsFactories.createGetManyActionsFactory<
  EntityType<DonationOption>,
  DonationOptionsParamsType
>()(
  'GET_ADMIN_DONATION_OPTIONS_REQUEST',
  'GET_ADMIN_DONATION_OPTIONS_SUCCESS',
  'GET_ADMIN_DONATION_OPTIONS_FAILURE',
  'RESET_ADMIN_DONATION_OPTIONS',
  keyWindowNames.adminEvent,
);

export const createAdminDonationOption = actionsFactories.createCreateOneActionsFactory<
  EntityType<DonationOption>,
  DonationOptionsParamsType
>()(
  'CREATE_ADMIN_DONATION_OPTION_REQUEST',
  'CREATE_ADMIN_DONATION_OPTION_SUCCESS',
  'CREATE_ADMIN_DONATION_OPTION_FAILURE',
  'CREATE_ADMIN_DONATION_OPTION_RESET',
  keyWindowNames.adminEvent,
);

export const runtimeUpdateAdminDonationOption = actionsFactories.createUpdateOneActionsFactory<
  EntityType<DonationOption>,
  DonationOptionsParamsType
>()(
  'RUNTIME_UPDATE_ADMIN_DONATION_OPTION_REQUEST',
  'RUNTIME_UPDATE_ADMIN_DONATION_OPTION_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_DONATION_OPTION_FAILURE',
  'RESET_DONATION_OPTION',
);

export const deleteAdminDonationOption = actionsFactories.createDeleteOneActionsFactory<DonationOptionsParamsType>()(
  'DELETE_ADMIN_DONATION_OPTION_REQUEST',
  'DELETE_ADMIN_DONATION_OPTION_SUCCESS',
  'DELETE_ADMIN_DONATION_OPTION_FAILURE',
);
