import { QuestionStatistics } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventAgeStatisticsParamsType } from './types';

export const getAdminEventAgeStatistics = actionsFactories.createGetOneActionsFactory<
  EntityType<QuestionStatistics>,
  GetAdminEventAgeStatisticsParamsType
>()(
  'GET_ADMIN_EVENT_AGE_QUESTION_STATISTICS_REQUEST',
  'GET_ADMIN_EVENT_AGE_QUESTION_STATISTICS_SUCCESS',
  'GET_ADMIN_EVENT_AGE_QUESTION_STATISTICS_FAILURE',
  'GET_ADMIN_EVENT_AGE_QUESTION_STATISTICS_RESET',
);
