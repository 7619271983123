import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import deepmerge from 'deepmerge';

const hydrateReducer = (state: any, action: AnyAction) => {
  if (action.type === HYDRATE) {
    return deepmerge(state, action.payload, {
      arrayMerge: (target, source) => source,
    });
  }

  return state;
};

export default hydrateReducer;
