import { PlatformUser } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getAdminPlatformUsers = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<PlatformUser>>()(
  'GET_ADMIN_USERS_PLATFORM_REQUEST',
  'GET_ADMIN_USERS_PLATFORM_SUCCESS',
  'GET_ADMIN_USERS_PLATFORM_FAILURE',
  'GET_ADMIN_USERS_PLATFORM_RESET',
);
