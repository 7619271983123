import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { UserType } from '../types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<UserType>();
const initialState = stateFactory.getInitialState({
  isImageUpdating: false,
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const usersReducer = createReducer<State, Actions>(initialState, {
  [actions.getUserDetail.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getUserDetail.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getUserDetail.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getUserDetail.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.partialUpdateUser.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.partialUpdateUser.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.partialUpdateUser.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.partialUpdateUser.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.updateUserImage.request.type]: (draft, action) => {
    actionsHandlers.handleUpdateOneRequest(draft, action);
    draft.isImageUpdating = true;
  },
  [actions.updateUserImage.success.type]: (draft, action) => {
    actionsHandlers.handleUpdateOneSuccess(draft, action);
    draft.isImageUpdating = false;
  },
  [actions.updateUserImage.failure.type]: (draft, action) => {
    actionsHandlers.handleUpdateOneFailure(draft, action);
    draft.isImageUpdating = false;
  },
  [actions.updateUserImage.reset.type]: (draft, action) => {
    actionsHandlers.handleUpdateOneReset(draft, action);
    draft.isImageUpdating = false;
  },
});

export default usersReducer;
