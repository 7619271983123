import { Report } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Report>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationReportsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationReports.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrganizationReports.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrganizationReports.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrganizationReports.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.createAdminOrganizationReport.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminOrganizationReport.success.type]: (draft, action) => {
    actionsHandlers.handleCreateOneSuccess(draft, action);
    const {
      payload: { keyWindowName },
    } = action;

    if (keyWindowName && draft.keyWindows.includes(keyWindowName)) {
      const keyWindow = draft[keyWindowName];
      const lastItem = keyWindow.ids[keyWindow.ids.length - 1];
      keyWindow.ids.unshift(lastItem);
      keyWindow.ids.pop();
    }
  },
  [actions.createAdminOrganizationReport.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminOrganizationReport.reset.type]: actionsHandlers.handleCreateOneReset,
});

export default adminOrganizationReportsReducer;
