import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { Channel } from 'api/admin/models/Channel';
import * as keyWindowNames from './keyWindowNames';
import { AdminOrganizationChannelParamsType, CreateDeleteAdminOrganizationChannelParamsType } from './types';

export const getAdminOrganizationChannels = actionsFactories.createGetManyActionsFactory<
  EntityType<Channel>,
  AdminOrganizationChannelParamsType
>()(
  'GET_ADMIN_ORGANIZATION_CHANNELS_REQUEST',
  'GET_ADMIN_ORGANIZATION_CHANNELS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_CHANNELS_FAILURE',
  'RESET_ADMIN_ORGANIZATION_CHANNELS',
  keyWindowNames.adminOrganization,
);

export const createAdminOrganizationChannel = actionsFactories.createCreateOneActionsFactory<
  EntityType<Channel>,
  CreateDeleteAdminOrganizationChannelParamsType
>()(
  'CREATE_ADMIN_ORGANIZATION_CHANNEL_REQUEST',
  'CREATE_ADMIN_ORGANIZATION_CHANNEL_SUCCESS',
  'CREATE_ADMIN_ORGANIZATION_CHANNEL_FAILURE',
  'CREATE_ADMIN_ORGANIZATION_CHANNEL_RESET',
  keyWindowNames.adminOrganization,
);

export const deleteAdminOrganizationChannel =
  actionsFactories.createDeleteOneActionsFactory<AdminOrganizationChannelParamsType>()(
    'DELETE_ADMIN_ORGANIZATION_CHANNEL_REQUEST',
    'DELETE_ADMIN_ORGANIZATION_CHANNEL_SUCCESS',
    'DELETE_ADMIN_ORGANIZATION_CHANNEL_FAILURE',
  );
