import {
  AllAttendeesCustomQuestion,
  BaseDefaultQuestionDisplay,
  OrganizationManyTimeQuestionDisplay,
} from 'api/admin/models';
import { createAction } from 'store/utils';
import { EntityType } from 'store/entities/types';
import { actionsFactories as featureActionsFactories } from 'store/features/utils';
import { AdminHideQuestionParamsType, AdminHideQuestionType } from 'api/types';
import { actionsFactories } from '../utils';

import {
  CloneQuestionParamsType,
  CloneSpecificEntityQuestionParamsType,
  CreateAdminManyTimeQuestionsParamsType,
  CreateAdminQuestionOptionParamsType,
  CreateAdminQuestionParamsType,
  CreateAdminQuestionResultType,
  CreateSpecificEntityQuestionParamsType,
  DeleteAdminAllAttendeesQuestionParamsType,
  DeleteAdminEventQuestionParamsType,
  DeleteAdminManyTimeQuestionsParamsType,
  DeleteSpecificEntityQuestionParamsType,
  GetAdminAllAttendeesQuestionsParamsType,
  GetAdminManyTimeQuestionsParamsType,
  SubmitAdminQuestionType,
  UpdateManyTimeQuestionParamsType,
  UpdateQuestionParamsType,
  UpdateQuestionResultType,
  UpdateSpecificEntityParamsType,
} from './types';
import { QuestionTypes } from './constants';
import * as keyWindowNames from './keyWindowNames';

export const getAdminDefaultQuestions = actionsFactories.createGetManyActionsFactory<
  EntityType<BaseDefaultQuestionDisplay>
>()(
  'GET_ADMIN_DEFAULT_QUESTIONS_REQUEST',
  'GET_ADMIN_DEFAULT_QUESTIONS_SUCCESS',
  'GET_ADMIN_DEFAULT_QUESTIONS_FAILURE',
  'GET_ADMIN_DEFAULT_QUESTIONS_RESET',
);

export const createAdminQuestionOption = actionsFactories.createCreateOneActionsFactory<
  any,
  CreateAdminQuestionOptionParamsType
>()(
  'CREATE_ADMIN_EVENT_QUESTION_OPTION_REQUEST',
  'CREATE_ADMIN_EVENT_QUESTION_OPTION_SUCCESS',
  'CREATE_ADMIN_EVENT_QUESTION_OPTION_FAILURE',
  'CREATE_ADMIN_EVENT_QUESTION_OPTION_RESET',
);

export const updateAdminQuestionOption = actionsFactories.createUpdateOneActionsFactory<
  any,
  CreateAdminQuestionOptionParamsType
>()(
  'UPDATE_ADMIN_EVENT_QUESTION_OPTION_REQUEST',
  'UPDATE_ADMIN_EVENT_QUESTION_OPTION_SUCCESS',
  'UPDATE_ADMIN_EVENT_QUESTION_OPTION_FAILURE',
  'UPDATE_ADMIN_EVENT_QUESTION_OPTION_RESET',
);

export const deleteAdminQuestionOption =
  actionsFactories.createDeleteOneActionsFactory<CreateAdminQuestionOptionParamsType>()(
    'DELETE_ADMIN_EVENT_QUESTION_OPTION_REQUEST',
    'DELETE_ADMIN_EVENT_QUESTION_OPTION_SUCCESS',
    'DELETE_ADMIN_EVENT_QUESTION_OPTION_FAILURE',
  );

// ----- Admin Event Questions Actions -----
export const createAdminEventQuestion = actionsFactories.createCreateOneActionsFactory<
  EntityType<CreateAdminQuestionResultType>,
  CreateAdminQuestionParamsType
>()(
  'CREATE_ADMIN_EVENT_QUESTION_REQUEST',
  'CREATE_ADMIN_EVENT_QUESTION_SUCCESS',
  'CREATE_ADMIN_EVENT_QUESTION_FAILURE',
  'CREATE_ADMIN_EVENT_QUESTION_RESET',
);

export const updateAdminEventQuestion = actionsFactories.createUpdateOneActionsFactory<
  EntityType<UpdateQuestionResultType>,
  UpdateQuestionParamsType
>()(
  'UPDATE_ADMIN_EVENT_QUESTION_REQUEST',
  'UPDATE_ADMIN_EVENT_QUESTION_SUCCESS',
  'UPDATE_ADMIN_EVENT_QUESTION_FAILURE',
  'UPDATE_ADMIN_EVENT_QUESTION_RESET',
);

export const deleteAdminEventQuestion =
  actionsFactories.createDeleteOneActionsFactory<DeleteAdminEventQuestionParamsType>()(
    'DELETE_ADMIN_EVENT_QUESTION_REQUEST',
    'DELETE_ADMIN_EVENT_QUESTION_SUCCESS',
    'DELETE_ADMIN_EVENT_QUESTION_FAILURE',
  );

export const cloneAdminEventQuestion = actionsFactories.createCreateOneActionsFactory<any, CloneQuestionParamsType>()(
  'CLONE_ADMIN_EVENT_QUESTION_REQUEST',
  'CLONE_ADMIN_EVENT_QUESTION_SUCCESS',
  'CLONE_ADMIN_EVENT_QUESTION_FAILURE',
  'CLONE_ADMIN_EVENT_QUESTION_RESET',
);

// ----- All Attendees Questions Actions -----
export const getAdminAllAttendeesQuestions = actionsFactories.createGetManyActionsFactory<
  EntityType<AllAttendeesCustomQuestion>,
  GetAdminAllAttendeesQuestionsParamsType
>()(
  'GET_ADMIN_ALL_ATTENDEES_QUESTIONS_REQUEST',
  'GET_ADMIN_ALL_ATTENDEES_QUESTIONS_SUCCESS',
  'GET_ADMIN_ALL_ATTENDEES_QUESTIONS_FAILURE',
  'GET_ADMIN_ALL_ATTENDEES_QUESTIONS_RESET',
  keyWindowNames.adminEvent,
);

export const createAdminAllAttendeesQuestion = actionsFactories.createCreateOneActionsFactory<
  EntityType<CreateAdminQuestionResultType>,
  CreateAdminQuestionParamsType
>()(
  'CREATE_ADMIN_ALL_ATTENDEES_QUESTION_REQUEST',
  'CREATE_ADMIN_ALL_ATTENDEES_QUESTION_SUCCESS',
  'CREATE_ADMIN_ALL_ATTENDEES_QUESTION_FAILURE',
  'CREATE_ADMIN_ALL_ATTENDEES_QUESTION_RESET',
  keyWindowNames.adminEvent,
);

export const updateAdminAllAttendeesQuestion = actionsFactories.createUpdateOneActionsFactory<
  EntityType<UpdateQuestionResultType>,
  UpdateQuestionParamsType
>()(
  'UPDATE_ADMIN_ALL_ATTENDEES_QUESTION_REQUEST',
  'UPDATE_ADMIN_ALL_ATTENDEES_QUESTION_SUCCESS',
  'UPDATE_ADMIN_ALL_ATTENDEES_QUESTION_FAILURE',
  'UPDATE_ADMIN_ALL_ATTENDEES_QUESTION_RESET',
);

export const deleteAdminAllAttendeesQuestion =
  actionsFactories.createDeleteOneActionsFactory<DeleteAdminAllAttendeesQuestionParamsType>()(
    'DELETE_ADMIN_ALL_ATTENDEES_QUESTION_REQUEST',
    'DELETE_ADMIN_ALL_ATTENDEES_QUESTION_SUCCESS',
    'DELETE_ADMIN_ALL_ATTENDEES_QUESTION_FAILURE',
  );

export const cloneAdminAllAttendeesQuestion = actionsFactories.createCreateOneActionsFactory<
  any,
  CloneQuestionParamsType
>()(
  'CLONE_ADMIN_ALL_ATTENDEES_QUESTION_REQUEST',
  'CLONE_ADMIN_ALL_ATTENDEES_QUESTION_SUCCESS',
  'CLONE_ADMIN_ALL_ATTENDEES_QUESTION_FAILURE',
  'CLONE_ADMIN_ALL_ATTENDEES_QUESTION_RESET',
  keyWindowNames.adminEvent,
);

// ----- Specific Tickets Questions Actions -----
export const createAdminTicketQuestion = actionsFactories.createCreateOneActionsFactory<
  EntityType<CreateAdminQuestionResultType>,
  CreateSpecificEntityQuestionParamsType
>()(
  'CREATE_ADMIN_TICKET_QUESTION_REQUEST',
  'CREATE_ADMIN_TICKET_QUESTION_SUCCESS',
  'CREATE_ADMIN_TICKET_QUESTION_FAILURE',
  'CREATE_ADMIN_TICKET_QUESTION_RESET',
);

export const updateAdminTicketQuestion = actionsFactories.createUpdateOneActionsFactory<
  EntityType<UpdateQuestionResultType>,
  UpdateSpecificEntityParamsType
>()(
  'UPDATE_ADMIN_TICKET_QUESTION_REQUEST',
  'UPDATE_ADMIN_TICKET_QUESTION_SUCCESS',
  'UPDATE_ADMIN_TICKET_QUESTION_FAILURE',
  'UPDATE_ADMIN_TICKET_QUESTION_RESET',
);

export const deleteAdminTicketQuestion =
  actionsFactories.createDeleteOneActionsFactory<DeleteSpecificEntityQuestionParamsType>()(
    'DELETE_ADMIN_TICKET_QUESTION_REQUEST',
    'DELETE_ADMIN_TICKET_QUESTION_SUCCESS',
    'DELETE_ADMIN_TICKET_QUESTION_FAILURE',
  );

export const cloneAdminTicketQuestion = actionsFactories.createCreateOneActionsFactory<
  any,
  CloneSpecificEntityQuestionParamsType
>()(
  'CLONE_ADMIN_TICKET_QUESTION_REQUEST',
  'CLONE_ADMIN_TICKET_QUESTION_SUCCESS',
  'CLONE_ADMIN_TICKET_QUESTION_FAILURE',
  'CLONE_ADMIN_TICKET_QUESTION_RESET',
);

// ----- Specific Addons Questions Actions -----
export const createAdminAddonQuestion = actionsFactories.createCreateOneActionsFactory<
  EntityType<CreateAdminQuestionResultType>,
  CreateSpecificEntityQuestionParamsType
>()(
  'CREATE_ADMIN_ADDON_QUESTION_REQUEST',
  'CREATE_ADMIN_ADDON_QUESTION_SUCCESS',
  'CREATE_ADMIN_ADDON_QUESTION_FAILURE',
  'CREATE_ADMIN_ADDON_QUESTION_RESET',
);

export const updateAdminAddonQuestion = actionsFactories.createUpdateOneActionsFactory<
  EntityType<UpdateQuestionResultType>,
  UpdateSpecificEntityParamsType
>()(
  'UPDATE_ADMIN_ADDON_QUESTION_REQUEST',
  'UPDATE_ADMIN_ADDON_QUESTION_SUCCESS',
  'UPDATE_ADMIN_ADDON_QUESTION_FAILURE',
  'UPDATE_ADMIN_ADDON_QUESTION_RESET',
);

export const deleteAdminAddonQuestion =
  actionsFactories.createDeleteOneActionsFactory<DeleteSpecificEntityQuestionParamsType>()(
    'DELETE_ADMIN_ADDON_QUESTION_REQUEST',
    'DELETE_ADMIN_ADDON_QUESTION_SUCCESS',
    'DELETE_ADMIN_ADDON_QUESTION_FAILURE',
  );

export const cloneAdminAddonQuestion = actionsFactories.createCreateOneActionsFactory<
  any,
  CloneSpecificEntityQuestionParamsType
>()(
  'CLONE_ADMIN_ADDON_QUESTION_REQUEST',
  'CLONE_ADMIN_ADDON_QUESTION_SUCCESS',
  'CLONE_ADMIN_ADDON_QUESTION_FAILURE',
  'CLONE_ADMIN_ADDON_QUESTION_RESET',
);

// ----- Many Time Questions Actions -----

export const getAdminManyTimeQuestions = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<OrganizationManyTimeQuestionDisplay>,
  GetAdminManyTimeQuestionsParamsType
>()(
  'GET_ADMIN_MANY_TIME_QUESTIONS_REQUEST',
  'GET_ADMIN_MANY_TIME_QUESTIONS_SUCCESS',
  'GET_ADMIN_MANY_TIME_QUESTIONS_FAILURE',
  'GET_ADMIN_MANY_TIME_QUESTIONS_RESET',
  keyWindowNames.adminManyTimeQuestions,
);

export const createAdminManyTimeQuestion = actionsFactories.createCreateOneActionsFactory<
  EntityType<OrganizationManyTimeQuestionDisplay>,
  CreateAdminManyTimeQuestionsParamsType
>()(
  'CREATE_ADMIN_MANY_TIME_QUESTION_REQUEST',
  'CREATE_ADMIN_MANY_TIME_QUESTION_SUCCESS',
  'CREATE_ADMIN_MANY_TIME_QUESTION_FAILURE',
  'CREATE_ADMIN_MANY_TIME_QUESTION_RESET',
  keyWindowNames.adminManyTimeQuestions,
);

export const updateAdminManyTimeQuestion = actionsFactories.createUpdateOneActionsFactory<
  EntityType<OrganizationManyTimeQuestionDisplay>,
  UpdateManyTimeQuestionParamsType
>()(
  'UPDATE_ADMIN_MANY_TIME_QUESTION_REQUEST',
  'UPDATE_ADMIN_MANY_TIME_QUESTION_SUCCESS',
  'UPDATE_ADMIN_MANY_TIME_QUESTION_FAILURE',
  'UPDATE_ADMIN_MANY_TIME_QUESTION_RESET',
);

export const deleteAdminManyTimeQuestion =
  actionsFactories.createDeleteOneActionsFactory<DeleteAdminManyTimeQuestionsParamsType>()(
    'DELETE_ADMIN_MANY_TIME_QUESTION_REQUEST',
    'DELETE_ADMIN_MANY_TIME_QUESTION_SUCCESS',
    'DELETE_ADMIN_MANY_TIME_QUESTION_FAILURE',
  );

export const hideAdminQuestion = featureActionsFactories.createMultipleFeatureActionsFactory<
  AdminHideQuestionParamsType,
  AdminHideQuestionType
>()(
  'HIDE_ADMIN_QUESTION_REQUEST',
  'HIDE_ADMIN_QUESTION_SUCCESS',
  'HIDE_ADMIN_QUESTION_FAILURE',
  'HIDE_ADMIN_QUESTION_RESET',
);

// ----- General-----

export const changeAdminQuestionOrder = createAction(
  'CHANGE_ADMIN_QUESTION_ORDER',
  <P extends { eventId: number }>(oldIndex: number, newIndex: number, type: QuestionTypes, params: P) => ({
    payload: {
      ...params,
      oldIndex,
      newIndex,
      type,
    },
  }),
);

export const submitAdminQuestion = createAction(
  'SUBMIT_ADMIN_QUESTION_QUESTION',
  (params: SubmitAdminQuestionType) => ({
    payload: {
      ...params,
    },
  }),
);
