import { DefaultMetaTagDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<DefaultMetaTagDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const defaultMetaTagsReducer = createReducer<State, Actions>(initialState, {
  [actions.getDefaultMetaTags.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getDefaultMetaTags.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getDefaultMetaTags.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getDefaultMetaTags.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default defaultMetaTagsReducer;
