import { EventTotalDonationsStatictics } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventTotalDonationsStatisticsParamsType } from './types';

export const getAdminEventTotalDonationsStatistics = actionsFactories.createGetOneActionsFactory<
  EntityType<EventTotalDonationsStatictics>,
  GetAdminEventTotalDonationsStatisticsParamsType
>()(
  'GET_ADMIN_EVENT_TOTAL_DONATIONS_STATISTICS_REQUEST',
  'GET_ADMIN_EVENT_TOTAL_DONATIONS_STATISTICS_SUCCESS',
  'GET_ADMIN_EVENT_TOTAL_DONATIONS_STATISTICS_FAILURE',
  'GET_ADMIN_EVENT_TOTAL_DONATIONS_STATISTICS_RESET',
);
