import { del, get, post } from './client';
import { buildPath, toJson } from './utils';
import { API_GENERAL_URL, AUTH_PATH, USER_PATH } from './constants';
import { MagicLinkCreate, MagicLinkLogin, TokenRefresh } from './general/models';
import { IdType } from './types';
import { SocialLinks } from './general/models/SocailLinks';

const LOGIN_PATH = 'login';
const MAGIC_LINK_PATH = 'magic-link';
const JOIN_JLIVE = 'join-jlive';
const API_TOKEN_REFRESH = 'api-token-refresh';
const USER = 'user';
const USERS = 'users';
const MFA = 'mfa';
const CONFIRM = 'confirm';
const TOGGLE_ACTIVE = 'toggle-active';
const SOCIAL = 'social';
const GOOGLE = 'google';
const OAUTH = 'oauth';
const RESET_PASSWORD_PATH = 'password-reset';
const SET_PASSWORD_PATH = 'set-password';
const GET_VERIFICATION_CODE = 'phone_verification';
const CHECK_VERIFICATION_CODE = 'phone_verification_check';

export const loginMagicLink = (options: any): Promise<MagicLinkLogin> =>
  post(buildPath(AUTH_PATH, LOGIN_PATH, MAGIC_LINK_PATH), options).then(toJson);

export const socialLoginURLS = (options: any): Promise<SocialLinks> =>
  get(buildPath(AUTH_PATH, SOCIAL, GOOGLE, OAUTH), options).then(toJson);

export const sendMagicLink = (options: any): Promise<MagicLinkCreate> =>
  post(buildPath(AUTH_PATH, MAGIC_LINK_PATH), options).then(toJson);

export const sendResetPassword = (options: any): Promise<MagicLinkCreate> =>
  post(buildPath(AUTH_PATH, RESET_PASSWORD_PATH), options).then(toJson);

export const resetPassword = (uidb64: string, token: string, options: any): Promise<MagicLinkCreate> =>
  post(buildPath(AUTH_PATH, RESET_PASSWORD_PATH, uidb64, token), options).then(toJson);

export const setPassword = (options: any): Promise<MagicLinkCreate> =>
  post(buildPath(API_GENERAL_URL, USERS, SET_PASSWORD_PATH), options).then(toJson);

export const refreshToken = (options: any): Promise<TokenRefresh> =>
  post(buildPath(API_GENERAL_URL, API_TOKEN_REFRESH), options).then(toJson);

export const getUserMfa = (options: any): Promise<any> => get(buildPath(AUTH_PATH, USER, MFA), options).then(toJson);

export const createUserMfa = (options: any): Promise<any> =>
  post(buildPath(AUTH_PATH, USER, MFA), options).then(toJson);

export const deleteUserMfa = (id: IdType, options: any) => del(buildPath(AUTH_PATH, USER, MFA, id), options);

export const confirmUserMfa = (options: any): Promise<any> =>
  post(buildPath(AUTH_PATH, USER, MFA, CONFIRM), options).then(toJson);

export const toggleActiveUserMfa = (options: any): Promise<any> =>
  post(buildPath(AUTH_PATH, USER, MFA, TOGGLE_ACTIVE), options).then(toJson);

export const join = (options: any): Promise<any> => post(buildPath(AUTH_PATH, JOIN_JLIVE), options);

export const login = (options: any): Promise<MagicLinkLogin> =>
  post(buildPath(AUTH_PATH, LOGIN_PATH), options).then(toJson);

export const getVerificationCode = (options: any): Promise<MagicLinkLogin> =>
  post(buildPath(USER_PATH, GET_VERIFICATION_CODE), options).then(toJson);

export const checkVerificationCode = (options: any): Promise<MagicLinkLogin> =>
  post(buildPath(USER_PATH, CHECK_VERIFICATION_CODE), options).then(toJson);
