import { RootState } from 'store/rootReducer';
import { createSelector } from 'reselect';
import { selectorsFactory } from 'store/entities/utils';
import { selectorsFactory as featureSelectorsFactory } from 'store/features/utils';
import { OrdersAggregatedData } from 'api/admin/models';
import * as keyWindowNames from './keyWindowNames';

export const adminOrderSlice = (state: RootState) => state.entities.adminOrders.details;

export const adminOrdersSlice = (state: RootState) => state.entities.adminOrders.lists;

export const adminOrdersStatsSlice = (state: RootState) => state.entities.adminOrders.adminOrdersStats;

export const fullRefundAdminOrderSlice = (state: RootState) => state.entities.adminOrders.fullRefundAdminOrder;

export const partialRefundAdminOrderSlice = (state: RootState) => state.entities.adminOrders.partialRefundAdminOrder;

export const resendAdminOrderEmailSlice = (state: RootState) => state.entities.adminOrders.resendAdminOrderEmail;

export const setPayOfflineAdminOrderSlice = (state: RootState) => state.entities.adminOrders.payOffline;

export const resendAdminOrderEmailStateById =
  featureSelectorsFactory.createMultipleFeatureStateByIdSelectors(resendAdminOrderEmailSlice);

export const setPayOfflineAdminOrderStateById =
  featureSelectorsFactory.createMultipleFeatureStateByIdSelectors(setPayOfflineAdminOrderSlice);

export const adminOrderStateById = selectorsFactory.createEntityStateByIdSelector(adminOrderSlice);

export const adminOrdersState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrdersSlice,
  keyWindowNames.adminOrders,
);

export const adminOrdersPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminOrdersSlice,
  keyWindowNames.adminOrders,
);

export const adminOrdersStatsById = selectorsFactory.createEntityByIdSelector(adminOrdersStatsSlice);

export const adminOrdersStatsStateById = selectorsFactory.createEntityStateByIdSelector(adminOrdersStatsSlice);

export const fullRefundAdminOrderState =
  featureSelectorsFactory.createMultipleFeatureStateByIdSelectors(fullRefundAdminOrderSlice);

export const partialRefundAdminOrderState =
  featureSelectorsFactory.createMultipleFeatureStateByIdSelectors(partialRefundAdminOrderSlice);

// todo: check stats type conflict
export const adminOrdersCountsByStats = createSelector(adminOrdersStatsById, (stats: any) => {
  if (!stats?.counters) return [];
  return stats.counters.reduce(
    (counts: any, { status, count }: OrdersAggregatedData) => {
      counts.all += +count;
      counts[status] = count;
      return counts;
    },
    {
      all: 0,
    },
  );
});

export const getSelectedPaymentMethod = createSelector(
  adminOrderStateById,
  (state) =>
    state.entity?.payment_data.find((data: any) => data.status === 'succeeded') || state.entity?.payment_data[0],
);

export const isOfflinePaymentOrder = createSelector(adminOrderStateById, (state) => {
  const offlinePaymentStatuses = ['paid_offline', 'waiting_for_offline_payment'];
  return offlinePaymentStatuses.includes(state.entity?.status);
});
