// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TicketShort, UserShort } from '.';

/**
 * @export
 * @interface Attendee
 */
export interface Attendee {
  /**
   * @type {number}
   * @memberof Attendee
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof Attendee
   */
  order?: string | null;
  /**
   * @type {string}
   * @memberof Attendee
   */
  first_name?: string | null;
  /**
   * @type {string}
   * @memberof Attendee
   */
  last_name?: string | null;
  /**
   * @type {{ [key: string]: string; }}
   * @memberof Attendee
   */
  attendee_full_name: { [key: string]: string };
  /**
   * @type {{ [key: string]: string; }}
   * @memberof Attendee
   */
  readonly attendee_email?: { [key: string]: string };
  /**
   * @type {string}
   * @memberof Attendee
   */
  attendee_status: AttendeeAttendeeStatusEnum;
  /**
   * @type {TicketShort}
   * @memberof Attendee
   */
  ticket?: TicketShort;
  /**
   * @type {string}
   * @memberof Attendee
   */
  readonly created?: string;
  /**
   * @type {string}
   * @memberof Attendee
   */
  readonly updated?: string;
  /**
   * @type {string}
   * @memberof Attendee
   */
  checked_in_by_type?: AttendeeCheckedInByTypeEnum;
  /**
   * @type {UserShort}
   * @memberof Attendee
   */
  checked_in_by?: UserShort;
  /**
   * @type {string}
   * @memberof Attendee
   */
  checked_in_time?: string | null;
  /**
   * @type {string}
   * @memberof Attendee
   */
  event_title?: string | null;
  /**
   * @type {number}
   * @memberof Attendee
   */
  organization_id?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum AttendeeAttendeeStatusEnum {
  Incomplete = 'incomplete',
  Registered = 'registered',
  CheckedIn = 'checked-in',
  Canceled = 'canceled',
}

/**
 * @export
 * @enum {string}
 */
export enum AttendeeCheckedInByTypeEnum {
  Empty = '',
  Self = 'self',
  Admin = 'admin',
  Zoom = 'zoom',
}
