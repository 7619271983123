const palette = {
  primary: {
    main: '#4E6DF5',
    light: '#9BB3FA',
    extraLight: '#D1DFFF',
  },
  secondary: {
    main: '#F76E63',
    light: '#F7A9A3',
    extraLight: '#F7D7D7',
  },
  text: {
    primary: '#383C44',
    secondary: '#7B8BAD',
    disabled: '#A1ACC4',
  },
  warning: {
    main: '#FF7043',
  },
  info: {
    main: '#4E6DF5',
  },
  error: {
    main: '#F65675',
  },
  background: {
    default: '#F5F6FA',
    disabled: '#E0E0E0',
  },
  custom: {
    yellow: {
      main: '#FFE46B',
      light: '#FFEFA6',
      extraLight: '#FFF5C7',
    },
    orange: {
      main: '#FFA66B',
      light: '#FFCDAB',
      extraLight: '#FFE9DA',
    },
    purple: {
      main: '#8978F5',
      light: '#C0B8F5',
      extraLight: '#E2D9FA',
    },
    green: {
      main: '#5FC6B9',
      light: '#A9DED7',
      extraLight: '#CEEBE0',
    },
    darkBlue: {
      main: '#222652',
    },
    gray: {
      dark: '#6A7898',
      main: '#7B8BAD',
      regular: '#A1ACC4',
      medium: '#CAD1DE',
      light: '#E9EBF2',
      extraLight: '#F5F6FA',
    },
    border: {
      gray: '#CAD1DE',
    },
    white: '#FFFFFF',
  },
} as const;

export default palette;
