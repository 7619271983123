import { fork, takeEvery } from 'redux-saga/effects';
import deepmerge from 'deepmerge';
import { AdminTranslationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const arrayMerge = (target: any[], source: any[]): any[] => {
  if (!target[0]?.id) return source;

  const result = target.concat();
  source.forEach((s) => {
    const index = target.findIndex((t) => t.id === s.id);
    if (index === -1) {
      result.push(s);
    } else {
      result[index] = deepmerge(target[index], s, {
        arrayMerge,
      });
    }
  });

  return result;
};

const handleGetAdminEventTranslationRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminEventTranslation,
  request: AdminTranslationsApi.getEventTranslation,
});

const handleUpdateAdminEventTranslationRequest = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.updateAdminEventTranslation,
  request: AdminTranslationsApi.updateEventTranslation,
  arrayMerge,
});

export default function* adminEventTranslationsSagas() {
  yield takeEvery(actions.getAdminEventTranslation.request.type, handleGetAdminEventTranslationRequest);
  yield fork(handleUpdateAdminEventTranslationRequest);
}
