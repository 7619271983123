import { combineReducers } from 'redux';
import confirmOauthAuthorizationReducer from './confirmOauthAuthorizationReducer';
import requestOauthAuthorizationReducer from './requestOauthAuthorizationReducer';
import removeOauthAuthorizationReducer from './removeOauthAuthorizationReducer';

export default combineReducers({
  confirmOauthAuthorization: confirmOauthAuthorizationReducer,
  requestOauthAuthorization: requestOauthAuthorizationReducer,
  removeOauthAuthorization: removeOauthAuthorizationReducer,
});
