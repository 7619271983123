import { call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { isServer } from 'utils/next';
import { UserActions, AdminOrganizationsActions } from 'store/actions';
import * as constants from './constants';

function handleSync(action: any) {
  localStorage.setItem(constants.SYNC, JSON.stringify(action));
}

const createLocalStorageChannel = () =>
  eventChannel((emit) => {
    const listener = (event: StorageEvent) => {
      if (event.key === constants.SYNC) {
        emit(event.newValue);
      }
    };

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  });

function* handleAction(chan: any): any {
  while (true) {
    const JSONValue = yield take(chan);
    const action = yield call(JSON.parse, JSONValue);
    yield put(action);
  }
}

function* handleStartSync(): any {
  const localStorageChannel = yield call(createLocalStorageChannel);
  yield fork(handleAction, localStorageChannel);
}

export default function* syncSagas(): any {
  if (isServer()) return;
  yield fork(handleStartSync);
  yield takeEvery(UserActions.partialUpdateUser.success.type, handleSync);
  yield takeEvery(UserActions.getUserDetail.success.type, handleSync);
  yield takeEvery(AdminOrganizationsActions.getStripeDashboardLink.success.type, handleSync);
}
