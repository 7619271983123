import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import {
  createGetUnsavedIdsSelector,
  createHasIgnoredUnsavingIdSelector,
  createHasUnsavedIdSelector,
} from 'store/entities/adminEvents/unsavedUtils';

import * as keyWindowNames from './keyWindowNames';

export const adminTicketsSlice = (state: RootState) => state.entities.adminTickets;

export const adminTicketById = selectorsFactory.createEntityByIdSelector(adminTicketsSlice);

export const adminTicketUpdatingErrorById = selectorsFactory.createUpdatingErrorByIdSelector(adminTicketsSlice);

export const adminTicketIsUpdatingById = selectorsFactory.createIsUpdatingByIdSelector(adminTicketsSlice);

export const adminTicketsChangingState = selectorsFactory.createChangingStateSelector(adminTicketsSlice);

export const adminEventTicketsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminTicketsSlice,
  keyWindowNames.adminEventTickets,
);

export const adminEventTicketsIds = selectorsFactory.createDynamicKeyWindowEntitiesIdsSelector(
  adminTicketsSlice,
  keyWindowNames.adminEventTickets,
);

export const adminTicketCreatingState = selectorsFactory.createCreatingStateSelector(adminTicketsSlice);

export const hasAdminEventTickets = selectorsFactory.createDynamicKeyWindowHasEntitiesSelector(
  adminTicketsSlice,
  keyWindowNames.adminEventTickets,
);

export const getUnsavedIds = createGetUnsavedIdsSelector(adminTicketsSlice);

export const hasUnsavedId = createHasUnsavedIdSelector(adminTicketsSlice);

export const hasIgnoredUnsavingId = createHasIgnoredUnsavingIdSelector(adminTicketsSlice);
