import { takeLeading } from 'redux-saga/effects';
import { AdminEventOptionsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetEventTargetAgesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getEventTargetAges,
  request: AdminEventOptionsApi.getEventTargetAges,
  requestArgsBuilder: (action) => {
    const { params }: any = action.payload;

    return { params: { ...params, page: 1, page_size: 100 } };
  },
});

export default function* eventTargetAgesSagas() {
  yield takeLeading(actions.getEventTargetAges.request.type, handleGetEventTargetAgesRequest);
}
