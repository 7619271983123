// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface EventCategory
 */
export interface EventCategory {
  /**
   * @type {number}
   * @memberof EventCategory
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof EventCategory
   */
  title: string;
  /**
   * @type {number}
   * @memberof EventCategory
   */
  parent?: number | null;
  /**
   * @type {string}
   * @memberof EventCategory
   */
  type: EventCategoryTypeEnum;
  /**
   * @type {string}
   * @memberof EventCategory
   */
  label?: string;
  /**
   * @type {string}
   * @memberof EventCategory
   */
  slug?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum EventCategoryTypeEnum {
  Topic = 'topic',
  Type = 'type',
}
