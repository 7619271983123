import produce from 'immer';
import { ActionsHandlers, GetAction } from '../types';
import { Action, CreateAction } from './createAction';

export const createReducer =
  <S, CA extends CreateAction>(initialState: S, handlers: ActionsHandlers<S, CA>) =>
  //  TODO:
  // eslint-disable-next-line default-param-last
  (state = initialState, action: GetAction<CA>): S =>
    produce(state, (draft) => {
      const handler = handlers[(action as Action<CA['type']>).type];
      if (handler) return handler(draft, action) as any;
    }) as S;
