import React from 'react';

const renderUserWayScript = () =>
  typeof window !== 'undefined' &&
  !(window as any).UserWay &&
  window.location.hostname !== 'localhost' && (
    <script
      dangerouslySetInnerHTML={{
        __html: `
          (function(d){
          var s = d.createElement("script");
          /* uncomment the following line to override default position*/
          /* s.setAttribute("data-position", 5);*/
          /* uncomment the following line to override default size (values: small, large)*/
          /* s.setAttribute("data-size", "large");*/
          /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
          /* s.setAttribute("data-language", "language");*/
          /* uncomment the following line to override color set via widget (e.g., #053f67)*/
          /* s.setAttribute("data-color", "#4E6DF5");*/
          /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text)*/
          /* s.setAttribute("data-type", "1");*/
          /* s.setAttribute("data-statement_text:", "Our Accessibility Statement");*/
          /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility")";*/
          /* uncomment the following line to override support on mobile devices*/
          /* s.setAttribute("data-mobile", true);*/
          /* uncomment the following line to set custom trigger action for accessibility menu*/
          /* s.setAttribute("data-trigger", "triggerId")*/
          s.setAttribute("data-account", "ltWJAQ2PPl");
          s.setAttribute("src", "https://cdn.userway.org/widget.js");
          (d.body || d.head).appendChild(s);
          })(document)
        `,
      }}
    />
  );

export default renderUserWayScript;
