import { fork, takeEvery } from 'redux-saga/effects';
import { AdminEventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminEventRemindersRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventReminders,
  request: AdminEventsApi.getAdminEventReminders,
  requestArgsBuilder: (action) => action.payload.params.eventId,
});

const handleRuntimeUpdateAdminEventReminder = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminEventReminder,
  request: AdminEventsApi.partialUpdateAdminEventReminder,
  requestArgsBuilder: (action) => {
    const { id, entity, params } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

export default function* adminEventRemindersSagas() {
  yield takeEvery(actions.getAdminEventReminders.request.type, handleGetAdminEventRemindersRequest);
  yield fork(handleRuntimeUpdateAdminEventReminder);
}
