import { AdminOrganizationAutoCompleteType } from 'api/types';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<AdminOrganizationAutoCompleteType>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationsAutoCompleteReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationsAutoComplete.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrganizationsAutoComplete.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrganizationsAutoComplete.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrganizationsAutoComplete.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default adminOrganizationsAutoCompleteReducer;
