import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationImagesTranslationSlice = (state: RootState) =>
  state.entities.adminOrganizationImageTranslation;

export const adminOrganizationImagesTranslationState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationImagesTranslationSlice,
  keyWindowNames.adminOrganizationImageTranslation,
);

export const adminOrganizationImagesTranslation = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminOrganizationImagesTranslationSlice,
  keyWindowNames.adminOrganizationImageTranslation,
);

export const isAdminOrganizationImagesTranslationUpdatingById = selectorsFactory.createIsUpdatingByIdSelector(
  adminOrganizationImagesTranslationSlice,
);

export const isAdminOrganizationMediaTranslationUpdatingById = (state: RootState) =>
  Object.values(adminOrganizationImagesTranslationSlice(state).isUpdating).some(Boolean);

export const adminOrganizationTranslatedImage = createSelector(
  adminOrganizationImagesTranslation,
  (state, params: { language: string; defaultLanguage: string }) => params,
  (images, params) =>
    images.map((image: any) =>
      image[`image_${params.language}`] === null
        ? image[`image_${params.defaultLanguage}`]
        : image[`image_${params.language}`],
    )[0],
);

export const adminOrganizationGalleryImagesTranslation = createSelector(
  adminOrganizationImagesTranslation,
  (state, params: { language: string; defaultLanguage: string }) => params,
  (images, params) =>
    images.map((image: any) => {
      const data =
        image[`image_${params.language}`] === null
          ? image[`image_${params.defaultLanguage}`]
          : image[`image_${params.language}`];

      return {
        id: image.id,
        order: image.order,
        image: {
          ...data,
        },
      };
    }),
);
