import { takeEvery } from 'redux-saga/effects';
import { AdminOrganizationMetaTagsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminOrganizationsMetaTagsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationMetaTags,
  request: AdminOrganizationMetaTagsApi.getOrganizationMetaTags,
  requestArgsBuilder: ({ payload: { params } }) => {
    const organizationId = params;
    return [organizationId, { params: { limit: 100 } }];
  },
});

const handleCreateAdminOrganizationsMetaTagsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.createAdminOrganizationMetaTags,
  request: AdminOrganizationMetaTagsApi.createOrganizationMetaTags,
  requestArgsBuilder: ({ payload: { entities, params } }) => {
    const { organizationId } = params;
    return [organizationId, { body: entities }];
  },
});

const handleUpdateAdminOrganizationsMetaTagsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.updateAdminOrganizationMetaTags,
  request: AdminOrganizationMetaTagsApi.updateOrganizationMetaTags,
  requestArgsBuilder: ({ payload: { entities, params } }) => {
    const { organizationId } = params;
    return [organizationId, { body: entities }];
  },
});

const handleDeleteAdminOrganizationsMetaTagsRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminOrganizationMetaTags,
  request: AdminOrganizationMetaTagsApi.deleteOrganizationMetaTag,
  requestArgsBuilder: ({ payload: { id, params } }) => {
    const { organizationId } = params;
    return [organizationId, id];
  },
});

export default function* adminOrganizationsMetaTagsSagas() {
  yield takeEvery(actions.getAdminOrganizationMetaTags.request.type, handleGetAdminOrganizationsMetaTagsRequest);
  yield takeEvery(actions.createAdminOrganizationMetaTags.request.type, handleCreateAdminOrganizationsMetaTagsRequest);
  yield takeEvery(actions.updateAdminOrganizationMetaTags.request.type, handleUpdateAdminOrganizationsMetaTagsRequest);
  yield takeEvery(actions.deleteAdminOrganizationMetaTags.request.type, handleDeleteAdminOrganizationsMetaTagsRequest);
}
