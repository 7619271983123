import { AdminTicketDetails } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import {
  createAddIdActionHandler,
  createRemoveIdActionHandler,
  createUnsavingState,
} from 'store/entities/adminEvents/unsavedUtils';

import * as questionsActions from 'store/entities/adminQuestions/actions';
import { AdminQuestionsActions } from 'store/actions';
import * as actions from './actions';
import {
  handleCreateQuestion,
  handleCreateQuestionOption,
  handleDeleteQuestionOption,
  handleDeleteSpecificEntityQuestion,
  handleUpdateQuestion,
  handleUpdateQuestionOption,
  handleFindEntityId,
} from '../adminAddons/reducer';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AdminTicketDetails>>();
const initialState = stateFactory.getInitialState({
  ...createUnsavingState(),
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminTicketsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminTickets.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminTickets.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminTickets.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminTickets.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminTicket.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminTicket.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminTicket.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminTicket.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminTicket.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminTicket.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminTicket.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminTicket.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.runtimeUpdateAdminTicket.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.runtimeUpdateAdminTicket.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.runtimeUpdateAdminTicket.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.runtimeUpdateAdminTicket.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminTicket.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminTicket.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminTicket.failure.type]: actionsHandlers.handleDeleteOneFailure,
  [actions.addUnsavedAdminTicket.type]: createAddIdActionHandler('unsavedIds'),
  [actions.removeUnsavedAdminTicket.type]: createRemoveIdActionHandler('unsavedIds'),
  [actions.addIgnoredUnsavingAdminTicket.type]: createAddIdActionHandler('ignoredUnsavingIds'),
  [actions.removeIgnoredUnsavingAdminTicket.type]: createRemoveIdActionHandler('ignoredUnsavingIds'),
  [questionsActions.cloneAdminTicketQuestion.success.type as any]: (draft: any, action: any) => {
    const ticket = draft.byId[action.payload.params.entityId];
    handleCreateQuestion(ticket, action);
  },
  [questionsActions.createAdminTicketQuestion.success.type as any]: (draft: any, action: any) => {
    const ticket = draft.byId[action.payload.params.entityId];
    if (ticket)
      ticket.questions.push({
        ...action.payload.entity,
        ticket: action.payload.params.entityId,
      });
  },
  [questionsActions.updateAdminTicketQuestion.success.type as any]: (draft: any, action: any) => {
    const ticket: any = draft.byId[action.payload.params.entityId];
    handleUpdateQuestion(ticket, action);
  },
  [questionsActions.deleteAdminTicketQuestion.success.type as any]: (draft: any, action: any) => {
    const ticket = draft.byId[action.payload.params.entityId];
    handleDeleteSpecificEntityQuestion(ticket, action);
  },
  [questionsActions.updateAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const ticketId = handleFindEntityId(draft, action);
    const ticket: any = draft.byId[ticketId];
    handleUpdateQuestionOption(ticket, action);
  },
  [questionsActions.createAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const ticketId = handleFindEntityId(draft, action);
    const ticket: any = draft.byId[ticketId];
    handleCreateQuestionOption(ticket, action);
  },
  [questionsActions.deleteAdminQuestionOption.success.type as any]: (draft: any, action: any) => {
    const ticketId = handleFindEntityId(draft, action);
    const ticket: any = draft.byId[ticketId];
    handleDeleteQuestionOption(ticket, action);
  },
  [AdminQuestionsActions.hideAdminQuestion.success.type as any]: (draft: any, action: any) => {
    Object.keys(draft.byId).forEach((id) => {
      const ticket = draft.byId[id];
      ticket.questions.forEach((question: { id: any; is_hidden: any }) => {
        if (question.id === action.payload.id) {
          question.is_hidden = action.payload.result.is_hidden;
        }
      });
    });
  },
});

export default adminTicketsReducer;
