import { put } from 'redux-saga/effects';
import { AnyFunction, AsyncReturnType } from 'types';
import { CreateAsyncActions, apiCall, prepareArgs, prepareNotifyError } from 'store/utils';

export const createSingleFeatureRequestHandler = <
  A extends CreateAsyncActions<string, AnyFunction, string, AnyFunction, string, AnyFunction>,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  T extends (result: AsyncReturnType<R>, action?: ReturnType<A['request']>) => any,
  N extends ((action: ReturnType<A['failure']>) => boolean) | boolean,
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  transformResponse?: T;
  notifyError?: N;
}) =>
  function* handleSingleFeatureRequest(action: ReturnType<A['request']>): any {
    const {
      actions,
      request,
      requestArgsBuilder,
      transformResponse = (response) => response,
      notifyError: notifyErrorBuilder = true,
    } = args;

    try {
      const requestArgs = yield prepareArgs(requestArgsBuilder, action);
      const response = yield apiCall(request as AnyFunction, ...requestArgs);
      yield put(actions.success({ result: transformResponse(response, action), params: action.payload.params }));
    } catch (error) {
      if (error instanceof Error) {
        const notifyError = yield prepareNotifyError(notifyErrorBuilder, error, action);
        yield put(actions.failure({ error, notifyError, params: action.payload.params }));
      }
    }
  };

export const createMultipleFeatureRequestHandler = <
  A extends CreateAsyncActions<string, AnyFunction, string, AnyFunction, string, AnyFunction>,
  R extends AnyFunction,
  B extends (action: ReturnType<A['request']>) => any,
  T extends (result: AsyncReturnType<R>, action?: ReturnType<A['request']>) => any,
  N extends (<E extends Error>(error: E, action?: ReturnType<A['request']>) => boolean) | boolean,
>(args: {
  actions: A;
  request: R;
  requestArgsBuilder?: B;
  transformResponse?: T;
  notifyError?: N;
}) =>
  function* handleMultipleFeatureRequest(action: ReturnType<A['request']>): any {
    const {
      actions,
      request,
      requestArgsBuilder,
      transformResponse = (response) => response,
      notifyError: notifyErrorBuilder = true,
    } = args;

    try {
      const requestArgs = yield prepareArgs(requestArgsBuilder, action);
      const response = yield apiCall(request as AnyFunction, ...requestArgs);
      yield put(
        actions.success({
          result: transformResponse(response, action),
          id: action.payload.id,
          params: action.payload.params,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        const notifyError = yield prepareNotifyError(notifyErrorBuilder, error, action);
        yield put(actions.failure({ error, notifyError, id: action.payload.id, params: action.payload.params }));
      }
    }
  };
