import { call, fork, put, race, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminEventsApi, UploadApi } from 'api';
import { apiCall } from 'store/utils';
import { GetAction } from 'store/types';
import { sagasHandlersFactory } from 'store/entities/utils';
import { AdminEventSponsorsSelectors } from 'store/selectors';
import { createChangeOrderHandler } from 'store/entities/adminEvents/sagasUtils';
import { EventSponsorsDisplay } from 'api/admin/models';
import { HYDRATE } from 'next-redux-wrapper';
import { MEDIA_ORDER_OFFSET, MEDIA_ORDER_SPREAD } from '../adminEvents/constants';
import * as actions from './actions';
import { isServer } from '../../../utils/next';
import { loadUnsavedIds } from '../adminEventSettings/sagas';

export function* getNextMediaOrder(params: { eventId: number }): any {
  const medias = yield select(AdminEventSponsorsSelectors.adminEventSponsor, params);
  return (medias.length === 0 ? MEDIA_ORDER_OFFSET : medias[medias.length - 1].order) + MEDIA_ORDER_SPREAD;
}

const handleGetAdminEventSponsorsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventSponsors,
  request: AdminEventsApi.getAdminSponsors,
  requestArgsBuilder: (action) => action.payload.params.eventId,
});

const handleCreateAdminEventSponsorRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminEventSponsor,
  request: AdminEventsApi.createSponsor,
  requestArgsBuilder: function* builder(action): any {
    const { params } = action.payload;
    const { file, imageType, eventId } = params;

    const order = yield call(getNextMediaOrder, params);
    const response = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type: imageType,
      },
    });

    return [eventId, { body: { image: response.id, order } }];
  },
});

const handleUpdateAdminEventSponsorRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminEventSponsor,
  request: AdminEventsApi.updateSponsor,
  requestArgsBuilder: function* builder(action): any {
    const { id, entity, params } = action.payload;
    const { eventId, file, imageType } = params;

    if (!(file && imageType)) {
      return [eventId, id, { body: entity }];
    }

    const response = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type: imageType,
      },
    });

    return [eventId, id, { body: { ...entity, image: response.id } }];
  },
});

const handleDeleteAdminEventSponsorRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEventSponsor,
  request: AdminEventsApi.deleteSponsor,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

function* handleUploadAdminEventSponsors(action: GetAction<typeof actions.createAdminEventSponsors>) {
  const { files, ...rest } = action.payload.params;
  for (let i = 0; i < files.length; i += 1) {
    yield put(actions.createAdminEventSponsor.request({ params: { file: files[i], ...rest } }));
    yield race([
      take(actions.createAdminEventSponsor.success.type),
      take(actions.createAdminEventSponsor.failure.type),
    ]);
  }
}

const handleChangeAdminEventSponsorOrder = createChangeOrderHandler<
  GetAction<typeof actions.changeAdminEventSponsorOrder>,
  EventSponsorsDisplay
>({
  spread: MEDIA_ORDER_SPREAD,
  offset: MEDIA_ORDER_OFFSET,
  *getEntities(params: { eventId: number }) {
    return yield select(AdminEventSponsorsSelectors.adminEventSponsor, params);
  },
  updateEntityOrder: function* update(sponsor, order, params) {
    yield put(
      actions.updateAdminEventSponsor.request({
        id: sponsor.id,
        params,
        entity: {
          order,
          image: (sponsor as EventSponsorsDisplay).image.id,
        },
      }),
    );
  },
});

export default function* adminEventSponsorsSagas() {
  yield takeEvery(actions.getAdminEventSponsors.request.type, handleGetAdminEventSponsorsRequest);
  yield takeEvery(actions.createAdminEventSponsor.request.type, handleCreateAdminEventSponsorRequest);
  yield takeEvery(actions.updateAdminEventSponsor.request.type, handleUpdateAdminEventSponsorRequest);
  yield takeEvery(actions.deleteAdminEventSponsor.request.type, handleDeleteAdminEventSponsorRequest);
  yield takeLeading(actions.createAdminEventSponsors.type, handleUploadAdminEventSponsors);
  yield takeEvery(actions.changeAdminEventSponsorOrder.type, handleChangeAdminEventSponsorOrder);

  if (!isServer()) {
    // Added it due to consistency break after returning to edit event flow after editing of CoHost org list
    yield fork(function* wait() {
      yield take(HYDRATE);
      yield call(loadUnsavedIds);
    });
  }
}
