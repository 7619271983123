import { Group } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Group>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminUsersReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminUsersGroups.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminUsersGroups.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminUsersGroups.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminUsersGroups.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default adminUsersReducer;
