import { EventMetaTagDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';
import { adminEventMetaTags } from './keyWindowNames';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventMetaTagDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventMetaTagsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventMetaTags.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventMetaTags.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventMetaTags.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventMetaTags.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminEventMetaTag.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminEventMetaTag.success.type]: (draft, action) => {
    actionsHandlers.handleCreateOneSuccess(draft, action);
    draft[adminEventMetaTags(action.payload.params)].ids.push(action.payload.entity.id);
  },
  [actions.createAdminEventMetaTag.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminEventMetaTag.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.runtimeUpdateAdminEventMetaTag.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.runtimeUpdateAdminEventMetaTag.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.runtimeUpdateAdminEventMetaTag.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.runtimeUpdateAdminEventMetaTag.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminEventMetaTag.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEventMetaTag.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEventMetaTag.failure.type]: actionsHandlers.handleDeleteOneFailure,
  [actions.getMetaTagsTypes.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getMetaTagsTypes.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getMetaTagsTypes.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getMetaTagsTypes.reset.type]: actionsHandlers.handleGetManyReset,
});

export default adminEventMetaTagsReducer;
