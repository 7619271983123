import { Access, SessionUser } from '../types';

export const createSessionUser = <U extends SessionUser>(data: U): SessionUser => ({
  id: data.id,
  is_terms_and_conditions_accepted: data.is_terms_and_conditions_accepted,
  is_admin: data.is_admin,
  is_super_admin: data.is_super_admin,
  is_market_admin: data.is_market_admin,
  google_sync: data.google_sync,
});

export const createSessionUserFromAccess = (access: Access): SessionUser => createSessionUser({ id: access.user_id });
