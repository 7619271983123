import React, { useEffect, useState } from 'react';
import FirebaseContext from './FirebaseContext';

export interface FirebaseProviderProps {
  disableInitialization?: boolean;
  children: React.ReactNode;
}

const firebaseConfig: any = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

// default app name based on firebase docs
const defaultAppName = '[DEFAULT]';

const FirebaseProvider: React.FC<React.PropsWithChildren<FirebaseProviderProps>> = (props) => {
  const { disableInitialization = false, ...rest } = props;
  const [firebase, setFirebase] = useState(null);

  useEffect(() => {
    if (disableInitialization) return;

    // check if firebase already exist
    if (firebase) return;

    // check if firebase config has values
    if (!Object.values(firebaseConfig).every(Boolean)) return;

    // firebase lazy loading
    Promise.all([import('firebase/app'), import('firebase/analytics')])
      .then(([{ default: firebase }]) => {
        const app: any = firebase.apps.find((app) => app.name === defaultAppName);
        if (!app) {
          firebase.initializeApp(firebaseConfig);
          firebase.analytics();

          // check if existing app has different firebase config values
        } else if (!Object.keys(firebaseConfig).every((key) => app.options[key] === firebaseConfig[key])) {
          throw new Error('Firebase app already exist with different firebase config values');
        }

        // set initialized firebase module to state
        setFirebase(firebase);
      })
      .catch((error) => console.error(error));
  }, [firebase, disableInitialization]);

  return <FirebaseContext.Provider {...rest} value={firebase} />;
};

export default FirebaseProvider;
