import { RootActions } from 'store/types';
import { createReducer } from 'store/utils';
import * as actions from '../actions';

const initialState = {
  error: null as any,
};

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const errorEventReducer = createReducer<State, Actions>(initialState, {
  [actions.getErrorEvent.type]: (draft, action) => {
    draft.error = action.payload.error;
  },
  [actions.removeErrorEvent.type]: (draft) => {
    draft.error = null;
  },
});

export default errorEventReducer;
