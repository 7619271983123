import { combineReducers } from 'redux';
import adminEventsReducer from './adminEventsReducer';
import adminEventReducer from './adminEventReducer';
import publishAdminEventReducer from './publishAdminEventReducer';
import saveAsDraftAdminEventReducer from './saveAsDraftAdminEventReducer';
import cloneAdminEventReducer from './cloneAdminEventReducer';
import cancelAdminEventReducer from './cancelAdminEventReducer';
import markAsTestAdminEventReducer from './markAsTestAdminEventReducer';
import archiveAdminEventReducer from './archiveAdminEventReducer';
import adminCCEventsReducer from './adminCCEventsReducer';
import updateAdminCCEventStatusReducer from './updateAdminCCEventStatusReducer';
import adminOrgEventsAutocompleteReducer from './adminOrgEventsAucomplete';
import adminForRoleEventsReducer from './adminForRoleEventsReducer';
import adminForSelectRoleEventsReducer from './adminForSelectRoleReducer';

export default combineReducers({
  entitiesState: adminEventsReducer,
  entityState: adminEventReducer,
  publishAdminEvent: publishAdminEventReducer,
  saveAsDraftAdminEvent: saveAsDraftAdminEventReducer,
  cancelAdminEvent: cancelAdminEventReducer,
  archiveAdminEvent: archiveAdminEventReducer,
  markAsTestAdminEvent: markAsTestAdminEventReducer,
  cloneAdminEvent: cloneAdminEventReducer,
  ccEventsState: adminCCEventsReducer,
  updateAdminCCEventStatus: updateAdminCCEventStatusReducer,
  orgEventsAutocomplete: adminOrgEventsAutocompleteReducer,
  adminForRoleEvents: adminForRoleEventsReducer,
  adminForSelectRoleEvents: adminForSelectRoleEventsReducer,
});
