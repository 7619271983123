import { get } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_EVENT_CATEGORIES_PATH, ADMIN_EVENT_TARGET_AGES_PATH, ADMIN_TAGS } from './constants';
import { EventCategory, EventTargetAge, Tag } from './admin/models';
import { GeneralListType } from './types';

export const getEventCategories = (options: any): Promise<EventCategory> =>
  get(buildPath(ADMIN_EVENT_CATEGORIES_PATH), options).then(toJson);

export const getEventTargetAges = (options: any): Promise<EventTargetAge> =>
  get(buildPath(ADMIN_EVENT_TARGET_AGES_PATH), options).then(toJson);

export const getTags = (options: any): Promise<GeneralListType<Tag>> =>
  get(buildPath(ADMIN_TAGS), options).then(toJson);
