import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationsAutoCompleteSlice = (state: RootState) => state.entities.adminOrganizationsAutoComplete;

export const adminOrganizationsAutoComplete = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(
  adminOrganizationsAutoCompleteSlice,
);

export const adminOrganizationsAutoCompleteState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationsAutoCompleteSlice,
  keyWindowNames.adminEventMentions,
);

export const adminOrganizationsAutoCompletePaginationState = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminOrganizationsAutoCompleteSlice,
  keyWindowNames.adminEventMentions,
);
