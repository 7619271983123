import { takeEvery } from 'redux-saga/effects';
import { OrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetOrgTypesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getOrgTypes,
  request: OrganizationsApi.getOrganizationTypes,
  requestArgsBuilder: () => ({
    params: {
      page: 1,
      page_size: 100,
    },
  }),
});

export default function* orgTypesSagas() {
  yield takeEvery(actions.getOrgTypes.request.type, handleGetOrgTypesRequest);
}
