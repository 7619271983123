import { EventTargetAge } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getEventTargetAges = actionsFactories.createGetManyActionsFactory<EntityType<EventTargetAge>>()(
  'GET_EVENT_TARGET_AGES_REQUEST',
  'GET_EVENT_TARGET_AGES_SUCCESS',
  'GET_EVENT_TARGET_AGES_FAILURE',
  'RESET_EVENT_TARGET_AGES',
);
