export type SerializedResponse = Pick<Response, 'ok' | 'redirected' | 'status' | 'statusText' | 'type' | 'url'> & {
  responseText: string;
};

export type SerializedError = {
  name: string;
  message: string;
  stack?: string;
  code?: string;
  response?: SerializedResponse;
};

const defaultErrorName = 'SerializedError';

const errorProperties: Array<keyof Omit<SerializedError, 'response'>> = ['name', 'message', 'stack', 'code'];
const responseProperties: Array<keyof SerializedResponse> = [
  'ok',
  'redirected',
  'status',
  'statusText',
  'responseText',
  'type',
  'url',
];

const serializableProperties = <T, K extends string[]>(obj: any, properties: K): T => {
  if (typeof obj === 'object' && obj !== null) {
    return properties.reduce((serialized, property) => {
      const value = obj[property];
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        serialized[property] = value;
      }

      return serialized;
    }, {} as any);
  }

  return null;
};

export const serializeError = (error: any): SerializedError => {
  if (error) {
    return {
      ...serializableProperties(error, errorProperties),
      ...(error.response && {
        response: serializableProperties(error.response, responseProperties),
      }),
    };
  }

  return {
    name: defaultErrorName,
    message: String(error),
  };
};
