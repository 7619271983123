import { combineReducers } from 'redux';
import languagesSpokenReducer from './languagesSpokenReducer';
import occupationsReducer from './occupationsReducer';
import jewishDescendantsReducer from './jewishDescendantsReducer';
import userInterestsReducer from './userInterestsReducer';

export default combineReducers({
  languagesSpoken: languagesSpokenReducer,
  jewishDescendants: jewishDescendantsReducer,
  occupations: occupationsReducer,
  userInterests: userInterestsReducer,
});
