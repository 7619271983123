import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/features/utils';
import * as actions from '../actions';
import { createReducer } from '../../../utils';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const spreedlyReducer = createReducer<State, Actions>(initialState, {
  [actions.connectSpreedlyMoneris.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.connectSpreedlyMoneris.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.connectSpreedlyMoneris.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.connectSpreedlyMoneris.reset.type]: actionsHandlers.handleSingleFeatureReset,
  [actions.connectSpreedlyStripe.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.connectSpreedlyStripe.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.connectSpreedlyStripe.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.connectSpreedlyStripe.reset.type]: actionsHandlers.handleSingleFeatureReset,
  [actions.connectSpreedlyAuthorizeNet.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.connectSpreedlyAuthorizeNet.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.connectSpreedlyAuthorizeNet.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.connectSpreedlyAuthorizeNet.reset.type]: actionsHandlers.handleSingleFeatureReset,
  [actions.disconnectSpreedly.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.disconnectSpreedly.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.disconnectSpreedly.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.disconnectSpreedly.reset.type]: actionsHandlers.handleSingleFeatureReset,
});

export default spreedlyReducer;
