import { actionsFactories } from 'store/entities/utils';
import { createAction } from 'store/utils';
import { EntityType } from 'store/entities/types';
import { EventSponsorsCreateUpdate, EventSponsorsDisplay } from 'api/admin/models';
import * as keyWindowNames from './keyWindowNames';
import {
  AdminEventSponsorParamsType,
  CreateAdminEventSponsorParamsType,
  UpdateAdminEventSponsorParamsType,
} from './types';

export const getAdminEventSponsors = actionsFactories.createGetManyActionsFactory<
  EntityType<EventSponsorsDisplay>,
  AdminEventSponsorParamsType
>()(
  'GET_ADMIN_EVENT_SPONSORS_REQUEST',
  'GET_ADMIN_EVENT_SPONSORS_SUCCESS',
  'GET_ADMIN_EVENT_SPONSORS_FAILURE',
  'GET_ADMIN_EVENT_SPONSORS_RESET',
  keyWindowNames.adminEvent,
);

export const createAdminEventSponsor = actionsFactories.createCreateOneActionsFactory<
  EntityType<Omit<EventSponsorsCreateUpdate, 'order'>>,
  CreateAdminEventSponsorParamsType
>()(
  'CREATE_ADMIN_EVENT_SPONSOR_REQUEST',
  'CREATE_ADMIN_EVENT_SPONSOR_SUCCESS',
  'CREATE_ADMIN_EVENT_SPONSOR_FAILURE',
  'CREATE_ADMIN_EVENT_SPONSOR_RESET',
  keyWindowNames.adminEvent,
);

export const updateAdminEventSponsor = actionsFactories.createUpdateOneActionsFactory<
  EntityType<Partial<EventSponsorsCreateUpdate>>,
  UpdateAdminEventSponsorParamsType
>()(
  'UPDATE_ADMIN_EVENT_SPONSOR_REQUEST',
  'UPDATE_ADMIN_EVENT_SPONSOR_SUCCESS',
  'UPDATE_ADMIN_EVENT_SPONSOR_FAILURE',
  'UPDATE_ADMIN_EVENT_SPONSOR_RESET',
);

export const deleteAdminEventSponsor = actionsFactories.createDeleteOneActionsFactory<AdminEventSponsorParamsType>()(
  'DELETE_ADMIN_EVENT_SPONSOR_REQUEST',
  'DELETE_ADMIN_EVENT_SPONSOR_SUCCESS',
  'DELETE_ADMIN_EVENT_SPONSOR_FAILURE',
);

export const createAdminEventSponsors = createAction(
  'CREATE_ADMIN_EVENT_SPONSORS',
  (params: Omit<CreateAdminEventSponsorParamsType, 'file'> & { files: File[] }) => ({
    payload: {
      params,
    },
  }),
);

export const changeAdminEventSponsorOrder = createAction(
  'CHANGE_ADMIN_EVENT_SPONSORS_ORDER',
  (oldIndex: number, newIndex: number, eventId: number) => ({
    payload: {
      oldIndex,
      newIndex,
      eventId,
    },
  }),
);
