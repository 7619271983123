import { RootState } from 'store/rootReducer';
import * as UserSelectors from 'store/entities/users/selectors';

export const authSlice = (state: RootState) => state.features.auth;

export const joinState = (state: RootState) => authSlice(state).join;

export const loginState = (state: RootState) => authSlice(state).loginPassword;

export const loginMagicLinkState = (state: RootState) => authSlice(state).loginMagicLink;

export const socialLinksState = (state: RootState) => authSlice(state).socialLinks;

export const refreshTokenState = (state: RootState) => authSlice(state).refreshToken;

export const sendMagicLinkState = (state: RootState) => authSlice(state).sendMagicLink;

export const sendResetPasswordState = (state: RootState) => authSlice(state).sendResetPassword;

export const resetPasswordState = (state: RootState) => authSlice(state).resetPassword;

export const setPasswordState = (state: RootState) => authSlice(state).setPassword;

export const sessionUser = (state: RootState) => authSlice(state).sessionUser;

export const currentUser = (state: RootState) => UserSelectors.userById(state, sessionUser(state).id);

export const isAuthenticated = (state: RootState) => Boolean(sessionUser(state).id);

export const isHardUser = (state: RootState) => sessionUser(state).is_terms_and_conditions_accepted;

export const isPreCreateUser = (state: RootState) => {
  const user = currentUser(state);
  return Boolean(user?.email && user?.interests.length === 0);
};

export const isAdmin = (state: RootState) => sessionUser(state).is_admin;

export const isSuperAdmin = (state: RootState) => sessionUser(state).is_super_admin;
export const isMarketAdmin = (state: RootState) => sessionUser(state).is_market_admin;

export const hasZoomConnected = (state: RootState) => {
  const user = currentUser(state);
  return Boolean(user?.has_zoom_connected);
};

export const preferredLanguage = (state: RootState) => {
  const user = currentUser(state);
  return user?.preferred_language;
};

export const zoomOauthData = (state: RootState) => {
  const user = currentUser(state);
  return user?.zoom_oauth_data;
};

export const getVerificationCodeState = (state: RootState) => authSlice(state).getVerificationCode;

export const checkVerificationCodeState = (state: RootState) => authSlice(state).checkVerificationCode;
