import { createSelector } from 'reselect';
import { DefaultLanguageEventDisplayStatusEnum } from 'api/admin/models';
import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { selectorsFactory as featureSelectorsFactory } from 'store/features/utils';
import { getDisabledFieldsByStatuses, getPublishErrors } from './utils';
import * as keyWindowNames from './keyWindowNames';
import * as AdminEventImagesSelectors from '../adminEventImages/selectors';
import {
  createGetUnsavedIdsSelector,
  createHasIgnoredUnsavingIdSelector,
  createHasUnsavedIdSelector,
} from './unsavedUtils';

export const adminEventsSlice = (state: RootState) => state.entities.adminEvents.entitiesState;

export const adminCCEventsSlice = (state: RootState) => state.entities.adminEvents.ccEventsState;

export const adminEventSlice = (state: RootState) => state.entities.adminEvents.entityState;

export const publishAdminEventSlice = (state: RootState) => state.entities.adminEvents.publishAdminEvent;

export const saveAsDraftAdminEventSlice = (state: RootState) => state.entities.adminEvents.saveAsDraftAdminEvent;

export const cancelAdminEventSlice = (state: RootState) => state.entities.adminEvents.cancelAdminEvent;

export const archiveAdminEventSlice = (state: RootState) => state.entities.adminEvents.archiveAdminEvent;

export const markAsTestAdminEventSlice = (state: RootState) => state.entities.adminEvents.markAsTestAdminEvent;

export const updateAdminCCEventStatusSlice = (state: RootState) => state.entities.adminEvents.updateAdminCCEventStatus;

export const adminForRoleEventsSlice = (state: RootState) => state.entities.adminEvents.adminForRoleEvents;

export const adminForSelectRoleEventsSlice = (state: RootState) => state.entities.adminEvents.adminForSelectRoleEvents;

export const adminEventsById = selectorsFactory.createEntityByIdSelector(adminEventsSlice);

export const adminEventById = selectorsFactory.createEntityByIdSelector(adminEventSlice);

export const adminEventStateById = selectorsFactory.createEntityStateByIdSelector(adminEventSlice);

export const adminEventIsUpdatingById = selectorsFactory.createIsUpdatingByIdSelector(adminEventSlice);

export const adminEventsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventsSlice,
  keyWindowNames.adminOrganization,
);

export const adminForRoleEventsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminForRoleEventsSlice,
  keyWindowNames.adminOrganizationAdmin,
);

export const adminForSelectRoleEventsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminForSelectRoleEventsSlice,
  keyWindowNames.adminOrganizationAdmin,
);

export const adminEventUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(adminEventSlice);

export const adminEventsPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminEventsSlice,
  keyWindowNames.adminOrganization,
);

export const adminForRoleEventsPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminForRoleEventsSlice,
  keyWindowNames.adminOrganizationAdmin,
);

export const adminForSelectEventsPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminForSelectRoleEventsSlice,
  keyWindowNames.adminOrganizationAdmin,
);

export const adminAllEventsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminEventsSlice);

export const adminAllEventsPagination = selectorsFactory.createStaticKeyWindowPaginationSelector(adminEventsSlice);

export const adminAllCCEventsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminCCEventsSlice);

export const adminAllCCEventsPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminCCEventsSlice,
  keyWindowNames.adminAllCCEvents,
);

export const publishAdminEventStateById =
  featureSelectorsFactory.createMultipleFeatureStateByIdSelectors(publishAdminEventSlice);

export const publishAdminEventErrorById =
  featureSelectorsFactory.createMultipleFeatureErrorByIdSelector(publishAdminEventSlice);

export const publishErrors = createSelector(
  adminEventById,
  publishAdminEventErrorById,
  (state, eventId) => AdminEventImagesSelectors.adminEventImages(state, { eventId }),
  getPublishErrors,
);

export const disabledFieldsByStatuses = createSelector(
  adminEventById,
  (state, eventId, fieldsByStatuses) => fieldsByStatuses,
  getDisabledFieldsByStatuses,
);

export const eventQuestions = (state: RootState, id: number) => {
  const adminEvent = adminEventById(state, id);
  return Boolean(adminEvent) && adminEvent.questions;
};

export const eventQuestionOptions = (state: RootState, id: number, questionId: number) => {
  const questions = eventQuestions(state, id);
  return Boolean(questions) && questions.find(({ id }) => id === questionId)?.options;
};

export const canTranslate = (state: RootState, id: number) => {
  const adminEvent = adminEventById(state, id);
  return (
    Boolean(adminEvent) &&
    adminEvent.organization.settings?.translation_languages_required?.length > 0 &&
    adminEvent.settings?.event_translation_allowance !== 'translations_disabled_all'
  );
};

export const isTranslationRequired = (state: RootState, id: number) => {
  const adminEvent = adminEventById(state, id);
  return Boolean(adminEvent) && adminEvent.settings?.event_translation_allowance === 'translations_required_all';
};

// todo: check usage of EventList type and EventDisplayList
export const isLiveEvent = (state: RootState, id: number) => {
  const adminEvent: any = adminEventById(state, id);
  return Boolean(adminEvent) && adminEvent.status_display === 'scheduled' && adminEvent.has_orders;
};

export const canUseThirdPartyLinks = (state: RootState, id: number) => {
  const adminEvent = adminEventById(state, id);
  return Boolean(adminEvent) && adminEvent.organization.settings?.can_use_third_party_links;
};

export const canUseDonations = (state: RootState, id: number) => {
  const adminEvent = adminEventById(state, id);
  return Boolean(adminEvent) && adminEvent.organization.settings?.accept_donations_on_profile_page;
};

export const canHaveCrmMetaData = (state: RootState, id: number) => {
  const adminEvent = adminEventById(state, id);
  return Boolean(adminEvent) && adminEvent.organization.settings?.can_have_crm_meta_data;
};

export const isPublished = (state: RootState, id: number) => {
  const adminEvent = adminEventById(state, id);
  return adminEvent?.status === DefaultLanguageEventDisplayStatusEnum.Published;
};

export const getUnsavedIds = createGetUnsavedIdsSelector(adminEventSlice);

export const hasUnsavedId = createHasUnsavedIdSelector(adminEventSlice);

export const hasIgnoredUnsavingId = createHasIgnoredUnsavingIdSelector(adminEventSlice);

export const adminOrgEventsAutocompleteSlice = (state: RootState) => state.entities.adminEvents.orgEventsAutocomplete;

export const adminOrgEventsAutocompleteState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrgEventsAutocompleteSlice,
  keyWindowNames.adminOrganization,
);
