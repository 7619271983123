import { OrganizationType } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationType>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationTypesReducer = createReducer<State, Actions>(initialState, {
  [actions.getOrganizationTypes.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getOrganizationTypes.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getOrganizationTypes.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getOrganizationTypes.reset.type]: actionsHandlers.handleGetManyReset,
});

export default adminOrganizationTypesReducer;
