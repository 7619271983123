// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Refund, UserShort } from '.';

/**
 * @export
 * @interface OrderAdmin
 */
export interface OrderAdmin {
  /**
   * @type {string}
   * @memberof OrderAdmin
   */
  readonly id?: string;
  /**
   * @type {UserShort}
   * @memberof OrderAdmin
   */
  user?: UserShort;
  /**
   * @type {string}
   * @memberof OrderAdmin
   */
  status: OrderAdminStatusEnum;
  /**
   * @type {string}
   * @memberof OrderAdmin
   */
  readonly created?: string;
  /**
   * @type {string}
   * @memberof OrderAdmin
   */
  readonly updated?: string;
  /**
   * @type {number}
   * @memberof OrderAdmin
   */
  charged_amount?: number;
  /**
   * @type {string}
   * @memberof OrderAdmin
   */
  charged_currency?: OrderAdminChargedCurrencyEnum;
  /**
   * @type {Refund}
   * @memberof OrderAdmin
   */
  refunds?: Refund[];
}

/**
 * @export
 * @enum {string}
 */
export enum OrderAdminStatusEnum {
  InProgress = 'in_progress',
  Pending = 'pending',
  Canceled = 'canceled',
  Abandoned = 'abandoned',
  Paid = 'paid',
  Refunded = 'refunded',
  WaitingForOfflinePayment = 'waiting_for_offline_payment',
  PaidOffline = 'paid_offline',
}

/**
 * @export
 * @enum {string}
 */
export enum OrderAdminChargedCurrencyEnum {
  Usd = 'usd',
  Cad = 'cad',
}
