import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationReportsSlice = (state: RootState) => state.entities.adminOrganizationReports;

export const adminOrganizationReportsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationReportsSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationReportsPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminOrganizationReportsSlice,
  keyWindowNames.adminOrganization,
);
