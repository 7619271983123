import { put, select, takeEvery } from 'redux-saga/effects';
import { UploadApi, UserApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { apiCall } from 'store/utils';
import { GetAction } from 'store/types';
import { AuthSelectors } from 'store/selectors';
import { UserPassport } from 'api/general/models/UserPassport';
import * as actions from './actions';

const handleGetUserDetailRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getUserDetail,
  request: UserApi.getUserDetail,
  requestArgsBuilder: null,
});

const handlePartialUpdateUserRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.partialUpdateUser,
  request: UserApi.partialUpdateUser,
});

const handleUpdateUserImageRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateUserImage,
  request: UserApi.partialUpdateUser,
  requestArgsBuilder: function* builder(action): any {
    const {
      id,
      params: { file },
    } = action.payload;

    let response = null;

    if (file) {
      response = yield apiCall(UploadApi.upload, {
        form: {
          file,
          image_type: 'avatar',
        },
      });
    }

    return [
      id,
      {
        body: {
          image: response?.id || null,
        },
      },
    ];
  },
});

const handleGetUserPreferencesRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getUserPreferences,
  request: UserApi.getUserPreferences,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    return [id, { params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handlePartialUpdateUserPreferencesRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.partialUpdateUserPreferences,
  request: UserApi.partialUpdateUserPreferences,
  requestArgsBuilder: (action) => {
    const { id, entity } = action.payload;
    return [id, { body: entity }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetUserPreferencesByEmailRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getUserPreferencesByEmail,
  request: UserApi.getUserPreferencesByEmail,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    return [id, { params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handlePartialUpdateUserPreferencesByEmailRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.partialUpdateUserPreferencesByEmail,
  request: UserApi.partialUpdateUserPreferencesByEmail,
  requestArgsBuilder: (action) => {
    const { id, entity } = action.payload;
    return [id, { body: entity }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

function* handleToggleUserInterests(action: GetAction<typeof actions.toggleUserInterest>): any {
  const { id } = action.payload;
  const currentUser = yield select(AuthSelectors.currentUser);
  const userId = currentUser.id;
  const userInterestsIds = currentUser?.interests.map((item: any) => item.id);

  const index = userInterestsIds.indexOf(id);
  if (index === -1) {
    userInterestsIds.push(id);
  } else {
    userInterestsIds.splice(index, 1);
  }

  yield put(
    actions.partialUpdateUser.request({
      id: userId,
      entity: {
        interests: userInterestsIds,
      },
    }),
  );
}

const handleGetUserPassports = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getUserPassports,
  request: UserApi.getUserPassports,
  transformResponse: (response) =>
    response.map((item: UserPassport, index: number) => ({
      id: index,
      ...item,
    })),
});

const handleCreateUserPassportRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createUserPassport,
  request: UserApi.createUserPassport,
  requestArgsBuilder: (action) => {
    const { entity } = action.payload;
    return [{ body: entity }];
  },
  transformResponse: (response) => ({
    id: 1,
    ...response,
  }),
});

export default function* userSagas() {
  yield takeEvery(actions.getUserDetail.request.type, handleGetUserDetailRequest);
  yield takeEvery(actions.partialUpdateUser.request.type, handlePartialUpdateUserRequest);
  yield takeEvery(actions.updateUserImage.request.type, handleUpdateUserImageRequest);
  yield takeEvery(actions.getUserPreferences.request.type, handleGetUserPreferencesRequest);
  yield takeEvery(actions.partialUpdateUserPreferences.request.type, handlePartialUpdateUserPreferencesRequest);
  yield takeEvery(actions.getUserPreferencesByEmail.request.type, handleGetUserPreferencesByEmailRequest);
  yield takeEvery(
    actions.partialUpdateUserPreferencesByEmail.request.type,
    handlePartialUpdateUserPreferencesByEmailRequest,
  );
  yield takeEvery(actions.toggleUserInterest.type, handleToggleUserInterests);
  yield takeEvery(actions.getUserPassports.request.type, handleGetUserPassports);
  yield takeEvery(actions.createUserPassport.request.type, handleCreateUserPassportRequest);
}
