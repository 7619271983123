import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const defaultMetaTagsSlice = (state: RootState) => state.entities.defaultMetaTags;

export const defaultMetaTags = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(defaultMetaTagsSlice);

export const defaultMetaTagsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(defaultMetaTagsSlice);

export const hasDefaultMetaTags = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(defaultMetaTagsSlice);
