import { BaseDefaultQuestionDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<BaseDefaultQuestionDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminDefaultQuestionsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminDefaultQuestions.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminDefaultQuestions.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminDefaultQuestions.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminDefaultQuestions.reset.type]: actionsHandlers.handleGetManyReset,
});

export default adminDefaultQuestionsReducer;
