import { takeEvery } from 'redux-saga/effects';
import { AdminEventImagesTranslationApi, UploadApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { apiCall } from 'store/utils';
import * as actions from './actions';

const handleGetAdminEventImageTranslationsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventImageTranslations,
  request: AdminEventImagesTranslationApi.getEventImagesTranslation,
  requestArgsBuilder: ({ payload: { params } }) => {
    const { eventId, image__image_type } = params;
    return [eventId, { params: { image__image_type } }];
  },
});

const handleReplaceAdminEventImageTranslationRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.replaceAdminEventImageTranslation,
  request: AdminEventImagesTranslationApi.updateEventImageTranslation,
  requestArgsBuilder: function* builder(action): any {
    const {
      id,
      params: { eventId, language, file, image_type },
    } = action.payload;

    const response = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type,
      },
    });

    return [
      eventId,
      id,
      {
        body: {
          [`image_${language}`]: response.id,
        },
      },
    ];
  },
});

const handleRemoveAdminEventImageTranslationRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.removeAdminEventImageTranslation,
  request: AdminEventImagesTranslationApi.updateEventImageTranslation,
  requestArgsBuilder: (action) => {
    const {
      id,
      params: { eventId, language },
    } = action.payload;

    return [
      eventId,
      id,
      {
        body: {
          [`image_${language}`]: null,
        },
      },
    ];
  },
});

export default function* adminEventImageTranslationsSagas() {
  yield takeEvery(actions.getAdminEventImageTranslations.request.type, handleGetAdminEventImageTranslationsRequest);
  yield takeEvery(actions.removeAdminEventImageTranslation.request.type, handleRemoveAdminEventImageTranslationRequest);
  yield takeEvery(
    actions.replaceAdminEventImageTranslation.request.type,
    handleReplaceAdminEventImageTranslationRequest,
  );
}
