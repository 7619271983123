import { DonationOption } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<DonationOption>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const donationOptionsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminDonationOptions.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminDonationOptions.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminDonationOptions.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminDonationOptions.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminDonationOption.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminDonationOption.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminDonationOption.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminDonationOption.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.runtimeUpdateAdminDonationOption.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.runtimeUpdateAdminDonationOption.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.runtimeUpdateAdminDonationOption.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.runtimeUpdateAdminDonationOption.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminDonationOption.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminDonationOption.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminDonationOption.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default donationOptionsReducer;
