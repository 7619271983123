import { EventSettings } from 'api/admin/models';
import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getAdminEventSettings = actionsFactories.createGetOneActionsFactory<EntityType<EventSettings>>()(
  'GET_ADMIN_EVENT_SETTINGS_REQUEST',
  'GET_ADMIN_EVENT_SETTINGS_SUCCESS',
  'GET_ADMIN_EVENT_SETTINGS_FAILURE',
  'GET_ADMIN_EVENT_SETTINGS_RESET',
);

export const runtimeUpdateAdminEventSettings = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EventSettings>
>()(
  'RUNTIME_UPDATE_ADMIN_EVENT_SETTINGS_REQUEST',
  'RUNTIME_UPDATE_ADMIN_EVENT_SETTINGS_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_EVENT_SETTINGS_FAILURE',
  'RUNTIME_UPDATE_ADMIN_EVENT_SETTINGS_RESET',
);

export const updateAdminEventSettings = actionsFactories.createUpdateOneActionsFactory<EntityType<EventSettings>>()(
  'UPDATE_ADMIN_EVENT_SETTINGS_REQUEST',
  'UPDATE_ADMIN_EVENT_SETTINGS_SUCCESS',
  'UPDATE_ADMIN_EVENT_SETTINGS_FAILURE',
  'UPDATE_ADMIN_EVENT_SETTINGS_RESET',
);

export const addUnsavedAdminEventSettings = createAction('ADD_UNSAVED_ADMIN_EVENT_SETTINGS', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeUnsavedAdminEventSettings = createAction('REMOVE_UNSAVED_ADMIN_EVENT_SETTINGS', (id: number) => ({
  payload: {
    id,
  },
}));

export const addIgnoredUnsavingAdminEventSettings = createAction(
  'ADD_IGNORED_UNSAVING_ADMIN_EVENT_SETTINGS',
  (id: number) => ({
    payload: {
      id,
    },
  }),
);

export const removeIgnoredUnsavingAdminEventSettings = createAction(
  'REMOVE_IGNORED_UNSAVING_ADMIN_EVENT_SETTINGS',
  (id: number) => ({
    payload: {
      id,
    },
  }),
);
