import { OrderStatus } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory<EntityType<OrderStatus>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const spreedlyPayOrderReducer = createReducer<State, Actions>(initialState, {
  [actions.spreedlyPayOrder.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.spreedlyPayOrder.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.spreedlyPayOrder.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.spreedlyPayOrder.reset.type]: actionsHandlers.handleSingleFeatureReset,
  [actions.resetCreatingOrder.type]: actionsHandlers.handleSingleFeatureReset,
});

export default spreedlyPayOrderReducer;
