import { PlatformUser } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

// todo/ add types
const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<PlatformUser>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminPlatformUsersReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminPlatformUsers.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminPlatformUsers.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminPlatformUsers.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminPlatformUsers.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default adminPlatformUsersReducer;
