import { takeEvery, takeLeading } from 'redux-saga/effects';
import { CalendarSettingsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import { sagasHandlersFactory as featureSagasHandlersFactory } from '../../features/utils';

const handleGetCalendarSettingsListRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getCalendarSettingsList,
  request: CalendarSettingsApi.getCalendarSettingsList,
});

const handleGetCalendarSettingsRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getCalendarSettings,
  request: CalendarSettingsApi.getCalendarSettings,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { marketSlug } = params;
    return [marketSlug];
  },
});

const handleUpdateCalendarSettingsRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateCalendarSettings,
  request: CalendarSettingsApi.updateCalendarSettings,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { marketSlug } = params;
    return [marketSlug, { body: entity }];
  },
});

const handleGetPublicCalendarIcsFeedLink = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.getPublicCalendarIcsFeedLink,
  request: CalendarSettingsApi.generateMarketICSFeedUrl,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { marketSlug } = params;
    return [marketSlug];
  },
});

const handleGetCommunityCalendarIcsFeedLink = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.getCommunityCalendarIcsFeedLink,
  request: CalendarSettingsApi.generateCommunityCalendarICSFeedUrl,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { marketSlug } = params;
    return [marketSlug];
  },
});

export default function* marketsSagas() {
  yield takeLeading(actions.getCalendarSettingsList.request.type, handleGetCalendarSettingsListRequest);
  yield takeEvery(actions.getCalendarSettings.request.type, handleGetCalendarSettingsRequest);
  yield takeEvery(actions.updateCalendarSettings.request.type, handleUpdateCalendarSettingsRequest);
  yield takeEvery(actions.getPublicCalendarIcsFeedLink.request.type, handleGetPublicCalendarIcsFeedLink);
  yield takeEvery(actions.getCommunityCalendarIcsFeedLink.request.type, handleGetCommunityCalendarIcsFeedLink);
}
