import { EventTargetAge } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventTargetAge>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const eventTargetAgesReducer = createReducer<State, Actions>(initialState, {
  [actions.getEventTargetAges.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getEventTargetAges.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getEventTargetAges.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getEventTargetAges.reset.type]: actionsHandlers.handleGetManyReset,
});

export default eventTargetAgesReducer;
