import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const eventTargetAgesSlice = (state: RootState) => state.entities.eventTargetAges;

export const eventTargetAges = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(eventTargetAgesSlice);

export const eventTargetAgesState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(eventTargetAgesSlice);

export const hasEventTargetAges = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(eventTargetAgesSlice);
