import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const refreshTokenReducer = createReducer<State, Actions>(initialState, {
  [actions.refreshToken.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.refreshToken.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.refreshToken.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.refreshToken.reset.type]: actionsHandlers.handleSingleFeatureReset,
});

export default refreshTokenReducer;
