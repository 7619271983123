import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';
import { UpdateAdminCCEventStatusResultType } from '../types';

const stateFactory = stateFactories.createInitialMultipleFeatureStateFactory<UpdateAdminCCEventStatusResultType>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const updateAdminCCEventStatusReducer = createReducer<State, Actions>(initialState, {
  [actions.updateAdminCCEventStatus.request.type]: actionsHandlers.handleMultipleFeatureRequest,
  [actions.updateAdminCCEventStatus.success.type]: actionsHandlers.handleMultipleFeatureSuccess,
  [actions.updateAdminCCEventStatus.failure.type]: actionsHandlers.handleMultipleFeatureFailure,
  [actions.updateAdminCCEventStatus.reset.type]: actionsHandlers.handleMultipleFeatureReset,
});

export default updateAdminCCEventStatusReducer;
