import { fork, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminEventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminDonationRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminDonation,
  request: AdminEventsApi.getAdminDonation,
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleRuntimeUpdateAdminDonationRequest = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminDonation,
  request: AdminEventsApi.partialUpdateAdminDonation,
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminDonationOptionsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminDonationOptions,
  request: AdminEventsApi.getAdminDonationOptions,
  requestArgsBuilder: (action) => action.payload.params.eventId,
});

const handleCreateAdminDonationOptionsRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminDonationOption,
  request: AdminEventsApi.createDonationOptions,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, { body: entity }];
  },
});

const handleRuntimeUpdateDonationsOptionsRequest = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminDonationOption,
  request: AdminEventsApi.partialUpdateDonationOptions,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    return [params.eventId, id, { body: entity }];
  },
});

const handleDeleteDonationOptionRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminDonationOption,
  request: AdminEventsApi.deleteDonationOptions,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

export default function* adminDonationsSagas() {
  yield takeLeading(actions.getAdminDonation.request.type, handleGetAdminDonationRequest);

  yield takeEvery(actions.getAdminDonationOptions.request.type, handleGetAdminDonationOptionsRequest);
  yield takeEvery(actions.createAdminDonationOption.request.type, handleCreateAdminDonationOptionsRequest);
  yield takeEvery(actions.deleteAdminDonationOption.request.type, handleDeleteDonationOptionRequest);

  yield fork(handleRuntimeUpdateAdminDonationRequest);
  yield fork(handleRuntimeUpdateDonationsOptionsRequest);
}
