// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AppliedPromoCode, OrderAddOnShort, OrderDonation, OrderEventList, UserShort, UserTicketOrder } from '.';

/**
 * @export
 * @interface OrderList
 */
export interface OrderList {
  /**
   * @type {string}
   * @memberof OrderList
   */
  readonly id?: string;
  /**
   * @type {number}
   * @memberof OrderList
   */
  charged_amount?: number;
  /**
   * @type {string}
   * @memberof OrderList
   */
  charged_currency?: OrderListChargedCurrencyEnum;
  /**
   * @type {OrderEventList}
   * @memberof OrderList
   */
  event?: OrderEventList;
  /**
   * @type {Array<OrderAddOnShort>}
   * @memberof OrderList
   */
  readonly addons?: Array<OrderAddOnShort>;
  /**
   * @type {Array<UserTicketOrder>}
   * @memberof OrderList
   */
  readonly tickets?: Array<UserTicketOrder>;
  /**
   * @type {Array<OrderDonation>}
   * @memberof OrderList
   */
  readonly donations?: Array<OrderDonation>;
  /**
   * @type {Array<AppliedPromoCode>}
   * @memberof OrderList
   */
  applied_promocodes: Array<AppliedPromoCode>;
  /**
   * @type {UserShort}
   * @memberof OrderList
   */
  user?: UserShort;
  /**
   * @type {string}
   * @memberof OrderList
   */
  send_to: string;
  /**
   * @type {string}
   * @memberof OrderList
   */
  readonly created?: string;
  /**
   * @type {string}
   * @memberof OrderList
   */
  readonly updated?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OrderListChargedCurrencyEnum {
  Usd = 'usd',
  Cad = 'cad',
}
