import { put, takeEvery } from 'redux-saga/effects';
import { GetAction } from 'store/types';
import { Action, SerializedError, parseError } from 'store/utils';
import { severity } from './shared';
import * as actions from './actions';

function* handleErrorNotification<
  T extends string,
  A extends Action<T, unknown, { notifyError?: boolean }, SerializedError>,
>(action: A) {
  if (action.meta?.notifyError) {
    yield put(actions.showError(action.error));
  }
}

function* handleShowError(action: GetAction<typeof actions.showError>) {
  const id = Math.random().toString(36);
  const message = parseError(action.payload.error);
  yield put(actions.showNotification(id, message, severity.error));
}

export default function* notificationsSagas() {
  yield takeEvery('*', handleErrorNotification);
  yield takeEvery(actions.showError.type, handleShowError);
}
