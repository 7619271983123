import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const ordersSlice = (state: RootState) => state.entities.orders.entitiesState;

export const orderSlice = (state: RootState) => state.entities.orders.entityState;

export const resendOrderEmailState = (state: RootState) => state.entities.orders.resendOrderEmail;

export const ordersState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(ordersSlice);

export const ordersPagination = selectorsFactory.createStaticKeyWindowPaginationSelector(ordersSlice);

export const orderByIdState = selectorsFactory.createEntityStateByIdSelector(orderSlice);
