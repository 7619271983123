import { combineReducers } from 'redux';
import organizationsReducer from './organizationsReducer';
import searchOrganizationsReducer from './searchOrganizationsReducer';
import getOrganizationsReducer from './getOrganizationsReducer';

export default combineReducers({
  organizationsState: organizationsReducer,
  searchOrganizationsState: searchOrganizationsReducer,
  getOrganizationsState: getOrganizationsReducer,
});
