import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { Channel } from 'api/admin/models';

export const getChannels = actionsFactories.createGetManyActionsFactory<EntityType<Channel>>()(
  'GET_CHANNELS_REQUEST',
  'GET_CHANNELS_SUCCESS',
  'GET_CHANNELS_FAILURE',
  'GET_CHANNELS_RESET',
);
