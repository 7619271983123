import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import userPreferencesReducer from './userPreferencesReducer';
import userPreferencesByEmailReducer from './userPreferenceReduce';
import userPassportsReducer from './userPassportsReducer';

export default combineReducers({
  entitiesState: usersReducer,
  userPreferences: userPreferencesReducer,
  userPreferencesByEmail: userPreferencesByEmailReducer,
  userPassports: userPassportsReducer,
});
