import { EventOrdersStatistics } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventDashboardParamsType } from './types';

export const getAdminEventDashboard = actionsFactories.createGetOneActionsFactory<
  EntityType<EventOrdersStatistics>,
  GetAdminEventDashboardParamsType
>()(
  'GET_ADMIN_EVENT_DASHBOARD_REQUEST',
  'GET_ADMIN_EVENT_DASHBOARD_SUCCESS',
  'GET_ADMIN_EVENT_DASHBOARD_FAILURE',
  'GET_ADMIN_EVENT_DASHBOARD_RESET',
);
