// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PromoCodeDisplay
 */
export interface PromoCodeDisplay {
  /**
   * @type {number}
   * @memberof PromoCodeDisplay
   */
  discount_value?: number;
  /**
   * @type {string}
   * @memberof PromoCodeDisplay
   */
  discount_type?: PromoCodeDisplayDiscountTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PromoCodeDisplayDiscountTypeEnum {
  Flat = 'flat',
  Percent = 'percent',
}
