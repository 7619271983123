import { OrganizationImage } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationImage>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationImagesReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationImages.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminOrganizationImages.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminOrganizationImages.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminOrganizationImages.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminOrganizationImage.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminOrganizationImage.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminOrganizationImage.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminOrganizationImage.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminOrganizationImage.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminOrganizationImage.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminOrganizationImage.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminOrganizationImage.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminOrganizationImage.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminOrganizationImage.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminOrganizationImage.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminOrganizationImagesReducer;
