import { call, fork, getContext, put, race, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import { AuthApi } from 'api';
import { GetAction } from 'store/types';
import { UserActions } from 'store/actions';
import { apiCall, Pipeline } from 'store/utils';
import { sagasHandlersFactory } from 'store/features/utils';
import { getParsedCookie, setSerializedCookie } from 'utils/cookie';
import { isServer } from 'utils/next';
import jwtDecode from 'jwt-decode';
import { Access, Expirable, SessionUser } from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import createSessionUserWatcher from './utils/createSessionUserWatcher';
import { createSessionUser, createSessionUserFromAccess } from './utils/sessionUser';
import {
  decodeAuthCookie,
  decodeUserCookie,
  expiredSessionCookie,
  isAlive,
  isSameUser,
  serializeAuthCookie,
  serializeUserCookie,
} from './utils/sessionCookie';

function* clearSessionCookie(): any {
  const next: any = yield getContext('next');
  setSerializedCookie(next, expiredSessionCookie);
}

function* setAuthCookie(data: Record<string, string>): any {
  const next: any = yield getContext('next');
  setSerializedCookie(next, serializeAuthCookie(data));
}

function* setUserCookie(user: SessionUser, exp: number): any {
  const next: any = yield getContext('next');
  setSerializedCookie(next, serializeUserCookie(user, exp));
}

function* refreshToken(refresh: string): any {
  const { isPending } = yield select(selectors.refreshTokenState);
  if (!isPending) yield put(actions.refreshToken.request({ params: { refresh } }));
  yield race([
    take(actions.logout.type),
    take(actions.refreshToken.success.type),
    take(actions.refreshToken.failure.type),
  ]);
}

function* getAccessToken(canRefresh = true): Generator<any, string, any> {
  const next: any = yield getContext('next');
  const parsedCookie = getParsedCookie(next);

  if (parsedCookie.access) {
    const access = jwtDecode(parsedCookie.access);
    if (isAlive(access as Expirable)) return parsedCookie.access;
  }

  if (parsedCookie.refresh && canRefresh) {
    const refresh = jwtDecode(parsedCookie.refresh);
    if (isAlive(refresh as Expirable) && canRefresh) {
      yield call(refreshToken, parsedCookie.refresh);
      return yield call(getAccessToken, false);
    }
  }
}

function* initSession(): any {
  const next: any = yield getContext('next');
  const parsedCookie = getParsedCookie(next);

  const accessToken = yield call(getAccessToken);

  if (accessToken) {
    const access = jwtDecode(accessToken);
    const user = decodeUserCookie(parsedCookie);
    yield put(
      actions.setSessionUser(
        user && isSameUser(user, access as Access) ? user : createSessionUserFromAccess(access as Access),
      ),
    );
    yield put(actions.reloadCurrentUser());
  }
}

function* initPipeline() {
  const pipeline: Pipeline = yield getContext('apiPipeline');

  pipeline.push(function* authApiMiddleware(options) {
    try {
      const access = yield call(getAccessToken);
      if (access) {
        options.headers = {
          ...options.headers,
          Authorization: `JWT ${access}`,
        };
      }
    } catch (e) {}
  });
}

function* handleLoginMagicLinkRequest(action: GetAction<typeof actions.loginMagicLink.request>): any {
  try {
    const response = yield apiCall(AuthApi.loginMagicLink, {
      body: action.payload.params,
    });

    const { access, refresh } = yield call(decodeAuthCookie, response);
    yield call(setAuthCookie, response);

    const createActionPattern = (type: string) => (action: any) =>
      action.type === type && action.payload.entity.id === access.user_id;

    yield put(UserActions.getUserDetail.request({ id: access.user_id }));
    const effects = yield race([
      take(createActionPattern(UserActions.getUserDetail.success.type)),
      take(createActionPattern(UserActions.getUserDetail.failure.type)),
    ]);

    const [success] = effects;
    if (success) {
      const user = createSessionUser(success.payload.entity);

      yield call(setUserCookie, user, refresh.exp);
      yield put(actions.setSessionUser(user));
    }

    yield put(actions.loginMagicLink.success());
  } catch (error) {
    if (error instanceof Error)
      yield put(
        actions.loginMagicLink.failure({
          error,
        }),
      );
  }
}

function* handleLoginRequest(action: GetAction<typeof actions.login.request>): any {
  try {
    yield put(actions.login.reset);
    const response = yield apiCall(AuthApi.login, {
      body: action.payload.params,
    });

    const { access, refresh } = yield call(decodeAuthCookie, response);
    yield call(setAuthCookie, response);

    const createActionPattern = (type: string) => (action: any) =>
      action.type === type && action.payload.entity.id === access.user_id;

    yield put(UserActions.getUserDetail.request({ id: access.user_id }));
    const effects = yield race([
      take(createActionPattern(UserActions.getUserDetail.success.type)),
      take(createActionPattern(UserActions.getUserDetail.failure.type)),
    ]);

    const [success] = effects;
    if (success) {
      const user = createSessionUser(success.payload.entity);

      yield call(setUserCookie, user, refresh.exp);
      yield put(actions.setSessionUser(user));
    }

    yield put(actions.login.success());
  } catch (error) {
    if (error instanceof Error)
      yield put(
        actions.login.failure({
          error,
        }),
      );
  }
}

const handleSocialLinksRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.socialLinks,
  request: AuthApi.socialLoginURLS,
});

function* handleRefreshTokenRequest(action: GetAction<typeof actions.refreshToken.request>): any {
  try {
    const response = yield call(AuthApi.refreshToken, {
      body: action.payload.params,
    });

    const { refresh } = decodeAuthCookie(response);
    const user = yield select(selectors.sessionUser);

    yield call(setAuthCookie, response);
    yield call(setUserCookie, user, refresh.exp);

    yield put(actions.refreshToken.success());
  } catch (error) {
    if (error instanceof Error)
      yield put(
        actions.refreshToken.failure({
          error,
        }),
      );
  }
}

function* handleReloadCurrentUser(): any {
  const sessionUser = yield select(selectors.sessionUser);
  if (sessionUser.id) yield put(UserActions.getUserDetail.request({ id: sessionUser.id, silent: true }));
}

const watchSessionUser = createSessionUserWatcher({
  fork: function* syncSessionUser(sessionUser: any) {
    const createActionPattern = (type: string) => (action: any) =>
      action.type === type &&
      action.payload.entity.id === sessionUser.id &&
      Object.keys(sessionUser).some((key) => sessionUser[key] !== action.payload.entity[key]);

    function* updateSessionUser(action: any): any {
      const next: any = yield getContext('next');
      const parsedCookie = getParsedCookie(next);

      const { refresh } = decodeAuthCookie(parsedCookie);
      const user = createSessionUser(action.payload.entity);

      yield call(setUserCookie, user, refresh.exp);
      yield put(actions.setSessionUser(user));
    }

    yield takeEvery(createActionPattern(UserActions.getUserDetail.success.type), updateSessionUser);
    yield takeEvery(createActionPattern(UserActions.partialUpdateUser.success.type), updateSessionUser);
  },
});

const handleJoinRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.join,
  request: AuthApi.join,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleSendMagicLinkRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.sendMagicLink,
  request: AuthApi.sendMagicLink,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleSendRestPasswordRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.sendResetPassword,
  request: AuthApi.sendResetPassword,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleGetVerificationCodeRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.getVerificationCode,
  request: AuthApi.getVerificationCode,
  notifyError: false,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleCheckVerificationCodeRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.checkVerificationCode,
  request: AuthApi.checkVerificationCode,
  notifyError: false,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleResetPasswordRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.resetPassword,
  request: AuthApi.resetPassword,
  requestArgsBuilder: (action) => {
    const { uidb64, token, password_one, password_two } = action.payload.params;
    return [uidb64, token, { body: { password_one, password_two } }];
  },
});

const handleSetPasswordRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.setPassword,
  request: AuthApi.setPassword,
  requestArgsBuilder: (action) => {
    const { password, confirm } = action.payload.params;
    return [{ body: { password, confirm } }];
  },
});

export default function* authSagas() {
  yield takeEvery((action: any) => action.error?.response?.status === 401, clearSessionCookie);

  yield takeEvery(actions.logout.type, clearSessionCookie);
  yield takeEvery(actions.join.success.type, handleReloadCurrentUser);
  yield takeEvery(actions.reloadCurrentUser.type, handleReloadCurrentUser);

  yield takeEvery(actions.socialLinks.request.type, handleSocialLinksRequest);

  yield takeLeading(actions.join.request.type, handleJoinRequest);
  yield takeLeading(actions.sendMagicLink.request.type, handleSendMagicLinkRequest);
  yield takeLeading(actions.sendResetPassword.request.type, handleSendRestPasswordRequest);
  yield takeLeading(actions.resetPassword.request.type, handleResetPasswordRequest);
  yield takeLeading(actions.setPassword.request.type, handleSetPasswordRequest);
  yield takeLeading(actions.loginMagicLink.request.type, handleLoginMagicLinkRequest);
  yield takeLeading(actions.login.request.type, handleLoginRequest);
  yield takeLeading(actions.refreshToken.request.type, handleRefreshTokenRequest);
  yield takeLeading(actions.getVerificationCode.request.type, handleGetVerificationCodeRequest);
  yield takeLeading(actions.checkVerificationCode.request.type, handleCheckVerificationCodeRequest);
  yield fork(watchSessionUser);

  yield fork(function* init() {
    if (!isServer()) yield take(HYDRATE);
    yield call(initPipeline);
    yield call(initSession);
  });
}
