import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/features/utils';
import * as actions from '../actions';
import { PublishAdminEventResultType } from '../types';

const stateFactory = stateFactories.createInitialMultipleFeatureStateFactory<PublishAdminEventResultType>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const publishAdminEventReducer = createReducer<State, Actions>(initialState, {
  [actions.publishAdminEvent.request.type]: actionsHandlers.handleMultipleFeatureRequest,
  [actions.publishAdminEvent.success.type]: actionsHandlers.handleMultipleFeatureSuccess,
  [actions.publishAdminEvent.failure.type]: actionsHandlers.handleMultipleFeatureFailure,
  [actions.publishAdminEvent.reset.type]: actionsHandlers.handleMultipleFeatureReset,
  [actions.publishUnsaved.request.type]: actionsHandlers.handleMultipleFeatureRequest,
  [actions.publishUnsaved.success.type]: actionsHandlers.handleMultipleFeatureSuccess,
  [actions.publishUnsaved.failure.type]: actionsHandlers.handleMultipleFeatureFailure,
  [actions.publishUnsaved.reset.type]: actionsHandlers.handleMultipleFeatureReset,
});

export default publishAdminEventReducer;
