import { fork, takeEvery } from 'redux-saga/effects';
import { AdminTranslationsApi } from 'api';
import { ResponseError } from 'api/types';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminAdditionalInstructionTranslationsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminAdditionalInstructionTranslations,
  request: AdminTranslationsApi.getAdminAdditionalInstructionTranslations,
  requestArgsBuilder: (action) => action.payload.params.eventId,
  notifyError: (error) => (error as ResponseError).response?.status !== 404,
});

const handleUpdateAdminAdditionalInstructionTranslationRequest = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.updateAdminAdditionalInstructionTranslation,
  request: AdminTranslationsApi.updateAdminAdditionalInstructionTranslation,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

export default function* adminAdditionalInstructionTranslationsSagas() {
  yield takeEvery(
    actions.getAdminAdditionalInstructionTranslations.request.type,
    handleGetAdminAdditionalInstructionTranslationsRequest,
  );

  yield fork(handleUpdateAdminAdditionalInstructionTranslationRequest);
}
