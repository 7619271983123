import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';
import * as AdminEventsSelectors from '../adminEvents/selectors';
import * as AdminTicketsSelectors from '../adminTickets/selectors';
import * as AdminAddonsSelectors from '../adminAddons/selectors';

export const questionOptionSlice = (state: RootState) => state.entities.adminQuestions.questionOption;

// Ticket questions

export const specificTicketQuestionsSlice = (state: RootState) => state.entities.adminQuestions.specificTicketQuestions;

export const specificTicketQuestionsChangingState =
  selectorsFactory.createChangingStateSelector(specificTicketQuestionsSlice);

// Addon questions

export const specificAddonQuestionsSlice = (state: RootState) => state.entities.adminQuestions.specificAddonQuestions;

export const specificAddonQuestionsChangingState =
  selectorsFactory.createChangingStateSelector(specificAddonQuestionsSlice);

// event question

export const eventQuestionSlice = (state: RootState) => state.entities.adminQuestions.eventQuestions;

export const eventQuestionState = selectorsFactory.createUpdatingStateByIdSelector(eventQuestionSlice);

export const eventQuestionChangingState = selectorsFactory.createChangingStateSelector(eventQuestionSlice);

// default questions

export const adminDefaultQuestionsSlice = (state: RootState) => state.entities.adminQuestions.defaultQuestions;

export const adminDefaultQuestions =
  selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(adminDefaultQuestionsSlice);

export const adminDefaultQuestionsState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminDefaultQuestionsSlice);

// ManyTime

export const adminManyTimeQuestionsSlice = (state: RootState) => state.entities.adminQuestions.manyTimeQuestions;

export const adminManyTimeQuestions = selectorsFactory.createDynamicKeyWindowEntitiesMemoizedSelector(
  adminManyTimeQuestionsSlice,
  keyWindowNames.adminManyTimeQuestions,
);

export const adminManyTimeQuestionsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminManyTimeQuestionsSlice,
  keyWindowNames.adminManyTimeQuestions,
);

export const adminManyTimeQuestionsPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminManyTimeQuestionsSlice,
  keyWindowNames.adminManyTimeQuestions,
);

export const adminManyTimeQuestionsCreatingState =
  selectorsFactory.createCreatingStateSelector(adminManyTimeQuestionsSlice);

export const adminManyTimeQuestionsUpdatingStateById =
  selectorsFactory.createUpdatingStateByIdSelector(adminManyTimeQuestionsSlice);

// AllAttendees

export const adminAllAttendeesQuestionsSlice = (state: RootState) =>
  state.entities.adminQuestions.allAttendeesQuestions;

export const adminAllAttendeesQuestionsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminAllAttendeesQuestionsSlice,
  keyWindowNames.adminEvent,
);

export const adminAllAttendeesQuestionsChangingState = selectorsFactory.createChangingStateSelector(
  adminAllAttendeesQuestionsSlice,
);

export const adminAllAttendeesQuestions = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminAllAttendeesQuestionsSlice,
  keyWindowNames.adminEvent,
);

const createQuestionSelector =
  <K extends string>(idKey: K, entityByIdSelector: any) =>
  <P extends Record<K, number>>(state: RootState, params: P) => {
    const entity = entityByIdSelector(state, params[idKey]);
    return entity?.questions;
  };

export const adminEventQuestions = createQuestionSelector('eventId', AdminEventsSelectors.adminEventById);

export const adminTicketQuestions = createQuestionSelector('entityId', AdminTicketsSelectors.adminTicketById);

export const adminAddonQuestions = createQuestionSelector('entityId', AdminAddonsSelectors.adminAddonById);

export const adminHideQuestionsSlice = (state: RootState) => state.entities.adminQuestions.hideQuestions;

export const isHideQuestionsPending = (state: RootState) => adminHideQuestionsSlice(state).isPending;
