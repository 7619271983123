import { RootState } from 'store/rootReducer';
import { selectorsFactory } from '../utils';

export const adminTrustProfilesSlice = (state: RootState) => state.entities.adminTrustProfiles.trustProfiles;

export const adminTrustProfilesState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminTrustProfilesSlice);

export const adminTrustProfilesPagination =
  selectorsFactory.createStaticKeyWindowPaginationSelector(adminTrustProfilesSlice);

export const adminTrustProfileSlice = (state: RootState) => state.entities.adminTrustProfiles.trustProfile;

export const adminTrustProfileState = selectorsFactory.createEntityStateByIdSelector(adminTrustProfileSlice);

export const adminTrustProfileChangingState = selectorsFactory.createChangingStateSelector(adminTrustProfileSlice);
