import { Market } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Market>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const marketsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminMarkets.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminMarkets.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminMarkets.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminMarkets.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default marketsReducer;
