import { combineReducers } from 'redux';
import adminDefaultQuestionsReducer from './adminDefaultQuestionsReducer';
import adminManyTimeQuestionsReducer from './adminManyTimeQuestionsReducer';
import adminQuestionOptionReducer from './adminQuestionOptionReducer';
import adminAllAttendeesQuestionsReducer from './adminAllAttendeesQuestionsReducer';
import adminEventQuestionsReducer from './adminEventQuestionsReducer';
import adminTicketQuestionsReducer from './adminTicketQuestionsReducer';
import adminAddonQuestionsReducer from './adminAddonQuestionsReducer';
import hideQuestionsReducer from './hideQuestionsReducer';

export default combineReducers({
  defaultQuestions: adminDefaultQuestionsReducer,
  manyTimeQuestions: adminManyTimeQuestionsReducer,
  questionOption: adminQuestionOptionReducer,
  allAttendeesQuestions: adminAllAttendeesQuestionsReducer,
  eventQuestions: adminEventQuestionsReducer,
  specificTicketQuestions: adminTicketQuestionsReducer,
  specificAddonQuestions: adminAddonQuestionsReducer,
  hideQuestions: hideQuestionsReducer,
});
