import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { createSelector } from 'reselect';

export const languagesSpokenSlice = (state: RootState) => state.entities.options.languagesSpoken;

export const languagesSpoken = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(languagesSpokenSlice);

export const languagesSpokenState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(languagesSpokenSlice);

export const hasLanguagesSpoken = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(languagesSpokenSlice);

export const jewishDescendantsSlice = (state: RootState) => state.entities.options.jewishDescendants;

export const jewishDescendants = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(jewishDescendantsSlice);

export const jewishDescendantsState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(jewishDescendantsSlice);

export const hasJewishDescendants = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(jewishDescendantsSlice);

export const occupationsSlice = (state: RootState) => state.entities.options.occupations;

export const occupations = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(occupationsSlice);

export const occupationsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(occupationsSlice);

export const hasOccupations = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(occupationsSlice);

export const userInterestsSlice = (state: RootState) => state.entities.options.userInterests;

export const userInterests = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(userInterestsSlice);

export const userInterestsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(userInterestsSlice);

export const interestsCategories = createSelector(userInterestsState, (state) =>
  state.entities.filter((category: any) => !category.parent),
);

export const interestsItems = createSelector(userInterestsState, (state) =>
  state.entities.filter((category: any) => category.parent),
);

export const hasUserInterests = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(userInterestsSlice);
