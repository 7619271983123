import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const adminPlatformUsersSlice = (state: RootState) => state.entities.adminPlatformUsers;

export const adminPlatformUsersState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminPlatformUsersSlice);

export const adminPlatformUsersPagination =
  selectorsFactory.createStaticKeyWindowPaginationSelector(adminPlatformUsersSlice);
