import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const stripeOauthReducer = createReducer<State, Actions>(initialState, {
  [actions.requestOauthAuthorization.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.requestOauthAuthorization.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.requestOauthAuthorization.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.requestOauthAuthorization.reset.type]: actionsHandlers.handleSingleFeatureReset,
  [actions.disconnectStripe.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.disconnectStripe.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.disconnectStripe.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.disconnectStripe.reset.type]: actionsHandlers.handleSingleFeatureReset,
});

export default stripeOauthReducer;
