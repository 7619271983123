import { OrganizationDonationsStatistic } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationDonationsStatistic>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationDonationsStatisticReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationDonationsStatistic.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminOrganizationDonationsStatistic.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminOrganizationDonationsStatistic.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminOrganizationDonationsStatistic.reset.type]: actionsHandlers.handleGetOneReset,
});

export default adminOrganizationDonationsStatisticReducer;
