import { EntityType } from 'store/entities/types';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { AdminEventSettingsActions } from 'store/actions';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';
import {
  handleArchiveAdminEvent,
  handleCancelAdminEvent,
  handleMarkAsTestAdminEvent,
  handleSaveAsDraftAdminEvent,
  handleUnarchiveAdminEvent,
} from './adminEventReducer';

// todo: deal with type conflicts
const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<any>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions & typeof AdminEventSettingsActions>;

const adminEventsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.getAdminAllEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminAllEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminAllEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminAllEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.deleteAdminEvent.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.cancelAdminEvent.success.type]: handleCancelAdminEvent,
  [actions.saveAsDraftAdminEvent.success.type]: handleSaveAsDraftAdminEvent,
  [actions.markAsTestAdminEvent.success.type]: handleMarkAsTestAdminEvent,
  [actions.archiveAdminEvent.success.type]: handleArchiveAdminEvent,
  [actions.unarchiveAdminEvent.success.type]: handleUnarchiveAdminEvent,
  [AdminEventSettingsActions.updateAdminEventSettings.success.type]: (draft, action) => {
    const { id, ...settings } = action.payload.entity;
    const event = draft.byId[id];
    if (event) {
      event.settings = settings;
    }
  },
});

export default adminEventsReducer;
