import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { SpreedlyPaymentMethodType } from '../types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<SpreedlyPaymentMethodType>();
const initialState = stateFactory.getInitialState({
  tempSpreedlyPaymentMethod: null,
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const spreedlyPaymentMethodsReducer = createReducer<State, Actions>(initialState, {
  [actions.getSpreedlyPaymentMethods.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getSpreedlyPaymentMethods.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getSpreedlyPaymentMethods.failure.type]: actionsHandlers.handleGetManyFailure,

  [actions.createSpreedlyPaymentMethods.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createSpreedlyPaymentMethods.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createSpreedlyPaymentMethods.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createSpreedlyPaymentMethods.reset.type]: actionsHandlers.handleCreateOneReset,

  [actions.updateSpreedlyPaymentMethods.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateSpreedlyPaymentMethods.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.updateSpreedlyPaymentMethods.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateSpreedlyPaymentMethods.success.type]: (draft, action) => {
    const { params, entity } = action.payload;
    Object.keys(draft.byId).forEach((id) => {
      const paymentMethod = draft.byId[id];
      if (paymentMethod.id === params.payment_method_id) {
        paymentMethod.billing_details = entity.billing_details;
        entity.id = paymentMethod.id;
      }
      if (params.is_default) {
        paymentMethod.is_default = paymentMethod.id === params.payment_method_id;
      }
    });
    actionsHandlers.handleUpdateOneSuccess(draft, action);
  },

  [actions.deleteSpreedlyPaymentMethod.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteSpreedlyPaymentMethod.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteSpreedlyPaymentMethod.failure.type]: actionsHandlers.handleDeleteOneFailure,

  [actions.setTempSpreedlyPaymentMethod.type]: (draft, action) => {
    draft.tempSpreedlyPaymentMethod = action.payload.paymentMethod;
  },
  [actions.resetTempSpreedlyPaymentMethod.type]: (draft) => {
    draft.tempSpreedlyPaymentMethod = null;
  },
  [actions.setDefaultSpreedlyPaymentMethod.success.type]: (draft, action) => {
    const { params } = action.payload;

    Object.keys(draft.byId).forEach((id) => {
      const paymentMethod = draft.byId[id];

      if (paymentMethod.id === params.payment_method_token) {
        paymentMethod.is_default = true;
      } else {
        paymentMethod.is_default = false;
      }
    });
  },
});

export default spreedlyPaymentMethodsReducer;
