import { takeEvery } from 'redux-saga/effects';
import { AdminOrganizationImagesTranslationApi, UploadApi } from 'api';
import { apiCall } from 'store/utils';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminOrganizationImagesTranslationRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationImagesTranslation,
  request: AdminOrganizationImagesTranslationApi.getOrganizationImagesTranslation,
  requestArgsBuilder: ({ payload: { params } }) => {
    const { organizationId, imageType } = params;
    return [organizationId, { params: { image__image_type: imageType } }];
  },
});

const handleReplaceAdminOrganizationImageTranslationRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.replaceAdminOrganizationImageTranslation,
  request: AdminOrganizationImagesTranslationApi.updateOrganizationImageTranslation,
  requestArgsBuilder: function* builder(action): any {
    const {
      id,
      params: { organizationId, lng, file, type },
    } = action.payload;

    const response = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type: type,
      },
    });

    return [
      organizationId,
      id,
      {
        body: {
          [`image_${lng}`]: response.id,
        },
      },
    ];
  },
});

export default function* adminEventTranslationSagas() {
  yield takeEvery(
    actions.getAdminOrganizationImagesTranslation.request.type,
    handleGetAdminOrganizationImagesTranslationRequest,
  );
  yield takeEvery(
    actions.replaceAdminOrganizationImageTranslation.request.type,
    handleReplaceAdminOrganizationImageTranslationRequest,
  );
}
