import { takeEvery } from 'redux-saga/effects';
import { AuthApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featuresSagasHandlersFactory } from 'store/features/utils';
import * as actions from './actions';

const handleGetUserMfa = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getUserMfa,
  request: AuthApi.getUserMfa,
});

const handleCreateUserMfa = featuresSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.createUserMfa,
  request: AuthApi.createUserMfa,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleConfirmUserMfa = featuresSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.confirmUserMfa,
  request: AuthApi.confirmUserMfa,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleToggleActiveUserMfa = featuresSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.toggleActiveUserMfa,
  request: AuthApi.toggleActiveUserMfa,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleDeleteUserMfa = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteUserMfa,
  request: AuthApi.deleteUserMfa,
  requestArgsBuilder: (action) => {
    const { id } = action.payload;
    return [id];
  },
});

export default function* userMfaSagas() {
  yield takeEvery(actions.getUserMfa.request.type, handleGetUserMfa);
  yield takeEvery(actions.createUserMfa.request.type, handleCreateUserMfa);
  yield takeEvery(actions.confirmUserMfa.request.type, handleConfirmUserMfa);
  yield takeEvery(actions.deleteUserMfa.request.type, handleDeleteUserMfa);
  yield takeEvery(actions.toggleActiveUserMfa.request.type, handleToggleActiveUserMfa);
}
