import { OrganizationType } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';

export const getOrgTypes = actionsFactories.createGetManyActionsFactory<EntityType<OrganizationType>>()(
  'GET_ORG_TYPES_REQUEST',
  'GET_ORG_TYPES_SUCCESS',
  'GET_ORG_TYPES_FAILURE',
  'GET_ORG_TYPES_RESET',
  keyWindowNames.orgTypes,
);
