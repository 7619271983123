import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { AdminPromoCode } from 'api/general/models/AdminPromoCode';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AdminPromoCode>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminPromoCodeReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminPromoCode.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminPromoCode.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminPromoCode.failure.type]: actionsHandlers.handleGetOneFailure,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [actions.createAdminPromoCode.reset.type]: actionsHandlers.handleGetOneRequest,
  [actions.createAdminPromoCode.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminPromoCode.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminPromoCode.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminPromoCode.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.partialUpdateAdminPromoCode.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.partialUpdateAdminPromoCode.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.partialUpdateAdminPromoCode.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.partialUpdateAdminPromoCode.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminPromoCode.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminPromoCode.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminPromoCode.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminPromoCodeReducer;
