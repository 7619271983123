import { EmailSubscribeToken } from 'api/types';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EmailSubscribeToken>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const eventEmailSubscriptionReducer = createReducer<State, Actions>(initialState, {
  [actions.getEventEmailSubscription.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getEventEmailSubscription.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getEventEmailSubscription.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getEventEmailSubscription.reset.type]: actionsHandlers.handleGetOneReset,

  [actions.toggleEventEmailSubscription.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.toggleEventEmailSubscription.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.toggleEventEmailSubscription.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.toggleEventEmailSubscription.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default eventEmailSubscriptionReducer;
