import { OrganizationImageCreateUpdate, OrganizationImage } from 'api/admin/models';
import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import {
  AdminOrganizationImageParamsType,
  CreateAdminOrganizationImageParamsType,
  UpdateAdminOrganizationImageParamsType,
} from './types';

export const getAdminOrganizationImages = actionsFactories.createGetManyActionsFactory<
  EntityType<OrganizationImage>,
  AdminOrganizationImageParamsType
>()(
  'GET_ADMIN_ORGANIZATION_IMAGES_REQUEST',
  'GET_ADMIN_ORGANIZATION_IMAGES_SUCCESS',
  'GET_ADMIN_ORGANIZATION_IMAGES_FAILURE',
  'RESET_ADMIN_ORGANIZATION_IMAGES',
  keyWindowNames.adminOrganization,
);

export const createAdminOrganizationImage = actionsFactories.createCreateOneActionsFactory<
  EntityType<Omit<OrganizationImageCreateUpdate, 'order'>>,
  CreateAdminOrganizationImageParamsType
>()(
  'CREATE_ADMIN_ORGANIZATION_IMAGE_REQUEST',
  'CREATE_ADMIN_ORGANIZATION_IMAGE_SUCCESS',
  'CREATE_ADMIN_ORGANIZATION_IMAGE_FAILURE',
  'CREATE_ADMIN_ORGANIZATION_IMAGE_RESET',
  keyWindowNames.adminOrganization,
);

export const updateAdminOrganizationImage = actionsFactories.createUpdateOneActionsFactory<
  EntityType<OrganizationImageCreateUpdate>,
  UpdateAdminOrganizationImageParamsType
>()(
  'UPDATE_ADMIN_ORGANIZATION_IMAGE_REQUEST',
  'UPDATE_ADMIN_ORGANIZATION_IMAGE_SUCCESS',
  'UPDATE_ADMIN_ORGANIZATION_IMAGE_FAILURE',
  'UPDATE_ADMIN_ORGANIZATION_IMAGE_RESET',
);

export const deleteAdminOrganizationImage =
  actionsFactories.createDeleteOneActionsFactory<AdminOrganizationImageParamsType>()(
    'DELETE_ADMIN_ORGANIZATION_IMAGE_REQUEST',
    'DELETE_ADMIN_ORGANIZATION_IMAGE_SUCCESS',
    'DELETE_ADMIN_ORGANIZATION_IMAGE_FAILURE',
  );

export const createAdminOrganizationImages = createAction(
  'CREATE_ADMIN_ORGANIZATION_IMAGES',
  (params: Omit<CreateAdminOrganizationImageParamsType, 'file'> & { files: File[] }) => ({
    payload: {
      params,
    },
  }),
);
