import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { NotCompletedOrderType } from './types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<NotCompletedOrderType>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const notCompletedOrdersReducer = createReducer<State, Actions>(initialState, {
  [actions.getNotCompletedOrder.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getNotCompletedOrder.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getNotCompletedOrder.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getNotCompletedOrder.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.getNotCompletedOrders.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getNotCompletedOrders.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getNotCompletedOrders.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getNotCompletedOrders.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.createNotCompletedOrder.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createNotCompletedOrder.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createNotCompletedOrder.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createNotCompletedOrder.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateNotCompletedOrder.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateNotCompletedOrder.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateNotCompletedOrder.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateNotCompletedOrder.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default notCompletedOrdersReducer;
