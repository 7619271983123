import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { SessionUser } from '../types';
import * as actions from '../actions';

const initialState: SessionUser = {
  id: null,
  is_terms_and_conditions_accepted: false,
  is_admin: false,
  is_super_admin: false,
  is_market_admin: false,
  google_sync: false,
};

type Actions = RootActions<typeof actions>;

const sessionUserReducer = createReducer<SessionUser, Actions>(initialState, {
  [actions.setSessionUser.type]: (draft, action) => action.payload.sessionUser,
  [actions.logout.type]: () => {
    sessionStorage.removeItem('sidKey');
    return initialState;
  },
});

export default sessionUserReducer;
