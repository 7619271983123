import { get, patch } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_TRANSLATIONS_PATH } from './constants';
import { IdType } from './types';
import { OrganizationTranslation } from './admin/models';

const ORGANIZATION_PATH = 'organization';

export const getOrganizationTranslation = (organizationId: IdType, options: any): Promise<OrganizationTranslation> =>
  get(buildPath(ADMIN_TRANSLATIONS_PATH, ORGANIZATION_PATH, organizationId), options).then(toJson);

export const updateOrganizationTranslation = (organizationId: IdType, options: any): Promise<OrganizationTranslation> =>
  patch(buildPath(ADMIN_TRANSLATIONS_PATH, ORGANIZATION_PATH, organizationId), options).then(toJson);
