import { combineReducers } from 'redux';
import adminUserReducer from './adminUserReducer';
import adminUsersReducer from './adminUsersReducer';
import adminUsersGroupsReducer from './adminUsersGroupsReducer';
import adminUserOrganizationsPermissionsReducer from './adminUserOrganizationsPermissionsReducer';
import adminUserEventsPermissionsReducer from './adminUserEventsPermissionsReducer';
import adminOrgUsersReducer from './adminOrgUsers';
import adminOrgUserInvites from './adminOrgUserInvites';
import adminOrgUserReducer from './adminOrgUser';

export default combineReducers({
  user: adminUserReducer,
  users: adminUsersReducer,
  orgUser: adminOrgUserReducer,
  orgUsers: adminOrgUsersReducer,
  orgUserInvites: adminOrgUserInvites,
  usersGroups: adminUsersGroupsReducer,
  organizationsPermissions: adminUserOrganizationsPermissionsReducer,
  eventsPermissions: adminUserEventsPermissionsReducer,
});
