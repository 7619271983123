import { addDays, endOfWeek, format, startOfWeek, endOfMonth, startOfMonth, addMonths, lastDayOfMonth } from 'date-fns';

export const dateFilterFormat = 'yyyy-M-d';

export const dateCases = [
  'today',
  'tomorrow',
  'thisWeekend',
  'thisWeek',
  'nextWeek',
  'thisMonth',
  'nextMonth',
] as const;

export type DateCasesType = (typeof dateCases)[number];

export type DateElementType = string | string[];

export type DateFilterType = {
  start_at__date__gte: string | undefined;
  start_at__date__lte: string | undefined;
};

export const getDateFilter = <T extends number | Date>(startDate: T, endDate: T): DateFilterType => ({
  start_at__date__gte: startDate ? format(startDate, dateFilterFormat) : undefined,
  start_at__date__lte: endDate ? format(endDate, dateFilterFormat) : undefined,
});

export const caseOptions: Record<DateCasesType, () => DateFilterType> = {
  today: () => getDateFilter(new Date(), new Date()),
  tomorrow: () => getDateFilter(addDays(new Date(), 1), addDays(new Date(), 1)),
  thisWeekend: () => getDateFilter(endOfWeek(new Date()), addDays(endOfWeek(new Date()), 1)),
  thisWeek: () => getDateFilter(startOfWeek(new Date()), endOfWeek(new Date())),
  nextWeek: () => getDateFilter(addDays(startOfWeek(new Date()), 7), addDays(endOfWeek(new Date()), 7)),
  thisMonth: () => getDateFilter(startOfMonth(new Date()), endOfMonth(new Date())),
  nextMonth: () =>
    getDateFilter(addMonths(startOfMonth(new Date()), 1), lastDayOfMonth(addMonths(startOfMonth(new Date()), 1))),
};

export const parseDateFilterValue = (startAt: DateElementType, endAt: DateElementType) => {
  let filterOption = caseOptions.today();
  if (filterOption.start_at__date__gte === startAt && filterOption.start_at__date__lte === endAt) {
    return 'today';
  }

  filterOption = caseOptions.tomorrow();
  if (filterOption.start_at__date__gte === startAt && filterOption.start_at__date__lte === endAt) {
    return 'tomorrow';
  }

  filterOption = caseOptions.thisWeekend();
  if (filterOption.start_at__date__gte === startAt && filterOption.start_at__date__lte === endAt) {
    return 'thisWeekend';
  }

  filterOption = caseOptions.thisWeek();
  if (filterOption.start_at__date__gte === startAt && filterOption.start_at__date__lte === endAt) {
    return 'thisWeek';
  }

  filterOption = caseOptions.nextWeek();
  if (filterOption.start_at__date__gte === startAt && filterOption.start_at__date__lte === endAt) {
    return 'nextWeek';
  }

  filterOption = caseOptions.thisMonth();
  if (filterOption.start_at__date__gte === startAt && filterOption.start_at__date__lte === endAt) {
    return 'thisMonth';
  }

  filterOption = caseOptions.nextMonth();
  if (filterOption.start_at__date__gte === startAt && filterOption.start_at__date__lte === endAt) {
    return 'nextMonth';
  }
  return '';
};
