// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserShort } from '.';

/**
 * @export
 * @interface Report
 */
export interface Report {
  /**
   * @type {number}
   * @memberof Report
   */
  readonly id?: number;
  /**
   * @type {UserShort}
   * @memberof Report
   */
  created_by?: UserShort;
  /**
   * @type {number}
   * @memberof Report
   */
  organization: number;
  /**
   * @type {string}
   * @memberof Report
   */
  report_type: ReportReportTypeEnum;
  /**
   * @type {string}
   * @memberof Report
   */
  readonly report_type_display?: string;
  /**
   * @type {string}
   * @memberof Report
   */
  status: ReportStatusEnum;
  /**
   * @type {string}
   * @memberof Report
   */
  readonly processing_started?: string;
  /**
   * @type {string}
   * @memberof Report
   */
  processing_finished?: string | null;
  /**
   * @type {string}
   * @memberof Report
   */
  date_from: string;
  /**
   * @type {string}
   * @memberof Report
   */
  date_to: string;
  /**
   * @type {string}
   * @memberof Report
   */
  filename?: string;
  /**
   * @type {number}
   * @memberof Report
   */
  size?: number | null;
  /**
   * @type {boolean}
   * @memberof Report
   */
  readonly is_expired?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum ReportReportTypeEnum {
  Orders = 'orders',
}
/**
 * @export
 * @enum {string}
 */
export enum ReportStatusEnum {
  Pending = 'pending',
  Finished = 'finished',
  Failed = 'failed',
}
