import { OrganizationVideoDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationVideoDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationVideosReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationVideos.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminOrganizationVideos.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminOrganizationVideos.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminOrganizationVideos.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminOrganizationVideo.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminOrganizationVideo.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminOrganizationVideo.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminOrganizationVideo.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminOrganizationVideo.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminOrganizationVideo.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminOrganizationVideo.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminOrganizationVideo.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminOrganizationVideo.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminOrganizationVideo.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminOrganizationVideo.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminOrganizationVideosReducer;
