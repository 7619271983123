import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { TrustProfile } from 'api/admin/models/TrustProfile';
import { UpdateTrustProfileParams } from './types';

export const getAdminTrustProfiles = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<TrustProfile>>()(
  'GET_ADMIN_TRUST_PROFILES_REQUEST',
  'GET_ADMIN_TRUST_PROFILES_SUCCESS',
  'GET_ADMIN_TRUST_PROFILES_FAILURE',
  'GET_ADMIN_TRUST_PROFILES_RESET',
);

export const getAdminTrustProfile = actionsFactories.createGetOneActionsFactory<EntityType<TrustProfile>>()(
  'GET_ADMIN_TRUST_PROFILE_REQUEST',
  'GET_ADMIN_TRUST_PROFILE_SUCCESS',
  'GET_ADMIN_TRUST_PROFILE_FAILURE',
  'GET_ADMIN_TRUST_PROFILE_RESET',
);

export const updateAdminTrustProfile = actionsFactories.createUpdateOneActionsFactory<
  EntityType<TrustProfile>,
  UpdateTrustProfileParams
>()(
  'UPDATE_ADMIN_TRUST_PROFILE_REQUEST',
  'UPDATE_ADMIN_TRUST_PROFILE_SUCCESS',
  'UPDATE_ADMIN_TRUST_PROFILE_FAILURE',
  'UPDATE_ADMIN_TRUST_PROFILE_RESET',
);
