import { fork, takeEvery } from 'redux-saga/effects';
import { AdminTranslationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminDonationTranslationRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminDonationTranslation,
  request: AdminTranslationsApi.getAdminDonationTranslation,
  transformResponse: (response, action) => ({
    ...response,
    donation_id: response.id,
    id: action.payload.id,
  }),
});

const handleUpdateAdminDonationTranslationRequest = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.updateAdminDonationTranslation,
  request: AdminTranslationsApi.updateAdminDonationTranslation,
});

export default function* adminDonationTranslationsSagas() {
  yield takeEvery(actions.getAdminDonationTranslation.request.type, handleGetAdminDonationTranslationRequest);
  yield fork(handleUpdateAdminDonationTranslationRequest);
}
