import { EventImageTranslationDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventImageTranslationDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventImageTranslationsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventImageTranslations.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventImageTranslations.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventImageTranslations.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventImageTranslations.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.removeAdminEventImageTranslation.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.removeAdminEventImageTranslation.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.removeAdminEventImageTranslation.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.removeAdminEventImageTranslation.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.replaceAdminEventImageTranslation.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.replaceAdminEventImageTranslation.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.replaceAdminEventImageTranslation.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.replaceAdminEventImageTranslation.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default adminEventImageTranslationsReducer;
