// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ZoomHost } from '.';

/**
 * @export
 * @interface ZoomEvent
 */
export interface ZoomEvent {
  /**
   * @type {number}
   * @memberof ZoomEvent
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof ZoomEvent
   */
  type: ZoomEventTypeEnum;
  /**
   * @type {number}
   * @memberof ZoomEvent
   */
  zoom_id: number;
  /**
   * @type {string}
   * @memberof ZoomEvent
   */
  readonly topic?: string;
  /**
   * @type {number}
   * @memberof ZoomEvent
   */
  readonly approval_type?: number;
  /**
   * @type {string}
   * @memberof ZoomEvent
   */
  readonly join_url?: string;
  /**
   * @type {ZoomHost}
   * @memberof ZoomEvent
   */
  host?: ZoomHost;
}

/**
 * @export
 * @enum {string}
 */
export enum ZoomEventTypeEnum {
  Meeting = 'meeting',
  Webinar = 'webinar',
}
