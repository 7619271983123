import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const userMfaReducer = createReducer<State, Actions>(initialState, {
  [actions.getUserMfa.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getUserMfa.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getUserMfa.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getUserMfa.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.deleteUserMfa.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.toggleActiveUserMfa.success.type]: (draft, action) => {
    const { id } = action.payload.params;
    const item: any = draft.byId[id];

    if (item && item.id === id) {
      item.is_active = action.payload.result.is_active;
    }
  },
});

export default userMfaReducer;
