import { Country } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getCountries = actionsFactories.createGetManyActionsFactory<EntityType<Country>>()(
  'GET_COUNTRIES_REQUEST',
  'GET_COUNTRIES_SUCCESS',
  'GET_COUNTRIES_FAILURE',
  'RESET_COUNTRIES',
);
