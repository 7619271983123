import { MultipleFeatureStateType, SingleFeatureStateType } from './types';

export const createInitialSingleFeatureStateFactory = <R = never>() => {
  function getInitialState(): SingleFeatureStateType<R>;
  function getInitialState<S extends Record<string, unknown>>(additionalState: S): SingleFeatureStateType<R> & S;
  function getInitialState(additionalState = {}) {
    return {
      isPending: false,
      isFulfilled: false,
      result: null as R,
      error: null as Error,
      ...additionalState,
    };
  }

  return {
    getInitialState,
  };
};

export const createInitialMultipleFeatureStateFactory = <R = never>() => {
  function getInitialState(): MultipleFeatureStateType<R>;
  function getInitialState<S extends Record<string, unknown>>(additionalState: S): MultipleFeatureStateType<R> & S;
  function getInitialState(additionalState = {}) {
    return {
      isPending: {},
      isFulfilled: {},
      results: {},
      errors: {},
      ...additionalState,
    };
  }

  return {
    getInitialState,
  };
};
