import { DefaultMetaTagContentType, EventMetaTagDisplay } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventMetaTagsParamsType, UpdateAdminEventMetaTagParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getMetaTagsTypes = actionsFactories.createGetManyActionsFactory<EntityType<DefaultMetaTagContentType>>()(
  'GET_ADMIN_META_TAG_CONTENT_TYPES_REQUEST',
  'GET_ADMIN_META_TAG_CONTENT_TYPES_SUCCESS',
  'GET_ADMIN_META_TAG_CONTENT_TYPES_FAILURE',
  'GET_ADMIN_META_TAG_CONTENT_TYPES_RESET',
  keyWindowNames.adminMetaTagsContentTypes,
);

export const getAdminEventMetaTags = actionsFactories.createGetManyActionsFactory<
  EntityType<EventMetaTagDisplay>,
  GetAdminEventMetaTagsParamsType
>()(
  'GET_ADMIN_EVENT_META_TAGS_REQUEST',
  'GET_ADMIN_EVENT_META_TAGS_SUCCESS',
  'GET_ADMIN_EVENT_META_TAGS_FAILURE',
  'GET_ADMIN_EVENT_META_TAGS_RESET',
  keyWindowNames.adminEventMetaTags,
);

export const createAdminEventMetaTag = actionsFactories.createCreateOneActionsFactory<
  EntityType<EventMetaTagDisplay>,
  UpdateAdminEventMetaTagParamsType
>()(
  'CREATE_ADMIN_EVENT_META_TAG_REQUEST',
  'CREATE_ADMIN_EVENT_META_TAG_SUCCESS',
  'CREATE_ADMIN_EVENT_META_TAG_FAILURE',
  'CREATE_ADMIN_EVENT_META_TAG_RESET',
);

export const runtimeUpdateAdminEventMetaTag = actionsFactories.createUpdateOneActionsFactory<
  EntityType<EventMetaTagDisplay>,
  UpdateAdminEventMetaTagParamsType
>()(
  'RUNTIME_UPDATE_ADMIN_EVENT_META_TAG_REQUEST',
  'RUNTIME_UPDATE_ADMIN_EVENT_META_TAG_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_EVENT_META_TAG_FAILURE',
  'RUNTIME_UPDATE_ADMIN_EVENT_META_TAG_RESET',
);

export const deleteAdminEventMetaTag =
  actionsFactories.createDeleteOneActionsFactory<UpdateAdminEventMetaTagParamsType>()(
    'DELETE_ADMIN_EVENT_META_TAG_REQUEST',
    'DELETE_ADMIN_EVENT_META_TAG_SUCCESS',
    'DELETE_ADMIN_EVENT_META_TAG_FAILURE',
  );
