import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationMetaTagsSlice = (state: RootState) => state.entities.adminOrganizationMetaTags;

export const adminOrganizationMetaTags = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationMetaTagsSlice,
  keyWindowNames.adminOrganizationMetaTags,
);
