import { Draft } from 'immer';
import { AnyFunction } from 'types';
import { MultipleFeatureStateType, SingleFeatureStateType } from './types';
import * as actionsFactories from './actionsFactories';

type SingleFeatureActionsFactory = typeof actionsFactories.createSingleFeatureActionsFactory;
type MultipleFeatureActionsFactory = typeof actionsFactories.createMultipleFeatureActionsFactory;

interface ISingleFeatureHandler<F extends AnyFunction, K extends string> {
  <R, S extends SingleFeatureStateType<R>>(draft: Draft<S>, action: ReturnType<ReturnType<ReturnType<F>>[K]>): S | void;
}

interface IMultipleFeatureHandler<F extends AnyFunction, K extends string> {
  <R, S extends MultipleFeatureStateType<R>>(
    draft: Draft<S>,
    action: ReturnType<ReturnType<ReturnType<F>>[K]>,
  ): S | void;
}

export const handleSingleFeatureRequest: ISingleFeatureHandler<SingleFeatureActionsFactory, 'request'> = (
  draft,
  action,
) => {
  draft.error = null;
  draft.isFulfilled = false;
  if (!action.meta.silent) draft.isPending = true;
};

export const handleSingleFeatureSuccess: ISingleFeatureHandler<SingleFeatureActionsFactory, 'success'> = (
  draft,
  action,
) => {
  draft.isPending = false;
  draft.isFulfilled = true;
  if (action.payload.result) (draft.result as any) = action.payload.result;
};

export const handleSingleFeatureFailure: ISingleFeatureHandler<SingleFeatureActionsFactory, 'failure'> = (
  draft,
  action,
) => {
  draft.isPending = false;
  draft.isFulfilled = true;
  draft.error = action.error;
  (draft.result as any) = null;
};

export const handleSingleFeatureReset: ISingleFeatureHandler<SingleFeatureActionsFactory, 'reset'> = (draft) => {
  draft.isPending = false;
  draft.isFulfilled = false;
  draft.error = null;
  (draft.result as any) = null;
};

export const handleMultipleFeatureRequest: IMultipleFeatureHandler<MultipleFeatureActionsFactory, 'request'> = (
  draft,
  action,
) => {
  delete draft.errors[action.payload.id];
  draft.isFulfilled[action.payload.id] = false;
  if (!action.meta.silent) draft.isPending[action.payload.id] = true;
};

export const handleMultipleFeatureSuccess: IMultipleFeatureHandler<MultipleFeatureActionsFactory, 'success'> = (
  draft,
  action,
) => {
  draft.isPending[action.payload.id] = false;
  draft.isFulfilled[action.payload.id] = true;
  if (action.payload.result) (draft.results[action.payload.id] as any) = action.payload.result;
};

export const handleMultipleFeatureFailure: IMultipleFeatureHandler<MultipleFeatureActionsFactory, 'failure'> = (
  draft,
  action,
) => {
  draft.isPending[action.payload.id] = false;
  draft.isFulfilled[action.payload.id] = true;
  draft.errors[action.payload.id] = action.error;
  (draft.results[action.payload.id] as any) = null;
};

export const handleMultipleFeatureReset: IMultipleFeatureHandler<MultipleFeatureActionsFactory, 'reset'> = (
  draft,
  action,
) => {
  draft.isPending[action.payload.id] = false;
  draft.isFulfilled[action.payload.id] = false;
  draft.errors[action.payload.id] = null;
  (draft.results[action.payload.id] as any) = null;
};
