import { call, fork, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { addDays } from 'date-fns';
import { MarketsApi } from 'api';
import { UserActions } from 'store/actions';
import { sagasHandlersFactory } from 'store/features/utils';
import createSessionUserWatcher from 'store/features/auth/utils/createSessionUserWatcher';
import { GetAction } from 'store/types';
import { getParsedCookie, serializeCookie, setSerializedCookie } from 'utils/cookie';
import * as actions from './actions';
import * as selectors from './selectors';

const COOKIE_NAME = 'market';

function* clearSelectedMarket(): any {
  const next: any = yield getContext('next');
  const serializedCookie = serializeCookie(COOKIE_NAME, '', {
    maxAge: 0,
    path: '/',
  });

  setSerializedCookie(next, serializedCookie);
}

function* setSelectedMarket(slug: string): any {
  const next: any = yield getContext('next');
  const serializedCookie = serializeCookie(COOKIE_NAME, slug, {
    expires: addDays(new Date(), 3),
    path: '/',
  });
  clearSelectedMarket();
  setSerializedCookie(next, serializedCookie);
}

function* initSelectedMarket(): any {
  const next: any = yield getContext('next');
  const { [COOKIE_NAME]: market } = yield call(getParsedCookie, next);
  if (market) {
    yield put(actions.setSelectedMarket(market));
  }
}

function* handleSelectMarket(action: GetAction<typeof actions.selectMarket>) {
  yield call(setSelectedMarket, action.payload.market);
}

const watchUserMarket = createSessionUserWatcher({
  fork: function* syncUserMarket(sessionUser): any {
    const isGetUser = (action: any) => action.type === UserActions.getUserDetail.success.type;
    const isUpdateUser = (action: any) => action.type === UserActions.partialUpdateUser.success.type;
    const isCurrentUser = (action: any) => action.payload.entity.id === sessionUser.id;

    function* updateSelectedMarket(action: any): any {
      const market = yield select(selectors.getSelectedMarket);
      const { primary_city } = action.payload.entity;
      if (primary_city && primary_city.slug !== market && !(market && isGetUser(action))) {
        yield put(actions.selectMarket(primary_city.slug));
      }
    }

    yield takeEvery(
      (action: any) => (isGetUser(action) || isUpdateUser(action)) && isCurrentUser(action),
      updateSelectedMarket,
    );
  },
});

const handleDetectMarketRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.detectMarket,
  request: MarketsApi.detectMarket,
});

export default function* detectMarketSaga() {
  yield call(initSelectedMarket);
  yield fork(watchUserMarket);

  yield takeEvery(actions.detectMarket.request.type, handleDetectMarketRequest);
  yield takeEvery(actions.selectMarket.type, handleSelectMarket);
  yield takeEvery(actions.resetSelectedMarket.type, clearSelectedMarket);
}
