import { OrderAdmin } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/features/utils';
import { RootActions } from 'store/types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialMultipleFeatureStateFactory<EntityType<OrderAdmin>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const fullRefundAdminOrderReducer = createReducer<State, Actions>(initialState, {
  [actions.fullRefundAdminOrder.request.type]: actionsHandlers.handleMultipleFeatureRequest,
  [actions.fullRefundAdminOrder.success.type]: actionsHandlers.handleMultipleFeatureSuccess,
  [actions.fullRefundAdminOrder.failure.type]: actionsHandlers.handleMultipleFeatureFailure,
  [actions.fullRefundAdminOrder.reset.type]: actionsHandlers.handleMultipleFeatureReset,
});

export default fullRefundAdminOrderReducer;
