import { EventTranslation } from 'api/admin/models';
import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as AdminEventsSelectors from 'store/entities/adminEvents/selectors';
import { getTranslationName } from 'utils/format';
import {
  eventAddressFieldsNames,
  eventDetailsFieldsNames,
  eventVirtualFieldsNames,
  orderItemFieldsNames,
  questionFieldsNames,
  questionOptionFieldsNames,
} from './fieldsNames';

export const defaultLanguage = 'en';
export const translationLanguage = 'fr';

export const createMissedTranslationsCounter = (fieldsNames: string[] | readonly string[]) => (translation: any) =>
  (fieldsNames as string[]).reduce((missedTranslation, fieldName) => {
    if (!translation) return 0;
    return (
      missedTranslation +
      Number(
        translation[getTranslationName(fieldName, defaultLanguage)] &&
          !translation[getTranslationName(fieldName, translationLanguage)],
      )
    );
  }, 0);

export const createHasTranslations =
  (fieldsNames: string[] | readonly string[], language: string) => (translation: any) =>
    Boolean(translation) && fieldsNames.some((fieldName) => translation[getTranslationName(fieldName, language)]);

const createMissedOrderItemsTranslationsCounter = (orderItemsType: 'tickets' | 'addons') => {
  const counter = createMissedTranslationsCounter(orderItemFieldsNames);
  return (eventTranslation: EventTranslation) => {
    if (!eventTranslation) return 0;
    return eventTranslation[orderItemsType].reduce(
      (missedTranslations, translation) => missedTranslations + counter(translation),
      0,
    );
  };
};

const createHasOrderItemsTranslations = (orderItemsType: 'tickets' | 'addons', language: string) => {
  const hasTranslations = createHasTranslations(orderItemFieldsNames, language);
  return (eventTranslation: EventTranslation) =>
    Boolean(eventTranslation) && eventTranslation[orderItemsType].some(hasTranslations);
};

const missedQuestionTranslationsCounter = createMissedTranslationsCounter(questionFieldsNames);

const missedQuestionOptionTranslationsCounter = createMissedTranslationsCounter(questionOptionFieldsNames);

const hasQuestionDefaultLanguageTranslations = createHasTranslations(questionFieldsNames, defaultLanguage);

const hasQuestionOptionDefaultLanguageTranslations = createHasTranslations(questionOptionFieldsNames, defaultLanguage);

export const adminEventTranslationsSlice = (state: RootState) => state.entities.adminEventTranslations;

export const adminEventTranslationById = selectorsFactory.createEntityByIdSelector(adminEventTranslationsSlice);

export const adminEventTranslationStateById =
  selectorsFactory.createEntityStateByIdSelector(adminEventTranslationsSlice);

export const getMissedAdminEventDetailsTranslations = createSelector(
  adminEventTranslationById,
  createMissedTranslationsCounter(eventDetailsFieldsNames),
);

export const getMissedAdminEventAddressTranslations = createSelector(
  adminEventTranslationById,
  createMissedTranslationsCounter(eventAddressFieldsNames),
);

export const getMissedAdminEventVirtualTranslations = createSelector(
  adminEventTranslationById,
  createMissedTranslationsCounter(eventVirtualFieldsNames),
);

export const hasAdminEventDetailsDefaultLanguageTranslations = createSelector(
  adminEventTranslationById,
  createHasTranslations(eventDetailsFieldsNames, defaultLanguage),
);

export const hasAdminEventAddressDefaultLanguageTranslations = createSelector(
  adminEventTranslationById,
  createHasTranslations(eventAddressFieldsNames, defaultLanguage),
);

export const hasAdminEventVirtualDefaultLanguageTranslations = createSelector(
  adminEventTranslationById,
  createHasTranslations(eventVirtualFieldsNames, defaultLanguage),
);

export const getMissedAdminEventTranslations = createSelector(
  AdminEventsSelectors.adminEventById,
  getMissedAdminEventDetailsTranslations,
  getMissedAdminEventAddressTranslations,
  getMissedAdminEventVirtualTranslations,
  (event, missedEventDetailsTranslations, missedEventAddressTranslations, missedEventVirtualTranslations) =>
    missedEventDetailsTranslations +
    (event?.has_location ? missedEventAddressTranslations : 0) +
    (event?.is_virtual ? missedEventVirtualTranslations : 0),
);

export const hasAdminEventDefaultLanguageTranslations = createSelector(
  AdminEventsSelectors.adminEventById,
  hasAdminEventDetailsDefaultLanguageTranslations,
  hasAdminEventAddressDefaultLanguageTranslations,
  hasAdminEventVirtualDefaultLanguageTranslations,
  (
    event,
    hasAdminEventDetailsDefaultLanguageTranslations,
    hasAdminEventAddressDefaultLanguageTranslations,
    hasAdminEventVirtualDefaultLanguageTranslations,
  ) =>
    hasAdminEventDetailsDefaultLanguageTranslations ||
    (event?.has_location && hasAdminEventAddressDefaultLanguageTranslations) ||
    (event?.is_virtual && hasAdminEventVirtualDefaultLanguageTranslations),
);

export const getMissedAdminTicketsTranslations = createSelector(
  adminEventTranslationById,
  createMissedOrderItemsTranslationsCounter('tickets'),
);

export const getMissedAdminAddonsTranslations = createSelector(
  adminEventTranslationById,
  createMissedOrderItemsTranslationsCounter('addons'),
);

export const hasAdminTicketsDefaultTranslations = createSelector(
  adminEventTranslationById,
  createHasOrderItemsTranslations('tickets', defaultLanguage),
);

export const hasAdminAddonsDefaultTranslations = createSelector(
  adminEventTranslationById,
  createHasOrderItemsTranslations('addons', defaultLanguage),
);

// todo: research conflict with translations types
export const getMissedAdminQuestionsTranslations = createSelector(
  adminEventTranslationById,
  (eventTranslation: EventTranslation) => {
    if (!eventTranslation) return 0;
    return eventTranslation.questions.reduce(
      (missedTranslations, questionTranslation: any) =>
        missedTranslations +
        missedQuestionTranslationsCounter(questionTranslation) +
        questionTranslation.options.reduce(
          (missedTranslations: any, questionOptionTranslation: any) =>
            missedTranslations + missedQuestionOptionTranslationsCounter(questionOptionTranslation),
          0,
        ),
      0,
    );
  },
);

export const hasAdminQuestionsDefaultTranslations = createSelector(
  adminEventTranslationById,
  (eventTranslation: EventTranslation) =>
    Boolean(eventTranslation) &&
    eventTranslation.questions.some(
      (questionTranslation: any) =>
        hasQuestionDefaultLanguageTranslations(questionTranslation) ||
        questionTranslation.options.some((questionOptionTranslation: any) =>
          hasQuestionOptionDefaultLanguageTranslations(questionOptionTranslation),
        ),
    ),
);
