import { get, post, put } from './client';
import { buildPath, toJson } from './utils';
import { NOT_COMPLETED_ORDER_DATA_PATH } from './constants';
import { IdType } from './types';

export const getNotCompletedOrders = (options: any): Promise<any> =>
  get(buildPath(NOT_COMPLETED_ORDER_DATA_PATH), options).then(toJson);

export const getNotCompletedOrder = (id: IdType, options: any): Promise<any> =>
  get(buildPath(NOT_COMPLETED_ORDER_DATA_PATH, id), options).then(toJson);

export const createNotCompletedOrder = (options: any): Promise<any> =>
  post(buildPath(NOT_COMPLETED_ORDER_DATA_PATH), options).then(toJson);

export const updateNotCompletedOrder = (id: IdType, options: any): Promise<any> =>
  put(buildPath(NOT_COMPLETED_ORDER_DATA_PATH, id), options).then(toJson);
