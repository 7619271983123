import { Admin } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Admin>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrgUsersReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrgUsers.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrgUsers.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrgUsers.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrgUsers.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default adminOrgUsersReducer;
