import { del, get, patch, post, put } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_EVENTS_PATH, API_ADMIN_URL } from './constants';
import { CCListType, GeneralListType, IdType, Options } from './types';
import {
  AdditionalInstructionAdmin,
  AddOnCreate,
  AddOnUpdate,
  AdminAddOnDetails,
  AdminTicketDetails,
  Attendee,
  AudienceAggregatedData,
  Donation,
  DonationOption,
  EventDisplayList,
  EventImageDisplay,
  EventMetaTag,
  EventMetaTagDisplay,
  EventOrganizationMentionCreateUpdate,
  EventOrganizationMentionDisplayBase,
  EventRegistrantsEmailMessageCreate,
  EventRegistrantsEmailMessageDisplay,
  EventRegistrationAggregatedData,
  EventReminder,
  EventSettings,
  EventVideoDisplay,
  OfflineOrderCheckout,
  OrderAdmin,
  OrderAdminDetail,
  OrderFullRefundRequest,
  OrderPartialRefundRequest,
  OrdersAggregatedData,
  TicketCreate,
  TicketUpdate,
} from './admin/models';
import { AttendeeDetails } from './admin/models/AttendeeDetails';

const TICKETS_PATH = 'tickets';
const ADDONS_PATH = 'addons';
const ATTENDEE = 'attendee';
const ATTENDEES = 'attendees';
const CHECK_IN = 'check-in';
const UNCHECK_IN = 'uncheck-in';
const IMAGES = 'images';
const VIDEOS = 'videos';
const REGISTRATION_STATS = 'registration-stats';
const ORDERS = 'orders';
const AGGREGATED_DATA = 'aggregated-data';
const SETTINGS = 'settings';
const REMINDERS = 'reminders';
const FULL_REFUND = 'full-refund';
const PARTIAL_REFUND = 'partial-refund';
const ORGANIZATION_MENTIONS = 'organization-mentions';
const RESEND_EMAIL = 'resend-email';
const META_TAGS = 'meta-tags';
const META_TAGS_V2 = 'meta-tags-v2';
const ALL = 'all';
const ADDITIONAL_INSTRUCTIONS = 'additional-instructions';
const DONATION = 'donation';
const OPTIONS = 'options';
const REGISTRANTS_EMAILS = 'registrants-emails';
const REGISTRANTS_STATS = 'registrants-stats';
const PAY_OFFLINE = 'pay-offline';
const SPONSORS = 'sponsors';
const CC = 'cc';
const TICKETS = 'tickets';
const CANCEL = 'cancel';

export const getTickets = (eventId: IdType, options: any): Promise<AdminTicketDetails[]> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, TICKETS_PATH), options).then(toJson);

export const getAddons = (eventId: IdType, options: any): Promise<AdminAddOnDetails[]> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ADDONS_PATH), options).then(toJson);

export const getImages = (eventId: IdType, options: any): Promise<EventImageDisplay> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, IMAGES), options).then(toJson);

export const createImage = (eventId: IdType, options: any): Promise<EventImageDisplay> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, IMAGES), options).then(toJson);

export const updateImage = (eventId: IdType, imageId: IdType, options: any): Promise<EventImageDisplay> =>
  put(buildPath(ADMIN_EVENTS_PATH, eventId, IMAGES, imageId), options).then(toJson);

export const deleteImage = (eventId: IdType, imageId: IdType, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, IMAGES, imageId), options);

export const getVideos = (eventId: IdType, options: any): Promise<EventVideoDisplay> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, VIDEOS), options).then(toJson);

export const createVideo = (eventId: IdType, options: any): Promise<EventVideoDisplay> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, VIDEOS), options).then(toJson);

export const updateVideo = (eventId: IdType, videoId: IdType, options: any): Promise<EventVideoDisplay> =>
  put(buildPath(ADMIN_EVENTS_PATH, eventId, VIDEOS, videoId), options).then(toJson);

export const deleteVideo = (eventId: IdType, videoId: IdType, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, VIDEOS, videoId), options);

export const createTicket = (eventId: IdType, options: any): Promise<TicketCreate> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, TICKETS_PATH), options).then(toJson);

export const createAddon = (eventId: IdType, options: any): Promise<AddOnCreate> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ADDONS_PATH), options).then(toJson);

export const partialUpdateTicket = (
  eventId: IdType,
  ticketId: IdType,
  options: Options<TicketUpdate>,
): Promise<AdminTicketDetails> =>
  patch(buildPath(ADMIN_EVENTS_PATH, eventId, TICKETS_PATH, ticketId), options).then(toJson);

export const partialUpdateAddon = (
  eventId: IdType,
  addonId: IdType,
  options: Options<AddOnUpdate>,
): Promise<AdminAddOnDetails> =>
  patch(buildPath(ADMIN_EVENTS_PATH, eventId, ADDONS_PATH, addonId), options).then(toJson);

export const deleteTicket = (eventId: IdType, ticketId: IdType, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, TICKETS_PATH, ticketId), options);

export const deleteAddon = (eventId: IdType, addonId: IdType, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, ADDONS_PATH, addonId), options);

export const getAttendees = (eventId: IdType, options: any): Promise<GeneralListType<Attendee>> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ATTENDEES), options).then(toJson);

export const getAttendee = (eventId: IdType, id: IdType, options: any): Promise<Attendee> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ATTENDEES, id), options).then(toJson);

export const getAttendeeDetails = (id: string, options: any): Promise<AttendeeDetails> =>
  get(buildPath(API_ADMIN_URL, ATTENDEE, id), options).then(toJson);

export const getAttendeeTickets = (passport_hash: string, options: any): Promise<GeneralListType<Attendee>> =>
  get(buildPath(API_ADMIN_URL, ATTENDEE, TICKETS, passport_hash), options).then(toJson);

export const getRegistrationStats = (eventId: IdType, options: any): Promise<EventRegistrationAggregatedData> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, REGISTRATION_STATS), options).then(toJson);

export const attendeeCheckIn = (
  eventId: IdType,
  orderId: IdType,
  attendeeId: IdType,
  options: any,
): Promise<Attendee> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, orderId, ATTENDEES, attendeeId, CHECK_IN), options).then(toJson);

export const attendeeUncheckIn = (
  eventId: IdType,
  orderId: IdType,
  attendeeId: IdType,
  options: any,
): Promise<Attendee> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, orderId, ATTENDEES, attendeeId, UNCHECK_IN), options).then(toJson);

export const attendeeCancelTicket = (
  eventId: IdType,
  orderId: IdType,
  attendeeId: IdType,
  options: any,
): Promise<Attendee> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, orderId, ATTENDEES, attendeeId, CANCEL), options).then(toJson);

export const getAdminOrdersStats = (eventId: IdType, options: any): Promise<OrdersAggregatedData> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, AGGREGATED_DATA), options).then(toJson);

export const getAdminOrders = (eventId: IdType, options: any): Promise<OrderAdmin> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS), options).then(toJson);

export const getAdminOrder = (eventId: IdType, orderId: IdType, options: any): Promise<OrderAdminDetail> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, orderId), options).then(toJson);

export const payOffline = (eventId: IdType, orderId: IdType, options: any): Promise<OfflineOrderCheckout> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, orderId, PAY_OFFLINE), options).then(toJson);

export const getAdminEventSettings = (eventId: IdType, options: any): Promise<EventSettings> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, SETTINGS), options).then(toJson);

export const partialUpdateAdminEventSettings = (
  eventId: IdType,
  options: Options<EventSettings>,
): Promise<EventSettings> => patch(buildPath(ADMIN_EVENTS_PATH, eventId, SETTINGS), options).then(toJson);

export const getAdminEventReminders = (eventId: IdType, options: any): Promise<EventReminder> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, REMINDERS), options).then(toJson);

export const getAdminEventAdditionalInstructions = (
  eventId: IdType,
  options: any,
): Promise<GeneralListType<AdditionalInstructionAdmin>> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ADDITIONAL_INSTRUCTIONS), options).then(toJson);

export const postAdminEventAdditionalInstructions = (
  eventId: IdType,
  options: any,
): Promise<AdditionalInstructionAdmin> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ADDITIONAL_INSTRUCTIONS), options).then(toJson);

export const updateAdminEventAdditionalInstructions = (
  eventId: IdType,
  id: IdType,
  options: any,
): Promise<AdditionalInstructionAdmin> =>
  patch(buildPath(ADMIN_EVENTS_PATH, eventId, ADDITIONAL_INSTRUCTIONS, id), options).then(toJson);

export const deleteAdminEventAdditionalInstructions = (eventId: IdType, id: IdType, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, ADDITIONAL_INSTRUCTIONS, id), options);

export const partialUpdateAdminEventReminder = (
  eventId: IdType,
  reminderId: IdType,
  options: any,
): Promise<EventReminder> => patch(buildPath(ADMIN_EVENTS_PATH, eventId, REMINDERS, reminderId), options).then(toJson);

export const fullRefundAdminOrder = (eventId: IdType, orderId: IdType, options: any): Promise<OrderFullRefundRequest> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, orderId, FULL_REFUND), options).then(toJson);

export const partialRefundAdminOrder = (
  eventId: IdType,
  orderId: IdType,
  options: any,
): Promise<OrderPartialRefundRequest> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, orderId, PARTIAL_REFUND), options).then(toJson);

export const getOrganizationMentions = (eventId: IdType, options: any): Promise<EventOrganizationMentionDisplayBase> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ORGANIZATION_MENTIONS), options).then(toJson);

export const deleteOrganizationMention = (eventId: IdType, organizationMentionId: IdType, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, ORGANIZATION_MENTIONS, organizationMentionId), options);

export const addOrganizationMention = (eventId: IdType, options: any): Promise<EventOrganizationMentionCreateUpdate> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ORGANIZATION_MENTIONS), options).then(toJson);

export const resendEmail = (eventId: IdType, orderId: IdType, options: any) =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, ORDERS, orderId, RESEND_EMAIL), options);

export const getAdminEventMetaTags = (eventId: IdType, options: any): Promise<EventMetaTagDisplay> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, META_TAGS_V2), options).then(toJson);

export const postAdminEventMetaTag = (eventId: IdType, options: any): Promise<EventMetaTag> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, META_TAGS_V2), options).then(toJson);

export const updateAdminEventMetaTag = (eventId: IdType, tagId: IdType, options: any): Promise<EventMetaTag> =>
  put(buildPath(ADMIN_EVENTS_PATH, eventId, META_TAGS, tagId), options).then(toJson);

export const partialUpdateAdminEventMetaTag = (eventId: IdType, tagId: IdType, options: any): Promise<EventMetaTag> =>
  patch(buildPath(ADMIN_EVENTS_PATH, eventId, META_TAGS_V2, tagId), options).then(toJson);

export const deleteAdminEventMetaTag = (eventId: IdType, tagId: IdType, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, META_TAGS, tagId), options);

export const getAdminAllEvents = (eventId: IdType, options: any): Promise<GeneralListType<EventDisplayList>> =>
  get(buildPath(ADMIN_EVENTS_PATH, ALL), options).then(toJson);

export const getAdminAllCCEvents = (eventId: IdType, options: any): Promise<CCListType<EventDisplayList>> =>
  get(buildPath(ADMIN_EVENTS_PATH, ALL, CC), options).then(toJson);

export const getAdminDonation = (eventId: IdType, options: any): Promise<Donation> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, DONATION), options).then(toJson);

export const getAdminDonationOptions = (eventId: IdType, options: any): Promise<DonationOption> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, DONATION, OPTIONS), options).then(toJson);

export const partialUpdateAdminDonation = (eventId: IdType, options: any): Promise<Donation> =>
  patch(buildPath(ADMIN_EVENTS_PATH, eventId, DONATION), options).then(toJson);

export const partialUpdateDonationOptions = (eventId: IdType, id: IdType, options: any): Promise<DonationOption> =>
  patch(buildPath(ADMIN_EVENTS_PATH, eventId, DONATION, OPTIONS, id), options).then(toJson);

export const createDonationOptions = (eventId: IdType, options: any): Promise<DonationOption> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, DONATION, OPTIONS), options).then(toJson);

export const deleteDonationOptions = (eventId: IdType, id: IdType, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, DONATION, OPTIONS, id), options);

export const getAdminEventRegistrantsEmails = (
  eventId: IdType,
  options: any,
): Promise<EventRegistrantsEmailMessageDisplay> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, REGISTRANTS_EMAILS), options).then(toJson);

export const createAdminEventRegistrantsEmails = (
  eventId: IdType,
  options: any,
): Promise<EventRegistrantsEmailMessageCreate> =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, REGISTRANTS_EMAILS), options).then(toJson);

export const getAdminEventRegistrantsStats = (eventId: IdType, options: any): Promise<AudienceAggregatedData> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, REGISTRANTS_EMAILS, REGISTRANTS_STATS), options).then(toJson);

export const getAdminEventRegistrantsEmail = (
  eventId: IdType,
  emailId: IdType,
  options: any,
): Promise<EventRegistrantsEmailMessageDisplay> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, REGISTRANTS_EMAILS, emailId), options).then(toJson);

export const getAdminSponsors = (eventId: IdType, options: any) =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, SPONSORS), options).then(toJson);

export const createSponsor = (eventId: IdType, options: any) =>
  post(buildPath(ADMIN_EVENTS_PATH, eventId, SPONSORS), options).then(toJson);

export const updateSponsor = (eventId: IdType, id: number, options: any) =>
  patch(buildPath(ADMIN_EVENTS_PATH, eventId, SPONSORS, id), options).then(toJson);

export const deleteSponsor = (eventId: IdType, id: number, options: any) =>
  del(buildPath(ADMIN_EVENTS_PATH, eventId, SPONSORS, id), options);
