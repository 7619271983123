import React from 'react';
import { NextComponentType } from 'next';
import { AppContext, AppInitialProps, AppProps } from 'next/app';
import Head from 'next/head';

import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { END } from 'redux-saga';
import { setLocale } from 'yup';
import { appWithTranslation } from 'i18n';
import { wrapper } from 'store/makeStore';
import { isServer } from 'utils/next';
import { routes } from 'utils/routing';
import locale from 'utils/locale';
import theme from 'theme';
import { Provider } from 'react-redux';
import { CacheProvider } from '@emotion/react';

import renderGtagScript from 'components/scripts/renderGtagScript';
import renderFacebookScript from 'components/scripts/renderFacebookScript';
import NotificationManager from 'components/common/NotificationManager';
import { ModalProvider } from 'components/common/ModalManager';
import { RollbarProvider } from 'components/common/Rollbar';
import { FirebaseProvider } from 'components/common/Firebase';
import { useExcludedScripts } from 'hooks/useExcludedScripts';
import createEmotionCache from 'utils/createEmotionCache';
import renderMixpanelScript from 'components/scripts/renderMixpanelScript';
import renderUserWayScript from 'components/scripts/renderUserWayScript';
import renderSpreedlyScript from 'components/scripts/renderSpreedlyScript';
import { useIncludedScripts } from '../hooks/useIncludedScripts';
import useMedian from '../hooks/useMedian';

setLocale(locale);
const clientSideEmotionCache = createEmotionCache();

const excludedPaths = [routes.eventOrderPaymentMethod(0).href.pathname, routes.userPaymentMethods().href.pathname];
const includedPaths = [routes.eventOrderPaymentMethod(0).href.pathname, routes.userPaymentMethods().link];

const CustomApp: NextComponentType<AppContext, AppInitialProps, AppProps> = ({ Component, router, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { emotionCache = clientSideEmotionCache, pageProps } = props;
  const { isMedian } = useMedian();
  const excluded = useExcludedScripts(router.pathname, excludedPaths);
  const included = useIncludedScripts(router.pathname, includedPaths);

  return (
    <>
      <Head>
        <title>JLive</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width user-scalable=0" />
        {excluded || renderGtagScript()}
        {excluded || renderFacebookScript()}
        {renderMixpanelScript()}
        {excluded || (!isMedian && renderUserWayScript())}
        {included && renderSpreedlyScript()}
      </Head>
      <Provider store={store}>
        <CacheProvider value={emotionCache}>
          <RollbarProvider>
            <FirebaseProvider disableInitialization={excluded}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <NotificationManager maxSnacks={3} />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ModalProvider>
                      <Component {...pageProps} />
                    </ModalProvider>
                  </LocalizationProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </FirebaseProvider>
          </RollbarProvider>
        </CacheProvider>
      </Provider>
    </>
  );
};

// todo change to getServerSide
CustomApp.getInitialProps = wrapper.getInitialAppProps((store) => async (appContext: AppContext) => {
  const { Component, ctx } = appContext;

  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({ ...ctx, store });
  }

  if (isServer()) {
    store.dispatch(END);
    await store.sagaTask.toPromise();
  }

  return {
    pageProps,
  };
});

export default appWithTranslation(CustomApp);
