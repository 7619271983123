import { fork, takeEvery } from 'redux-saga/effects';
import { AdminContentTypesApi, AdminEventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminEventMetaTagsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventMetaTags,
  request: AdminEventsApi.getAdminEventMetaTags,
  requestArgsBuilder: (action) => {
    const { eventId } = action.payload.params;
    return [eventId, { params: { limit: 100 } }];
  },
});

const handleCreateAdminEventMetaTagRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminEventMetaTag,
  request: AdminEventsApi.postAdminEventMetaTag,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, { body: entity }];
  },
});

const handleDeleteAdminEventMetaTagRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEventMetaTag,
  request: AdminEventsApi.deleteAdminEventMetaTag,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

const handleRuntimeUpdateAdminEventMetaTag = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminEventMetaTag,
  request: AdminEventsApi.partialUpdateAdminEventMetaTag,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

const handleGetEventMetaTagsContentTypesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getMetaTagsTypes,
  request: AdminContentTypesApi.getMetaTagsContentTypes,
  requestArgsBuilder: () => [{ params: { limit: 100 } }],
  transformResponse: (response) => response.results,
});

export default function* adminEventSettingsSagas() {
  yield takeEvery(actions.getAdminEventMetaTags.request.type, handleGetAdminEventMetaTagsRequest);
  yield takeEvery(actions.createAdminEventMetaTag.request.type, handleCreateAdminEventMetaTagRequest);
  yield takeEvery(actions.deleteAdminEventMetaTag.request.type, handleDeleteAdminEventMetaTagRequest);
  yield takeEvery(actions.getMetaTagsTypes.request.type, handleGetEventMetaTagsContentTypesRequest);
  yield fork(handleRuntimeUpdateAdminEventMetaTag);
}
