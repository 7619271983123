import { OrganizationDetail } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import { GetSearchOrganizationsParamsType } from './types';

export const getOrganization = actionsFactories.createGetOneActionsFactory<EntityType<OrganizationDetail>>()(
  'GET_ORGANIZATION_REQUEST',
  'GET_ORGANIZATION_SUCCESS',
  'GET_ORGANIZATION_FAILURE',
  'RESET_ORGANIZATION',
);

export const getSearchOrganizations = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<OrganizationDetail>,
  GetSearchOrganizationsParamsType
>()(
  'GET_SEARCH_ORGANIZATIONS_REQUEST',
  'GET_SEARCH_ORGANIZATIONS_SUCCESS',
  'GET_SEARCH_ORGANIZATIONS_FAILURE',
  'GET_SEARCH_ORGANIZATIONS_RESET',
  keyWindowNames.searchOrganizations,
);

export const getOrganizations = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<OrganizationDetail>>()(
  'GET_ORGANIZATIONS_REQUEST',
  'GET_ORGANIZATIONS_SUCCESS',
  'GET_ORGANIZATIONS_FAILURE',
  'GET_ORGANIZATIONS_RESET',
  keyWindowNames.getOrganizations,
);
