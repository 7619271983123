import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const notCompletedOrdersSlice = (state: RootState) => state.entities.notCompletedOrders;

export const notCompletedOrdersState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(notCompletedOrdersSlice);

export const notCompletedOrdersPagination =
  selectorsFactory.createStaticKeyWindowPaginationSelector(notCompletedOrdersSlice);
