import { call, put, select, takeEvery } from 'redux-saga/effects';
import { GetAction } from 'store/types';
import { CreatingOrderActions, EventsActions } from 'store/actions';
import { CreatingOrderSelectors } from 'store/selectors';
import { OrderItemStringType } from 'store/features/creatingOrder/types';
import { isServer } from 'utils/next';
import {
  GtagEventName,
  sendGtagEvent,
  createEcommerceItem,
  createEcommerceItemMapper,
  createEcommerceItemData,
} from 'utils/gtag';

import * as actions from './actions';
import * as selectors from './selectors';

function* createDefaultEcommerce(): any {
  const eventEntity = yield select(CreatingOrderSelectors.getEventEntity);
  const ticketEntities = yield select(CreatingOrderSelectors.currentTicketEntities);
  const addonEntities = yield select(CreatingOrderSelectors.currentAddonEntities);
  const ticketItemsCounts = yield select(CreatingOrderSelectors.getOrderItemsCounts, 'ticket');
  const addonItemsCounts = yield select(CreatingOrderSelectors.getOrderItemsCounts, 'addon');
  const value = yield select(CreatingOrderSelectors.getTotalPrice);
  const tax = yield select(CreatingOrderSelectors.getTotalTaxes);
  const coupon = yield select(CreatingOrderSelectors.getPromoCode);

  return {
    affiliation: '',
    value,
    tax,
    shipping: 0,
    currency: eventEntity.default_currency,
    coupon,
    items: [
      ...ticketEntities.map(createEcommerceItemMapper(eventEntity, ticketItemsCounts)),
      ...addonEntities.map(createEcommerceItemMapper(eventEntity, addonItemsCounts)),
    ],
  };
}

function* sendOrderItemEvent(event: GtagEventName, itemType: OrderItemStringType, id: number, count?: number): any {
  const eventEntity = yield select(CreatingOrderSelectors.getEventEntity);
  const orderEntity = yield select(CreatingOrderSelectors.getOrderEntity, itemType, id);
  yield call(sendGtagEvent, event, createEcommerceItemData(eventEntity, orderEntity, count));
}

function* handleAddOrderItem(action: GetAction<typeof CreatingOrderActions.addOrderItem>): any {
  yield call(
    sendOrderItemEvent,
    GtagEventName.add_to_cart,
    action.payload.itemType,
    action.payload.id,
    action.payload.count,
  );
}

function* handleRemoveOrderItem(action: GetAction<typeof CreatingOrderActions.addOrderItem>): any {
  yield call(sendOrderItemEvent, GtagEventName.remove_from_cart, action.payload.itemType, action.payload.id);
}

function* handleInitRegistration(): any {
  const ecommerce = yield call(createDefaultEcommerce);
  yield call(sendGtagEvent, GtagEventName.begin_checkout, ecommerce);
}

function* handleSelectStripePaymentMethod(): any {
  const paymentMethod = yield select(CreatingOrderSelectors.getSelectedStripePaymentMethod);
  const ecommerce = yield call(createDefaultEcommerce);

  yield call(sendGtagEvent, GtagEventName.add_payment_info, {
    payment_type: paymentMethod.type,
    ...ecommerce,
  });
}

function* handleCreateOrderSuccess(action: GetAction<typeof CreatingOrderActions.createOrder.success>): any {
  const ecommerce = yield call(createDefaultEcommerce);
  const isOrderCompleted = yield select(CreatingOrderSelectors.isOrderCompleted);

  if (isOrderCompleted) {
    yield call(sendGtagEvent, GtagEventName.purchase, {
      transaction_id: action.payload.result.id,
      ...ecommerce,
    });
  }
}

function* handleViewItem(action: GetAction<typeof EventsActions.getEvent.success>) {
  yield call(sendGtagEvent, GtagEventName.view_item, {
    items: [createEcommerceItem(action.payload.entity)],
  });
}

function* handleViewItemList(action: GetAction<typeof EventsActions.getEvents.success>) {
  yield call(sendGtagEvent, GtagEventName.view_item_list, {
    items: action.payload.entities.results.map((entity) => createEcommerceItem(entity)),
  });
}

function* handleGtagEvents(): any {
  const gtagEvents = yield select(selectors.gtagEvents);
  if (gtagEvents.length === 0) return;

  for (let i = 0; i < gtagEvents.length; i += 1) {
    const gtagEvent = gtagEvents[i];
    yield call(sendGtagEvent, gtagEvent.event, gtagEvent.data);
  }

  yield put(actions.resetGtagEvents());
}

export default function* analyticsSaga() {
  if (isServer()) return;

  yield call(handleGtagEvents);

  yield takeEvery(CreatingOrderActions.addOrderItem.type, handleAddOrderItem);
  yield takeEvery(CreatingOrderActions.removeOrderItem.type, handleRemoveOrderItem);
  yield takeEvery(CreatingOrderActions.initRegistration.type, handleInitRegistration);
  yield takeEvery(CreatingOrderActions.selectStripePaymentMethod.type, handleSelectStripePaymentMethod);
  yield takeEvery(CreatingOrderActions.createOrder.success.type, handleCreateOrderSuccess);
  yield takeEvery(CreatingOrderActions.payOrder.success.type, handleCreateOrderSuccess);
  yield takeEvery(CreatingOrderActions.setupOfflinePaymentStripe.success.type, handleCreateOrderSuccess);
  yield takeEvery(CreatingOrderActions.setupOfflinePaymentSpreedly.success.type, handleCreateOrderSuccess);

  yield takeEvery(EventsActions.getEvent.success.type, handleViewItem);
  yield takeEvery(EventsActions.getEvents.success.type, handleViewItemList);
  yield takeEvery(EventsActions.getPastEvents.success.type, handleViewItemList);
  yield takeEvery(EventsActions.getUpcomingEvents.success.type, handleViewItemList);
  yield takeEvery(EventsActions.getUserFavoriteEvents.success.type, handleViewItemList);
  yield takeEvery(EventsActions.getUserFavoritePastEvents.success.type, handleViewItemList);
  yield takeEvery(EventsActions.getUserFavoriteUpcomingEvents.success.type, handleViewItemList);
}
