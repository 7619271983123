import { createAction } from 'store/utils';

export const createSingleFeatureActionsFactory =
  <P, R = never>() =>
  <T1 extends string, T2 extends string, T3 extends string, T4 extends string>(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
  ) => ({
    request: createAction(type1, (args?: { params?: P; silent?: boolean }) => ({
      meta: {
        silent: args?.silent,
      },
      payload: {
        params: args?.params,
      },
    })),
    success: createAction(type2, (args?: { result?: R; params?: P }) => ({
      payload: {
        result: args?.result,
        params: args?.params,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; params?: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        params: args.params,
      },
    })),
    reset: createAction(type4),
  });

export const createMultipleFeatureActionsFactory =
  <P = never, R = never>() =>
  <T1 extends string, T2 extends string, T3 extends string, T4 extends string>(
    type1: T1,
    type2: T2,
    type3: T3,
    type4: T4,
  ) => ({
    request: createAction(type1, (args: { id: number; params?: P; silent?: boolean }) => ({
      meta: {
        silent: args.silent,
      },
      payload: {
        id: args.id,
        params: args.params,
      },
    })),
    success: createAction(type2, (args: { result?: R; id: number; params?: P }) => ({
      payload: {
        id: args.id,
        result: args.result,
        params: args.params,
      },
    })),
    failure: createAction(type3, (args: { error: Error; notifyError?: boolean; id: number; params?: P }) => ({
      error: args.error,
      meta: {
        notifyError: args.notifyError,
      },
      payload: {
        id: args.id,
        params: args.params,
      },
    })),
    reset: createAction(type4, (args: { id: number }) => ({
      payload: {
        id: args.id,
      },
    })),
  });
