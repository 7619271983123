// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface UpdateUserRole
 */
export interface UpdateUserRole {
  /**
   * @type {string}
   * @memberof UpdateUserRole
   */
  readonly user_id?: string;
  /**
   * @type {number}
   * @memberof UpdateUserRole
   */
  object_id: number;
  /**
   * @type {string}
   * @memberof UpdateUserRole
   */
  object_type: UpdateUserRoleObjectTypeEnum;
  /**
   * @type {number}
   * @memberof UpdateUserRole
   */
  group_id: number;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateUserRoleObjectTypeEnum {
  Event = 'event',
  Organization = 'organization',
}
