import { createAction } from 'store/utils';
import { actionsFactories } from 'store/features/utils';
import {
  CheckVerificationCodeParams,
  GetVerificationCodeParams,
  JoinParamsType,
  LoginParamsType,
  LoginPasswordType,
  ResetPasswordType,
  SendMagicLinkParamsType,
  SendResetPasswordType,
  SessionUser,
  SetPasswordType,
  SocialLinksType,
} from './types';

export const sendMagicLink = actionsFactories.createSingleFeatureActionsFactory<SendMagicLinkParamsType>()(
  'SEND_MAGIC_LINK_REQUEST',
  'SEND_MAGIC_LINK_SUCCESS',
  'SEND_MAGIC_LINK_FAILURE',
  'SEND_MAGIC_LINK_RESET',
);

export const loginMagicLink = actionsFactories.createSingleFeatureActionsFactory<LoginParamsType>()(
  'LOGIN_MAGIC_LINK_REQUEST',
  'LOGIN_MAGIC_LINK_SUCCESS',
  'LOGIN_MAGIC_LINK_FAILURE',
  'LOGIN_MAGIC_LINK_RESET',
);

export const socialLinks = actionsFactories.createSingleFeatureActionsFactory<LoginParamsType, SocialLinksType>()(
  'SOCIAL_LINKS_REQUEST',
  'SOCIAL_LINKS_SUCCESS',
  'SOCIAL_LINKS_FAILURE',
  'SOCIAL_LINKS_RESET',
);

export const refreshToken = actionsFactories.createSingleFeatureActionsFactory()(
  'REFRESH_TOKEN_REQUEST',
  'REFRESH_TOKEN_SUCCESS',
  'REFRESH_TOKEN_FAILURE',
  'REFRESH_TOKEN_RESET',
);

export const join = actionsFactories.createSingleFeatureActionsFactory<JoinParamsType>()(
  'JOIN_REQUEST',
  'JOIN_SUCCESS',
  'JOIN_FAILURE',
  'JOIN_RESET',
);

export const login = actionsFactories.createSingleFeatureActionsFactory<LoginPasswordType>()(
  'LOGIN_REQUEST',
  'LOGIN_SUCCESS',
  'LOGIN_FAILURE',
  'LOGIN_RESET',
);

export const sendResetPassword = actionsFactories.createSingleFeatureActionsFactory<SendResetPasswordType>()(
  'SEND_RESET_PASSWORD_REQUEST',
  'SEND_RESET_PASSWORD_SUCCESS',
  'SEND_RESET_PASSWORD_FAILURE',
  'SEND_RESET_PASSWORD_RESET',
);

export const resetPassword = actionsFactories.createSingleFeatureActionsFactory<ResetPasswordType>()(
  'RESET_PASSWORD_REQUEST',
  'RESET_PASSWORD_SUCCESS',
  'RESET_PASSWORD_FAILURE',
  'RESET_PASSWORD_RESET',
);

export const setPassword = actionsFactories.createSingleFeatureActionsFactory<SetPasswordType>()(
  'SET_PASSWORD_REQUEST',
  'SET_PASSWORD_SUCCESS',
  'SET_PASSWORD_FAILURE',
  'SET_PASSWORD_RESET',
);

export const getVerificationCode = actionsFactories.createSingleFeatureActionsFactory<GetVerificationCodeParams>()(
  'GET_VERIFICATION_CODE_REQUEST',
  'GET_VERIFICATION_CODE_SUCCESS',
  'GET_VERIFICATION_CODE_FAILURE',
  'GET_VERIFICATION_CODE_RESET',
);

export const checkVerificationCode = actionsFactories.createSingleFeatureActionsFactory<CheckVerificationCodeParams>()(
  'CHECK_VERIFICATION_CODE_REQUEST',
  'CHECK_VERIFICATION_CODE_SUCCESS',
  'CHECK_VERIFICATION_CODE_FAILURE',
  'CHECK_VERIFICATION_CODE_RESET',
);

export const reloadCurrentUser = createAction('RELOAD_CURRENT_USER');

export const logout = createAction('LOGOUT');

export const setSessionUser = createAction('SET_SESSION_USER', (sessionUser: SessionUser) => ({
  payload: {
    sessionUser,
  },
}));
