import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { AdditionalInfo, EntityType } from 'store/entities/types';
import { AdminPromoCode } from 'api/general/models/AdminPromoCode';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AdminPromoCode>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminPromoCodesReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminPromoCodes.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminPromoCodes.success.type]: (draft, action) => {
    actionsHandlers.handleGetPaginatedManySuccess(draft, action);
    const keyWindow = draft[action.payload.keyWindowName] as AdditionalInfo;
    const { entities } = action.payload as any;
    keyWindow.additionalInfo = {};
    keyWindow.additionalInfo.total = entities.total;
    keyWindow.additionalInfo.discount_values = entities.discount_values;
  },
  [actions.getAdminPromoCodes.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [actions.getAdminPromoCodes.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.deleteAdminPromoCode.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminPromoCode.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminPromoCode.failure.type]: actionsHandlers.handleDeleteOneFailure,
  [actions.partialUpdateAdminPromoCode.success.type]: (draft, action) => {
    const promoCode = action.payload.entity;
    draft.byId[promoCode.id] = {
      ...draft.byId[promoCode.id],
      ...promoCode,
    };
  },
});

export default adminPromoCodesReducer;
