import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const tagsSlice = (state: RootState) => state.entities.tags;

export const whatsIncludedTags = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(
  tagsSlice,
  keyWindowNames.whatsIncluded,
);

export const whatsIncludedTagsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(
  tagsSlice,
  keyWindowNames.whatsIncluded,
);

export const hasWhatsIncludedTags = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(
  tagsSlice,
  keyWindowNames.whatsIncluded,
);
