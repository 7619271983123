export enum UserPassportTypes {
  INTERNAL = 'internal',
  PLUS = 'plus',
}

export interface UserPassport {
  /**
   * @type {number}
   * @memberof UserPassport
   */
  readonly id?: number;
  /**
   * @type {UserPassportTypes}
   * @memberof UserPassport
   */
  readonly passport_type: UserPassportTypes;
  /**
   * @type {boolean}
   * @memberof UserPassport
   */
  readonly verified?: boolean;
  /**
   * @type {string}
   * @memberof UserPassport
   */
  readonly passport_hash?: string;
}
