// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface TicketsStatistics
 */
export interface TicketsStatistics {
  /**
   * @type {string}
   * @memberof TicketsStatistics
   */
  timestamp?: string | null;
  /**
   * @type {number}
   * @memberof TicketsStatistics
   */
  value?: number;
  /**
   * @type {string}
   * @memberof TicketsStatistics
   */
  event_type?: TicketsStatisticsEventTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum TicketsStatisticsEventTypeEnum {
  TotalOrdered = 'total_ordered',
}
