import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { Event } from 'api/general/models';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Event>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const searchEventsReducer = createReducer<State, Actions>(initialState, {
  [actions.getSearchEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getSearchEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getSearchEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getSearchEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default searchEventsReducer;
