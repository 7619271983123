import { del, get, post } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_ORGANIZATIONS_PATH, ADMIN_USERS } from './constants';
import { GeneralListType, IdType, Options } from './types';
import { Admin, CreateAdmin, Group, PlatformUser } from './admin/models';

const ADMINS = 'admins';
const GROUPS = 'groups';
const CREATE_ORG_ADMIN = 'create-org-admin';
const INVITE_USER_TO_ROLE = 'invite-user-to-role';
const GRANTED_PERMISSIONS = 'granted-permissions';
const CONFIRM_USER_TO_ROLE_INVITE = 'confirm-user-to-role-invite';
const USER_ROLES = 'user-roles';
const USER_INVITES = 'user-invites';

export const getAdminPlatformUsers = (options: any): Promise<GeneralListType<PlatformUser>> =>
  get(buildPath(ADMIN_USERS), options).then(toJson);

export const getAdminOrgUser = (id: IdType, organizationId: IdType, options: any): Promise<Admin> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, ADMINS, id), options).then(toJson);

export const getAdminOrgUsers = (organizationId: IdType, options: any): Promise<GeneralListType<Admin>> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, ADMINS), options).then(toJson);

export const getAdminOrgInvites = (organizationId: IdType, options: any): Promise<GeneralListType<Admin>> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, USER_INVITES), options).then(toJson);

export const getAdminUsers = (options: any): Promise<GeneralListType<Admin>> =>
  get(buildPath(ADMIN_USERS, ADMINS), options).then(toJson);

export const getAdminUser = (id: IdType, options: any): Promise<Admin> =>
  get(buildPath(ADMIN_USERS, ADMINS, id), options).then(toJson);

export const getUser = (id: IdType, options: any): Promise<any> =>
  get(buildPath(ADMIN_USERS, id), options).then(toJson);

export const getAdminUsersGroups = (options: any): Promise<GeneralListType<Group>> =>
  get(buildPath(ADMIN_USERS, GROUPS), options).then(toJson);

export const getAdminUserGrantedPermissions = (options: any): Promise<any> =>
  get(buildPath(ADMIN_USERS, GRANTED_PERMISSIONS), options).then(toJson);

export const createUser = (options: any): Promise<CreateAdmin> =>
  post(buildPath(ADMIN_USERS, CREATE_ORG_ADMIN), options).then(toJson);

export const createAdminPermission = (options: any): Promise<any> =>
  post(buildPath(ADMIN_USERS, INVITE_USER_TO_ROLE), options).then(toJson);

export const createConfirmToInvite = (options: any): Promise<any> =>
  post(buildPath(ADMIN_USERS, CONFIRM_USER_TO_ROLE_INVITE), options).then(toJson);

export const removeAdminUserRole = (id: IdType, options: any): Promise<any> =>
  post(buildPath(ADMIN_USERS, id), options).then(toJson);

export const removeAdminOrgUserRole = (id: IdType, organizationId: IdType, options: Options) =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, USER_ROLES, id), options);

export const removeAdminOrgUserInvite = (id: IdType, organizationId: IdType, options: Options) =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, USER_INVITES, id), options);
