import { AddOn } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import { GetEventAddonsParamsType } from './types';

export const getEventAddons = actionsFactories.createGetManyActionsFactory<
  EntityType<AddOn>,
  GetEventAddonsParamsType
>()(
  'GET_EVENT_ADDONS_REQUEST',
  'GET_EVENT_ADDONS_SUCCESS',
  'GET_EVENT_ADDONS_FAILURE',
  'RESET_EVENT_ADDONS',
  keyWindowNames.eventAddons,
);
