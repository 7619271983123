import { createReducer } from '../../../utils';
import { RootActions } from '../../../types';
import { actionsHandlers, stateFactories } from '../../utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const updateAdminOrgRecaptchaReducer = () =>
  createReducer<State, Actions>(initialState, {
    [actions.updateAdminOrganizationReCaptcha.request.type]: actionsHandlers.handleSingleFeatureRequest,
    [actions.updateAdminOrganizationReCaptcha.success.type]: actionsHandlers.handleSingleFeatureSuccess,
    [actions.updateAdminOrganizationReCaptcha.failure.type]: actionsHandlers.handleSingleFeatureFailure,
    [actions.updateAdminOrganizationReCaptcha.reset.type]: actionsHandlers.handleSingleFeatureReset,
  });

export default updateAdminOrgRecaptchaReducer;
