import { takeEvery } from 'redux-saga/effects';
import { AdminUsersApi } from 'api';
import { sagasHandlersFactory } from 'store/features/utils';
import * as actions from './actions';

const handleInviteUserToRoleRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.sendInviteUserToRole,
  request: AdminUsersApi.createAdminPermission,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

const handleConfirmToInviteRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.sendConfirmToInvite,
  request: AdminUsersApi.createConfirmToInvite,
  requestArgsBuilder: (action) => ({
    body: action.payload.params,
  }),
});

export default function* authSagas() {
  yield takeEvery(actions.sendConfirmToInvite.request.type, handleConfirmToInviteRequest);
  yield takeEvery(actions.sendInviteUserToRole.request.type, handleInviteUserToRoleRequest);
}
