import { Attendee } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Attendee>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminAttendeeTicketsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminAttendeeTickets.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminAttendeeTickets.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminAttendeeTickets.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminAttendeeTickets.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.changeAdminAttendeeStatus.success.type]: (draft) => {
    draft.keyWindows.forEach((key) => {
      draft[key] = undefined;
    });
    draft.keyWindows = [];
  },
  [actions.cancelAdminAttendeeTicket.success.type]: (draft) => {
    draft.keyWindows.forEach((key) => {
      draft[key] = undefined;
    });
    draft.keyWindows = [];
  },
});

export default adminAttendeeTicketsReducer;
