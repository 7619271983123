import { del, get, patch, post } from './client';
import { buildPath, toJson } from './utils';
import {
  ADMIN_DEFAULT_QUESTIONS,
  ADMIN_EVENTS_PATH,
  ADMIN_EVENTS_PATH_V2,
  ADMIN_ORGANIZATIONS_PATH,
} from './constants';
import { GeneralListType, IdType } from './types';
import {
  AllAttendeesCustomQuestion,
  BaseDefaultQuestionDisplay,
  OrganizationManyTimeQuestionDisplay,
} from './admin/models';

const QUESTIONS = 'questions';
const CLONE_QUESTION = 'clone-question';
const MANY_TIME_QUESTIONS = 'many-time-questions';
const ALL_ATTENDEES_QUESTIONS = 'all-attendees-questions';
const ALL_ATTENDEE = 'all-attendee';
const OPTIONS = 'options';
const TICKETS = 'tickets';
const ADDONS = 'addons';

export const getDefaultQuestions = (options: any): Promise<GeneralListType<BaseDefaultQuestionDisplay>> =>
  get(buildPath(ADMIN_DEFAULT_QUESTIONS), options).then(toJson);

// ----- Many Time Questions -----

export const getManyTimeQuestions = (
  organizationId: IdType,
  options: any,
): Promise<GeneralListType<OrganizationManyTimeQuestionDisplay>> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS), options).then(toJson);

export const createAdminManyTimeQuestion = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationManyTimeQuestionDisplay> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS), options).then(toJson);

export const deleteAdminManyTimeQuestion = (organizationId: IdType, questionId: IdType, options: any): Promise<any> =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS, questionId), options);

export const partialUpdateManyTimeQuestion = (
  organizationId: IdType,
  questionId: IdType,
  options: any,
): Promise<OrganizationManyTimeQuestionDisplay> =>
  patch(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS, questionId), options).then(toJson);

// ----- Event Questions -----
export const createEventQuestion = (eventId: IdType, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS), options).then(toJson);

export const deleteEventQuestion = (eventId: IdType, questionId: IdType, options: any): Promise<any> =>
  del(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS, questionId), options);

export const partialUpdateEventQuestion = (eventId: IdType, questionId: IdType, options: any): Promise<any> =>
  patch(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS, questionId), options).then(toJson);

export const cloneEventQuestion = (eventId: IdType, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, CLONE_QUESTION), options).then(toJson);

export const createQuestionOption = (eventId: IdType, questionId: number, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS, questionId, OPTIONS), options).then(toJson);

export const updateQuestionOption = (
  eventId: IdType,
  questionId: number,
  optionId: number,
  options: any,
): Promise<any> =>
  patch(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS, questionId, OPTIONS, optionId), options).then(toJson);

export const deleteQuestionOption = (
  eventId: IdType,
  questionId: number,
  optionId: number,
  options: any,
): Promise<any> => del(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS, questionId, OPTIONS, optionId), options);

// ----- All Attendees Questions -----
export const getAllAttendeesQuestions = (
  eventId: IdType,
  options: any,
): Promise<GeneralListType<AllAttendeesCustomQuestion>> =>
  get(buildPath(ADMIN_EVENTS_PATH, eventId, ALL_ATTENDEES_QUESTIONS), options).then(toJson);

export const createAllAttendeesQuestion = (eventId: IdType, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS, ALL_ATTENDEE), options).then(toJson);

export const deleteAllAttendeesQuestion = (eventId: IdType, questionId: IdType, options: any): Promise<any> =>
  del(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS, ALL_ATTENDEE, questionId), options);

export const partialUpdateAllAttendeesQuestion = (eventId: IdType, questionId: IdType, options: any): Promise<any> =>
  patch(buildPath(ADMIN_EVENTS_PATH_V2, eventId, QUESTIONS, ALL_ATTENDEE, questionId), options).then(toJson);

export const cloneAllAttendeesQuestion = (eventId: IdType, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, CLONE_QUESTION, ALL_ATTENDEE), options).then(toJson);

// ----- Specific Tickets Questions -----
export const createAdminTicketQuestion = (eventId: IdType, ticketId: number, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, TICKETS, ticketId, QUESTIONS), options).then(toJson);

export const cloneAdminTicketQuestion = (eventId: IdType, ticketId: number, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, TICKETS, ticketId, CLONE_QUESTION), options).then(toJson);

export const partialUpdateAdminTicketQuestion = (
  eventId: IdType,
  ticketId: number,
  questionId: number,
  options: any,
): Promise<any> =>
  patch(buildPath(ADMIN_EVENTS_PATH_V2, eventId, TICKETS, ticketId, QUESTIONS, questionId), options).then(toJson);

export const deleteAdminTicketQuestion = (
  eventId: IdType,
  ticketId: number,
  questionId: number,
  options: any,
): Promise<any> => del(buildPath(ADMIN_EVENTS_PATH_V2, eventId, TICKETS, ticketId, QUESTIONS, questionId), options);

// ----- Admin Addons Questions -----
export const createAdminAddonQuestion = (eventId: IdType, addonId: number, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, ADDONS, addonId, QUESTIONS), options).then(toJson);

export const cloneAdminAddonQuestion = (eventId: IdType, addonId: number, options: any): Promise<any> =>
  post(buildPath(ADMIN_EVENTS_PATH_V2, eventId, ADDONS, addonId, CLONE_QUESTION), options).then(toJson);

export const partialUpdateAdminAddonQuestion = (
  eventId: IdType,
  addonId: number,
  questionId: number,
  options: any,
): Promise<any> =>
  patch(buildPath(ADMIN_EVENTS_PATH_V2, eventId, ADDONS, addonId, QUESTIONS, questionId), options).then(toJson);

export const deleteAdminAddonQuestion = (
  eventId: IdType,
  addonId: number,
  questionId: number,
  options: any,
): Promise<any> => del(buildPath(ADMIN_EVENTS_PATH_V2, eventId, ADDONS, addonId, QUESTIONS, questionId), options);
