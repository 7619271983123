import { OrganizationVideoCreateUpdate, OrganizationVideoDisplay } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import { AdminOrganizationVideoParamsType } from './types';

export const getAdminOrganizationVideos = actionsFactories.createGetManyActionsFactory<
  EntityType<OrganizationVideoDisplay>,
  AdminOrganizationVideoParamsType
>()(
  'GET_ADMIN_ORGANIZATION_VIDEOS_REQUEST',
  'GET_ADMIN_ORGANIZATION_VIDEOS_SUCCESS',
  'GET_ADMIN_ORGANIZATION_VIDEOS_FAILURE',
  'GET_ADMIN_ORGANIZATION_VIDEOS_RESET',
  keyWindowNames.adminOrganization,
);

export const createAdminOrganizationVideo = actionsFactories.createCreateOneActionsFactory<
  EntityType<Omit<OrganizationVideoCreateUpdate, 'order'>>,
  AdminOrganizationVideoParamsType
>()(
  'CREATE_ADMIN_ORGANIZATION_VIDEO_REQUEST',
  'CREATE_ADMIN_ORGANIZATION_VIDEO_SUCCESS',
  'CREATE_ADMIN_ORGANIZATION_VIDEO_FAILURE',
  'CREATE_ADMIN_ORGANIZATION_VIDEO_RESET',
  keyWindowNames.adminOrganization,
);

export const updateAdminOrganizationVideo = actionsFactories.createUpdateOneActionsFactory<
  EntityType<OrganizationVideoCreateUpdate>,
  AdminOrganizationVideoParamsType
>()(
  'UPDATE_ADMIN_ORGANIZATION_VIDEO_REQUEST',
  'UPDATE_ADMIN_ORGANIZATION_VIDEO_SUCCESS',
  'UPDATE_ADMIN_ORGANIZATION_VIDEO_FAILURE',
  'UPDATE_ADMIN_ORGANIZATION_VIDEO_RESET',
);

export const deleteAdminOrganizationVideo =
  actionsFactories.createDeleteOneActionsFactory<AdminOrganizationVideoParamsType>()(
    'DELETE_ADMIN_ORGANIZATION_VIDEO_REQUEST',
    'DELETE_ADMIN_ORGANIZATION_VIDEO_SUCCESS',
    'DELETE_ADMIN_ORGANIZATION_VIDEO_FAILURE',
  );
