import { put, call, select, takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi, UploadApi } from 'api';
import { GetAction } from 'store/types';
import { apiCall } from 'store/utils';
import { NotificationsActions } from 'store/actions';
import { AdminEventImagesSelectors, AdminEventsSelectors } from 'store/selectors';
import { sagasHandlersFactory as featuresSagasHandlersFactory } from 'store/features/utils';
import { getVideoThumb } from 'utils/video';
import * as actions from './actions';
import * as selectors from './selectors';

function* transformToEventData(values: any): any {
  const heroImage = yield select(selectors.getHeroImage);
  const selectedMedia = yield select(selectors.getMedia);
  const media = [...selectedMedia];

  const {
    has_location,
    address,
    is_virtual,
    virtual_platform,
    how_to_participate_non_registered,
    how_to_participate_registered,
    zoom_event,
    livestream_link,
    whats_included_tags,
    ...other
  } = values;

  const data: any = {
    address: has_location ? address : null,
    virtual_platform: '',
    description: '',
    how_to_participate_non_registered: '',
    how_to_participate_registered: '',
    zoom_event: null,
    livestream_link: '',
    images: [],
    videos: [],
    whats_included_tags: [],
    ...other,
  };

  if (is_virtual) {
    data.virtual_platform = virtual_platform;
    data.how_to_participate_non_registered = how_to_participate_non_registered;
    data.how_to_participate_registered = how_to_participate_registered;

    if (virtual_platform === 'zoom') {
      if (zoom_event) {
        data.zoom_event = zoom_event;
      }
    } else {
      data.livestream_link = livestream_link;
    }
  }

  Object.keys(whats_included_tags).forEach((key) => {
    if (whats_included_tags[key]) {
      data.whats_included_tags.push(Number(key));
    }
  });

  if (heroImage) {
    media.unshift(heroImage);
  }

  media.forEach(({ id, video_url, objectId }, index) => {
    const order = index + 1;
    if (video_url) {
      data.videos.push({
        link: video_url,
        order,
      });
    } else {
      data.images.push({
        image: id,
        id: objectId,
        order,
      });
    }
  });

  return data;
}

function* handleUploadHeroImageRequest({ payload: { file } }: GetAction<typeof actions.uploadHeroImage.request>): any {
  try {
    const image: any = yield apiCall(UploadApi.upload, {
      form: {
        file,
        image_type: 'hero_image',
      },
    });

    yield put(actions.uploadHeroImage.success(image));
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.uploadHeroImage.failure(e));
      yield put(NotificationsActions.showError(e));
    }
  }
}

function* handleAddImages({ payload: { files } }: GetAction<typeof actions.addImages>): any {
  yield put(actions.setMediaItemsPending(true));

  try {
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const image = yield apiCall(UploadApi.upload, {
        form: {
          file,
          image_type: 'event',
        },
      });

      yield put(actions.addMediaItem(image));
    }
  } catch (e) {
    if (e instanceof Error) yield put(NotificationsActions.showError(e));
  } finally {
    yield put(actions.setMediaItemsPending(false));
  }
}

function* handleAddVideos({ payload: { urls } }: GetAction<typeof actions.addVideos>): any {
  yield put(actions.setMediaItemsPending(true));

  for (let i = 0; i < urls.length; i += 1) {
    try {
      const url = urls[i];
      const thumb = yield call(getVideoThumb, url, true);
      const video = {
        id: Math.random(),
        original_url: thumb,
        video_url: url,
      };

      yield put(actions.addMediaItem(video));
    } catch (e) {
      if (e instanceof Error) yield put(NotificationsActions.showError(e));
    }
  }

  yield put(actions.setMediaItemsPending(false));
}

const handleCreateEvent = featuresSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.createEvent,
  request: AdminOrganizationsApi.createEvent,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { organizationId, entity } = params;
    return [organizationId, { body: entity }];
  },
});

function* handleSaveEvent({
  payload: { organizationId, eventId, values, callback },
}: GetAction<typeof actions.saveEvent>): any {
  try {
    const data: any = yield call(transformToEventData, values);
    yield apiCall(AdminOrganizationsApi.partialUpdateEvent, organizationId, eventId, {
      body: data,
    });
    yield call(callback);
  } catch (e) {
    yield put(actions.getErrorEvent(e));
  }
}

function* handleInitEventMedia({ payload: { eventId } }: GetAction<typeof actions.initEventMedia>): any {
  const event = yield select(AdminEventsSelectors.adminEventById, eventId);
  const eventImages = yield select(AdminEventImagesSelectors.adminEventImages, { eventId });

  let heroImage = null;
  const mediaItems = [];

  for (let i = 0; i < event.videos.length; i += 1) {
    try {
      const { id, link, order } = event.videos[i];
      const thumb = yield call(getVideoThumb, link, true);

      mediaItems.push({
        id,
        original_url: thumb,
        video_url: link,
        order,
      });
    } catch (e) {
      if (e instanceof Error) {
        yield put(NotificationsActions.showError(e));
      }
    }
  }

  for (let i = 0; i < eventImages.length; i += 1) {
    const { main, image, id } = eventImages[i];
    if (main) {
      heroImage = { ...image, objectId: id };
    } else {
      mediaItems.push(image);
    }
  }

  mediaItems.sort((a, b) => a.order - b.order);

  yield put(actions.setHeroImage(heroImage));
  yield put(actions.setMediaItems(mediaItems));
}

export default function* eventDraftSagas() {
  yield takeEvery(actions.uploadHeroImage.request.type, handleUploadHeroImageRequest);
  yield takeEvery(actions.addImages.type, handleAddImages);
  yield takeEvery(actions.addVideos.type, handleAddVideos);
  yield takeEvery(actions.createEvent.request.type, handleCreateEvent);
  yield takeEvery(actions.saveEvent.type, handleSaveEvent);
  yield takeEvery(actions.initEventMedia.type, handleInitEventMedia);
}
