import { get } from './client';
import { buildPath, toJson } from './utils';
import { GeneralListType, Options } from './types';
import { DefaultMetaTagContentType } from './admin/models';
import { ADMIN_CONTENT_TYPES } from './constants';

const ALLOWED_MODELS = 'allowed-meta-tag-models';

export const getMetaTagsContentTypes = (options: Options): Promise<GeneralListType<DefaultMetaTagContentType>> =>
  get(buildPath(ADMIN_CONTENT_TYPES, ALLOWED_MODELS), options).then(toJson);
