import { Market } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getAdminMarkets = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<Market>>()(
  'GET_ADMIN_MARKETS_REQUEST',
  'GET_ADMIN_MARKETS_SUCCESS',
  'GET_ADMIN_MARKETS_FAILURE',
  'GET_ADMIN_MARKETS_RESET',
);
