import { EventRegistrationAggregatedData } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { adminAttendeeStatuses } from '../shared';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventRegistrationAggregatedData>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminRegistrationStatReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminRegistrationStat.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminRegistrationStat.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminRegistrationStat.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminRegistrationStat.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.changeAdminAttendeeStatus.success.type]: (draft, action) => {
    const { eventId, attendee_status } = action.payload.params;
    if (draft.byId[eventId]) {
      adminAttendeeStatuses.forEach((status) => {
        if (status === 'canceled') return;
        const index = draft.byId[eventId].attendees_data.findIndex(({ attendee_status }) => attendee_status === status);
        if (index !== -1) {
          draft.byId[eventId].attendees_data[index].count += status === attendee_status ? 1 : -1;
        } else {
          draft.byId[eventId].attendees_data.push({
            attendee_status: status,
            count: status === attendee_status ? 1 : 0,
          });
        }
      });
    }
  },
  [actions.cancelAdminAttendeeTicket.success.type]: (draft, action) => {
    const { eventId, prevStatus } = action.payload.params;
    if (draft.byId[eventId]) {
      adminAttendeeStatuses.forEach((status) => {
        const index = draft.byId[eventId].attendees_data.findIndex(({ attendee_status }) => attendee_status === status);
        const decr = status === prevStatus ? -1 : 0;
        if (index !== -1) {
          draft.byId[eventId].attendees_data[index].count += status === 'canceled' ? 1 : decr;
        } else {
          draft.byId[eventId].attendees_data.push({
            attendee_status: status,
            count: status === 'canceled' ? 1 : 0,
          });
        }
      });
    }
  },
});

export default adminRegistrationStatReducer;
