import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminOrganizationReportsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationReports,
  request: AdminOrganizationsApi.getAdminOrganizationReport,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organizationId, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminOrganizationReportsState, params);

    return [organizationId, { params: { ...rest, page, page_size } }];
  },
});

const handleCreateAdminOrganizationReportRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminOrganizationReport,
  request: AdminOrganizationsApi.createAdminOrganizationReport,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { organizationId } = params;
    return [organizationId, { body: { ...entity } }];
  },
});

export default function* adminAttendeesSagas() {
  yield takeLeading(actions.getAdminOrganizationReports.request.type, handleGetAdminOrganizationReportsRequest);
  yield takeEvery(actions.createAdminOrganizationReport.request.type, handleCreateAdminOrganizationReportRequest);
}
