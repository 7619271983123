import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminUserEventsPermissionsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminUserEventsPermissions.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminUserEventsPermissions.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminUserEventsPermissions.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminUserEventsPermissions.reset.type]: actionsHandlers.handleGetOneReset,
});

export default adminUserEventsPermissionsReducer;
