import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const adminEventNeighborhoodStatisticsSlice = (state: RootState) =>
  state.entities.adminEventNeighborhoodStatistics;

export const adminEventNeighborhoodStatisticsUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(
  adminEventNeighborhoodStatisticsSlice,
);

export const adminEventNeighborhoodStatisticsStateById = selectorsFactory.createEntityStateByIdSelector(
  adminEventNeighborhoodStatisticsSlice,
);
