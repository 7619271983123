import fetch from 'isomorphic-unfetch';
import uuid from 'react-uuid';
import { toQueryString, toFormData } from './utils';
import { RequestFunction, ResponseError } from './types';

const sendRequest: RequestFunction = (path, options = {}) => {
  const { params, form, body, ...rest } = options;
  const init: RequestInit = rest;
  if (!options.headers) options.headers = {};

  let url = path;

  if (params) {
    url = `${url}?${toQueryString(params)}`;
  }

  if (form) {
    init.body = toFormData(form);
  } else if (body) {
    init.body = JSON.stringify(options.body, (key, value) => {
      if (typeof value === 'string') {
        return value.trim();
      }
      return value;
    });

    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json',
    };
  }

  let sessionId = typeof sessionStorage !== 'undefined' && sessionStorage.getItem('sidKey');
  if (!sessionId) {
    sessionId = uuid();
    if (typeof sessionStorage !== 'undefined') sessionStorage.setItem('sidKey', sessionId);
  }
  init.headers = {
    ...options.headers,
    'x-Jlive-SID': sessionId,
  };

  return fetch(url, init).then((response) => {
    if (!response.ok) {
      const error = new Error(response.statusText) as ResponseError;
      error.response = response;
      return Promise.reject(error);
    }

    return response;
  });
};

export const get: RequestFunction = (path, options) =>
  sendRequest(path, {
    ...options,
    method: 'GET',
  });

export const post: RequestFunction = (path, options) =>
  sendRequest(path, {
    ...options,
    method: 'POST',
  });

export const put: RequestFunction = (path, options) =>
  sendRequest(path, {
    ...options,
    method: 'PUT',
  });

export const del: RequestFunction = (path, options) =>
  sendRequest(path, {
    ...options,
    method: 'DELETE',
  });

export const patch: RequestFunction = (path, options) =>
  sendRequest(path, {
    ...options,
    method: 'PATCH',
  });
