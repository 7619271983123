import { EventOrganizationMentionDisplayBase } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory =
  stateFactories.createInitialEntitiesStateFactory<EntityType<EventOrganizationMentionDisplayBase>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventOrganizationMentionsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventOrganizationMentions.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventOrganizationMentions.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventOrganizationMentions.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventOrganizationMentions.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.addAdminEventOrganizationMention.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.addAdminEventOrganizationMention.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.addAdminEventOrganizationMention.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.addAdminEventOrganizationMention.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.deleteAdminEventOrganizationMention.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEventOrganizationMention.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEventOrganizationMention.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminEventOrganizationMentionsReducer;
