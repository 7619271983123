import { TicketsStatistics } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventTicketsStatisticsParamsType } from './types';

export const getAdminEventTicketsStatistics = actionsFactories.createGetOneActionsFactory<
  EntityType<TicketsStatistics>,
  GetAdminEventTicketsStatisticsParamsType
>()(
  'GET_ADMIN_EVENT_TICKETS_STATISTICS_REQUEST',
  'GET_ADMIN_EVENT_TICKETS_STATISTICS_SUCCESS',
  'GET_ADMIN_EVENT_TICKETS_STATISTICS_FAILURE',
  'GET_ADMIN_EVENT_TICKETS_STATISTICS_RESET',
);
