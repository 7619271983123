import { takeEvery } from 'redux-saga/effects';
import { AdminEventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminEventAdditionalInstructions = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventAdditionalInstructions,
  request: AdminEventsApi.getAdminEventAdditionalInstructions,
  requestArgsBuilder: (action) => action.payload.params.eventId,
  transformResponse: (response) => response.results,
});

const handleCreateAdminEventAdditionalInstruction = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminEventAdditionalInstruction,
  request: AdminEventsApi.postAdminEventAdditionalInstructions,
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, { body: entity }];
  },
});

const handleUpdateAdminEventAdditionalInstruction = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminEventAdditionalInstruction,
  request: AdminEventsApi.updateAdminEventAdditionalInstructions,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

const handleDeleteAdminEventAdditionalInstruction = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEventAdditionalInstruction,
  request: AdminEventsApi.deleteAdminEventAdditionalInstructions,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

export default function* adminEventAdditionalInstructionsSagas() {
  yield takeEvery(actions.getAdminEventAdditionalInstructions.request.type, handleGetAdminEventAdditionalInstructions);
  yield takeEvery(
    actions.createAdminEventAdditionalInstruction.request.type,
    handleCreateAdminEventAdditionalInstruction,
  );
  yield takeEvery(
    actions.updateAdminEventAdditionalInstruction.request.type,
    handleUpdateAdminEventAdditionalInstruction,
  );
  yield takeEvery(
    actions.deleteAdminEventAdditionalInstruction.request.type,
    handleDeleteAdminEventAdditionalInstruction,
  );
}
