import { Admin } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Admin>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrgUserReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrgUser.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminOrgUser.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminOrgUser.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminOrgUser.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.removeAdminOrgUserRole.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.removeAdminOrgUserRole.success.type]: (draft, action) => {
    delete draft.isDeleting[action.payload.id];
    const index = draft.byId[action.payload.params.userId].user_roles.findIndex(
      (item) => item.id === action.payload.id,
    );
    if (index !== -1) {
      draft.byId[action.payload.params.userId].user_roles.splice(index, 1);
    }
  },
  [actions.removeAdminOrgUserRole.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminOrgUserReducer;
