import { CommunityCalendarIcsFeedLinkType } from 'api/types';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialMultipleFeatureStateFactory<CommunityCalendarIcsFeedLinkType>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const communityCalendarIcsFeedLinkReducer = createReducer<State, Actions>(initialState, {
  [actions.getCommunityCalendarIcsFeedLink.request.type]: actionsHandlers.handleMultipleFeatureRequest,
  [actions.getCommunityCalendarIcsFeedLink.success.type]: actionsHandlers.handleMultipleFeatureSuccess,
  [actions.getCommunityCalendarIcsFeedLink.failure.type]: actionsHandlers.handleMultipleFeatureFailure,
  [actions.getCommunityCalendarIcsFeedLink.reset.type]: actionsHandlers.handleMultipleFeatureReset,
});

export default communityCalendarIcsFeedLinkReducer;
