import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<any>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminQuestionOptionReducer = createReducer<State, Actions>(initialState, {
  [actions.createAdminQuestionOption.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminQuestionOption.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminQuestionOption.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminQuestionOption.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminQuestionOption.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminQuestionOption.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminQuestionOption.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminQuestionOption.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminQuestionOption.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminQuestionOption.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminQuestionOption.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminQuestionOptionReducer;
