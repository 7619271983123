import { EventTotalTicketsStatictics } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventTotalTicketsStatisticsParamsType } from './types';

export const getAdminEventTotalTicketsStatistics = actionsFactories.createGetOneActionsFactory<
  EntityType<EventTotalTicketsStatictics>,
  GetAdminEventTotalTicketsStatisticsParamsType
>()(
  'GET_ADMIN_EVENT_TOTAL_TICKETS_STATISTICS_REQUEST',
  'GET_ADMIN_EVENT_TOTAL_TICKETS_STATISTICS_SUCCESS',
  'GET_ADMIN_EVENT_TOTAL_TICKETS_STATISTICS_FAILURE',
  'GET_ADMIN_EVENT_TOTAL_TICKETS_STATISTICS_RESET',
);
