import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { Channel } from 'api/admin/models/Channel';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Channel>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationChannelsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationChannels.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminOrganizationChannels.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminOrganizationChannels.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminOrganizationChannels.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminOrganizationChannel.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminOrganizationChannel.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminOrganizationChannel.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminOrganizationChannel.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.deleteAdminOrganizationChannel.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminOrganizationChannel.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminOrganizationChannel.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminOrganizationChannelsReducer;
