import { BreakpointsOptions } from '@mui/system';

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 992,
    lg: 1280,
    xl: 1920,
  },
} as const;

export default breakpoints;
