// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface EventRegistrantsEmailMessageCreate
 */
export interface EventRegistrantsEmailMessageCreate {
  /**
   * @type {number}
   * @memberof EventRegistrantsEmailMessageCreate
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageCreate
   */
  group_type: EventRegistrantsEmailMessageCreateGroupTypeEnum;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageCreate
   */
  subject_en: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageCreate
   */
  subject_he?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageCreate
   */
  subject_fr?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageCreate
   */
  body_en?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageCreate
   */
  body_he?: string | null;
  /**
   * @type {string}
   * @memberof EventRegistrantsEmailMessageCreate
   */
  body_fr?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum EventRegistrantsEmailMessageCreateGroupTypeEnum {
  AllBuyers = 'all_buyers',
  AllAttendees = 'all_attendees',
  CheckedInAttendees = 'checked_in_attendees',
  NotYetCheckedInAttendees = 'not_yet_checked_in_attendees',
  Cancelled = 'cancelled',
  Itself = 'itself',
}
