// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface OrganizationSettings
 */
export interface OrganizationSettings {
  id: any;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  show_powered_by?: boolean;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  show_past_events?: boolean;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  accept_donations_on_profile_page?: boolean;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  enable_multilingual_profile_page?: boolean;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  jobs_enabled?: boolean;
  /**
   * @type {string}
   * @memberof OrganizationSettings
   */
  jobs_slug?: string;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  readonly can_use_third_party_links?: boolean;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  readonly can_have_linked_page?: boolean;
  /**
   * @type {string}
   * @memberof OrganizationSettings
   */
  linked_page_button_text?: string;
  /**
   * @type {string}
   * @memberof OrganizationSettings
   */
  linked_page_button_url?: string;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  readonly can_have_crm_meta_data?: boolean;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  can_include_suborganizations_events?: boolean;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  exclude_events_ongoing_opportunities?: boolean;
  /**
   * @type {string}
   * @memberof OrganizationSettings
   */
  readonly event_translation_allowance?: OrganizationSettingsEventTranslationAllowanceEnum;
  /**
   * @type {Array<string>}
   * @memberof OrganizationSettings
   */
  readonly translation_languages_required?: Array<OrganizationSettingsTranslationLanguagesRequiredEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationSettingsEventTranslationAllowanceEnum {
  DisabledAll = 'translations_disabled_all',
  RequiredAll = 'translations_required_all',
  OptionalAll = 'translations_optional_all',
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationSettingsTranslationLanguagesRequiredEnum {
  En = 'en',
  He = 'he',
  Fr = 'fr',
}

/**
 * @export
 * @interface OrganizationSettingsSpecificEvent
 */
export interface OrganizationSettingsSpecificEvent {
  /**
   * @type {number}
   * @memberof OrganizationSettingsSpecificEvent
   */
  readonly id: number;
  /**
   * @type {string}
   * @memberof OrganizationSettingsSpecificEvent
   */
  readonly main_image?: string | null;
  /**
   * @type {string}
   * @memberof OrganizationSettingsSpecificEvent
   */
  title: string;
  /**
   * @type {string}
   * @memberof OrganizationSettingsSpecificEvent
   */
  start_at: string;
}
