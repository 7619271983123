import { call } from 'redux-saga/effects';

export type Middleware<C> = <T = unknown>(context: C) => Generator<T, void>;

export type Pipeline<C = any> = {
  push: (...middlewares: Middleware<C>[]) => void;
  execute: <T = unknown>(context: C) => Generator<T, void>;
};

export const createPipeline = <C>(...middlewares: Middleware<C>[]): Pipeline<C> => {
  const stack: Middleware<C>[] = middlewares;

  const push: Pipeline<C>['push'] = (...middlewares) => {
    stack.push(...middlewares);
  };

  function* execute(context: C) {
    for (let index = 0; index < stack.length; index += 1) {
      yield call(stack[index], context);
    }
  }

  return {
    push,
    execute,
  };
};
