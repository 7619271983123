import { TicketSessions } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetEventTicketsParams } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getEventTickets = actionsFactories.createGetManyActionsFactory<
  EntityType<TicketSessions>,
  GetEventTicketsParams
>()(
  'GET_EVENT_TICKETS_REQUEST',
  'GET_EVENT_TICKETS_SUCCESS',
  'GET_EVENT_TICKETS_FAILURE',
  'RESET_EVENT_TICKETS',
  keyWindowNames.eventTickets,
);
