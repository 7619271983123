// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Address, OrganizationMarket, OrganizationSettings, OrganizationShortLogo, OrganizationType } from '.';

/**
 * @export
 * @interface OrganizationAdminDetail
 */
export interface OrganizationAdminDetail {
  /**
   * @type {number}
   * @memberof OrganizationAdminDetail
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  status?: OrganizationAdminDetailStatusEnum;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  readonly status_display?: string;
  /**
   * @type {OrganizationType}
   * @memberof OrganizationAdminDetail
   */
  type?: OrganizationType;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  title: string;
  /**
   * @type {Address}
   * @memberof OrganizationAdminDetail
   */
  address?: Address;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  site_url?: string | null;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  site_url_preview?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  description?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  tagline?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  email?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  facebook?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  twitter?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  instagram?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  readonly default_currency?: OrganizationAdminDetailDefaultCurrencyEnum;
  /**
   * @type {OrganizationMarket}
   * @memberof OrganizationAdminDetail
   */
  org_market?: OrganizationMarket;
  /**
   * @type {OrganizationShortLogo}
   * @memberof OrganizationAdminDetail
   */
  parent?: OrganizationShortLogo | null;
  /**
   * @type {Array<OrganizationShortLogo>}
   * @memberof OrganizationAdminDetail
   */
  readonly family_tree?: Array<OrganizationShortLogo>;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  readonly images?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  readonly videos?: string;
  /**
   * @type {OrganizationSettings}
   * @memberof OrganizationAdminDetail
   */
  settings?: OrganizationSettings;
  /**
   * @type {number}
   * @memberof OrganizationAdminDetail
   */
  created_by?: number | null;
  /**
   * @type {number}
   * @memberof OrganizationAdminDetail
   */
  updated_by?: number | null;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  readonly created?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  readonly updated?: string;
  /**
   * @type {boolean}
   * @memberof OrganizationAdminDetail
   */
  is_test?: boolean;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  activated_at?: string | null;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  readonly payment_system?: string;
  /**
   * @type {string}
   * @memberof OrganizationAdminDetail
   */
  readonly gateway_type?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationAdminDetailStatusEnum {
  Draft = 'draft',
  Pending = 'pending',
  Published = 'published',
  Rejected = 'rejected',
  Hidden = 'hidden',
  Terminated = 'terminated',
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationAdminDetailDefaultCurrencyEnum {
  Usd = 'usd',
  Cad = 'cad',
}
