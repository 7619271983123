import { actionsFactories } from 'store/features/utils';
import { ConfirmOauthAuthorization } from './types';

export const requestOauthAuthorization = actionsFactories.createSingleFeatureActionsFactory<never>()(
  'ZOOM_REQUEST_OATH_AUTHORIZATION_REQUEST',
  'ZOOM_REQUEST_OATH_AUTHORIZATION_SUCCESS',
  'ZOOM_REQUEST_OATH_AUTHORIZATION_FAILURE',
  'RESET_ZOOM_REQUEST_OATH_AUTHORIZATION',
);

export const confirmOauthAuthorization =
  actionsFactories.createSingleFeatureActionsFactory<ConfirmOauthAuthorization>()(
    'ZOOM_CONFIRM_OATH_AUTHORIZATION_REQUEST',
    'ZOOM_CONFIRM_OATH_AUTHORIZATION_SUCCESS',
    'ZOOM_CONFIRM_OATH_AUTHORIZATION_FAILURE',
    'ZOOM_CONFIRM_OATH_AUTHORIZATION_RESET',
  );

export const removeOauthAuthorization = actionsFactories.createSingleFeatureActionsFactory<never>()(
  'ZOOM_REMOVE_OATH_AUTHORIZATION_REQUEST',
  'ZOOM_REMOVE_OATH_AUTHORIZATION_SUCCESS',
  'ZOOM_REMOVE_OATH_AUTHORIZATION_FAILURE',
  'RESET_ZOOM_REMOVE_OATH_AUTHORIZATION',
);
