import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const eventEmailSubscriptionSlice = (state: RootState) => state.entities.eventEmailSubscription;

export const eventEmailSubscriptionUpdatingStateById =
  selectorsFactory.createUpdatingStateByIdSelector(eventEmailSubscriptionSlice);

export const eventEmailSubscriptionStateById =
  selectorsFactory.createEntityStateByIdSelector(eventEmailSubscriptionSlice);
