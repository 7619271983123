import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminEventSponsorsSlice = (state: RootState) => state.entities.adminEventSponsors;

export const adminEventSponsorsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventSponsorsSlice,
  keyWindowNames.adminEvent,
);

export const adminEventSponsor = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminEventSponsorsSlice,
  keyWindowNames.adminEvent,
);

export const adminEventSponsorCreatingState = selectorsFactory.createCreatingStateSelector(adminEventSponsorsSlice);
