import { call, takeLeading } from 'redux-saga/effects';
import { AdminOrganizationsAutoComplete } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminOrganizationsAutoCompleteRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationsAutoComplete,
  request: AdminOrganizationsAutoComplete.getOrganizationAutoComplete,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminOrganizationsAutoCompleteState, params);
    return [{ params: { ...rest, page, page_size } }];
  },
});

export default function* adminOrganizationsAutocompleteSagas() {
  yield takeLeading(
    actions.getAdminOrganizationsAutoComplete.request.type,
    handleGetAdminOrganizationsAutoCompleteRequest,
  );
}
