import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { NotificationsActions } from 'store/actions';
import { NotificationsSelectors } from 'store/selectors';
import Notification from './Notification';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'fixed',
    left: 0,
    bottom: 0,
    padding: theme.spacing(2),
    zIndex: theme.zIndex.snackbar,
    '& > *': {
      position: 'relative',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(1),
      transform: 'none',
    },
  },
}));

function NotificationManager({ maxSnacks }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notifications = useSelector(NotificationsSelectors.notifications);

  const handleClose = useCallback((id) => dispatch(NotificationsActions.hideNotification(id)), [dispatch]);
  const handleExited = useCallback((id) => dispatch(NotificationsActions.removeNotification(id)), [dispatch]);

  return (
    <div className={classes.root}>
      {Object.keys(notifications)
        .slice(0, maxSnacks)
        .map((id) => {
          const { open, message, severity } = notifications[id];
          return (
            <Notification
              key={id}
              id={id}
              open={open}
              message={message}
              severity={severity}
              onClose={handleClose}
              onExited={handleExited}
            />
          );
        })}
    </div>
  );
}

NotificationManager.propTypes = {
  maxSnacks: PropTypes.number.isRequired,
};

export default memo(NotificationManager);
