import { call } from 'redux-saga/effects';
import { AnyFunction } from 'types';
import { Action } from './createAction';

export function* prepareNotifyError<
  E extends Error,
  A extends Action<string>,
  N extends (<FE extends E, FA extends A>(error: FE, action?: FA) => boolean) | boolean,
>(notifyErrorBuilder: N, error: E, action: A): any {
  if (typeof notifyErrorBuilder === 'function') return yield call(notifyErrorBuilder as AnyFunction, error, action);
  return notifyErrorBuilder;
}
