// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Occupation } from '.';

/**
 * @export
 * @interface UserOccupation
 */
export interface UserOccupation {
  /**
   * @type {number}
   * @memberof UserOccupation
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof UserOccupation
   */
  title: string;
  /**
   * @type {string}
   * @memberof UserOccupation
   */
  slug: UserOccupationSlugEnum;
  /**
   * @type {Occupation}
   * @memberof UserOccupation
   */
  parent?: Occupation;
  /**
   * @type {number}
   * @memberof UserOccupation
   */
  order?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum UserOccupationSlugEnum {
  Industry = 'industry',
  Occupation = 'occupation',
}
