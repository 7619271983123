import { takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminOrganizationChannelsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationChannels,
  request: AdminOrganizationsApi.getAdminOrganizationChannels,
  requestArgsBuilder: (action) => action.payload.params.organizationId,
  transformResponse: (response) => response.results,
});

const handleCreateAdminOrganizationChannelRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminOrganizationChannel,
  request: AdminOrganizationsApi.createAdminOrganizationChannel,
  requestArgsBuilder: (action) => {
    const { organizationId, id } = action.payload.params;

    return [organizationId, { body: { channel_id: id } }];
  },
});

const handleDeleteAdminOrganizationChannelRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminOrganizationChannel,
  request: AdminOrganizationsApi.deleteAdminOrganizationChannel,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

export default function* adminOrganizationChannelsSagas() {
  yield takeEvery(actions.getAdminOrganizationChannels.request.type, handleGetAdminOrganizationChannelsRequest);
  yield takeEvery(actions.createAdminOrganizationChannel.request.type, handleCreateAdminOrganizationChannelRequest);
  yield takeEvery(actions.deleteAdminOrganizationChannel.request.type, handleDeleteAdminOrganizationChannelRequest);
}
