import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Event>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const userFavoriteEventsReducer = createReducer<State, Actions>(initialState, {
  [actions.getUserFavoriteEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getUserFavoriteEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getUserFavoriteEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getUserFavoriteEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,

  [actions.getUserFavoriteUpcomingEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getUserFavoriteUpcomingEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getUserFavoriteUpcomingEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getUserFavoriteUpcomingEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,

  [actions.getUserFavoritePastEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getUserFavoritePastEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getUserFavoritePastEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getUserFavoritePastEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,

  [actions.toggleFavoriteEvent.success.type]: (draft, action) => {
    const event: any = draft.byId[action.payload.id];
    if (event) event.user_favorite = !event.user_favorite;
  },
});

export default userFavoriteEventsReducer;
