import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';
import { CheckAttendeeType } from '../types';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory<EntityType<CheckAttendeeType>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const checkAtendeeReducer = createReducer<State, Actions>(initialState, {
  [actions.checkAttendee.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.checkAttendee.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.checkAttendee.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.checkAttendee.reset.type]: actionsHandlers.handleSingleFeatureReset,
  [actions.resetCreatingOrder.type]: actionsHandlers.handleSingleFeatureReset,
});

export default checkAtendeeReducer;
