import { BaseDefaultQuestionDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<BaseDefaultQuestionDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventQuestionsReducer = createReducer<State, Actions>(initialState, {
  [actions.createAdminEventQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminEventQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminEventQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminEventQuestion.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminEventQuestion.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminEventQuestion.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminEventQuestion.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminEventQuestion.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.cloneAdminEventQuestion.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.cloneAdminEventQuestion.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.cloneAdminEventQuestion.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.cloneAdminEventQuestion.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.deleteAdminEventQuestion.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEventQuestion.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEventQuestion.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminEventQuestionsReducer;
