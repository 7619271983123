import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { TrustProfile } from 'api/admin/models/TrustProfile';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<TrustProfile>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminTrustProfileReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminTrustProfile.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminTrustProfile.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminTrustProfile.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminTrustProfile.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.updateAdminTrustProfile.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminTrustProfile.success.type]: (draft, action) => {
    const profile = draft.byId[action.payload.entity.id];
    if (profile) {
      profile.status = action.payload.entity.status;
      profile.note = action.payload.entity.note;
      delete draft.isUpdating[action.payload.entity.id];
      draft.isSuccessfullyUpdated[action.payload.entity.id] = true;
    }
  },
  [actions.updateAdminTrustProfile.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminTrustProfile.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default adminTrustProfileReducer;
