import { QuestionStatistics } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventGenderStatisticsParamsType } from './types';

export const getAdminEventGenderStatistics = actionsFactories.createGetOneActionsFactory<
  EntityType<QuestionStatistics>,
  GetAdminEventGenderStatisticsParamsType
>()(
  'GET_ADMIN_EVENT_GENDER_QUESTION_STATISTICS_REQUEST',
  'GET_ADMIN_EVENT_GENDER_QUESTION_STATISTICS_SUCCESS',
  'GET_ADMIN_EVENT_GENDER_QUESTION_STATISTICS_FAILURE',
  'GET_ADMIN_EVENT_GENDER_QUESTION_STATISTICS_RESET',
);
