import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const adminEventAgeStatisticsSlice = (state: RootState) => state.entities.adminEventAgeStatistics;

export const adminEventAgeStatisticsUpdatingStateById =
  selectorsFactory.createUpdatingStateByIdSelector(adminEventAgeStatisticsSlice);

export const adminEventAgeStatisticsStateById =
  selectorsFactory.createEntityStateByIdSelector(adminEventAgeStatisticsSlice);
