import { CalendarSettings } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<CalendarSettings>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const settingsReducer = createReducer<State, Actions>(initialState, {
  [actions.getCalendarSettingsList.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getCalendarSettingsList.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getCalendarSettingsList.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getCalendarSettingsList.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.getCalendarSettings.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getCalendarSettings.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getCalendarSettings.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getCalendarSettings.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.updateCalendarSettings.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateCalendarSettings.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateCalendarSettings.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateCalendarSettings.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default settingsReducer;
