import { EventSettings } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import {
  createAddIdActionHandler,
  createRemoveIdActionHandler,
  createUnsavingState,
} from 'store/entities/adminEvents/unsavedUtils';

import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventSettings>>();
const initialState = stateFactory.getInitialState({
  ...createUnsavingState(),
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventSettingsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventSettings.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminEventSettings.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminEventSettings.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminEventSettings.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.runtimeUpdateAdminEventSettings.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.runtimeUpdateAdminEventSettings.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.runtimeUpdateAdminEventSettings.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.runtimeUpdateAdminEventSettings.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.updateAdminEventSettings.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminEventSettings.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminEventSettings.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminEventSettings.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.addUnsavedAdminEventSettings.type]: createAddIdActionHandler('unsavedIds'),
  [actions.removeUnsavedAdminEventSettings.type]: createRemoveIdActionHandler('unsavedIds'),
  [actions.addIgnoredUnsavingAdminEventSettings.type]: createAddIdActionHandler('ignoredUnsavingIds'),
  [actions.removeIgnoredUnsavingAdminEventSettings.type]: createRemoveIdActionHandler('ignoredUnsavingIds'),
});

export default adminEventSettingsReducer;
