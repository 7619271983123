import { call, put, race, take } from 'redux-saga/effects';
import { AnyFunction } from 'types';
import { Action } from 'store/utils';
import * as actions from './actions';
import { QuestionOptionTypes, QuestionTypes } from './constants';

const withOptionsType = [QuestionOptionTypes.single, QuestionOptionTypes.multiple];

export const createSubmitQuestion = <
  A extends Action<
    string,
    {
      entity: any;
      questionId: number;
    }
  >,
  E extends {
    id: number;
    options: any[];
  },
>(args: {
  getQuestionActions: (params: A['payload']) => Record<QuestionTypes, any>;
  getOldOptions: (params: A['payload']) => E[] | Generator<unknown, E[]>;
  getRequestArgs: (params: A['payload']) => any;
}) =>
  function* handleSubmit(action: A): any {
    const { getRequestArgs, getQuestionActions, getOldOptions } = args;
    const params = action.payload;
    const questionAction = yield call(getQuestionActions as AnyFunction, params);
    const requestArgs = yield call(getRequestArgs as AnyFunction, params);
    yield put(questionAction.request(requestArgs));

    const effects = yield race([
      take((action: any) => action.type === questionAction.success.type),
      take((action: any) => action.type === questionAction.failure.type),
    ]);

    const [success] = effects;

    if (success) {
      if (params.entity.add_to_question_bank) {
        const args = { params: requestArgs.params, entity: params.entity };
        yield put(actions.createAdminManyTimeQuestion.request(args));
      }

      if (withOptionsType.includes(success.payload.entity.type)) {
        const newOptions = params.entity.options;
        const questions: { id: number; options: any[] }[] = yield call(getOldOptions as AnyFunction, params);
        const oldOptions = questions.find(({ id }) => id === params.questionId)?.options;
        const oldOptionsSet = new Set(
          oldOptions?.map((o: any) => !(params.entity.is_options_custom_text_allowed && o.is_other_option) && o.id),
        );

        for (let i = 0; i < newOptions.length; i += 1) {
          if (!newOptions[i].id) {
            yield put(
              actions.createAdminQuestionOption.request({
                params: {
                  eventId: requestArgs.params.eventId,
                  questionId: success.payload.entity.id,
                },
                entity: newOptions[i],
              }),
            );
          } else if (
            Array.isArray(oldOptions) &&
            oldOptions.some(
              (oldOption: any) => oldOption.id === newOptions[i].id && oldOption.text !== newOptions[i].text,
            )
          ) {
            oldOptionsSet.delete(newOptions[i].id);
            yield put(
              actions.updateAdminQuestionOption.request({
                id: newOptions[i].id,
                params: {
                  eventId: requestArgs.params.eventId,
                  questionId: success.payload.entity.id,
                },
                entity: {
                  text: newOptions[i].text,
                  is_other_option: newOptions[i].is_other_option,
                  has_details: newOptions[i].has_details,
                  order: newOptions[i].order,
                },
              }),
            );
          } else {
            oldOptionsSet.delete(newOptions[i].id);
          }
        }

        if (oldOptionsSet.size > 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-restricted-syntax
          for (const id of oldOptionsSet) {
            if (id) {
              yield put(
                actions.deleteAdminQuestionOption.request({
                  id: Number(id),
                  params: {
                    eventId: requestArgs.params.eventId,
                    questionId: success.payload.entity.id,
                  },
                }),
              );
            }
          }
        }
      }

      yield put(questionAction.reset(requestArgs));
    }
  };
