import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { OrdersApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from 'store/features/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetOrdersRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getOrders,
  request: OrdersApi.getOrders,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.ordersState, params);
    return {
      params: {
        ...rest,
        page,
        page_size,
      },
    };
  },
});

const handleGetOrderRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getOrder,
  request: OrdersApi.getOrder,
});

const handleResendOrderEmailRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.resendOrderEmail,
  request: OrdersApi.resendEmail,
  requestArgsBuilder: (action) => action.payload.id,
});

export default function* ordersSagas() {
  yield takeLeading(actions.getOrders.request.type, handleGetOrdersRequest);
  yield takeEvery(actions.getOrder.request.type, handleGetOrderRequest);
  yield takeEvery(actions.resendOrderEmail.request.type, handleResendOrderEmailRequest);
}
