import { EventCategory } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getEventCategories = actionsFactories.createGetManyActionsFactory<EntityType<EventCategory>>()(
  'GET_EVENT_CATEGORIES_REQUEST',
  'GET_EVENT_CATEGORIES_SUCCESS',
  'GET_EVENT_CATEGORIES_FAILURE',
  'RESET_EVENT_CATEGORIES',
);
