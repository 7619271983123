// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Occupation
 */
export interface Occupation {
  /**
   * @type {number}
   * @memberof Occupation
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof Occupation
   */
  title: string;
  /**
   * @type {string}
   * @memberof Occupation
   */
  slug: OccupationSlugEnum;
  /**
   * @type {number}
   * @memberof Occupation
   */
  parent?: number | null;
  /**
   * @type {number}
   * @memberof Occupation
   */
  order?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum OccupationSlugEnum {
  Industry = 'industry',
  Occupation = 'occupation',
}
