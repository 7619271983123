import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminEventAdditionalInstructionsSlice = (state: RootState) =>
  state.entities.adminEventAdditionalInstructions;

export const adminEventAdditionalInstructionsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventAdditionalInstructionsSlice,
  keyWindowNames.adminEventAdditionalInstructions,
);

export const adminEventAdditionalInstructionsChangingState = selectorsFactory.createChangingStateSelector(
  adminEventAdditionalInstructionsSlice,
);

export const adminEventAdditionalInstructionsInternalPassportRequired = (state: RootState, eventId: number) => {
  const instructions = adminEventAdditionalInstructionsState(state, { eventId });
  return Boolean(instructions) && instructions.entities?.find(({ slug }) => slug === 'internal_passport_required');
};
