import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { EventsApi } from 'api';
import { getStripe } from 'utils/stripe';
import { GetAction } from 'store/types';
import { sagasHandlersFactory } from 'store/features/utils';
import { AuthSelectors } from 'store/selectors';
import { is3dPartyCard } from 'utils/validation';
import { AnswerType, CartStateType, HistoryStateType, ItemStringType, StepType } from './types';
import * as actions from './actions';
import * as selectors from './selectors';

function* getDonation(): any {
  let result = null;
  const requirement_already_met = yield select(selectors.isRequirementDonationAlreadyMet);
  const custom_donation_amount = yield select(selectors.getCustomDonationAmount);
  const on_behalf_of = yield select(selectors.getOnBehalfOf);
  const option = yield select(selectors.getDonationOption);
  if (option) {
    result = {
      option,
    };
  }
  if (custom_donation_amount && !requirement_already_met) {
    result = {
      custom_donation_amount,
    };
  }
  if (requirement_already_met) {
    result = {
      requirement_already_met,
    };
  }
  if ((custom_donation_amount || option) && on_behalf_of) {
    result = {
      ...result,
      on_behalf_of,
    };
  }
  return result;
}

const handleCheckPromoCodeRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.checkPromoCode,
  request: EventsApi.checkPromoCode,
  requestArgsBuilder: function* builder(action): any {
    const { params } = action.payload;
    const cart = yield select(selectors.cartSlice);
    return [cart.event.event, { params }];
  },
  notifyError: false,
});

const handleCheckAtendeeRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.checkAttendee,
  request: EventsApi.checkAttendee,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { ticketID } = params;
    return [ticketID, { body: { email: params.email, first_name: params.first_name, last_name: params.last_name } }];
  },
  transformResponse: (response) => ({
    hasRegistered: response.status === 200,
    ...response,
  }),
  notifyError: false,
});

function* handleSubmitAnswer(action: GetAction<typeof actions.submitAnswer>): any {
  const step = yield select(selectors.presentStepSelector);
  yield put(actions.setAnswer(action.payload.answer, step.itemType, step.itemIndex));
}

function* handleEmailAnswer(action: GetAction<typeof actions.submitAnswer>): any {
  const step = yield select(selectors.presentStepSelector);
  const entity = yield select(selectors.currentCartEntity);
  const item = yield select(selectors.currentCartItem);

  if (action.payload.answer.slug === 'email') {
    const zoomEmailQuestion = entity.questions.find((question: any) => question.slug === 'zoom-email');
    if (zoomEmailQuestion) {
      const zoomAnswer = item.answers.find((answer: any) => answer.question === zoomEmailQuestion.id);
      if (!zoomAnswer?.answer_data?.email) {
        const answer: AnswerType = {
          question: zoomEmailQuestion.id,
          answer_data: action.payload.answer.answer_data,
        };

        yield put(actions.setAnswer(answer, step.itemType, step.itemIndex));
      }
    }
  }
}

function* handleFullNameAnswer(action: GetAction<typeof actions.submitAnswer>): any {
  const event = yield select(selectors.getEventEntity);
  const forBuyer = yield select(selectors.forBuyer);

  if (action.payload.answer.slug === 'full-name' && forBuyer) {
    const index = event.questions.findIndex((question: any) => question.slug === 'full-name');
    if (index !== -1) {
      const question = event.questions[index];
      const answer = {
        question: question.id,
        answer_data: action.payload.answer.answer_data,
      };

      yield put(actions.setAnswer(answer, 'event'));
    }
  }
}

function* handleGenerateRegistrationSteps() {
  const { tickets, addons } = yield select(selectors.cartSlice);
  const history: HistoryStateType<StepType> = {
    past: [],
    present: null,
    future: [],
  };

  const pushStep = (itemType: ItemStringType, itemIndex?: number, itemId?: number) => {
    history.future.push({ itemType, itemIndex, itemId });
  };

  pushStep('event');

  for (let i = 0; i < tickets.length; i += 1) {
    if (tickets[i].hasQuestions) {
      pushStep('ticket', i, tickets[i].ticket);
    }
  }

  for (let i = 0; i < addons.length; i += 1) {
    if (addons[i].hasQuestions) {
      pushStep('addon', i, addons[i].addon);
    }
  }

  history.present = history.future.shift();

  yield put(actions.initRegistration(history));
}

const handleCreateOrderRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.createOrder,
  request: EventsApi.createEventOrder,
  requestArgsBuilder: function* builder(): any {
    const cart: CartStateType = yield select(selectors.cartSlice);
    const promocode = yield select(selectors.getPromoCode);
    const donation = yield call(getDonation);
    const email = yield select(selectors.getBuyerEmailFromEventAnswers);
    const nameData = yield select(selectors.getBuyerNameDataFromEventAnswers);
    const totalPrice = yield select(selectors.getTotalPrice);
    const isPaidOffline = yield select(selectors.getPaidOffline);
    const paymentMethod =
      cart.paymentSystem === 'spreedly'
        ? yield select(selectors.getSelectedSpreedlyPaymentMethod)
        : yield select(selectors.getSelectedStripePaymentMethod);
    const third_party_buyer =
      totalPrice && !isPaidOffline ? is3dPartyCard(paymentMethod, cart.paymentSystem, nameData) : null;

    const body = {
      convert_to_hard_user: cart.convertToHardUser,
      ...nameData,
      send_to: cart.sendTo || email,
      answers: cart.event.answers,
      addons: cart.addons,
      tickets: cart.tickets.map((ticket) => {
        const { for_buyer, ...other } = ticket;
        if (!for_buyer) return other;
        return {
          ...other,
          answers: other.answers.map((answer) => ({
            ...answer,
            for_buyer: true,
          })),
        };
      }),
      ...(cart.isRegisteringByAdmin && {
        is_registered_by_admin: true,
      }),
      ...(cart.recaptcha && {
        recaptcha: cart.recaptcha,
      }),
      ...(promocode && {
        promocode,
      }),
      ...(donation && {
        donation,
      }),
      third_party_buyer,
    };
    return [cart.event.event, { body }];
  },
  notifyError: false,
});

const handlePayOrderRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.payOrder,
  request: EventsApi.payEventOrder,
  requestArgsBuilder: function* builder(): any {
    const isHardUser = yield select(AuthSelectors.isHardUser);
    const cart = yield select(selectors.cartSlice);
    const createOrder = yield select(selectors.createOrderSlice);
    const setup_future_usage = yield select(selectors.getSetupFutureUsage);
    const payment_method_id = yield select(selectors.getSelectedStripePaymentMethodId);
    const body = {
      ...(isHardUser && {
        setup_future_usage,
      }),
      ...(payment_method_id && {
        payment_method_id,
      }),
    };

    return [cart.event.event, createOrder.result.id, { body }];
  },
  notifyError: false,
});

const handleSpreedlyPayOrderRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.spreedlyPayOrder,
  request: EventsApi.spreedlyPayEventOrder,
  requestArgsBuilder: function* builder(): any {
    const isHardUser = yield select(AuthSelectors.isHardUser);
    const cart = yield select(selectors.cartSlice);
    const createOrder = yield select(selectors.createOrderSlice);
    const setup_future_usage = yield select(selectors.getSetupFutureUsage);
    const payment_method_token = yield select(selectors.getSelectedSpreedlyPaymentMethodId);
    const body = {
      ...(isHardUser && {
        setup_future_usage,
      }),
      ...(payment_method_token && {
        payment_method_token,
      }),
    };

    return [cart.event.event, createOrder.result.id, { body }];
  },
  notifyError: false,
});

const handleSetupOfflinePaymentStripeRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.setupOfflinePaymentStripe,
  request: EventsApi.setupEventOfflinePaymentStripe,
  requestArgsBuilder: function* builder(): any {
    const cart = yield select(selectors.cartSlice);
    const createOrder = yield select(selectors.createOrderSlice);

    return [cart.event.event, createOrder.result.id];
  },
});

const handleSetupOfflinePaymentSpreedlyRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.setupOfflinePaymentSpreedly,
  request: EventsApi.setupEventOfflinePaymentSpreedly,
  requestArgsBuilder: function* builder(): any {
    const cart = yield select(selectors.cartSlice);
    const createOrder = yield select(selectors.createOrderSlice);

    return [cart.event.event, createOrder.result.id];
  },
});

function* handleConfirmCardPaymentRequest(): any {
  try {
    const payment_method = yield select(selectors.getSelectedStripePaymentMethodId);
    const { result } = yield select(selectors.payOrderSlice);
    const { client_secret } = result;

    const stripe = yield call(getStripe);
    if (!stripe) {
      throw new Error('Stripe instance not found');
    }

    const response = yield call(stripe.confirmCardPayment, client_secret, { payment_method });
    if (response.error) {
      throw new Error(response.error.message);
    }

    yield put(actions.confirmCardPayment.success());
  } catch (error) {
    if (error instanceof Error) yield put(actions.confirmCardPayment.failure({ error }));
  }
}

const handleSpreedlyCheckPurchaseStatusRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.spreedlyCheckPurchaseStatus,
  request: EventsApi.spreedlyCheckPurchaseStatus,
  requestArgsBuilder: function* builder(): any {
    const { result } = yield select(selectors.createOrderSlice);

    return [{ params: { transaction_token: result.transaction_token } }];
  },
});

function* runCreationStripeOrder(): any {
  const { result } = yield select(selectors.createOrderSlice);
  if (!result) {
    yield put(actions.createOrder.request());
  } else if (result.status === 'in_progress') {
    const isPaidOffline = yield select(selectors.getPaidOffline);
    if (isPaidOffline) {
      yield put(actions.setupOfflinePaymentStripe.request());
    } else {
      yield put(actions.payOrder.request());
    }
  } else if (result.status === 'pending') {
    yield put(actions.confirmCardPayment.request());
  }
}

function* runCreationSpreedlyOrder(): any {
  const { result } = yield select(selectors.createOrderSlice);
  if (!result) {
    yield put(actions.createOrder.request());
  } else if (result.status === 'in_progress') {
    const isPaidOffline = yield select(selectors.getPaidOffline);
    if (isPaidOffline) {
      yield put(actions.setupOfflinePaymentSpreedly.request());
    } else {
      yield put(actions.spreedlyPayOrder.request());
    }
  } else if (result.status === 'pending') {
    if (result.statusFetchedTimes && result.statusFetchedTimes < 12) {
      yield delay(result.statusFetchedTimes * 5 * 1000);
    }
    yield put(actions.spreedlyCheckPurchaseStatus.request());
  }
}

function* runCreatingOrder(): any {
  const paymentSystem = yield select(selectors.paymentSystem);
  if (paymentSystem === 'spreedly') {
    yield call(runCreationSpreedlyOrder);
  } else yield call(runCreationStripeOrder);
}

export default function* creatingOrder() {
  yield takeEvery(actions.submitAnswer.type, handleSubmitAnswer);
  yield takeEvery(actions.submitAnswer.type, handleEmailAnswer);
  yield takeEvery(actions.submitAnswer.type, handleFullNameAnswer);
  yield takeEvery(actions.generateRegistrationSteps.type, handleGenerateRegistrationSteps);
  yield takeEvery(actions.checkPromoCode.request.type, handleCheckPromoCodeRequest);
  yield takeEvery(actions.createOrder.request.type, handleCreateOrderRequest);
  yield takeEvery(actions.createOrder.success.type, runCreatingOrder);
  yield takeEvery(actions.payOrder.request.type, handlePayOrderRequest);
  yield takeEvery(actions.payOrder.success.type, runCreatingOrder);
  yield takeEvery(actions.spreedlyPayOrder.request.type, handleSpreedlyPayOrderRequest);
  yield takeEvery(actions.spreedlyPayOrder.success.type, runCreatingOrder);
  yield takeEvery(actions.setupOfflinePaymentStripe.request.type, handleSetupOfflinePaymentStripeRequest);
  yield takeEvery(actions.setupOfflinePaymentStripe.success.type, runCreatingOrder);
  yield takeEvery(actions.setupOfflinePaymentSpreedly.request.type, handleSetupOfflinePaymentSpreedlyRequest);
  yield takeEvery(actions.setupOfflinePaymentSpreedly.success.type, runCreatingOrder);
  yield takeEvery(actions.confirmCardPayment.request.type, handleConfirmCardPaymentRequest);
  yield takeEvery(actions.spreedlyCheckPurchaseStatus.request.type, handleSpreedlyCheckPurchaseStatusRequest);
  yield takeEvery(actions.spreedlyCheckPurchaseStatus.success.type, runCreatingOrder);
  yield takeEvery(actions.submitOrder.type, runCreatingOrder);
  yield takeEvery(actions.checkAttendee.request.type, handleCheckAtendeeRequest);
}
