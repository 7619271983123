import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { selectorsFactory as featureSelectorsFactory } from 'store/features/utils';
import { selectorsFactory } from 'store/entities/utils';
import { adminAttendeeStatuses, AdminAttendeeStatusType } from './shared';
import * as keyWindowNames from './keyWindowNames';

export const adminAttendeesSlice = (state: RootState) => state.entities.adminAttendees.entitiesState;

export const adminAttendeeSlice = (state: RootState) => state.entities.adminAttendees.entityState;

export const adminAttendeeDetailsSlice = (state: RootState) => state.entities.adminAttendees.adminAttendeeDetailsState;

export const adminRegistrationStatSlice = (state: RootState) => state.entities.adminAttendees.adminRegistrationStat;

export const adminAttendeeTicketsSlice = (state: RootState) => state.entities.adminAttendees.adminAttendeeTicketsState;

export const changeAdminAttendeeStatusSlice = (state: RootState) =>
  state.entities.adminAttendees.changeAdminAttendeeStatus;

export const cancelAdminAttendeeTicketSlice = (state: RootState) =>
  state.entities.adminAttendees.cancelAdminAttendeeTicket;

export const adminAttendeesState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminAttendeesSlice,
  keyWindowNames.adminAttendees,
);

export const adminAttendeeTicketsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminAttendeeTicketsSlice,
  keyWindowNames.adminAttendeeTickets,
);

export const adminAttendeePagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminAttendeesSlice,
  keyWindowNames.adminAttendees,
);

export const adminAttendeeTicketsPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminAttendeeTicketsSlice,
  keyWindowNames.adminAttendeeTickets,
);

export const adminAttendeeChangingState = selectorsFactory.createChangingStateSelector(adminAttendeesSlice);

export const hasAdminAttendee = selectorsFactory.createDynamicKeyWindowHasEntitiesSelector(
  adminAttendeesSlice,
  keyWindowNames.adminAttendees,
);

export const adminRegistrationStatById = selectorsFactory.createEntityByIdSelector(adminRegistrationStatSlice);

export const adminRegistrationStatStateById =
  selectorsFactory.createEntityStateByIdSelector(adminRegistrationStatSlice);

export const adminAttendeeStateById = selectorsFactory.createEntityStateByIdSelector(adminAttendeeSlice);

export const adminAttendeeDetailsStateById = selectorsFactory.createEntityStateByIdSelector(adminAttendeeDetailsSlice);

export const changeAdminAttendeeStatusStateById =
  featureSelectorsFactory.createMultipleFeatureStateByIdSelectors(changeAdminAttendeeStatusSlice);

export const cancelAdminAttendeeTicketStateById =
  featureSelectorsFactory.createMultipleFeatureStateByIdSelectors(cancelAdminAttendeeTicketSlice);

export const adminRegistrationCountsByStatus = createSelector(adminRegistrationStatById, (stats) => {
  const attendees_data = stats?.attendees_data || [];
  const counts = {
    incomplete: stats?.incomplete_count || 0,
    allOfCount: 0,
  };

  return attendees_data.reduce((obj, { attendee_status, count }) => {
    if (adminAttendeeStatuses.includes(attendee_status)) {
      obj[attendee_status] = count;
      if (attendee_status !== 'canceled') obj.allOfCount += count;
    }

    return obj;
  }, counts as typeof counts & Record<AdminAttendeeStatusType, number>);
});
