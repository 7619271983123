import { actionsFactories } from 'store/features/utils';
import { ConfirmToInviteParams, InviteUserToRoleTypeParams } from './types';

export const sendInviteUserToRole = actionsFactories.createSingleFeatureActionsFactory<InviteUserToRoleTypeParams>()(
  'SEND_INVITE_USER_TO_ROLE_REQUEST',
  'SEND_INVITE_USER_TO_ROLE_SUCCESS',
  'SEND_INVITE_USER_TO_ROLE_FAILURE',
  'SEND_INVITE_USER_TO_ROLE_RESET',
);

export const sendConfirmToInvite = actionsFactories.createSingleFeatureActionsFactory<ConfirmToInviteParams>()(
  'SEND_CONFIRM_TO_INVITE_REQUEST',
  'SEND_CONFIRM_TO_INVITE_SUCCESS',
  'SEND_CONFIRM_TO_INVITE_FAILURE',
  'SEND_CONFIRM_TO_INVITE_RESET',
);
