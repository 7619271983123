import { actionsFactories } from 'store/entities/utils';
import { actionsFactories as featuresActionsFactories } from 'store/features/utils';
import {
  ConfirmUserMfaParamsType,
  CreateUserMfaParamsType,
  DeleteUserMfaParamsType,
  GetUserMfaType,
  ToggleActiveUserMfaParamsType,
  ToggleActiveUserMfaType,
} from './types';

export const getUserMfa = actionsFactories.createGetManyActionsFactory<GetUserMfaType>()(
  'GET_USER_MFA_REQUEST',
  'GET_USER_MFA_SUCCESS',
  'GET_USER_MFA_FAILURE',
  'GET_USER_MFA_RESET',
);

export const createUserMfa = featuresActionsFactories.createSingleFeatureActionsFactory<CreateUserMfaParamsType>()(
  'CREATE_USER_MFA_REQUEST',
  'CREATE_USER_MFA_SUCCESS',
  'CREATE_USER_MFA_FAILURE',
  'CREATE_USER_MFA_RESET',
);

export const confirmUserMfa = featuresActionsFactories.createSingleFeatureActionsFactory<ConfirmUserMfaParamsType>()(
  'CONFIRM_USER_MFA_REQUEST',
  'CONFIRM_USER_MFA_SUCCESS',
  'CONFIRM_USER_MFA_FAILURE',
  'CONFIRM_USER_MFA_RESET',
);

export const toggleActiveUserMfa = featuresActionsFactories.createSingleFeatureActionsFactory<
  ToggleActiveUserMfaParamsType,
  ToggleActiveUserMfaType
>()(
  'TOGGLE_ACTIVE_USER_MFA_REQUEST',
  'TOGGLE_ACTIVE_USER_MFA_SUCCESS',
  'TOGGLE_ACTIVE_USER_MFA_FAILURE',
  'TOGGLE_ACTIVE_USER_MFA_RESET',
);

export const deleteUserMfa = actionsFactories.createDeleteOneActionsFactory<DeleteUserMfaParamsType>()(
  'DELETE_USER_MFA_REQUEST',
  'DELETE_USER_MFA_SUCCESS',
  'DELETE_USER_MFA_FAILURE',
);
