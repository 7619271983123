import Cookie, { CookieSerializeOptions } from 'cookie';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { isServer } from './next';

const cookieSeparator = '; ';

export const serializeCookie = (name: string, value: string, options?: CookieSerializeOptions) =>
  Cookie.serialize(name, value, options);

export const setSerializedCookie = (ctx: NextPageContext | GetServerSidePropsContext, cookie: string | string[]) => {
  if (isServer()) {
    if (ctx) ctx.res.setHeader('Set-Cookie', cookie);
  } else {
    (Array.isArray(cookie) ? cookie : [cookie]).forEach((c) => {
      document.cookie = c;
    });
  }
};

export const getCookieFromRequest = (ctx: NextPageContext | GetServerSidePropsContext) => {
  if (ctx.req?.headers) return ctx.req.headers.cookie;
};

export const getCookieFromResponse = (ctx: NextPageContext | GetServerSidePropsContext) => {
  if (ctx.res?.hasHeader('Set-Cookie')) {
    const cookie = ctx.res.getHeader('Set-Cookie');
    if (Array.isArray(cookie)) return cookie.join(cookieSeparator);
    if (typeof cookie === 'string') return cookie;
  }

  return '';
};

export const getCookie = (ctx: NextPageContext | GetServerSidePropsContext) => {
  if (isServer()) {
    const cookie = [getCookieFromRequest(ctx), getCookieFromResponse(ctx)].filter(Boolean).join(cookieSeparator);
    if (cookie) return cookie;
  } else {
    return document.cookie;
  }

  return '';
};

export const getParsedCookie = (ctx: NextPageContext | GetServerSidePropsContext) => {
  const cookie = getCookie(ctx);
  if (cookie) return Cookie.parse(cookie);
  return {};
};
