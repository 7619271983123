export const adminOrganizationDashboard = (params: { organizationId: number }) =>
  `organization:${params.organizationId}`;

export const adminOrganizationTopInterest = (params: { organizationId: number }) =>
  `organization:${params.organizationId}:topInterests`;

export const adminOrganizationTopEvents = (params: { organizationId: number }) =>
  `organization:${params.organizationId}:topEvents`;

export const adminOrganizationTicketsRegistration = (params: { organizationId: number }) =>
  `organization:${params.organizationId}:ticketsRegistration`;
