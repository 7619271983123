import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { PromoCodeDisplay } from 'api/general/models';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory<EntityType<PromoCodeDisplay>>();
const initialState = stateFactory.getInitialState({
  code: '',
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const handleResetState = (draft: State, action: any) => {
  actionsHandlers.handleSingleFeatureReset(draft, action);
  draft.code = initialState.code;
};

const promoCodeReducer = createReducer<State, Actions>(initialState, {
  [actions.checkPromoCode.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.checkPromoCode.success.type]: (draft, action) => {
    actionsHandlers.handleSingleFeatureSuccess(draft, action);
    draft.code = action.payload.params.q;
  },
  [actions.checkPromoCode.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.checkPromoCode.reset.type]: handleResetState,
  [actions.resetCreatingOrder.type]: handleResetState,
});

export default promoCodeReducer;
