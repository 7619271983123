import { OrderStatus, OrderStatusStatusEnum } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory<EntityType<OrderStatus>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const createOrderReducer = createReducer<State, Actions>(initialState, {
  [actions.createOrder.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.createOrder.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.createOrder.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.createOrder.reset.type]: actionsHandlers.handleSingleFeatureReset,
  [actions.resetCreatingOrder.type]: actionsHandlers.handleSingleFeatureReset,
  [actions.payOrder.success.type]: (draft, action) => {
    draft.result = action.payload.result;
    draft.result.statusFetchedTimes = 0;
  },
  [actions.spreedlyPayOrder.success.type]: (draft, action) => {
    draft.result = action.payload.result;
    draft.result.statusFetchedTimes = 0;
  },
  [actions.setupOfflinePaymentStripe.success.type]: (draft, action) => {
    draft.result = action.payload.result;
  },
  [actions.setupOfflinePaymentSpreedly.success.type]: (draft, action) => {
    draft.result = action.payload.result;
  },
  [actions.confirmCardPayment.success.type]: (draft) => {
    draft.result.status = OrderStatusStatusEnum.Paid;
  },
  [actions.spreedlyCheckPurchaseStatus.success.type]: (draft, action) => {
    const times = draft.result.statusFetchedTimes;
    draft.result = action.payload.result;
    draft.result.statusFetchedTimes = times + 1;
  },
});

export default createOrderReducer;
