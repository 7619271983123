import { get, patch } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_TRANSLATIONS_PATH } from './constants';
import { IdType } from './types';
import { OrganizationImageTranslationDisplay } from './admin/models';

const ORGANIZATION_PATH = 'organization';
const IMAGES_PATH = 'organizationimages';
const IMAGE_PATH = 'organizationimage';

export const getOrganizationImagesTranslation = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationImageTranslationDisplay> =>
  get(buildPath(ADMIN_TRANSLATIONS_PATH, ORGANIZATION_PATH, organizationId, IMAGES_PATH), options).then(toJson);

export const updateOrganizationImageTranslation = (
  organizationId: IdType,
  organizationImageId: IdType,
  options: any,
): Promise<OrganizationImageTranslationDisplay> =>
  patch(
    buildPath(ADMIN_TRANSLATIONS_PATH, ORGANIZATION_PATH, organizationId, IMAGE_PATH, organizationImageId),
    options,
  ).then(toJson);
