import { call, takeLeading } from 'redux-saga/effects';
import { NotCompletedOrdersApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const pageSize = 20;

const handleGetNotCompletedOrdersRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getNotCompletedOrders,
  request: NotCompletedOrdersApi.getNotCompletedOrders,
  requestArgsBuilder: function* builder() {
    const { page, page_size } = yield call(getNextPageParams, pageSize, selectors.notCompletedOrdersState);
    return {
      params: {
        page,
        page_size,
      },
    };
  },
});

export default function* notCompletedOrdersSagas() {
  yield takeLeading(actions.getNotCompletedOrders.request.type, handleGetNotCompletedOrdersRequest);
}
