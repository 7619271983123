import { takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import { getDate, lastDayOfMonth, set, addMinutes, format, addYears } from 'date-fns';
import { OrganizationInterestStatistic } from 'api/admin/models';
import { dateFilterFormat } from 'components/filters/dateFilters';
import * as actions from './actions';

const handleGetAdminOrganizationDashboard = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationDashboard,
  request: AdminOrganizationsApi.getAdminOrganizationDashboard,
  transformResponse: (response, action) => {
    const { tickets_statistics = {} } = response;
    const { tickets_ordered = [] } = tickets_statistics;
    const ISODate = addMinutes(new Date(), new Date().getTimezoneOffset());
    const lastDay = getDate(lastDayOfMonth(ISODate));
    const ticketsOrdered = [];
    for (let i = 1; i <= lastDay; i += 1) {
      ticketsOrdered.push({
        date: format(set(ISODate, { date: i }), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        tickets_count: 0,
      });
    }

    tickets_ordered.forEach((order) => {
      const ticketDay = getDate(addMinutes(new Date(order.date), new Date().getTimezoneOffset()));
      ticketsOrdered[ticketDay - 1] = order;
    });

    return {
      id: action.payload.id,
      ...response,
      tickets_statistics: { ...tickets_statistics, tickets_ordered: ticketsOrdered },
    };
  },
});

const handleGetAdminOrganizationEventsStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationEventsStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationEventsStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationDonationsStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationDonationsStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationDonationsStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationSalesStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationSalesStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationSalesStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationTicketsStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationTicketsStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationTicketsStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationPeopleStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationPeopleStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationPeopleStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationTopOutsideOrgStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationTopOutsideOrgStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationTopOutsideOrgStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationNeighborhoodsStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationNeighborhoodsStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationNeighborhoodsStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationGenderStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationGenderStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationGenderStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationAgeStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationAgeStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationAgeStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationTopInterestStatistic = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationTopInterestStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationTopInterestStatistic,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { organizationId, ...rest } = params;

    return [organizationId, { params: { ...rest } }];
  },
  transformResponse: (response) =>
    response.map((item: OrganizationInterestStatistic, index: number) => ({
      id: index + 1,
      ...item,
    })),
});

const handleGetAdminOrganizationMostActivePeopleStatistic = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationMostActivePeopleStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationMostActivePeopleStatistic,
  requestArgsBuilder: (action) => {
    const { id: organizationId, ...params } = action.payload;
    return [organizationId, { ...params }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

const handleGetAdminOrganizationTopEventsStatistic = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationTopEventsStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationTopEventsStatistic,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { organizationId, ...rest } = params;

    return [organizationId, { params: { ...rest } }];
  },
  transformResponse: (response) =>
    response.map((item: OrganizationInterestStatistic, index: number) => ({
      id: index + 1,
      ...item,
    })),
});

const handleGetAdminOrganizationTicketsRegistrationStatistic = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationTicketsRegistrationStatistic,
  request: AdminOrganizationsApi.getAdminOrganizationTicketsRegistrationStatistic,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { organizationId, created__date__gte, ...rest } = params;
    const year = format(addYears(new Date(), -1), dateFilterFormat);
    const byMont = created__date__gte === '1970-1-1' || created__date__gte === year;

    return [
      organizationId,
      { params: { unit: byMont ? 'month' : 'day', timestamp__gte: created__date__gte, ...rest } },
    ];
  },
  transformResponse: (response) =>
    response.map((item: OrganizationInterestStatistic, index: number) => ({
      id: index + 1,
      ...item,
    })),
});

export default function* adminOrganizationDashboard() {
  yield takeEvery(actions.getAdminOrganizationDashboard.request.type, handleGetAdminOrganizationDashboard);
  yield takeEvery(actions.getAdminOrganizationEventsStatistic.request.type, handleGetAdminOrganizationEventsStatistic);
  yield takeEvery(
    actions.getAdminOrganizationDonationsStatistic.request.type,
    handleGetAdminOrganizationDonationsStatistic,
  );
  yield takeEvery(actions.getAdminOrganizationSalesStatistic.request.type, handleGetAdminOrganizationSalesStatistic);
  yield takeEvery(
    actions.getAdminOrganizationTicketsStatistic.request.type,
    handleGetAdminOrganizationTicketsStatistic,
  );
  yield takeEvery(actions.getAdminOrganizationPeopleStatistic.request.type, handleGetAdminOrganizationPeopleStatistic);
  yield takeEvery(
    actions.getAdminOrganizationTopOutsideOrgStatistic.request.type,
    handleGetAdminOrganizationTopOutsideOrgStatistic,
  );
  yield takeEvery(
    actions.getAdminOrganizationNeighborhoodsStatistic.request.type,
    handleGetAdminOrganizationNeighborhoodsStatistic,
  );
  yield takeEvery(actions.getAdminOrganizationGenderStatistic.request.type, handleGetAdminOrganizationGenderStatistic);
  yield takeEvery(actions.getAdminOrganizationAgeStatistic.request.type, handleGetAdminOrganizationAgeStatistic);
  yield takeEvery(
    actions.getAdminOrganizationTopInterestStatistic.request.type,
    handleGetAdminOrganizationTopInterestStatistic,
  );
  yield takeEvery(
    actions.getAdminOrganizationMostActivePeopleStatistic.request.type,
    handleGetAdminOrganizationMostActivePeopleStatistic,
  );
  yield takeEvery(
    actions.getAdminOrganizationTopEventsStatistic.request.type,
    handleGetAdminOrganizationTopEventsStatistic,
  );
  yield takeEvery(
    actions.getAdminOrganizationTicketsRegistrationStatistic.request.type,
    handleGetAdminOrganizationTicketsRegistrationStatistic,
  );
}
