import { PaymentMethod } from '@stripe/stripe-js';
import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { actionsFactories as featuresActionsFactories } from 'store/features/utils';
import {
  CreateStripePaymentMethodsType,
  SetDefaultStripePaymentMethodParamsType,
  StripePaymentMethodType,
  UpdateStripePaymentMethodsType,
} from './types';
import { defaultKeyWindowName } from '../shared';

export const getStripePaymentMethods = actionsFactories.createGetManyActionsFactory<never, StripePaymentMethodType>()(
  'GET_STRIPE_PAYMENT_METHODS_REQUEST',
  'GET_STRIPE_PAYMENT_METHODS_SUCCESS',
  'GET_STRIPE_PAYMENT_METHODS_FAILURE',
  'GET_STRIPE_PAYMENT_METHODS_RESET',
);

export const createStripePaymentMethods = actionsFactories.createCreateOneActionsFactory<
  PaymentMethod,
  CreateStripePaymentMethodsType
>()(
  'CREATE_STRIPE_PAYMENT_METHOD_REQUEST',
  'CREATE_STRIPE_PAYMENT_METHOD_SUCCESS',
  'CREATE_STRIPE_PAYMENT_METHOD_FAILURE',
  'CREATE_STRIPE_PAYMENT_METHOD_RESET',
  defaultKeyWindowName,
);

export const updateStripePaymentMethods = actionsFactories.createUpdateOneActionsFactory<
  PaymentMethod,
  UpdateStripePaymentMethodsType
>()(
  'UPDATE_STRIPE_PAYMENT_METHOD_REQUEST',
  'UPDATE_STRIPE_PAYMENT_METHOD_SUCCESS',
  'UPDATE_STRIPE_PAYMENT_METHOD_FAILURE',
  'UPDATE_STRIPE_PAYMENT_METHOD_RESET',
);

export const setDefaultStripePaymentMethod =
  featuresActionsFactories.createSingleFeatureActionsFactory<SetDefaultStripePaymentMethodParamsType>()(
    'SET_DEFAULT_STRIPE_PAYMENT_METHOD_REQUEST',
    'SET_DEFAULT_STRIPE_PAYMENT_METHOD_SUCCESS',
    'SET_DEFAULT_STRIPE_PAYMENT_METHOD_FAILURE',
    'SET_DEFAULT_STRIPE_PAYMENT_METHOD_RESET',
  );

export const deleteStripePaymentMethod = actionsFactories.createDeleteOneActionsFactory()(
  'DELETE_STRIPE_PAYMENT_METHOD_REQUEST',
  'DELETE_STRIPE_PAYMENT_METHOD_SUCCESS',
  'DELETE_STRIPE_PAYMENT_METHOD_FAILURE',
);

export const setTempStripePaymentMethod = createAction(
  'SET_TEMP_STRIPE_PAYMENT_METHOD',
  (paymentMethod: PaymentMethod) => ({
    payload: {
      paymentMethod,
    },
  }),
);

export const resetTempStripePaymentMethod = createAction('RESET_TEMP_STRIPE_PAYMENT_METHOD');
