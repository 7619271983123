import { EventTotalSalesStatictics } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminEventTotalSalesStatisticsParamsType } from './types';

export const getAdminEventTotalSalesStatistics = actionsFactories.createGetOneActionsFactory<
  EntityType<EventTotalSalesStatictics>,
  GetAdminEventTotalSalesStatisticsParamsType
>()(
  'GET_ADMIN_EVENT_TOTAL_SALES_STATISTICS_REQUEST',
  'GET_ADMIN_EVENT_TOTAL_SALES_STATISTICS_SUCCESS',
  'GET_ADMIN_EVENT_TOTAL_SALES_STATISTICS_FAILURE',
  'GET_ADMIN_EVENT_TOTAL_SALES_STATISTICS_RESET',
);
