import { combineReducers } from 'redux';
import adminOrganizationDashboardReducer from './adminOrganizationDashboard';
import adminOrganizationEventsStatisticReducer from './adminOrganizationEventsStatistic';
import adminOrganizationDonationsStatisticReducer from './adminOrganizationDonationsStatistic';
import adminOrganizationSalesStatisticReducer from './adminOrganizationSalesStatistic';
import adminOrganizationTicketsStatisticReducer from './adminOrganizationTicketsStatistic';
import adminOrganizationPeopleStatisticReducer from './adminOrganizatioPeopleStatistic';
import adminOrganizationTopOutsideOrgStatisticReducer from './adminOrganizationTopOutsideOrgStatistic';
import adminOrganizationNeighborhoodsStatisticReducer from './adminOrganizationNeighborhoodsStatistic';
import adminOrganizationAgeStatisticReducer from './adminOrganizationAgeStatistic';
import adminOrganizationGenderStatisticReducer from './adminOrganizationGenderStatistic';
import adminOrganizationTopInterestStatisticReducer from './adminOrganizationTopInterestStatistic';
import adminOrganizationMostActivePeopleStatisticReducer from './adminOrganizationMostActivePeopleStatistic';
import adminOrganizationTopEventsStatisticReducer from './adminOrganizationTopEventsStatistic';
import adminOrganizationTicketsRegistrationStatisticReducer from './adminOrganizationTicketsRegistationStatistic';

export default combineReducers({
  adminOrganizationDashboard: adminOrganizationDashboardReducer,
  adminOrganizationEventsStatistic: adminOrganizationEventsStatisticReducer,
  adminOrganizationDonationsStatistic: adminOrganizationDonationsStatisticReducer,
  adminOrganizationSalesStatistic: adminOrganizationSalesStatisticReducer,
  adminOrganizationTicketsStatistic: adminOrganizationTicketsStatisticReducer,
  adminOrganizationPeopleStatistic: adminOrganizationPeopleStatisticReducer,
  adminOrganizationTopOutsideOrgStatistic: adminOrganizationTopOutsideOrgStatisticReducer,
  adminOrganizationNeighborhoodsStatistic: adminOrganizationNeighborhoodsStatisticReducer,
  adminOrganizationGenderStatistic: adminOrganizationGenderStatisticReducer,
  adminOrganizationAgeStatistic: adminOrganizationAgeStatisticReducer,
  adminOrganizationTopInterestStatistic: adminOrganizationTopInterestStatisticReducer,
  adminOrganizationMostActivePeopleStatistic: adminOrganizationMostActivePeopleStatisticReducer,
  adminOrganizationTopEventsStatistic: adminOrganizationTopEventsStatisticReducer,
  adminOrganizationTicketsRegistrationStatistic: adminOrganizationTicketsRegistrationStatisticReducer,
});
