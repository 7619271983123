import { AdditionalinstructionTranslation } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import { GetAdminEventAdditionalInstructionTranslationsType } from './types';

export const getAdminAdditionalInstructionTranslations = actionsFactories.createGetManyActionsFactory<
  EntityType<AdditionalinstructionTranslation>,
  GetAdminEventAdditionalInstructionTranslationsType
>()(
  'GET_ADMIN_ADDITIONAL_INSTRUCTION_TRANSLATIONS_REQUEST',
  'GET_ADMIN_ADDITIONAL_INSTRUCTION_TRANSLATIONS_SUCCESS',
  'GET_ADMIN_ADDITIONAL_INSTRUCTION_TRANSLATIONS_FAILURE',
  'GET_ADMIN_ADDITIONAL_INSTRUCTION_TRANSLATIONS_RESET',
  keyWindowNames.adminEventAdditionalInstructionTranslations,
);

export const updateAdminAdditionalInstructionTranslation = actionsFactories.createUpdateOneActionsFactory<
  EntityType<AdditionalinstructionTranslation>,
  GetAdminEventAdditionalInstructionTranslationsType
>()(
  'UPDATE_ADMIN_ADDITIONAL_INSTRUCTION_TRANSLATION_REQUEST',
  'UPDATE_ADMIN_ADDITIONAL_INSTRUCTION_TRANSLATION_SUCCESS',
  'UPDATE_ADMIN_ADDITIONAL_INSTRUCTION_TRANSLATION_FAILURE',
  'UPDATE_ADMIN_ADDITIONAL_INSTRUCTION_TRANSLATION_RESET',
);
