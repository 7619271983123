import { EventReminder } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventReminder>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventRemindersReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventReminders.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventReminders.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventReminders.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventReminders.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.runtimeUpdateAdminEventReminder.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.runtimeUpdateAdminEventReminder.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.runtimeUpdateAdminEventReminder.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.runtimeUpdateAdminEventReminder.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default adminEventRemindersReducer;
