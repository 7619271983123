import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { AdminZoomEventType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminZoomMeetings = actionsFactories.createGetManyActionsFactory<AdminZoomEventType>()(
  'GET_ADMIN_ZOOM_MEETINGS_REQUEST',
  'GET_ADMIN_ZOOM_MEETINGS_SUCCESS',
  'GET_ADMIN_ZOOM_MEETINGS_FAILURE',
  'RESET_ADMIN_ZOOM_MEETINGS',
  keyWindowNames.meetings,
);

export const getAdminZoomWebinars = actionsFactories.createGetManyActionsFactory<AdminZoomEventType>()(
  'GET_ADMIN_ZOOM_WEBINARS_REQUEST',
  'GET_ADMIN_ZOOM_WEBINARS_SUCCESS',
  'GET_ADMIN_ZOOM_WEBINARS_FAILURE',
  'RESET_ADMIN_ZOOM_WEBINARS',
  keyWindowNames.webinars,
);

export const getAdminZoomEvents = createAction('GET_ADMIN_ZOOM_EVENTS');
