import { call, takeEvery } from 'redux-saga/effects';
import { OrganizationsApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetOrganizationRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getOrganization,
  request: OrganizationsApi.getOrganization,
});

const handleGetSearchOrganizationsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getSearchOrganizations,
  request: OrganizationsApi.getOrganizations,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.searchOrganizationsState(), params);
    return {
      params: {
        ...rest,
        page,
        page_size,
      },
    };
  },
});

const handleGetOrganizationsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getOrganizations,
  request: OrganizationsApi.getOrganizations,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.getOrganizationsState(), params);
    return {
      params: {
        ...rest,
        page,
        page_size,
      },
    };
  },
});

export default function* organizationsSagas() {
  yield takeEvery(actions.getOrganization.request.type, handleGetOrganizationRequest);
  yield takeEvery(actions.getSearchOrganizations.request.type, handleGetSearchOrganizationsRequest);
  yield takeEvery(actions.getOrganizations.request.type, handleGetOrganizationsRequest);
}
