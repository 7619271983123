// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Tag
 */
export interface Tag {
  /**
   * @type {number}
   * @memberof Tag
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof Tag
   */
  slug: string;
  /**
   * @type {string}
   * @memberof Tag
   */
  title: string;
  /**
   * @type {string}
   * @memberof Tag
   */
  type?: TagTypeEnum;
  /**
   * @type {string}
   * @memberof Tag
   */
  type_display: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TagTypeEnum {
  Empty = '',
  Age = 'age',
  Category = 'category',
  WhatsIncludedType = 'whats_included_type',
  WhatsIncludedReligious = 'whats_included_religious',
  WhatsIncluded = 'whats_included',
}
