// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Address, BillingAddress } from '.';

/**
 * @export
 * @interface UserEditAdmin
 */
export interface UserEditAdmin {
  /**
   * @type {number}
   * @memberof UserEditAdmin
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  email: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  first_name: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  last_name: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  readonly date_joined?: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  readonly last_login?: string;
  /**
   * @type {boolean}
   * @memberof UserEditAdmin
   */
  is_active?: boolean;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  title?: UserEditAdminTitleEnum;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  maiden_name?: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  phone: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  home_phone?: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  work_phone?: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  gender?: UserEditAdminGenderEnum;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  birth_date?: string;
  /**
   * @type {Address}
   * @memberof UserEditAdmin
   */
  address?: Address;
  /**
   * @type {BillingAddress}
   * @memberof UserEditAdmin
   */
  billing_address?: BillingAddress;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  about?: string;
  /**
   * @type {number}
   * @memberof UserEditAdmin
   */
  occupation?: number | null;
  /**
   * @type {Array<number>}
   * @memberof UserEditAdmin
   */
  interests?: Array<number>;
  /**
   * @type {boolean}
   * @memberof UserEditAdmin
   */
  billing_alert?: boolean;
  /**
   * @type {boolean}
   * @memberof UserEditAdmin
   */
  notifications?: boolean;
  /**
   * @type {boolean}
   * @memberof UserEditAdmin
   */
  is_terms_and_conditions_accepted?: boolean;
  /**
   * @type {number}
   * @memberof UserEditAdmin
   */
  primary_city?: number;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  mailing_address?: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  children_in_household?: string;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  preferred_language: UserEditAdminPreferredLanguageEnum;
  /**
   * @type {Array<number>}
   * @memberof UserEditAdmin
   */
  languages_spoken?: Array<number>;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  religion?: UserEditAdminReligionEnum;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  jewish_descendants?: UserEditAdminJewishDescendantsEnum;
  /**
   * @type {string}
   * @memberof UserEditAdmin
   */
  relationship_status?: UserEditAdminRelationshipStatusEnum;
  /**
   * @type {number}
   * @memberof UserEditAdmin
   */
  image?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum UserEditAdminTitleEnum {
  Empty = '',
  Dr = 'dr',
  Mr = 'mr',
  Mrs = 'mrs',
  Ms = 'ms',
  Miss = 'miss',
  Rabbi = 'rabbi',
  Cantor = 'cantor',
}
/**
 * @export
 * @enum {string}
 */
export enum UserEditAdminGenderEnum {
  Empty = '',
  F = 'f',
  M = 'm',
  Transgender = 'transgender',
  NonBinary = 'non_binary',
  Other = 'other',
  NoAnswer = 'no_answer',
}
/**
 * @export
 * @enum {string}
 */
export enum UserEditAdminPreferredLanguageEnum {
  En = 'en',
  Fr = 'fr',
}
/**
 * @export
 * @enum {string}
 */
export enum UserEditAdminReligionEnum {
  Empty = '',
  ReformJudaism = 'reform_judaism',
  ConservativeJudaism = 'conservative_judaism',
  ConservadoxJudaism = 'conservadox_judaism',
  OrthodoxJudaism = 'orthodox_judaism',
  ModernOrthodoxJudaism = 'modern_orthodox_judaism',
  HarediOrthodoxJudaism = 'haredi_orthodox_judaism',
  HasidicJudaism = 'hasidic_judaism',
  ReconstructionistJudaism = 'reconstructionist_judaism',
  JustJewish = 'just_jewish',
  CulturallyJewish = 'culturally_jewish',
  TraditionalJudaism = 'traditional_judaism',
  Other = 'other',
  NotJewish = 'not_jewish',
  JewishRenewal = 'jewish_renewal',
}
/**
 * @export
 * @enum {string}
 */
export enum UserEditAdminJewishDescendantsEnum {
  Empty = '',
  Ashkenazi = 'ashkenazi',
  Sephardic = 'sephardic',
  Mizrahi = 'mizrahi',
}
/**
 * @export
 * @enum {string}
 */
export enum UserEditAdminRelationshipStatusEnum {
  Empty = '',
  Married = 'married',
  CivilUnion = 'civil_union',
  Single = 'single',
  Divorced = 'divorced',
  Engaged = 'engaged',
  InRelationship = 'in_relationship',
  Complicated = 'complicated',
  Widowed = 'widowed',
}
