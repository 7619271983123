import { call, getContext } from 'redux-saga/effects';
import { AnyFunction } from 'types';

export function* apiCall<A extends any[]>(fn: AnyFunction, ...args: A): any {
  const options = (typeof args[args.length - 1] === 'object' && args.pop()) || {};

  const apiPipeline = yield getContext('apiPipeline');
  yield call(apiPipeline.execute, options);

  try {
    return yield call(fn, ...args, options);
  } catch (e) {
    // TODO:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.response) e.response.responseText = yield call(() => e.response.text());
    throw e;
  }
}
