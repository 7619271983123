// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface StripePaymentMethodsDisplay
 */
export interface StripePaymentMethodsDisplay {
  /**
   * @type {string}
   * @memberof StripePaymentMethodsDisplay
   */
  readonly id?: string;
  /**
   * @type {string}
   * @memberof StripePaymentMethodsDisplay
   */
  readonly payment_method_data?: string;
  /**
   * @type {string}
   * @memberof StripePaymentMethodsDisplay
   */
  readonly is_default?: string;
  /**
   * @type {number}
   * @memberof StripePaymentMethodsDisplay
   */
  created: number;
  /**
   * @type {string}
   * @memberof StripePaymentMethodsDisplay
   */
  type?: StripePaymentMethodsDisplayTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum StripePaymentMethodsDisplayTypeEnum {
  Card = 'card',
}
