// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface StripeRefund
 */
export interface StripeRefund {
  /**
   * @type {number}
   * @memberof StripeRefund
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof StripeRefund
   */
  status: StripeRefundStatusEnum;
  /**
   * @type {string}
   * @memberof StripeRefund
   */
  failure_reason?: StripeRefundFailureReasonEnum;
  /**
   * @type {string}
   * @memberof StripeRefund
   */
  readonly created?: string;
  /**
   * @type {string}
   * @memberof StripeRefund
   */
  readonly updated?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum StripeRefundStatusEnum {
  Pending = 'pending',
  Canceled = 'canceled',
  Failed = 'failed',
  Succeeded = 'succeeded',
}
/**
 * @export
 * @enum {string}
 */
export enum StripeRefundFailureReasonEnum {
  LostOrStolenCard = 'lost_or_stolen_card',
  ExpiredOrCanceledCard = 'expired_or_canceled_card',
  Unknown = 'unknown',
}
