import { get, patch, post, del, put } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_ORGANIZATIONS_PATH } from './constants';
import { IdType } from './types';
import { Metatag } from './general/models';

const TAGS_PATH = 'meta-tags';
const BULK_CREATE = 'bulk_create';
const BULK_UPDATE = 'bulk_update';

export const getOrganizationMetaTags = (orgId: IdType, options: any): Promise<Metatag[]> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, TAGS_PATH), options).then(toJson);

export const updateOrganizationMetaTag = (orgId: IdType, options: any): Promise<Metatag> =>
  patch(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, TAGS_PATH), options).then(toJson);

export const createOrganizationMetaTags = (orgId: IdType, options: any): Promise<Metatag[]> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, TAGS_PATH, BULK_CREATE), options).then(toJson);

export const updateOrganizationMetaTags = (orgId: IdType, options: any): Promise<Metatag> =>
  put(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, TAGS_PATH, BULK_UPDATE), options).then(toJson);

export const deleteOrganizationMetaTag = (orgId: IdType, id: IdType, options: any): Promise<Response> =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, TAGS_PATH, id), options);
