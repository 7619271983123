import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminMarketsApi } from 'api';
import { GetAction } from 'store/types';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetMarketsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminMarkets,
  request: AdminMarketsApi.getAdminMarkets,
});

function* handleGetNextMarkets(action: GetAction<typeof actions.getAdminMarkets.success>) {
  const { entities, params } = action.payload;
  if (entities.next) {
    const { page, page_size } = yield call(getNextPageParams, params.page_size, selectors.adminMarkets);
    yield put(actions.getAdminMarkets.request({ params: { ...params, page, page_size } }));
  }
}

export default function* marketsSagas() {
  yield takeLeading(actions.getAdminMarkets.request.type, handleGetMarketsRequest);
  yield takeEvery(actions.getAdminMarkets.success.type, handleGetNextMarkets);
}
