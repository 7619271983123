import { EventVideoCreateUpdate, EventVideoDisplay } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import { AdminEventVideoParamsType } from './types';

export const getAdminEventVideos = actionsFactories.createGetManyActionsFactory<
  EntityType<EventVideoDisplay>,
  AdminEventVideoParamsType
>()(
  'GET_ADMIN_EVENT_VIDEOS_REQUEST',
  'GET_ADMIN_EVENT_VIDEOS_SUCCESS',
  'GET_ADMIN_EVENT_VIDEOS_FAILURE',
  'GET_ADMIN_EVENT_VIDEOS_RESET',
  keyWindowNames.adminEvent,
);

export const createAdminEventVideo = actionsFactories.createCreateOneActionsFactory<
  EntityType<Omit<EventVideoCreateUpdate, 'order'>>,
  AdminEventVideoParamsType
>()(
  'CREATE_ADMIN_EVENT_VIDEO_REQUEST',
  'CREATE_ADMIN_EVENT_VIDEO_SUCCESS',
  'CREATE_ADMIN_EVENT_VIDEO_FAILURE',
  'CREATE_ADMIN_EVENT_VIDEO_RESET',
  keyWindowNames.adminEvent,
);

export const updateAdminEventVideo = actionsFactories.createUpdateOneActionsFactory<
  EntityType<Partial<EventVideoCreateUpdate>>,
  AdminEventVideoParamsType
>()(
  'UPDATE_ADMIN_EVENT_VIDEO_REQUEST',
  'UPDATE_ADMIN_EVENT_VIDEO_SUCCESS',
  'UPDATE_ADMIN_EVENT_VIDEO_FAILURE',
  'UPDATE_ADMIN_EVENT_VIDEO_RESET',
);

export const deleteAdminEventVideo = actionsFactories.createDeleteOneActionsFactory<AdminEventVideoParamsType>()(
  'DELETE_ADMIN_EVENT_VIDEO_REQUEST',
  'DELETE_ADMIN_EVENT_VIDEO_SUCCESS',
  'DELETE_ADMIN_EVENT_VIDEO_FAILURE',
);
