// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Token
 */
export interface Token {
  /**
   * @type {string}
   * @memberof Token
   */
  readonly key?: string;
  /**
   * @type {string}
   * @memberof Token
   */
  readonly audience?: TokenAudienceEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum TokenAudienceEnum {
  Automation = 'automation',
}
