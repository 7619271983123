export const fontStyles = {
  fontWeightMediumBold: 600,
};

const typography = {
  ...fontStyles,
  fontFamily: 'Poppins, sans-serif',
  h2: {
    fontSize: 56,
    fontWeight: 700,
    lineHeight: '67px',
  },
  h3: {
    fontSize: 46,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: '-0.02em',
  },
  h5: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.02em',
  },
  h6: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '27px',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.03em',
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '22px',
  },
  body1: {
    fontSize: 15,
    lineHeight: 1.65,
    letterSpacing: 'normal',
  },
  body2: {
    fontSize: 13,
    lineHeight: '19px',
  },
  button: {
    fontSize: 15,
    fontWeight: 600,
    letterSpacing: '0.03em',
    lineHeight: '22px',
  },
  caption: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
  },
} as const;

export default typography;
