import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminUsersSlice = (state: RootState) => state.entities.adminUsers.users;

export const adminOrgUsersSlice = (state: RootState) => state.entities.adminUsers.orgUsers;

export const adminOrgUserInvitesSlice = (state: RootState) => state.entities.adminUsers.orgUserInvites;

export const adminUsersState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminUsersSlice);

export const adminUsersPagination = selectorsFactory.createStaticKeyWindowPaginationSelector(adminUsersSlice);

export const adminOrgUsersState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrgUsersSlice,
  keyWindowNames.adminOrgUsers,
);

export const adminOrgUsersPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminOrgUsersSlice,
  keyWindowNames.adminOrgUsers,
);

export const adminOrgUserInvitesState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrgUserInvitesSlice,
  keyWindowNames.adminOrgUsersInvites,
);

export const adminOrgUserInvitesPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminOrgUserInvitesSlice,
  keyWindowNames.adminOrgUsersInvites,
);

export const adminUsersGroupsSlice = (state: RootState) => state.entities.adminUsers.usersGroups;

export const adminUsersGroupsState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(adminUsersGroupsSlice);

export const adminUserSlice = (state: RootState) => state.entities.adminUsers.user;

export const adminUserState = selectorsFactory.createEntityStateByIdSelector(adminUserSlice);

export const adminUserCreatingState = selectorsFactory.createCreatingStateSelector(adminUserSlice);

export const adminUserChangingState = selectorsFactory.createChangingStateSelector(adminUserSlice);

export const adminOrgUserSlice = (state: RootState) => state.entities.adminUsers.orgUser;

export const adminOrgUserState = selectorsFactory.createEntityStateByIdSelector(adminOrgUserSlice);

export const adminOrgUserChangingState = selectorsFactory.createChangingStateSelector(adminOrgUserSlice);

export const adminUserOrganizationsPermissionsSlice = (state: RootState) =>
  state.entities.adminUsers.organizationsPermissions;

export const adminUserOrganizationsPermissionsStateById = selectorsFactory.createEntityStateByIdSelector(
  adminUserOrganizationsPermissionsSlice,
);

export const adminUserEventsPermissionsSlice = (state: RootState) => state.entities.adminUsers.eventsPermissions;

export const adminUserEventsPermissionsStateById = selectorsFactory.createEntityStateByIdSelector(
  adminUserEventsPermissionsSlice,
);
