import { OrganizationType } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getOrganizationTypes = actionsFactories.createGetManyActionsFactory<EntityType<OrganizationType>>()(
  'GET_ORGANIZATION_TYPES_REQUEST',
  'GET_ORGANIZATION_TYPES_SUCCESS',
  'GET_ORGANIZATION_TYPES_FAILURE',
  'GET_ORGANIZATION_TYPES_RESET',
);
