import { AdditionalInstructionAdmin } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from './actions';
import { EntityType } from '../types';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AdditionalInstructionAdmin>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventAdditionalInstructionsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventAdditionalInstructions.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventAdditionalInstructions.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventAdditionalInstructions.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventAdditionalInstructions.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminEventAdditionalInstruction.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminEventAdditionalInstruction.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminEventAdditionalInstruction.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminEventAdditionalInstruction.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminEventAdditionalInstruction.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminEventAdditionalInstruction.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminEventAdditionalInstruction.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminEventAdditionalInstruction.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminEventAdditionalInstruction.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEventAdditionalInstruction.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEventAdditionalInstruction.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminEventAdditionalInstructionsReducer;
