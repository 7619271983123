import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminEventRemindersSlice = (state: RootState) => state.entities.adminEventReminders;

export const adminEventRemindersState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventRemindersSlice,
  keyWindowNames.adminEventReminders,
);

export const adminEventRemindersFetchingState = selectorsFactory.createDynamicKeyWindowFetchingStateSelector(
  adminEventRemindersSlice,
  keyWindowNames.adminEventReminders,
);
