import { OrganizationAdminList } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationAdminList>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizations.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrganizations.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrganizations.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrganizations.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default adminOrganizationsReducer;
