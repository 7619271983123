import { cancel, fork, race, select, take } from 'redux-saga/effects';
import { SessionUser } from '../types';
import * as actions from '../actions';
import * as selectors from '../selectors';

type Args = {
  fork: (sessionUser: SessionUser) => void;
};

const createSessionUserWatcher = (args: Args) =>
  function* sessionUserWatcher(): any {
    while (true) {
      const sessionUser = yield select(selectors.sessionUser);
      if (sessionUser) {
        const task = yield fork(args.fork, sessionUser);
        yield race([take(actions.setSessionUser.type), take(actions.logout.type)]);
        yield cancel(task);
      } else {
        yield take(actions.setSessionUser.type);
      }
    }
  };

export default createSessionUserWatcher;
