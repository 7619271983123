import { DonationTranslation } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getAdminDonationTranslation = actionsFactories.createGetOneActionsFactory<
  EntityType<DonationTranslation>
>()(
  'GET_ADMIN_DONATION_TRANSLATION_REQUEST',
  'GET_ADMIN_DONATION_TRANSLATION_SUCCESS',
  'GET_ADMIN_DONATION_TRANSLATION_FAILURE',
  'GET_ADMIN_DONATION_TRANSLATION_RESET',
);

export const updateAdminDonationTranslation = actionsFactories.createUpdateOneActionsFactory<
  EntityType<DonationTranslation>
>()(
  'UPDATE_ADMIN_DONATION_TRANSLATION_REQUEST',
  'UPDATE_ADMIN_DONATION_TRANSLATION_SUCCESS',
  'UPDATE_ADMIN_DONATION_TRANSLATION_FAILURE',
  'UPDATE_ADMIN_DONATION_TRANSLATION_RESET',
);
