import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminPromoCodesSlice = (state: RootState) => state.entities.adminPromoCodes.entitiesState;
export const adminPromoCodeSlice = (state: RootState) => state.entities.adminPromoCodes.entityState;

export const adminPromoCodesState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminPromoCodesSlice,
  keyWindowNames.adminPromoCodes,
);

export const adminPromoCodesPagination = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminPromoCodesSlice,
  keyWindowNames.adminPromoCodes,
);

export const adminPromoCodesByIdState = selectorsFactory.createEntityStateByIdSelector(adminPromoCodeSlice);

export const adminPromoCodesCreatingState = selectorsFactory.createCreatingStateSelector(adminPromoCodeSlice);

export const adminPromoCodesUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(adminPromoCodeSlice);
