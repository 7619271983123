import { AddOn } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AddOn>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const addonsReducer = createReducer<State, Actions>(initialState, {
  [actions.getEventAddons.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getEventAddons.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getEventAddons.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getEventAddons.reset.type]: actionsHandlers.handleGetManyReset,
});

export default addonsReducer;
