import { RootState } from 'store/rootReducer';
import { selectorsFactory } from '../utils';

export const userMfaSlice = (state: RootState) => state.entities.mfa.userMfa;

export const userMfaState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(userMfaSlice);

export const createUserMfaState = (state: RootState) => state.entities.mfa.createUserMfa;

export const confirmUserMfaState = (state: RootState) => state.entities.mfa.confirmUserMfa;
