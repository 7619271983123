import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { createSelector } from 'reselect';
import * as keyWindowNames from './keyWindowNames';

export const adminEventImagesSlice = (state: RootState) => state.entities.adminEventImages;

export const adminEventImagesState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventImagesSlice,
  keyWindowNames.adminEvent,
);

export const adminEventImages = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminEventImagesSlice,
  keyWindowNames.adminEvent,
);

export const adminEventImageCreatingState = selectorsFactory.createCreatingStateSelector(adminEventImagesSlice);

export const adminEventImageUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(adminEventImagesSlice);

export const adminEventHeroImage = createSelector(adminEventImages, (images) => images.find((image) => image.main));

export const adminEventGalleryImage = createSelector(adminEventImages, (images) =>
  images.filter((image) => !image.main),
);
