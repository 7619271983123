import { takeEvery } from 'redux-saga/effects';
import { ReCaptchaApi } from 'api';
import * as actions from './actions';
import { sagasHandlersFactory } from '../utils';

const handleCheckReCaptchaRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.checkReCaptcha,
  request: ReCaptchaApi.checkRecaptcha,
  requestArgsBuilder: (action) => ({ params: action.payload.params }),
  notifyError: false,
});

const handleUpdateAdminOrgReCaptchaRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.updateAdminOrganizationReCaptcha,
  request: ReCaptchaApi.updateAdminOrganizationEventsReCaptcha,
  requestArgsBuilder: (action) => {
    const { orgId } = action.payload.params;
    return [orgId, { body: { action: action.payload.params.action } }];
  },
  notifyError: false,
});

export default function* recaptchaSagas() {
  yield takeEvery(actions.checkReCaptcha.request.type, handleCheckReCaptchaRequest);
  yield takeEvery(actions.updateAdminOrganizationReCaptcha.request.type, handleUpdateAdminOrgReCaptchaRequest);
}
