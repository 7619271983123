// tslint:disable
/**
 * JLIVE API
 * JLIVE API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface OrderStatus
 */
export interface OrderStatus {
  /**
   * @type {string}
   * @memberof OrderStatus
   */
  readonly id?: string;
  /**
   * @type {string}
   * @memberof OrderStatus
   */
  status: OrderStatusStatusEnum;
  /**
   * @type {string}
   * @memberof OrderStatus
   */
  client_secret?: string;
  /**
   * @type {string}
   * @memberof OrderStatus
   */
  payment_method_id?: string;
  /**
   * @type {string}
   * @memberof OrderStatus
   */
  transaction_token?: string;
  /**
   * @type {number}
   * @memberof OrderStatus
   */
  statusFetchedTimes?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum OrderStatusStatusEnum {
  InProgress = 'in_progress',
  Pending = 'pending',
  Canceled = 'canceled',
  Abandoned = 'abandoned',
  Paid = 'paid',
  Refunded = 'refunded',
  WaitingForOfflinePayment = 'waiting_for_offline_payment',
  PaidOffline = 'paid_offline',
}
