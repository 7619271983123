import { OrganizationDetail } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationDetail>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const searchOrganizationsReducer = createReducer<State, Actions>(initialState, {
  [actions.getSearchOrganizations.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getSearchOrganizations.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getSearchOrganizations.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getSearchOrganizations.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default searchOrganizationsReducer;
