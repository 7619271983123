import { put, takeEvery } from 'redux-saga/effects';
import { SpreedlyPaymentMethodsApi } from 'api';
import { GetAction } from 'store/types';
import { sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from 'store/features/utils';
import * as actions from './actions';

const handleGetSpreedlyPaymentMethodsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getSpreedlyPaymentMethods,
  request: SpreedlyPaymentMethodsApi.getSpreedlyPaymentMethods,
  transformResponse: (response) => response.map((item: any) => ({ ...item, id: item.token })),
});

const handleSetDefaultSpreedlyPaymentMethodRequest = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.setDefaultSpreedlyPaymentMethod,
  request: SpreedlyPaymentMethodsApi.setDefaultSpreedlyPaymentMethod,
  requestArgsBuilder: (action) => {
    const { payment_method_token } = action.payload.params;
    return [{ body: { payment_method_token } }];
  },
});

const handleDeleteSpreedlyPaymentMethodRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteSpreedlyPaymentMethod,
  request: SpreedlyPaymentMethodsApi.deleteSpreedlyPaymentMethods,
  requestArgsBuilder: (action) => ({
    body: {
      payment_method_token: action.payload.id,
    },
  }),
});

const handleCreateSpreedlyPaymentMethodsRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createSpreedlyPaymentMethods,
  request: SpreedlyPaymentMethodsApi.createSpreedlyPaymentMethods,
  requestArgsBuilder: (action) => ({
    body: {
      payment_method_token: action.payload.entity.id,
    },
  }),
  transformResponse: (response) => ({ id: response.token, ...response }),
});

const handleUpdateSpreedlyPaymentMethodsRequest = sagasHandlersFactory.createPartialUpdateOneRequestHandler({
  actions: actions.updateSpreedlyPaymentMethods,
  request: SpreedlyPaymentMethodsApi.updateSpreedlyPaymentMethods,
  requestArgsBuilder: (action) => ({
    body: action.payload.entity,
  }),
});

function* handleCreateSpreedlyPaymentMethodsSuccess(
  action: GetAction<typeof actions.createSpreedlyPaymentMethods.success>,
) {
  const { params, entity } = action.payload;
  const { is_default } = params;
  if (is_default) {
    yield put(
      actions.setDefaultSpreedlyPaymentMethod.request({
        params: { payment_method_token: entity.token },
      }),
    );
  }
}

export default function* spreedlyPaymentMethodsSagas() {
  yield takeEvery(actions.getSpreedlyPaymentMethods.request.type, handleGetSpreedlyPaymentMethodsRequest);
  yield takeEvery(actions.setDefaultSpreedlyPaymentMethod.request.type, handleSetDefaultSpreedlyPaymentMethodRequest);
  yield takeEvery(actions.deleteSpreedlyPaymentMethod.request.type, handleDeleteSpreedlyPaymentMethodRequest);
  yield takeEvery(actions.updateSpreedlyPaymentMethods.request.type, handleUpdateSpreedlyPaymentMethodsRequest);
  yield takeEvery(actions.createSpreedlyPaymentMethods.request.type, handleCreateSpreedlyPaymentMethodsRequest);
  yield takeEvery(actions.createSpreedlyPaymentMethods.success.type, handleCreateSpreedlyPaymentMethodsSuccess);
}
