import { takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetOrganizationTypesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getOrganizationTypes,
  request: AdminOrganizationsApi.getOrganizationTypes,
});

export default function* adminOrganizationTypesSagas() {
  yield takeEvery(actions.getOrganizationTypes.request.type, handleGetOrganizationTypesRequest);
}
