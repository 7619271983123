import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const countriesSlice = (state: RootState) => state.entities.countries;

export const countries = selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(countriesSlice);

export const countriesState = selectorsFactory.createStaticKeyWindowStateMemoizedSelector(countriesSlice);

export const hasCountries = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(countriesSlice);
