import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { Channel } from 'api/admin/models';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Channel>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const channelsReducer = createReducer<State, Actions>(initialState, {
  [actions.getChannels.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getChannels.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getChannels.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getChannels.reset.type]: actionsHandlers.handleGetManyReset,
});

export default channelsReducer;
