export const buildPath = (...paths) => paths.join('/');

export const toJson = (res) => res.json();

export const toFormData = (form) =>
  Object.keys(form)
    .filter((key) => form[key] !== undefined)
    .reduce((formData, key) => {
      formData.append(key, form[key]);
      return formData;
    }, new FormData());

export const toQueryString = (params) =>
  Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => {
      const keyURI = encodeURIComponent(key);
      const paramURI = encodeURIComponent(params[key]);
      return `${keyURI}=${paramURI}`;
    })
    .join('&');
