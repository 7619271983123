import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { EventSponsorsDisplay } from 'api/admin/models';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventSponsorsDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventSponsorsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventSponsors.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventSponsors.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventSponsors.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventSponsors.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminEventSponsor.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminEventSponsor.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminEventSponsor.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminEventSponsor.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminEventSponsor.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminEventSponsor.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminEventSponsor.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminEventSponsor.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminEventSponsor.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEventSponsor.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEventSponsor.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminEventSponsorsReducer;
