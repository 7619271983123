const eventDetailsKey = [
  'categories',
  'target_ages',
  'types',
  'start_at',
  'end_at',
  'how_to_participate_non_registered',
  'how_to_participate_registered',
  'livestream_link',
  'whats_included_tags',
  'description',
  'contact_name',
  'contact_phone',
  'contact_email',
  'images',
];

const eventDetailsMap = {
  categorySettings: ['categories', 'target_ages', 'types'],
  dateTime: ['start_at', 'end_at'],
  features: ['whats_included_tags'],
  description: ['description'],
  contacts: ['contact_name', 'contact_phone', 'contact_email'],
};

const virtualEventDetailsMap = {
  livestream: ['how_to_participate_non_registered', 'how_to_participate_registered', 'livestream_link'],
};

// todo: api doesn't validate address fields
const addressEventDetailsMap = {};

const isGeneralError = (error) => Array.isArray(error) && error[0].message && error[0].code;

const getGeneralErrors = (errors) =>
  Object.keys(errors).reduce((generalErrors, key) => {
    if (!eventDetailsKey.includes(key) && isGeneralError(errors[key])) generalErrors.push(errors[key]);
    return generalErrors;
  }, []);

const getRequired = (array, errors) => array.some((key) => (errors[key] ? errors[key][0].code : false));

const getEventDetails = (map, event, errors) =>
  Object.keys(map).reduce((obj, eventKey) => {
    const arrayOfInvalidKey = map[eventKey].filter((key) => !(event && event[key]));
    if (arrayOfInvalidKey.length) {
      obj[eventKey] = {
        required: getRequired(map[eventKey], errors),
      };
    }

    return obj;
  }, {});

const getEventDetailsImage = (eventImages, eventVideos, errors) => {
  const { images = [] } = errors;
  const eventDetailsImageMap = ['cover', 'gallery'];
  const heroImageIndex = eventImages.findIndex(({ main }) => main);
  const galleryIndex = eventImages.findIndex(({ main }) => !main);

  if (heroImageIndex > -1) {
    eventDetailsImageMap.splice(eventDetailsImageMap.indexOf('cover'), 1);
  }

  if (galleryIndex > -1 || eventVideos?.length > 0) {
    eventDetailsImageMap.splice(eventDetailsImageMap.indexOf('gallery'), 1);
  }

  return eventDetailsImageMap.reduce(
    (obj, item) => ({
      ...obj,
      [item]: {
        required: images.some((error) => (error[item] ? error[item].code : false)),
      },
    }),
    {},
  );
};

const getTranslationsCount = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key].message) return acc + 1;
    return acc + getTranslationsCount(obj[key]);
  }, 0);

const getTranslationsErrorsCount = (errors) => {
  const { translation = {} } = errors;
  const { event = {}, ...translations } = translation;
  return Object.keys(event).length + getTranslationsCount(translations);
};

const getEventDetailsErrors = (errors) =>
  Object.keys(errors).reduce((obj, key) => {
    if (eventDetailsKey.includes(key)) obj[key] = errors[key];
    return obj;
  }, {});

export const getPublishErrors = (event, error, eventImages) => {
  let errors = {};
  try {
    if (error?.response?.responseText) errors = JSON.parse(error.response.responseText);
  } catch (e) {}

  const eventDetailsErrors = getEventDetailsErrors(errors);
  return {
    eventDetailsInfo: getEventDetails(eventDetailsMap, event, eventDetailsErrors),
    eventDetailsVirtual: getEventDetails(virtualEventDetailsMap, event, eventDetailsErrors),
    eventDetailsAddress: getEventDetails(addressEventDetailsMap, event, eventDetailsErrors),
    eventDetailsImage: getEventDetailsImage(eventImages, event?.videos, eventDetailsErrors),
    generalErrors: getGeneralErrors(errors),
    translationErrorsCount: getTranslationsErrorsCount(errors),
    hasTicketsErrors: Boolean(errors.tickets),
    hasAddonsErrors: Boolean(errors.addons),
    hasErrors: Boolean(error),
  };
};

export const getDisabledFieldsByStatuses = (event, fieldsByStatuses) =>
  Object.keys(fieldsByStatuses).reduce(
    (obj, key) => ({ ...obj, [key]: fieldsByStatuses[key].includes(event?.status_display) }),
    {},
  );
