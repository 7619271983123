import { EventImageDisplay } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventImageDisplay>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminEventImagesReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventImages.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventImages.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventImages.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventImages.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.createAdminEventImage.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminEventImage.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminEventImage.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminEventImage.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminEventImage.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminEventImage.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminEventImage.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminEventImage.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminEventImage.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEventImage.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEventImage.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminEventImagesReducer;
