import { AdditionalinstructionTranslation } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<AdditionalinstructionTranslation>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminAdditionalInstructionTranslationsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminAdditionalInstructionTranslations.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminAdditionalInstructionTranslations.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminAdditionalInstructionTranslations.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminAdditionalInstructionTranslations.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.updateAdminAdditionalInstructionTranslation.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminAdditionalInstructionTranslation.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminAdditionalInstructionTranslation.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminAdditionalInstructionTranslation.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default adminAdditionalInstructionTranslationsReducer;
