import { combineReducers } from 'redux';
import ordersReducer from './ordersReducer';
import orderReducer from './orderReducer';
import resendOrderEmailReducer from './resendOrderEmailReducer';

export default combineReducers({
  entitiesState: ordersReducer,
  entityState: orderReducer,
  resendOrderEmail: resendOrderEmailReducer,
});
