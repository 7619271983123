import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationImagesSlice = (state: RootState) => state.entities.adminOrganizationImages;

export const adminOrganizationImagesState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationImagesSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationImages = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminOrganizationImagesSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationImageCreatingState =
  selectorsFactory.createCreatingStateSelector(adminOrganizationImagesSlice);

export const adminOrganizationImageUpdatingStateById =
  selectorsFactory.createUpdatingStateByIdSelector(adminOrganizationImagesSlice);

export const adminOrganizationHeroImage = createSelector(adminOrganizationImages, (images) =>
  images.find((image) => image.main),
);

export const adminOrganizationLogoImage = createSelector(adminOrganizationImages, (images) =>
  images.find((image) => image.logo),
);

export const adminOrganizationGalleryImages = createSelector(adminOrganizationImages, (images) =>
  images.filter((image) => !image.logo && !image.main),
);
