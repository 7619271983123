import { OrganizationImageTranslationDisplay } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { GetAdminOrganizationImageTranslationParamsType, ReplaceOrganizationImageTranslationParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminOrganizationImagesTranslation = actionsFactories.createGetManyActionsFactory<
  EntityType<OrganizationImageTranslationDisplay>,
  GetAdminOrganizationImageTranslationParamsType
>()(
  'GET_ADMIN_ORGANIZATION_IMAGES_TRANSLATION_REQUEST',
  'GET_ADMIN_ORGANIZATION_IMAGES_TRANSLATION_SUCCESS',
  'GET_ADMIN_ORGANIZATION_IMAGES_TRANSLATION_FAILURE',
  'GET_ADMIN_ORGANIZATION_IMAGES_TRANSLATION_RESET',
  keyWindowNames.adminOrganizationImageTranslation,
);

export const replaceAdminOrganizationImageTranslation = actionsFactories.createUpdateOneActionsFactory<
  EntityType<OrganizationImageTranslationDisplay>,
  ReplaceOrganizationImageTranslationParamsType
>()(
  'REPLACE_ADMIN_ORGANIZATION_IMAGE_TRANSLATION_REQUEST',
  'REPLACE_ADMIN_ORGANIZATION_IMAGE_TRANSLATION_SUCCESS',
  'REPLACE_ADMIN_ORGANIZATION_IMAGE_TRANSLATION_FAILURE',
  'REPLACE_ADMIN_ORGANIZATION_IMAGE_TRANSLATION_RESET',
);
