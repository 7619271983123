import { call } from 'redux-saga/effects';
import { Action } from 'store/utils';
import { AnyFunction } from 'types';

export const checkOrders = <T extends { order?: number }>(entities: T[]) => {
  for (let i = 0; i < entities.length - 1; i += 1) {
    if (entities[i].order >= entities[i + 1].order) {
      return false;
    }
  }

  return true;
};

export const calcOrder = <T extends { order?: number }>(entities: T[], index: number, spread: number, offset = 0) => {
  const left = index === 0 ? 0 : entities[index - 1].order;
  const right = index + 1 === entities.length ? entities[index - 1].order + spread : entities[index + 1].order;
  return Math.ceil((left + right - offset) / 2) + offset;
};

export const createChangeOrderHandler = <
  A extends Action<string, { oldIndex: number; newIndex: number }>,
  E extends { order?: number },
>(args: {
  spread: number;
  offset?: number;
  getEntities: (params: Omit<A['payload'], 'oldIndex' | 'newIndex'>) => E[] | Generator<unknown, E[]>;
  updateEntityOrder: (entity: E, order: number, params: Omit<A['payload'], 'oldIndex' | 'newIndex'>) => void;
}) =>
  function* handleChangeOrder(action: A) {
    const { spread, offset = 0, getEntities, updateEntityOrder } = args;
    const { oldIndex, newIndex, ...params } = action.payload;
    if (oldIndex === newIndex) return;

    const entities: E[] = yield call(getEntities as AnyFunction, params);
    const sortedEntities = entities.slice().sort((a, b) => a.order - b.order);

    const [entity] = sortedEntities.splice(oldIndex, 1);
    sortedEntities.splice(newIndex, 0, entity);

    sortedEntities[newIndex] = {
      ...sortedEntities[newIndex],
      order: calcOrder(sortedEntities, newIndex, spread, offset),
    };

    if (checkOrders(sortedEntities)) {
      yield call(updateEntityOrder as AnyFunction, sortedEntities[newIndex], sortedEntities[newIndex].order, params);
    } else {
      for (let i = 0; i < sortedEntities.length; i += 1) {
        yield call(updateEntityOrder as AnyFunction, sortedEntities[i], (i + 1) * spread + offset, params);
      }
    }
  };
