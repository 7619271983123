import React from 'react';

const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;

/* eslint-disable react/no-danger */
const renderFacebookScript = () =>
  FACEBOOK_APP_ID && (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.fbAsyncInit = function() {
            FB.init({
              appId: '${FACEBOOK_APP_ID}',
              autoLogAppEvents: true,
              xfbml: true,
              version: 'v10.0'
            });
          };
          `,
        }}
      />
      <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />
    </>
  );

export default renderFacebookScript;
