import { OrganizationGenderStatistic } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationGenderStatistic>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrganizationGenderStatisticReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationGenderStatistic.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminOrganizationGenderStatistic.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminOrganizationGenderStatistic.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminOrganizationGenderStatistic.reset.type]: actionsHandlers.handleGetOneReset,
});

export default adminOrganizationGenderStatisticReducer;
