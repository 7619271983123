import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const spreedlyPaymentMethodsSlice = (state: RootState) => state.entities.spreedly.spreedlyPaymentMethods;

export const getSpreedlyPaymentMethods =
  selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(spreedlyPaymentMethodsSlice);

export const setDefaultSpreedlyPaymentMethodState = (state: RootState) =>
  state.entities.spreedly.setDefaultSpreedlyPaymentMethod;

export const getSpreedlyPaymentMethodsState =
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(spreedlyPaymentMethodsSlice);

export const hasSpreedlyPaymentMethods =
  selectorsFactory.createStaticKeyWindowHasEntitiesSelector(spreedlyPaymentMethodsSlice);

export const spreedlyPaymentMethodsCreatingState =
  selectorsFactory.createCreatingStateSelector(spreedlyPaymentMethodsSlice);

export const spreedlyPaymentMethodsUpdatingState =
  selectorsFactory.createUpdatingStateByIdSelector(spreedlyPaymentMethodsSlice);

export const getTempSpreedlyPaymentMethod = (state: RootState) =>
  spreedlyPaymentMethodsSlice(state).tempSpreedlyPaymentMethod;
