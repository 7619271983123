import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminEventVideosSlice = (state: RootState) => state.entities.adminEventVideos;

export const adminEventVideosState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventVideosSlice,
  keyWindowNames.adminEvent,
);

export const adminEventVideos = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminEventVideosSlice,
  keyWindowNames.adminEvent,
);

export const adminEventVideoCreatingState = selectorsFactory.createCreatingStateSelector(adminEventVideosSlice);
