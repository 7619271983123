import { combineReducers } from 'redux';
import joinReducer from './joinReducer';
import loginMagicLinkReducer from './loginMagicLinkReducer';
import refreshTokenReducer from './refreshTokenReducer';
import sendMagicLinkReducer from './sendMagicLinkReducer';
import sessionUserReducer from './sessionUserReducer';
import socialLinksReducer from './socialLinksReducer';
import loginPasswordReducer from './loginPasswordReducer';
import sendResetPasswordReducer from './sendResetPassword';
import resetPasswordReducer from './resetPasswordReducer';
import setPasswordReducer from './setPasswordReducer';
import getVerificationCodeReducer from './getVerificationCodeReducer';
import checkVerificationCodeReducer from './checkVerificationCodeReducer';

export default combineReducers({
  join: joinReducer,
  loginMagicLink: loginMagicLinkReducer,
  loginPassword: loginPasswordReducer,
  refreshToken: refreshTokenReducer,
  sendMagicLink: sendMagicLinkReducer,
  sendResetPassword: sendResetPasswordReducer,
  resetPassword: resetPasswordReducer,
  setPassword: setPasswordReducer,
  sessionUser: sessionUserReducer,
  socialLinks: socialLinksReducer,
  getVerificationCode: getVerificationCodeReducer,
  checkVerificationCode: checkVerificationCodeReducer,
});
