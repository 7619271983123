// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomQuestionOption } from '.';

/**
 * @export
 * @interface AllAttendeesCustomQuestion
 */
export interface AllAttendeesCustomQuestion {
  /**
   * @type {number}
   * @memberof AllAttendeesCustomQuestion
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof AllAttendeesCustomQuestion
   */
  text: string;
  /**
   * @type {string}
   * @memberof AllAttendeesCustomQuestion
   */
  description?: string;
  /**
   * @type {string}
   * @memberof AllAttendeesCustomQuestion
   */
  type?: AllAttendeesCustomQuestionTypeEnum;
  /**
   * @type {Array<CustomQuestionOption>}
   * @memberof AllAttendeesCustomQuestion
   */
  options: Array<CustomQuestionOption>;
  /**
   * @type {boolean}
   * @memberof AllAttendeesCustomQuestion
   */
  readonly is_event_specific_question?: boolean;
  /**
   * @type {string}
   * @memberof AllAttendeesCustomQuestion
   */
  readonly slug?: string;
  /**
   * @type {number}
   * @memberof AllAttendeesCustomQuestion
   */
  order?: number;
  /**
   * @type {number}
   * @memberof AllAttendeesCustomQuestion
   */
  readonly default_question?: number;
  /**
   * @type {number}
   * @memberof AllAttendeesCustomQuestion
   */
  readonly many_time_question?: number;
  /**
   * @type {boolean}
   * @memberof AllAttendeesCustomQuestion
   */
  is_required?: boolean;
  /**
   * @type {string}
   * @memberof AllAttendeesCustomQuestion
   */
  readonly is_for_all_attendees?: string;
  /**
   * @type {boolean}
   * @memberof AllAttendeesCustomQuestion
   */
  is_hidden?: boolean;
  /**
   * @type {number}
   * @memberof AllAttendeesCustomQuestion
   */
  readonly created_by?: number;
  /**
   * @type {number}
   * @memberof AllAttendeesCustomQuestion
   */
  readonly updated_by?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum AllAttendeesCustomQuestionTypeEnum {
  Single = 'single',
  Multiple = 'multiple',
  Paragraph = 'paragraph',
  Text = 'text',
  Custom = 'custom',
}
