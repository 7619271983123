import jwtDecode from 'jwt-decode';
import { serializeCookie } from 'utils/cookie';
import { Access, DecodedAuthCookie, Expirable, SessionUser } from '../types';

export const sessionCookieOptions = { path: '/' } as const;

export const userCookieName = 'user';

export const authCookieNames = ['access', 'refresh'] as const;

export const sessionCookieNames = [userCookieName, ...authCookieNames] as const;

export const expiredSessionCookie = sessionCookieNames.map((name) =>
  serializeCookie(name, '', {
    ...sessionCookieOptions,
    maxAge: 0,
    path: '/',
  }),
);

export const expirableSessionCookieOptions = (exp: number) => ({
  ...sessionCookieOptions,
  expires: new Date(exp * 1000),
});

export const isAlive = (expirable: Expirable) => expirable.exp > Date.now() / 1000;

export const isSameUser = (user: SessionUser, access: Access) => user.id === access.user_id;

export const hasAuthCookie = (parseCookie: Record<string, string>) =>
  authCookieNames.every((name) => parseCookie[name]);

export const decodeUserCookie = (parsedCookie: Record<string, string>) => {
  try {
    return JSON.parse(decodeURIComponent(parsedCookie.user)) as SessionUser;
  } catch (e) {}
};

export const decodeAuthCookie = (parsedCookie: Record<string, string>) =>
  authCookieNames.reduce((decoded, name) => {
    decoded[name] = jwtDecode(parsedCookie[name]);
    return decoded;
  }, {} as DecodedAuthCookie);

export const serializeUserCookie = (user: SessionUser, exp: number) => {
  const options = expirableSessionCookieOptions(exp);
  return serializeCookie(userCookieName, encodeURIComponent(JSON.stringify(user)), options);
};

export const serializeAuthCookie = (parsedCookie: Record<string, string>) =>
  authCookieNames.reduce((serialized, name) => {
    const value = parsedCookie[name];
    const decoded = jwtDecode<Expirable>(value);
    const options = expirableSessionCookieOptions(decoded.exp);
    serialized.push(serializeCookie(name, value, options));
    return serialized;
  }, [] as string[]);
