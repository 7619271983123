import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationChannelsSlice = (state: RootState) => state.entities.adminOrganizationChannels;

export const adminOrganizationChannelsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationChannelsSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationChannels = selectorsFactory.createDynamicKeyWindowEntitiesSelector(
  adminOrganizationChannelsSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationChannelCreatingState =
  selectorsFactory.createCreatingStateSelector(adminOrganizationChannelsSlice);
