import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/features/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialSingleFeatureStateFactory();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const joinReducer = createReducer<State, Actions>(initialState, {
  [actions.join.request.type]: actionsHandlers.handleSingleFeatureRequest,
  [actions.join.success.type]: actionsHandlers.handleSingleFeatureSuccess,
  [actions.join.failure.type]: actionsHandlers.handleSingleFeatureFailure,
  [actions.join.reset.type]: actionsHandlers.handleSingleFeatureReset,
});

export default joinReducer;
