import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { SeverityType } from './shared';
import * as actions from './actions';

type NotificationState = {
  message: string;
  severity: SeverityType;
  open: boolean;
};

const initialState: Record<string, NotificationState> = {};

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const notificationsReducer = createReducer<State, Actions>(initialState, {
  [actions.showNotification.type]: (draft, action) => {
    draft[action.payload.id] = {
      message: action.payload.message,
      severity: action.payload.severity,
      open: true,
    };
  },
  [actions.hideNotification.type]: (draft, action) => {
    draft[action.payload.id].open = false;
  },
  [actions.removeNotification.type]: (draft, action) => {
    delete draft[action.payload.id];
  },
});

export default notificationsReducer;
