import { select, takeEvery } from 'redux-saga/effects';
import { getDate, set, addMinutes, format, isSameMonth } from 'date-fns';
import { AdminOrganizationsApi } from 'api';
import { getDateOffset } from 'utils/transform';
import { formatDateFns } from 'utils/format';
import { sagasHandlersFactory } from 'store/entities/utils';
import { AdminEventsSelectors } from 'store/selectors';
import { TicketOrderType } from 'components/screens/AdminEventDashboardScreen';
import * as actions from './actions';

const handleGetAdminEventDashboard = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminEventDashboard,
  request: AdminOrganizationsApi.getAdminEventDashboard,
  requestArgsBuilder: (action) => {
    const { organizationId } = action.payload.params;
    return [organizationId, action.payload.id];
  },
  transformResponse: function* transformResponse(response, action) {
    const { tickets_ordered = [] } = response;

    const ISODate = addMinutes(new Date(), new Date().getTimezoneOffset());
    const currentDay = getDate(ISODate);

    const eventState: any = yield select(AdminEventsSelectors.adminEventStateById, Number(action.payload.id));

    const ticketsOrdered: TicketOrderType[] = [];
    for (let i = 1; i <= currentDay; i += 1) {
      ticketsOrdered.push({
        date: format(set(ISODate, { date: i }), 'yyyy-MM-dd'),
        tickets_count: 0,
      });
    }

    tickets_ordered.forEach((order) => {
      const ticketDay = getDate(addMinutes(new Date(order.date), new Date().getTimezoneOffset()));
      ticketsOrdered[ticketDay - 1] = order;
    });

    const handleGetDashboardStartDate = () => {
      const eventCreationDate = getDateOffset(eventState?.entity?.created);
      const transformedCreationDate = formatDateFns(eventCreationDate, 'yyyy-MM-dd');
      const transformedMonthStartDate = formatDateFns(getDateOffset(ticketsOrdered[0].date), 'yyyy-MM-dd');

      return isSameMonth(eventCreationDate, new Date()) ? transformedCreationDate : transformedMonthStartDate;
    };

    const startDate = handleGetDashboardStartDate();
    const startDateIndex = ticketsOrdered.findIndex((item: any) => item.date === startDate);
    const slicedTicketsOrdered = ticketsOrdered.slice(startDateIndex);

    return { id: action.payload.id, ...response, tickets_ordered: slicedTicketsOrdered };
  },
});

export default function* adminEventDashboard() {
  yield takeEvery(actions.getAdminEventDashboard.request.type, handleGetAdminEventDashboard);
}
