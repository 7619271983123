import { CreateAction, createAction, PrepareAction } from './createAction';

export type CreateAsyncActions<
  T1 extends string = string,
  PA1 extends PrepareAction = never,
  T2 extends string = string,
  PA2 extends PrepareAction = never,
  T3 extends string = string,
  PA3 extends PrepareAction = never,
  T4 extends string = never,
  PA4 extends PrepareAction = never,
> = {
  request: CreateAction<T1, PA1>;
  success: CreateAction<T2, PA2>;
  failure: CreateAction<T3, PA3>;
} & ([T4] extends [never]
  ? {}
  : {
      reset: CreateAction<T4, PA4>;
    });

/**
 * A utility function to create async actions creators for the given actions type strings.
 *
 *  @param type1 The action type to use for request action.
 *  @param prepareAction1 (optional) a method that takes any number of arguments and returns request action.
 *  @param type2 The action type to use for success action.
 *  @param prepareAction2 (optional) a method that takes any number of arguments and returns success action.
 *  @param type3 The action type to use for failure action.
 *  @param prepareAction3 (optional) a method that takes any number of arguments and returns failure action.
 *  @param type4 (optional) The action type to use for reset action.
 *  @param prepareAction4 (optional) a method that takes any number of arguments and returns reset action.
 *  @returns The async actions creators
 */
export const createAsyncActions = <
  T1 extends string,
  PA1 extends PrepareAction,
  T2 extends string,
  PA2 extends PrepareAction,
  T3 extends string,
  PA3 extends PrepareAction,
  T4 extends string = never,
  PA4 extends PrepareAction = never,
>(
  type1: T1,
  prepareAction1: PA1,
  type2: T2,
  prepareAction2: PA2,
  type3: T3,
  prepareAction3: PA3,
  type4?: T4,
  prepareAction4?: PA4,
): CreateAsyncActions<T1, PA1, T2, PA2, T3, PA3, T4, PA4> => ({
  request: createAction(type1, prepareAction1),
  success: createAction(type2, prepareAction2),
  failure: createAction(type3, prepareAction3),
  ...(type4 &&
    ({
      reset: createAction(type4, prepareAction4),
    } as any)),
});
