import { call, takeLeading } from 'redux-saga/effects';
import { AdminUserRolesApi, AdminUsersApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { GetAction } from 'store/types';
import Router from 'next/router';
import { routes } from 'utils/routing';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminUsersRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminUsers,
  request: AdminUsersApi.getAdminUsers,
  notifyError: false,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminUsersState, params);

    return { params: { ...rest, page, page_size } };
  },
});

const handleGetAdminOrgUsersRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrgUsers,
  request: AdminUsersApi.getAdminOrgUsers,
  notifyError: false,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organizationId, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminOrgUsersState, params);

    return [organizationId, { params: { ...rest, page, page_size } }];
  },
});

const handleGetAdminOrgInvitesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrgUserInvites,
  request: AdminUsersApi.getAdminOrgInvites,
  notifyError: false,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organizationId, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminOrgUsersState, params);

    return [organizationId, { params: { ...rest, page, page_size } }];
  },
});

const handleCreateAdminUserRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminUser,
  request: AdminUsersApi.createUser,
});

function* handleRouteNextPage(action: GetAction<typeof actions.createAdminUser.success>) {
  const route = routes.adminManageAdmin(action.payload.entity.id);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!action.payload.params?.dontRedirect) yield call(Router.push, route.href);
}

const handleGetAdminUserRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminUser,
  request: AdminUsersApi.getAdminUser,
  notifyError: false,
});

const handleGetAdminOrgUserRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrgUser,
  request: AdminUsersApi.getAdminOrgUser,
  notifyError: false,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [id, organizationId];
  },
});

const handleRemoveAdminOrgUserRoleRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.removeAdminOrgUserRole,
  request: AdminUsersApi.removeAdminOrgUserRole,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [id, organizationId];
  },
});

const handleRemoveAdminOrgUserInviteRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.removeAdminOrgUserInvite,
  request: AdminUsersApi.removeAdminOrgUserInvite,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [id, organizationId];
  },
});

const handleGetAdminUserOrganizationsPermissionsRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminUserOrganizationsPermissions,
  request: AdminUsersApi.getAdminUserGrantedPermissions,
  requestArgsBuilder: (action) => {
    const { id } = action.payload;
    return { params: { model: 'organization', object_id: id } };
  },
  transformResponse: (response, action) => ({ ...response, id: action.payload.id }),
});

const handleGetAdminUserEventsPermissionsRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminUserEventsPermissions,
  request: AdminUsersApi.getAdminUserGrantedPermissions,
  requestArgsBuilder: (action) => {
    const { id } = action.payload;
    return { params: { model: 'event', object_id: id } };
  },
  transformResponse: (response, action) => ({ ...response, id: action.payload.id }),
});

const handleGetUserRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminUser,
  request: AdminUsersApi.getUser,
});

const handleRemoveAdminUserRoleRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.removeAdminUserRole,
  request: AdminUserRolesApi.removeAdminUserRole,
});

const handleGetAdminUsersGroupsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminUsersGroups,
  request: AdminUsersApi.getAdminUsersGroups,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;

    return { params: { ...params, page: 1, page_size: 100 } };
  },
});

export default function* adminUsersSagas() {
  yield takeLeading(actions.getAdminUsers.request.type, handleGetAdminUsersRequest);
  yield takeLeading(actions.getAdminOrgUsers.request.type, handleGetAdminOrgUsersRequest);
  yield takeLeading(actions.getAdminOrgUserInvites.request.type, handleGetAdminOrgInvitesRequest);
  yield takeLeading(actions.createAdminUser.request.type, handleCreateAdminUserRequest);
  yield takeLeading(actions.createAdminUser.success.type, handleRouteNextPage);
  yield takeLeading(actions.getAdminUser.request.type, handleGetAdminUserRequest);
  yield takeLeading(actions.getAdminUser.failure.type, handleGetUserRequest);
  yield takeLeading(actions.getAdminOrgUser.request.type, handleGetAdminOrgUserRequest);
  yield takeLeading(actions.getAdminUsersGroups.request.type, handleGetAdminUsersGroupsRequest);
  yield takeLeading(actions.removeAdminUserRole.request.type, handleRemoveAdminUserRoleRequest);
  yield takeLeading(actions.removeAdminOrgUserRole.request.type, handleRemoveAdminOrgUserRoleRequest);
  yield takeLeading(actions.removeAdminOrgUserInvite.request.type, handleRemoveAdminOrgUserInviteRequest);
  yield takeLeading(
    actions.getAdminUserOrganizationsPermissions.request.type,
    handleGetAdminUserOrganizationsPermissionsRequest,
  );
  yield takeLeading(actions.getAdminUserEventsPermissions.request.type, handleGetAdminUserEventsPermissionsRequest);
}
