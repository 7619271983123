import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { actionsFactories as featuresActionsFactories } from 'store/features/utils';
import {
  CreateSpreedlyPaymentMethodsType,
  SetDefaultSpreedlyPaymentMethodParamsType,
  SpreedlyPaymentMethodType,
  UpdateSpreedlyPaymentMethodsType,
} from './types';
import { defaultKeyWindowName } from '../shared';

export const getSpreedlyPaymentMethods = actionsFactories.createGetManyActionsFactory<
  never,
  SpreedlyPaymentMethodType
>()(
  'GET_SPREEDLY_PAYMENT_METHODS_REQUEST',
  'GET_SPREEDLY_PAYMENT_METHODS_SUCCESS',
  'GET_SPREEDLY_PAYMENT_METHODS_FAILURE',
  'GET_SPREEDLY_PAYMENT_METHODS_RESET',
);

export const createSpreedlyPaymentMethods = actionsFactories.createCreateOneActionsFactory<
  any,
  CreateSpreedlyPaymentMethodsType
>()(
  'CREATE_SPREEDLY_PAYMENT_METHOD_REQUEST',
  'CREATE_SPREEDLY_PAYMENT_METHOD_SUCCESS',
  'CREATE_SPREEDLY_PAYMENT_METHOD_FAILURE',
  'CREATE_SPREEDLY_PAYMENT_METHOD_RESET',
  defaultKeyWindowName,
);

export const updateSpreedlyPaymentMethods = actionsFactories.createUpdateOneActionsFactory<
  any,
  UpdateSpreedlyPaymentMethodsType
>()(
  'UPDATE_SPREEDLY_PAYMENT_METHOD_REQUEST',
  'UPDATE_SPREEDLY_PAYMENT_METHOD_SUCCESS',
  'UPDATE_SPREEDLY_PAYMENT_METHOD_FAILURE',
  'UPDATE_SPREEDLY_PAYMENT_METHOD_RESET',
);

export const setDefaultSpreedlyPaymentMethod =
  featuresActionsFactories.createSingleFeatureActionsFactory<SetDefaultSpreedlyPaymentMethodParamsType>()(
    'SET_DEFAULT_SPREEDLY_PAYMENT_METHOD_REQUEST',
    'SET_DEFAULT_SPREEDLY_PAYMENT_METHOD_SUCCESS',
    'SET_DEFAULT_SPREEDLY_PAYMENT_METHOD_FAILURE',
    'SET_DEFAULT_SPREEDLY_PAYMENT_METHOD_RESET',
  );

export const deleteSpreedlyPaymentMethod = actionsFactories.createDeleteOneActionsFactory()(
  'DELETE_SPREEDLY_PAYMENT_METHOD_REQUEST',
  'DELETE_SPREEDLY_PAYMENT_METHOD_SUCCESS',
  'DELETE_SPREEDLY_PAYMENT_METHOD_FAILURE',
);

export const setTempSpreedlyPaymentMethod = createAction('SET_TEMP_SPREEDLY_PAYMENT_METHOD', (paymentMethod: any) => ({
  payload: {
    paymentMethod,
  },
}));

export const resetTempSpreedlyPaymentMethod = createAction('RESET_TEMP_SPREEDLY_PAYMENT_METHOD');
