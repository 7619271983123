import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const ticketsSlice = (state: RootState) => state.entities.tickets;

export const ticketsById = selectorsFactory.createEntitiesByIdSelector(ticketsSlice);

export const ticketById = selectorsFactory.createEntityByIdSelector(ticketsSlice);

export const eventTicketsEntities = selectorsFactory.createDynamicKeyWindowEntitiesMemoizedSelector(
  ticketsSlice,
  keyWindowNames.eventTickets,
);

export const eventTicketsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  ticketsSlice,
  keyWindowNames.eventTickets,
);
