import { takeEvery } from 'redux-saga/effects';
import { AdminOrganizationTranslationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminOrganizationTranslationRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationTranslation,
  request: AdminOrganizationTranslationsApi.getOrganizationTranslation,
});

const handleUpdateAdminOrganizationTranslationRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminOrganizationTranslation,
  request: AdminOrganizationTranslationsApi.updateOrganizationTranslation,
});

export default function* adminOrganizationTranslationSagas() {
  yield takeEvery(actions.getAdminOrganizationTranslation.request.type, handleGetAdminOrganizationTranslationRequest);
  yield takeEvery(
    actions.updateAdminOrganizationTranslation.request.type,
    handleUpdateAdminOrganizationTranslationRequest,
  );
}
