import { PaymentMethod } from '@stripe/stripe-js';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import { StripePaymentMethodType } from '../types';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<StripePaymentMethodType>();
const initialState = stateFactory.getInitialState({
  tempStripePaymentMethod: null as PaymentMethod,
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const stripePaymentMethodsReducer = createReducer<State, Actions>(initialState, {
  [actions.getStripePaymentMethods.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getStripePaymentMethods.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getStripePaymentMethods.failure.type]: actionsHandlers.handleGetManyFailure,

  [actions.createStripePaymentMethods.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createStripePaymentMethods.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createStripePaymentMethods.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createStripePaymentMethods.reset.type]: actionsHandlers.handleCreateOneReset,

  [actions.updateStripePaymentMethods.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateStripePaymentMethods.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.updateStripePaymentMethods.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateStripePaymentMethods.success.type]: (draft, action) => {
    const { params, entity } = action.payload;
    Object.keys(draft.byId).forEach((id) => {
      const paymentMethod = draft.byId[id];
      if (paymentMethod.id === params.payment_method_id) {
        paymentMethod.billing_details = entity.billing_details;
        entity.id = paymentMethod.id;
      }
      if (params.is_default) {
        paymentMethod.is_default = paymentMethod.id === params.payment_method_id;
      }
    });
    actionsHandlers.handleUpdateOneSuccess(draft, action);
  },

  [actions.deleteStripePaymentMethod.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteStripePaymentMethod.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteStripePaymentMethod.failure.type]: actionsHandlers.handleDeleteOneFailure,

  [actions.setTempStripePaymentMethod.type]: (draft, action) => {
    draft.tempStripePaymentMethod = action.payload.paymentMethod;
  },
  [actions.resetTempStripePaymentMethod.type]: (draft) => {
    draft.tempStripePaymentMethod = null;
  },
  [actions.setDefaultStripePaymentMethod.success.type]: (draft, action) => {
    const { params } = action.payload;

    Object.keys(draft.byId).forEach((id) => {
      const paymentMethod = draft.byId[id];

      if (paymentMethod.id === params.payment_method_id) {
        paymentMethod.is_default = true;
      } else {
        paymentMethod.is_default = false;
      }
    });
  },
});

export default stripePaymentMethodsReducer;
