import { CalendarSettings } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { CommunityCalendarIcsFeedLinkType, PublicCalendarIcsFeedLinkType } from 'api/types';
import { actionsFactories as featureActionsFactories } from 'store/features/utils';
import {
  GetCalendarSettingsParamsType,
  GetCommunityCalendarIcsFeedLinkParamsType,
  GetPublicCalendarIcsFeedLinkParamsType,
} from './types';

export const getCalendarSettingsList = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<CalendarSettings>
>()(
  'GET_CALENDAR_SETTINGS_LIST_REQUEST',
  'GET_CALENDAR_SETTINGS_LIST_SUCCESS',
  'GET_CALENDAR_SETTINGS_LIST_FAILURE',
  'GET_CALENDAR_SETTINGS_LIST_RESET',
);

export const getCalendarSettings = actionsFactories.createGetOneActionsFactory<
  EntityType<CalendarSettings>,
  GetCalendarSettingsParamsType
>()(
  'GET_CALENDAR_SETTINGS_REQUEST',
  'GET_CALENDAR_SETTINGS_SUCCESS',
  'GET_CALENDAR_SETTINGS_FAILURE',
  'GET_CALENDAR_SETTINGS_RESET',
);

export const updateCalendarSettings = actionsFactories.createUpdateOneActionsFactory<
  EntityType<CalendarSettings>,
  GetCalendarSettingsParamsType
>()(
  'UPDATE_CALENDAR_SETTINGS_REQUEST',
  'UPDATE_CALENDAR_SETTINGS_SUCCESS',
  'UPDATE_CALENDAR_SETTINGS_FAILURE',
  'UPDATE_CALENDAR_SETTINGS_RESET',
);

export const getPublicCalendarIcsFeedLink = featureActionsFactories.createMultipleFeatureActionsFactory<
  GetPublicCalendarIcsFeedLinkParamsType,
  PublicCalendarIcsFeedLinkType
>()(
  'GET_PUBLIC_CALENDAR_ICS_FEED_LINK_REQUEST',
  'GET_PUBLIC_CALENDAR_ICS_FEED_LINK_SUCCESS',
  'GET_PUBLIC_CALENDAR_ICS_FEED_LINK_FAILURE',
  'GET_PUBLIC_CALENDAR_ICS_FEED_LINK_RESET',
);

export const getCommunityCalendarIcsFeedLink = featureActionsFactories.createMultipleFeatureActionsFactory<
  GetCommunityCalendarIcsFeedLinkParamsType,
  CommunityCalendarIcsFeedLinkType
>()(
  'GET_COMMUNITY_CALENDAR_ICS_FEED_LINK_REQUEST',
  'GET_COMMUNITY_CALENDAR_ICS_FEED_LINK_SUCCESS',
  'GET_COMMUNITY_CALENDAR_ICS_FEED_LINK_FAILURE',
  'GET_COMMUNITY_CALENDAR_ICS_FEED_LINK_RESET',
);
