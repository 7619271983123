// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ImageDisplay, OrganizationMarket, UserShort } from '.';

/**
 * @export
 * @interface OrganizationAdminList
 */
export interface OrganizationAdminList {
  /**
   * @type {number}
   * @memberof OrganizationAdminList
   */
  readonly id?: number;
  /**
   * @type {string}
   * @memberof OrganizationAdminList
   */
  title: string;
  /**
   * @type {ImageDisplay}
   * @memberof OrganizationAdminList
   */
  image: ImageDisplay;
  /**
   * @type {OrganizationMarket}
   * @memberof OrganizationAdminList
   */
  org_market?: OrganizationMarket;
  /**
   * @type {number}
   * @memberof OrganizationAdminList
   */
  readonly parent?: number;
  /**
   * @type {string}
   * @memberof OrganizationAdminList
   */
  status?: OrganizationAdminListStatusEnum;
  /**
   * @type {string}
   * @memberof OrganizationAdminList
   */
  readonly status_display?: string;
  /**
   * @type {UserShort}
   * @memberof OrganizationAdminList
   */
  created_by?: UserShort;
  /**
   * @type {string}
   * @memberof OrganizationAdminList
   */
  readonly events_count?: string;
  /**
   * @type {boolean}
   * @memberof OrganizationAdminList
   */
  is_test?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationAdminListStatusEnum {
  Draft = 'draft',
  Pending = 'pending',
  Published = 'published',
  Rejected = 'rejected',
  Hidden = 'hidden',
  Terminated = 'terminated',
}
