import { Market } from 'api/general/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';

export const getMarkets = actionsFactories.createGetPaginatedManyActionsFactory<EntityType<Market>>()(
  'GET_MARKETS_REQUEST',
  'GET_MARKETS_SUCCESS',
  'GET_MARKETS_FAILURE',
  'GET_MARKETS_RESET',
);
