import { get, post } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_ZOOM } from './constants';

const CONFIRM_OAUTH_AUTHORIZATION = 'confirm-oauth-authorization';
const REQUEST_OAUTH_AUTHORIZATION = 'request-oauth-authorization';
const REMOVE_OAUTH_AUTHORIZATION = 'remove-oauth-authorization';
const EVENTS = 'events';
const MEETINGS = 'meetings';
const WEBINARS = 'webinars';

export const getMeetings = (options: any) => get(buildPath(ADMIN_ZOOM, EVENTS, MEETINGS), options).then(toJson);

export const getWebinars = (options: any) => get(buildPath(ADMIN_ZOOM, EVENTS, WEBINARS), options).then(toJson);

export const confirmOauthAuthorization = (options: any) =>
  get(buildPath(ADMIN_ZOOM, CONFIRM_OAUTH_AUTHORIZATION), options).then(toJson);

export const requestOauthAuthorization = (options: any) =>
  get(buildPath(ADMIN_ZOOM, REQUEST_OAUTH_AUTHORIZATION), options).then(toJson);

export const removeOauthAuthorization = (options: any) =>
  post(buildPath(ADMIN_ZOOM, REMOVE_OAUTH_AUTHORIZATION), options);
