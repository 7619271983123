import createSagaMiddleware from 'redux-saga';
import { getContext } from 'redux-saga/effects';
import { Context, createWrapper } from 'next-redux-wrapper';
import ReduxThunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { AppContext } from 'next/app';
import { i18n } from 'i18n';
import { createPipeline } from 'store/utils/pipeline';
import { rollbar } from 'components/common/Rollbar';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

export const makeStore = (context: Context) => {
  const { ctx } = context as AppContext;
  const apiPipeline = createPipeline();
  const sagaMiddleware = createSagaMiddleware({
    onError: (...args) => {
      rollbar.error(...args);
    },
  });

  apiPipeline.push(function* translationApiMiddleware(options: any) {
    const i18n: any = yield getContext('i18n');
    if (i18n && i18n.language) {
      options.headers = {
        ...options.headers,
        'Accept-Language': i18n.language,
      };
    }
  });

  sagaMiddleware.setContext({
    i18n: (ctx?.req as any)?.i18n || i18n,
    next: (context as AppContext).ctx || context,
    apiPipeline,
  });

  const middleware = [ReduxThunk, sagaMiddleware];
  const store = configureStore({ reducer: rootReducer, middleware });

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const wrapper = createWrapper(makeStore);
