import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const adminEventGenderStatisticsSlice = (state: RootState) => state.entities.adminEventGenderStatistics;

export const adminEventGenderStatisticsUpdatingStateById = selectorsFactory.createUpdatingStateByIdSelector(
  adminEventGenderStatisticsSlice,
);

export const adminEventGenderStatisticsStateById = selectorsFactory.createEntityStateByIdSelector(
  adminEventGenderStatisticsSlice,
);
