import { call, takeEvery } from 'redux-saga/effects';
import { AdminEventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import { getNextMediaOrder } from '../adminEvents/sagas';

const handleGetAdminEventVideosRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventVideos,
  request: AdminEventsApi.getVideos,
  requestArgsBuilder: (action) => action.payload.params.eventId,
});

const handleCreateAdminEventVideoRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminEventVideo,
  request: AdminEventsApi.createVideo,
  requestArgsBuilder: function* builder(action): any {
    const { params, entity } = action.payload;
    const { eventId } = params;
    const order = yield call(getNextMediaOrder, params);
    return [eventId, { body: { ...entity, order } }];
  },
});

const handleUpdateAdminEventVideoRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminEventVideo,
  request: AdminEventsApi.updateVideo,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

const handleDeleteAdminEventVideoRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEventVideo,
  request: AdminEventsApi.deleteVideo,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

export default function* adminEventVideosSagas() {
  yield takeEvery(actions.getAdminEventVideos.request.type, handleGetAdminEventVideosRequest);
  yield takeEvery(actions.createAdminEventVideo.request.type, handleCreateAdminEventVideoRequest);
  yield takeEvery(actions.updateAdminEventVideo.request.type, handleUpdateAdminEventVideoRequest);
  yield takeEvery(actions.deleteAdminEventVideo.request.type, handleDeleteAdminEventVideoRequest);
}
