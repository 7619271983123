import { call, fork, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import { AdminEventsApi } from 'api';
import { AdminTicketDetails, TicketUpdate } from 'api/admin/models';
import { isServer } from 'utils/next';
import { AdminEventsSelectors } from 'store/selectors';
import { sagasHandlersFactory } from 'store/entities/utils';
import { createFakeRequest } from 'store/entities/sagasHelpers';
import {
  createLoadUnsavedIds,
  createSyncUpdateUnsaved,
  getUnsaved,
  setUnsaved,
} from 'store/entities/adminEvents/unsavedUtils';
import * as actions from './actions';
import * as selectors from './selectors';

const UNSAVED_TYPE = 'ticket';

const ticketDetailsToTicketUpdate = (ticket: AdminTicketDetails): TicketUpdate => ({
  title: ticket.title,
  description: ticket.description,
  total_amount: ticket.total_amount,
  min_amount_per_order: ticket.min_amount_per_order,
  max_amount_per_order: ticket.max_amount_per_order,
  price: ticket.price,
  tax_rate: ticket.tax_rate,
  is_hidden: ticket.is_hidden,
  sales_start: ticket.sales_start,
  sales_end: ticket.sales_end,
  livestream_only: ticket.livestream_only,
  unique_attendees_only: ticket.unique_attendees_only,
  skip_attendees_question: ticket.skip_attendees_question,
  order: ticket.order,
});

const ticketUpdateToTicketDetails = (ticket: TicketUpdate): AdminTicketDetails => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { questions, ...rest } = ticket;
  return rest;
};

const loadUnsavedIds = createLoadUnsavedIds({
  type: UNSAVED_TYPE,
  action: actions.addUnsavedAdminTicket,
});

export const updateUnsavedAdminTicket = createSyncUpdateUnsaved({
  type: UNSAVED_TYPE,
  actions: actions.updateAdminTicket,
  entityBuilder: ticketDetailsToTicketUpdate,
});

const handleGetAdminEventTicketsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminTickets,
  request: AdminEventsApi.getTickets,
  requestArgsBuilder: (action) => action.payload.params.eventId,
  transformResponse: function* transformResponse(response) {
    const unsavedIds: number[] = yield select(selectors.getUnsavedIds);
    return response.reduce((entities, entity, index) => {
      if (unsavedIds.includes(entity.id)) {
        const data: any = getUnsaved(UNSAVED_TYPE, entity.id);
        if (data) entities[index] = data;
      }

      return entities.sort((a: AdminTicketDetails, b: AdminTicketDetails) => (a.order ? a.order - b.order : 1));
    }, response.slice());
  },
});

const handleCreateAdminEventTicketRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminTicket,
  request: createFakeRequest(AdminEventsApi.createTicket)({
    shouldCallFakeRequest: function* shouldCallFakeRequest(eventId) {
      return yield select(AdminEventsSelectors.isPublished, Number(eventId));
    },
    fakeRequest: function* fakeRequest(...args): any {
      const result = yield call(AdminEventsApi.createTicket, ...args);
      yield put(actions.addIgnoredUnsavingAdminTicket(result.id));
      return result;
    },
  }),
  requestArgsBuilder: (action) => {
    const { params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, { body: entity }];
  },
});

const handleDeleteAdminTicketRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminTicket,
  request: AdminEventsApi.deleteTicket,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

const handleUpdateAdminTicketRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminTicket,
  request: AdminEventsApi.partialUpdateTicket,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
  notifyError: false,
});

const handleRuntimeUpdateAdminTicket = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminTicket,
  request: createFakeRequest(AdminEventsApi.partialUpdateTicket)({
    shouldCallFakeRequest: function* shouldCallFakeRequest(eventId, ticketId) {
      const isPublished: boolean = yield select(AdminEventsSelectors.isPublished, Number(eventId));
      const hasIgnoredUnsavingId: boolean = yield select(selectors.hasIgnoredUnsavingId, Number(ticketId));
      return isPublished && !hasIgnoredUnsavingId;
    },
    fakeRequest: function* fakeRequest(eventId, ticketId, options): any {
      const id = Number(ticketId);
      yield put(actions.addUnsavedAdminTicket(id));
      const adminTicket: AdminTicketDetails = yield select(selectors.adminTicketById, id);
      const updates = yield call(ticketUpdateToTicketDetails, options.body);
      const unsaved = { ...adminTicket, ...updates };
      yield call(setUnsaved, UNSAVED_TYPE, id, unsaved);

      return unsaved;
    },
  }),
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

export default function* adminTicketsSagas() {
  yield takeLeading(actions.getAdminTickets.request.type, handleGetAdminEventTicketsRequest);
  yield takeEvery(actions.createAdminTicket.request.type, handleCreateAdminEventTicketRequest);
  yield takeEvery(actions.updateAdminTicket.request.type, handleUpdateAdminTicketRequest);
  yield takeEvery(actions.deleteAdminTicket.request.type, handleDeleteAdminTicketRequest);
  yield fork(handleRuntimeUpdateAdminTicket);

  if (!isServer()) {
    yield fork(function* wait() {
      yield take(HYDRATE);
      yield call(loadUnsavedIds);
    });
  }
}
