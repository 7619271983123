// tslint:disable
/**
 * JLIVE ADMIN API
 * JLIVE ADMIN API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomQuestionOption } from '.';

/**
 * @export
 * @interface CustomQuestion
 */
export interface CustomQuestion {
  /**
   * @type {number}
   * @memberof CustomQuestion
   */
  readonly id?: number;
  /**
   * @type {number}
   * @memberof CustomQuestion
   */
  readonly ticket?: number | null;
  /**
   * @type {number}
   * @memberof CustomQuestion
   */
  addon?: number | null;
  /**
   * @type {string}
   * @memberof CustomQuestion
   */
  text: string;
  /**
   * @type {string}
   * @memberof CustomQuestion
   */
  description?: string;
  /**
   * @type {string}
   * @memberof CustomQuestion
   */
  type?: CustomQuestionTypeEnum;
  /**
   * @type {Array<CustomQuestionOption>}
   * @memberof CustomQuestion
   */
  options: Array<CustomQuestionOption>;
  /**
   * @type {boolean}
   * @memberof CustomQuestion
   */
  readonly is_event_specific_question?: boolean;
  /**
   * @type {string}
   * @memberof CustomQuestion
   */
  readonly slug?: string;
  /**
   * @type {number}
   * @memberof CustomQuestion
   */
  order?: number;
  /**
   * @type {number}
   * @memberof CustomQuestion
   */
  readonly default_question?: number;
  /**
   * @type {number}
   * @memberof CustomQuestion
   */
  readonly many_time_question?: number;
  /**
   * @type {boolean}
   * @memberof CustomQuestion
   */
  is_required?: boolean;
  /**
   * @type {boolean}
   * @memberof CustomQuestion
   */
  is_hidden?: boolean;
  /**
   * @type {string}
   * @memberof CustomQuestion
   */
  readonly is_for_all_attendees?: string;
  /**
   * @type {boolean}
   * @memberof CustomQuestion
   */
  readonly can_be_saved_to_profile?: boolean;
  /**
   * @type {number}
   * @memberof CustomQuestion
   */
  readonly created_by?: number;
  /**
   * @type {number}
   * @memberof CustomQuestion
   */
  readonly updated_by?: number;
  /**
   * @type {boolean}
   * @memberof CustomQuestion
   */
  is_options_custom_text_allowed?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum CustomQuestionTypeEnum {
  Single = 'single',
  Multiple = 'multiple',
  Paragraph = 'paragraph',
  Text = 'text',
  Custom = 'custom',
}
