import { put, takeEvery } from 'redux-saga/effects';
import { StripePaymentMethodsApi } from 'api';
import { GetAction } from 'store/types';
import { sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from 'store/features/utils';
import * as actions from './actions';

const handleGetStripePaymentMethodsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getStripePaymentMethods,
  request: StripePaymentMethodsApi.getStripePaymentMethods,
});

const handleSetDefaultStripePaymentMethodRequest = featureSagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.setDefaultStripePaymentMethod,
  request: StripePaymentMethodsApi.setDefaultStripePaymentMethod,
  requestArgsBuilder: (action) => ({ body: action.payload.params }),
});

const handleDeleteStripePaymentMethodRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteStripePaymentMethod,
  request: StripePaymentMethodsApi.deleteStripePaymentMethods,
  requestArgsBuilder: (action) => ({
    body: {
      payment_method_id: action.payload.id,
    },
  }),
});

const handleCreateStripePaymentMethodsRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createStripePaymentMethods,
  request: StripePaymentMethodsApi.createStripePaymentMethods,
  requestArgsBuilder: (action) => ({
    body: {
      payment_method_id: action.payload.entity.id,
    },
  }),
});

const handleUpdateStripePaymentMethodsRequest = sagasHandlersFactory.createPartialUpdateOneRequestHandler({
  actions: actions.updateStripePaymentMethods,
  request: StripePaymentMethodsApi.updateStripePaymentMethods,
  requestArgsBuilder: (action) => ({
    body: action.payload.entity,
  }),
});

function* handleCreateStripePaymentMethodsSuccess(
  action: GetAction<typeof actions.createStripePaymentMethods.success>,
) {
  const { params, entity } = action.payload;
  const { is_default } = params;

  if (is_default) {
    yield put(actions.setDefaultStripePaymentMethod.request({ params: { payment_method_id: entity.id } }));
  }
}

export default function* stripePaymentMethodsSagas() {
  yield takeEvery(actions.getStripePaymentMethods.request.type, handleGetStripePaymentMethodsRequest);
  yield takeEvery(actions.setDefaultStripePaymentMethod.request.type, handleSetDefaultStripePaymentMethodRequest);
  yield takeEvery(actions.deleteStripePaymentMethod.request.type, handleDeleteStripePaymentMethodRequest);
  yield takeEvery(actions.updateStripePaymentMethods.request.type, handleUpdateStripePaymentMethodsRequest);
  yield takeEvery(actions.createStripePaymentMethods.request.type, handleCreateStripePaymentMethodsRequest);
  yield takeEvery(actions.createStripePaymentMethods.success.type, handleCreateStripePaymentMethodsSuccess);
}
