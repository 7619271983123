import { combineReducers } from 'redux';
import adminOrganizationReducer from './adminOrganizationReducer';
import adminOrganizationsReducer from './adminOrganizationsReducer';
import adminOrganizationIcsFeedLinkReducer from './adminOrganizationIcsFeedLinkReducer';
import adminOrganizationSettingReducer from './adminOrganizationSettingReducer';
import stripeConfirmOauthReducer from './stripeConfirmOauthReducer';
import stripeOauthReducer from './stripeOauthReducer';
import stripeDashboardLinkReducer from './stripeDashboardLinkReducer';
import updateAdminOrganizationDetailsReducer from './updateAdminOrganizationDetailsReducer';
import adminAllOrganizationsReducer from './adminAllOrganizationsReducer';
import adminCreateOrganizationReducer from './adminCreateOrganizationReducer';
import adminOrgSettingSpecificEventReducer from './adminOrgSettingSpecificEventReducer';
import spreedlyReducer from './spreedlyReducer';

export default combineReducers({
  details: adminOrganizationReducer,
  list: adminOrganizationsReducer,
  adminOrganizationIcsFeedLink: adminOrganizationIcsFeedLinkReducer,
  adminOrganizationSetting: adminOrganizationSettingReducer,
  stripe: stripeOauthReducer,
  spreedly: spreedlyReducer,
  adminOrgSettingSpecificEvent: adminOrgSettingSpecificEventReducer,
  confirmStripe: stripeConfirmOauthReducer,
  stripeDashboardLink: stripeDashboardLinkReducer,
  updateAdminOrganizationDetails: updateAdminOrganizationDetailsReducer,
  adminAllOrganizations: adminAllOrganizationsReducer,
  adminCreateOrganizations: adminCreateOrganizationReducer,
});
