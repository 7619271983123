import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const organizationsSlice = (state: RootState) => state.entities.organizations.organizationsState;

export const searchOrganizationsSlice = (state: RootState) => state.entities.organizations.searchOrganizationsState;

export const getOrganizationsSlice = (state: RootState) => state.entities.organizations.getOrganizationsState;

export const organizationById = selectorsFactory.createEntityByIdSelector(organizationsSlice);

export const organizationStateById = selectorsFactory.createEntityStateByIdSelector(organizationsSlice);

export const searchOrganizationsState = () =>
  selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
    searchOrganizationsSlice,
    keyWindowNames.searchOrganizations,
  );

export const getOrganizationsState = () =>
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(getOrganizationsSlice, keyWindowNames.getOrganizations);

export const getOrganizationsPagination = selectorsFactory.createStaticKeyWindowPaginationSelector(
  getOrganizationsSlice,
  keyWindowNames.getOrganizations,
);
