import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import {
  createGetUnsavedIdsSelector,
  createHasIgnoredUnsavingIdSelector,
  createHasUnsavedIdSelector,
} from 'store/entities/adminEvents/unsavedUtils';

import * as keyWindowNames from './keyWindowNames';

export const adminAddonsSlice = (state: RootState) => state.entities.adminAddons;

export const adminAddonById = selectorsFactory.createEntityByIdSelector(adminAddonsSlice);

export const adminAddonUpdatingErrorById = selectorsFactory.createUpdatingErrorByIdSelector(adminAddonsSlice);

export const adminAddonIsUpdatingById = selectorsFactory.createIsUpdatingByIdSelector(adminAddonsSlice);

export const adminAddonsChangingState = selectorsFactory.createChangingStateSelector(adminAddonsSlice);

export const adminEventAddonsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminAddonsSlice,
  keyWindowNames.adminEventAddons,
);

export const adminEventAddonsIds = selectorsFactory.createDynamicKeyWindowEntitiesIdsSelector(
  adminAddonsSlice,
  keyWindowNames.adminEventAddons,
);

export const adminAddonCreatingState = selectorsFactory.createCreatingStateSelector(adminAddonsSlice);

export const hasAdminEventAddons = selectorsFactory.createDynamicKeyWindowHasEntitiesSelector(
  adminAddonsSlice,
  keyWindowNames.adminEventAddons,
);

export const getUnsavedIds = createGetUnsavedIdsSelector(adminAddonsSlice);

export const hasUnsavedId = createHasUnsavedIdSelector(adminAddonsSlice);

export const hasIgnoredUnsavingId = createHasIgnoredUnsavingIdSelector(adminAddonsSlice);
