import { actionsFactories } from '../utils';
import { ActivationReCaptchaRequest, CheckReCaptchaRequest } from './types';

export const checkReCaptcha = actionsFactories.createSingleFeatureActionsFactory<CheckReCaptchaRequest>()(
  'CHECK_RECAPTCHA_REQUEST',
  'CHECK_RECAPTCHA_SUCCESS',
  'CHECK_RECAPTCHA_FAILURE',
  'CHECK_RECAPTCHA_RESET',
);

export const updateAdminOrganizationReCaptcha =
  actionsFactories.createSingleFeatureActionsFactory<ActivationReCaptchaRequest>()(
    'ADMIN_ORG_ACTIVATE_RECAPTCHA_REQUEST',
    'ADMIN_ORG_ACTIVATE_RECAPTCHA_SUCCESS',
    'ADMIN_ORG_ACTIVATE_RECAPTCHA_FAILURE',
    'ADMIN_ORG_ACTIVATE_RECAPTCHA_RESET',
  );
