import { takeEvery } from 'redux-saga/effects';
import { AdminEventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleAddAdminEventOrganizationMentionsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventOrganizationMentions,
  request: AdminEventsApi.getOrganizationMentions,
  requestArgsBuilder: (action) => action.payload.params.eventId,
});

const handleAddAdminEventOrganizationMentionRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.addAdminEventOrganizationMention,
  request: AdminEventsApi.addOrganizationMention,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { eventId, organizationId } = params;
    return [eventId, { body: { organization: organizationId } }];
  },
});

const handleDeleteAdminEventOrganizationMentionRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEventOrganizationMention,
  request: AdminEventsApi.deleteOrganizationMention,
  requestArgsBuilder: (action) => {
    const { id: organizationMentionId, params } = action.payload;
    const { eventId } = params;
    return [eventId, organizationMentionId];
  },
});

export default function* adminEventOrganizationMentionsSagas() {
  yield takeEvery(
    actions.getAdminEventOrganizationMentions.request.type,
    handleAddAdminEventOrganizationMentionsRequest,
  );

  yield takeEvery(
    actions.deleteAdminEventOrganizationMention.request.type,
    handleDeleteAdminEventOrganizationMentionRequest,
  );

  yield takeEvery(actions.addAdminEventOrganizationMention.request.type, handleAddAdminEventOrganizationMentionRequest);
}
