export const eventDetailsFieldsNames = ['title', 'description'] as const;

export const eventAddressFieldsNames = [
  'address1',
  'address2',
  'city',
  'address_name',
  'address_additional_info',
] as const;

export const eventVirtualFieldsNames = ['how_to_participate_non_registered', 'how_to_participate_registered'] as const;

export const orderItemFieldsNames = ['title', 'description'] as const;

export const questionFieldsNames = ['text', 'description'] as const;

export const questionOptionFieldsNames = ['text'] as const;

export type EventDetailsFieldsNames = (typeof eventDetailsFieldsNames)[number];

export type EventAddressFieldsNames = (typeof eventAddressFieldsNames)[number];

export type EventVirtualFieldsNames = (typeof eventVirtualFieldsNames)[number];

export type OrderItemFieldsNames = (typeof orderItemFieldsNames)[number];

export type QuestionFieldsNames = (typeof questionFieldsNames)[number];

export type QuestionOptionFieldsNames = (typeof questionOptionFieldsNames)[number];
