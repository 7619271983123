import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { DonationStateType } from '../types';
import * as actions from '../actions';

const initialState: DonationStateType = {
  option: null,
  custom_donation_amount: 0,
  requirement_already_met: false,
  enable_behalf_of: false,
  on_behalf_of: '',
};

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const handleResetState = () => initialState;

const donationReducer = createReducer<State, Actions>(initialState, {
  [actions.changeDonation.type]: (draft, action) => {
    const { value } = action.payload;
    Object.keys(value).forEach((key) => {
      (draft as any)[key] = (value as any)[key];
    });
  },
  [actions.resetDonation.type]: handleResetState,
  [actions.resetCreatingOrder.type]: handleResetState,
});

export default donationReducer;
