import { EventOrganizationMentionDisplayBase } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as keyWindowNames from './keyWindowNames';
import {
  CreateAdminEventOrganizationMentionParamsType,
  GetAdminEventOrganizationMentionsParamsType,
  UpdateAdminEventOrganizationMentionParamsType,
} from './types';

export const getAdminEventOrganizationMentions = actionsFactories.createGetManyActionsFactory<
  EntityType<EventOrganizationMentionDisplayBase>,
  GetAdminEventOrganizationMentionsParamsType
>()(
  'GET_ADMIN_EVENT_ORGANIZATION_MENTIONS_REQUEST',
  'GET_ADMIN_EVENT_ORGANIZATION_MENTIONS_SUCCESS',
  'GET_ADMIN_EVENT_ORGANIZATION_MENTIONS_FAILURE',
  'GET_ADMIN_EVENT_ORGANIZATION_MENTIONS_RESET',
  keyWindowNames.adminEvent,
);

export const addAdminEventOrganizationMention = actionsFactories.createCreateOneActionsFactory<
  EntityType<EventOrganizationMentionDisplayBase>,
  CreateAdminEventOrganizationMentionParamsType
>()(
  'ADD_ADMIN_EVENT_ORGANIZATION_MENTION_REQUEST',
  'ADD_ADMIN_EVENT_ORGANIZATION_MENTION_SUCCESS',
  'ADD_ADMIN_EVENT_ORGANIZATION_MENTION_FAILURE',
  'ADD_ADMIN_EVENT_ORGANIZATION_MENTION_RESET',
  keyWindowNames.adminEvent,
);

export const deleteAdminEventOrganizationMention =
  actionsFactories.createDeleteOneActionsFactory<UpdateAdminEventOrganizationMentionParamsType>()(
    'DELETE_ADMIN_EVENT_ORGANIZATION_MENTION_REQUEST',
    'DELETE_ADMIN_EVENT_ORGANIZATION_MENTION_SUCCESS',
    'DELETE_ADMIN_EVENT_ORGANIZATION_MENTION_FAILURE',
  );
